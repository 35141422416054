import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableFooter,
  TablePagination,
  Tooltip,
  Button,
  IconButton,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import Axios from "axios";
import Container from "src/layout/Container";
import { MoreVertical, Search } from "react-feather";
import Scrollbar from "react-perfect-scrollbar";
import ReviewedPatientMoreDetails from "./ReviewedPatientMoreDetails";
import { DatePicker } from "@material-ui/pickers";

const ReviewedPatient = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [list, setList] = useState([]);
  const [listRd, setListRd] = useState(false);

  let totalPF = 0;

  // const [orderCount , serOrderCount ] = useState()

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [dialog, setDialog] = useState({ open: false, data: null });

  const [to, setTo] = useState(new Date());
  const [from, setFrom] = useState(new Date());

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getReviewedList = useCallback(async () => {
    try {
      const params = {
        token,
        user_id,
      };

      const request = await Axios.get("telerad/patients/getreviewed-list", {
        params,
      });
      setList(request.data);
      setListRd(true);
    } catch (error) {
      Notify.requestError(error);
    }
  }, [token, user_id]);

  const handleFilterRecords = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    var error = [];

    if (parseInt(from.getTime()) > parseInt(to.getTime())) {
      console.log("from.getTime()", from.getTime());
      console.log("to.getTime()", to.getTime());

      error = "error";
      Notify.fieldInvalid("from");
    }

    if (error.length > 0) {
      console.log("form has an error");
    } else {
      try {
        const request = await Axios.post(
          "telerad/patients/getreviewed-list/filteredbydate",
          formdata
        );
        setList(request.data);
        setListRd(true);
      } catch (error) {
        Notify.requestError(error);
      }
    }
  };

  useEffect(() => {
    getReviewedList();
  }, [getReviewedList]);

  const calculatePF = (amount) => {
    totalPF += amount;

    return Notify.convertToNumber(amount);
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Report",
        items: [{ name: "Dashboard", path: "/clinic/app/telerad" }],
      }}
      title={"Reviewed Patient"}
    >
      <Paper component={Box} p={2}>
        <Box mb={2} display="flex">
          <Box flexGrow={1}>
            <Typography variant="h6" color="textSecondary">
              Reviewed Patients Report
            </Typography>
          </Box>

          <Box>
            <form onSubmit={handleFilterRecords}>
              <Box display="flex">
                <Box>
                  <DatePicker
                    fullWidth
                    margin="dense"
                    label="From"
                    format="MM/dd/yyyy"
                    value={from}
                    onChange={(date) => setFrom(date)}
                    inputVariant="outlined"
                    autoOk
                    name="date_from"
                  />
                </Box>
                <Box mx={1}>
                  <DatePicker
                    fullWidth
                    margin="dense"
                    label="To"
                    format="MM/dd/yyyy"
                    value={to}
                    onChange={(date) => setTo(date)}
                    inputVariant="outlined"
                    autoOk
                    name="date_to"
                  />
                </Box>
                <Box mt={1}>
                  <Button variant="contained" color="primary" type="submit">
                    <Search />
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>

        <Box>
          <TableContainer>
            <Scrollbar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> Date </TableCell>
                    <TableCell> Patient </TableCell>
                    <TableCell> Order </TableCell>
                    <TableCell> Shots </TableCell>
                    <TableCell> Hospital </TableCell>
                    <TableCell> PF </TableCell>
                    <TableCell> Action </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {listRd ? (
                    list.length > 0 ? (
                      (rowsPerPage > 0
                        ? list.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : list
                      ).map((data, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {" "}
                              {Notify.dateTimeConvert(data.created_at)}{" "}
                            </TableCell>
                            <TableCell> {data.patient_name} </TableCell>
                            <TableCell style={{ maxWidth: 200 }}>
                              <Typography noWrap>
                                {data.imaging_order}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              {" "}
                              {data.number_shots}{" "}
                            </TableCell>
                            <TableCell align="right">
                              {" "}
                              {data.hospital_name}{" "}
                            </TableCell>
                            <TableCell align="right">
                              {calculatePF(parseFloat(data.number_shots) * 50)}
                            </TableCell>

                            <TableCell align="center">
                              <Tooltip arrow title="Details">
                                <IconButton
                                  color="primary"
                                  onClick={() =>
                                    setDialog({ open: true, data })
                                  }
                                >
                                  <MoreVertical />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={76}>
                          {" "}
                          <Typography color="error">
                            {" "}
                            No patient recorded.{" "}
                          </Typography>{" "}
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={76}>
                        {" "}
                        <Typography color="primary">
                          {" "}
                          please wait...{" "}
                        </Typography>{" "}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell> Total </TableCell>
                    <TableCell align="right"> {totalPF} </TableCell>
                    <TableCell />
                  </TableRow>
                </TableFooter>
              </Table>
            </Scrollbar>
            <TablePagination
              labelRowsPerPage="List"
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={list.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
      </Paper>

      {/* more detauls */}
      {dialog.open && (
        <ReviewedPatientMoreDetails
          open={dialog.open}
          imagingorder={dialog.data}
          close={() => setDialog({ open: false, imagingid: null })}
        />
      )}
    </Container>
  );
};

export default ReviewedPatient;
