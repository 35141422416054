import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { Dialog, DialogTitle, DialogContent, Zoom, DialogActions, Button, Box, TextField, Grid, Select, MenuItem, FormControl, InputLabel, CircularProgress } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Axios from 'axios';
import Notify from '../../notification/Notify';

const PersonalHistoryFamilyEditDialog = ({ open, close, selectedId, title, history }) => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')

    const [editHistory, setEditHistory] = useState([])
    const [isAlive, setIsAlive] = useState('Alive')

    const [isProcess, setIsProcess] = useState(false)

    const getHistoryById = useCallback(() => {
        var params = {
            token: token,
            user_id: user_id,
            id: selectedId,
        }
        Axios.get('patient/information/personal/family-historybyId', { params })
            .then((response) => {
                const data = response.data;
                if (data.length > 0) {
                    setEditHistory(data)
                    setIsAlive(data[0].is_deceased === 0 ? 'Alive' : 'Deceased')
                }
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id, selectedId]);

    const handleUpdateHistory = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target);
        formdata.set('token', token);
        formdata.set('username', username);
        formdata.set('user_id', user_id);
        formdata.set('id', selectedId)

        var error = [];

        if (formdata.get('fullname').length === 0 || formdata.get('fullname').trim() === '') {
            error = 'error';
            Notify.fieldRequired('fullname');
        }

        if (formdata.get('address').length === 0 || formdata.get('address').trim() === '') {
            error = 'error';
            Notify.fieldRequired('address');
        }

        if (formdata.get('birthday').length === 0 || formdata.get('birthday').trim() === '') {
            error = 'error';
            Notify.fieldRequired('birthday');
        }

        if (formdata.get('occupation').length === 0 || formdata.get('occupation').trim() === '') {
            error = 'error';
            Notify.fieldRequired('occupation');
        }

        if (formdata.get('healthstatus').length === 0 || formdata.get('healthstatus').trim() === '') {
            error = 'error';
            Notify.fieldRequired('healthstatus');
        }

        if (formdata.get('isalive').length === 0 || formdata.get('isalive').trim() === '') {
            error = 'error';
            Notify.fieldRequired('isalive');
        }

        if (formdata.get('password').length === 0 || formdata.get('password').trim() === '') {
            error = 'error';
            Notify.fieldRequired('password');
        }

        if (error.length > 0) {
            console.warn("form has an error")
        } else {
            setIsProcess(true)
            Axios.post('patient/information/personal/family-historyupdateByid', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'success') {
                        history()
                        e.target.reset()
                        close()
                        Notify.successRequest('update history')
                    }
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                }).catch(error => {
                    Notify.requestError(error);
                }).finally(() => {
                    setIsProcess(false)
                })
        }
    }

    useEffect(() => {

        getHistoryById()

    }, [selectedId, getHistoryById])

    return (
        <Fragment>
            <Dialog
                transitionDuration={1000}
                TransitionComponent={Zoom}
                open={open}
                disableBackdropClick={true}
                onClose={close}
            >
                <form onSubmit={handleUpdateHistory}>
                    <DialogTitle style={{ textTransform: 'capitalize' }}> edit {title} </DialogTitle>
                    <DialogContent dividers>
                        {
                            editHistory.length > 0 ?
                                <Fragment>
                                    <Box mb={1}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Fullname"
                                                    defaultValue={editHistory[0].name}
                                                    margin="dense"
                                                    variant="outlined"
                                                    name="fullname"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    label="Address"
                                                    defaultValue={editHistory[0].address}
                                                    margin="dense"
                                                    variant="outlined"
                                                    name="address"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box mb={1}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Birthday"
                                                    defaultValue={editHistory[0].birthday}
                                                    margin="dense"
                                                    variant="outlined"
                                                    type="date"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        inputProps: {
                                                            readOnly: true
                                                        }
                                                    }}
                                                    name="birthday"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Occupation"
                                                    defaultValue={editHistory[0].occupation}
                                                    margin="dense"
                                                    variant="outlined"
                                                    name="occupation"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box mb={1}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Health Status"
                                                    defaultValue={editHistory[0].health_status}
                                                    margin="dense"
                                                    variant="outlined"
                                                    name="healthstatus"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="dense"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-outlined-label"
                                                    > Is Alive </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        label="Is Alive "
                                                        value={isAlive}
                                                        onChange={(e) => setIsAlive(e.target.value)}
                                                        name="isalive"
                                                    >
                                                        <MenuItem value={'Alive'}> Alive </MenuItem>
                                                        <MenuItem value={'Deceased'}> Deceased </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box mb={1}>
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            margin="dense"
                                            variant="outlined"
                                            name="password"
                                            type="password"
                                        />
                                    </Box>

                                    <Box
                                        mb={1}
                                        hidden={isAlive === 'Alive' ? true : false}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Deceased Date"
                                                    defaultValue={editHistory[0].is_deceased_date}
                                                    margin="dense"
                                                    variant="outlined"
                                                    type="date"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    name="deceaseddate"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    label="Deceased Reason"
                                                    defaultValue={editHistory[0].is_deceased_reason}
                                                    margin="dense"
                                                    variant="outlined"
                                                    name="deceasedreason"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Fragment>
                                : Notify.noRecord()
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={close}
                            startIcon={<CancelIcon />}
                        >
                            Cancel
                        </Button>

                        <Button
                            startIcon={isProcess ? <CircularProgress color="inherit" size={15} /> : <CheckCircleIcon />}
                            disabled={isProcess}
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>
    )
}

export default PersonalHistoryFamilyEditDialog;