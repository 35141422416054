import { Box, Button, Collapse, Grid, IconButton, Paper, TablePagination, Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PersonalDietForm from './PersonalDietForm';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import Container from 'src/layout/Container';

const PersonalDiet = () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [newdiet, setNewDiet] = useState(false)
    const [personalDietByDate, setPersonalDietByDate] = useState([])
    const [personalDietByDateReady, setPersonalDietByDateReady] = useState(false)
    const [personalDiet, setPersonalDiet] = useState([])
    const [suggestedDietByDate, setSuggestedDietByDate] = useState([])
    const [suggestedDietByDateReady, setSuggestedDietByDateReady] = useState(false)
    const [suggestedDiet, setSuggestedDiet] = useState([])
    const [selectedpid, setSelectedPid] = useState(null)
    const [suggestedpid, setSuggestedPid] = useState(null)
    const [pagePersonal, setPagePersonal] = useState(0);
    const [rowsPerPagePersonal, setRowsPerPagePersonal] = useState(7);
    const [pageSuggested, setPageSuggested] = useState(0);
    const [rowsPerPageSuggested, setRowsPerPageSuggested] = useState(7);
    const [patientID, setPatientID] = useState(null)

    const getPersonalDietByDate = () => {
        var params = {
            token: token,
            user_id: user_id,
            connection: 'online',
        }
        Axios.get('patient/diet/monitoring/personaldiet-listbydate', { params })
            .then((response) => {
                const data = response.data;
                setPersonalDietByDate(data)
                setPatientID(data[0].patient_id)
                setPersonalDietByDateReady(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const getSuggestedDietByDate = () => {
        var params = {
            token: token,
            user_id: user_id,
            connection: 'online',
        }
        Axios.get('patient/diet/monitoring/suggesteddiet-listbydate', { params })
            .then((response) => {
                const data = response.data;
                setSuggestedDietByDate(data)
                setSuggestedDietByDateReady(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const handleSelectedSuggestedDiet = (e) => {
        if (e === 'match') { return null }
        var date = e.currentTarget.getAttribute('created_at');
        var id = e.currentTarget.id;

        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('patient_id', patientID);
        formdata.set('id', id)
        formdata.set('created_at', date)

        Axios.post('patient/diet/monitoring/suggesteddiet-list', formdata)
            .then((response) => {
                const data = response.data;
                setSuggestedDiet(data)
            }).catch(error => {
                Notify.requestError(error);
            });
    }


    const handleSelectedPersonalDiet = (e) => {
        if (e === 'match') { return null }
        var date = e.currentTarget.getAttribute('created_at');
        var id = e.currentTarget.id;
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('patient_id', patientID);
        formdata.set('id', id)
        formdata.set('created_at', date)
        Axios.post('patient/diet/monitoring/personaldiet-bydate', formdata)
            .then((response) => {
                const data = response.data;
                setPersonalDiet(data)
            }).catch(error => {
                Notify.requestError(error);
            })
    }


    const handleChangePagePersonal = (event, newPage) => {
        event.persist()
        setPagePersonal(newPage);
    };

    const handleChangeRowsPerPagePersonal = (event) => {
        setRowsPerPagePersonal(parseInt(event.target.value, 10));
        setPagePersonal(0);
    };


    const handleChangePageSuggested = (event, newPage) => {
        event.persist()
        setPageSuggested(newPage);
    };

    const handleChangeRowsPerPageSuggested = (event) => {
        setRowsPerPageSuggested(parseInt(event.target.value, 10));
        setPageSuggested(0);
    };

    useEffect(() => {
        getPersonalDietByDate()
        getSuggestedDietByDate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'diet',
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' },
                        { name: 'Profile', path: '/clinic/app/patient/profile' }
                    ]
                }}
                title="My Diet Routine"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <Box
                            component={Paper}
                            variant="outlined"
                            p={2}
                        >
                            <Box display="flex">
                                <Box flexGrow={1}>
                                    <Typography variant="subtitle2" color="primary" className={`gtc-uppercase`}>
                                        Suggested Diet
                                </Typography>
                                </Box>
                            </Box>

                            <Box p={1} mt={2}>
                                {
                                    suggestedDietByDateReady ?
                                        suggestedDietByDate.length > 0 ?
                                            suggestedDietByDate.slice(pageSuggested * rowsPerPageSuggested, pageSuggested * rowsPerPageSuggested + rowsPerPageSuggested)
                                                .map((d, d_index) => {
                                                    return (
                                                        <Fragment key={d_index}>
                                                            <Box
                                                                id={d.id}
                                                                display="flex"
                                                            >
                                                                <IconButton
                                                                    color="primary"
                                                                    created_at={d.created_at}
                                                                    id={d.id}
                                                                    onClick={(e) => {
                                                                        setSuggestedPid(suggestedpid === d.id ? null : d.id);
                                                                        setSuggestedDiet([])
                                                                        handleSelectedSuggestedDiet(suggestedpid === d.id ? 'match' : e);
                                                                    }}
                                                                >
                                                                    {suggestedpid === d.id ? (<KeyboardArrowDown />) : (<KeyboardArrowRight />)}
                                                                </IconButton>

                                                                <Box ml={2} mt={2}>
                                                                    <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                                        {Notify.createdAt(d.created_at)}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>

                                                            <Collapse in={suggestedpid === d.id ? true : false}>
                                                                <Box ml={2}>
                                                                    {suggestedDiet.length > 0 ?
                                                                        suggestedDiet.map((data, index) => (
                                                                            <Box key={index}>
                                                                                <Box>
                                                                                    <Typography
                                                                                        color="primary"
                                                                                        variant="caption"
                                                                                        className={`gtc-uppercase`}
                                                                                    >
                                                                                        <b>
                                                                                            {data.meals} on {Notify.createdTime(data.created_at)}
                                                                                        </b>
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box p={1}>
                                                                                    <Box dangerouslySetInnerHTML={{ __html: data.description }} />
                                                                                </Box>
                                                                            </Box>
                                                                        ))
                                                                        : Notify.loading()}
                                                                </Box>
                                                            </Collapse>
                                                        </Fragment>
                                                    )
                                                })
                                            : <Box mt={2}>
                                                <Typography color="secondary">
                                                    No suggested diet
                                            </Typography>
                                            </Box>
                                        :
                                        Notify.loading()
                                }

                                <TablePagination
                                    labelRowsPerPage="List"
                                    rowsPerPageOptions={[7, 20, 50, 100]}
                                    component="div"
                                    count={suggestedDietByDate.length}
                                    rowsPerPage={rowsPerPageSuggested}
                                    page={pageSuggested}
                                    onChangePage={handleChangePageSuggested}
                                    onChangeRowsPerPage={handleChangeRowsPerPageSuggested}
                                />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <Box
                            component={Paper}
                            variant="outlined"
                            p={2}
                        >
                            <Box display="flex">
                                <Box flexGrow={1}>
                                    <Typography variant="subtitle2" color="primary" className={`gtc-uppercase`}>
                                        Personal Diet
                                </Typography>
                                </Box>
                                <Button
                                    color="primary"
                                    onClick={() => setNewDiet(true)}
                                    startIcon={<AddCircleOutlineIcon />}
                                >
                                    Update
                            </Button>
                            </Box>

                            <Box p={1}>
                                {
                                    personalDietByDateReady ?
                                        personalDietByDate.length > 0 ?
                                            personalDietByDate.slice(pagePersonal * rowsPerPagePersonal, pagePersonal * rowsPerPagePersonal + rowsPerPagePersonal)
                                                .map((d, d_index) => {
                                                    return (
                                                        <Fragment key={d_index}>
                                                            <Box
                                                                id={d.id}
                                                                display="flex"
                                                            >
                                                                <IconButton
                                                                    color="primary"
                                                                    created_at={d.created_at}
                                                                    id={d.id}
                                                                    onClick={(e) => {
                                                                        setSelectedPid(selectedpid === d.id ? null : d.id);
                                                                        setPersonalDiet([]);
                                                                        handleSelectedPersonalDiet(selectedpid === d.id ? 'match' : e)
                                                                    }}
                                                                >
                                                                    {selectedpid === d.id ? (<KeyboardArrowDown />) : (<KeyboardArrowRight />)}
                                                                </IconButton>

                                                                <Box ml={2} mt={2}>
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        className={`gtc-uppercase`}
                                                                    >
                                                                        {Notify.createdAt(d.created_at)}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>

                                                            <Collapse in={selectedpid === d.id ? true : false}>
                                                                <Box ml={2}>
                                                                    {
                                                                        personalDiet.length > 0 ?
                                                                            personalDiet.map((data, index) => {
                                                                                return (
                                                                                    <Box key={index} ml={3}>
                                                                                        <Box>
                                                                                            <Typography
                                                                                                color="primary"
                                                                                                variant="caption"
                                                                                                className={`gtc-uppercase`}
                                                                                            >
                                                                                                <b> {data.meals} on {Notify.createdTime(data.created_at)}  </b>
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <div dangerouslySetInnerHTML={{ __html: data.description }} />
                                                                                        </Box>
                                                                                    </Box>
                                                                                )
                                                                            })
                                                                            : Notify.loading()
                                                                    }
                                                                </Box>
                                                            </Collapse>
                                                        </Fragment>
                                                    )
                                                })
                                            : <Typography color="secondary"> No personal diet </Typography>
                                        : Notify.loading()
                                }

                                <TablePagination
                                    labelRowsPerPage="List"
                                    rowsPerPageOptions={[7, 20, 50, 100]}
                                    component="div"
                                    count={personalDietByDate.length}
                                    rowsPerPage={rowsPerPagePersonal}
                                    page={pagePersonal}
                                    onChangePage={handleChangePagePersonal}
                                    onChangeRowsPerPage={handleChangeRowsPerPagePersonal}
                                />
                            </Box>
                        </Box>
                        {/* diet form */}
                        <PersonalDietForm
                            open={newdiet}
                            close={() => setNewDiet(false)}
                            // getPersonalDiet = { getPersonalDiet } 
                            getPersonalDietByDate={getPersonalDietByDate} />
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}

export default PersonalDiet;