import { Paper, Box, Typography, Button } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useCallback, useState } from "react";
import Notify from "src/notification/Notify";

const MedicalCert = ({ patient_id, setPrintDialog, printDialog }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [doctorsInfo, setDoctorsInfo] = useState({ data: [], ready: false });
  const [patientInfo, setPatientInfo] = useState([]);
  const imageLocation = process.env.REACT_APP_API_IMAGE;

  const getDoctorsInfo = useCallback(async () => {
    let response = await Axios.get(
      "patients/rx/printable/doctors/header-details",
      {
        params: {
          user_id,
          token,
        },
      }
    );

    let result = response.data;
    console.log(result);
    setDoctorsInfo({ data: result, ready: true });
  }, [token, user_id]);

  const getPatientInfo = useCallback(() => {
    const params = {
      token,
      patient_id: patient_id,
    };

    Axios.get("doctor/patient/patient-information", { params })
      .then((response) => {
        const data = response.data;
        setPatientInfo(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, patient_id]);

  useEffect(() => {
    getDoctorsInfo();
    getPatientInfo();
  }, [getDoctorsInfo, getPatientInfo]);

  return (
    <Paper component={Box} elevation={0}>
      <Box p={2}>
        {doctorsInfo.data.length > 0 && (
          <Box align="center" display="flex" justifyContent="center" mb={1}>
            <Box mr={2} align="center" width={200}>
              {doctorsInfo.data[0].logo && (
                <img
                  alt=""
                  src={`${imageLocation}/doctors/rxlogo/${doctorsInfo.data[0].logo}`}
                  style={{
                    maxWidth: 100,
                  }}
                />
              )}
            </Box>

            <Box>
              <Box>
                <Typography variant="h6" className={`gtc-uppercase`}>
                  <b
                    dangerouslySetInnerHTML={{
                      __html: doctorsInfo.data[0].header,
                    }}
                  ></b>
                </Typography>
              </Box>

              <Box>
                <Typography
                  variant="subtitle1"
                  className={`gtc-uppercase`}
                  color="textPrimary"
                  dangerouslySetInnerHTML={{
                    __html: doctorsInfo.data[0].sub_header,
                  }}
                ></Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">
                  {doctorsInfo.data[0].location}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">
                  {doctorsInfo.data[0].contact_no}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">
                  {doctorsInfo.data[0].days_open}
                </Typography>
              </Box>
            </Box>

            <Box ml={2} align="center" width={200}>
              {doctorsInfo.data[0].sub_logo && (
                <img
                  alt=""
                  src={`${imageLocation}/doctors/rxlogo/${doctorsInfo.data[0].sub_logo}`}
                  style={{
                    maxWidth: 100,
                  }}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>

      <Box p={2}>
        <Box borderBottom={1} borderTop={1} mb={1}>
          <Typography variant="subtitle1" align="center">
            <strong> MEDICAL CERTIFICATE </strong>
          </Typography>
        </Box>

        <Box borderBottom={1} borderTop={1}>
          <Typography variant="subtitle1">
            <strong>
              Date : {Notify.dateTimeConvert(new Date().toLocaleString())}
            </strong>
          </Typography>
        </Box>
      </Box>

      <Box p={2}>
        <Box>
          <Typography>To Whom It May Concern :</Typography>
        </Box>

        <Box>
          <Typography
            suppressContentEditableWarning={true}
            contentEditable={printDialog}
          >
            This is to certify that{" "}
            {patientInfo.length > 0
              ? `${patientInfo[0].firstname} ${patientInfo[0].lastname}`
              : ""}
            {` ${
              patientInfo.length > 0
                ? patientInfo[0].birthday
                  ? Notify.calculateAge(patientInfo[0].birthday)
                  : ""
                : ""
            }`}{" "}
            {patientInfo.length > 0 ? `${patientInfo[0].gender}` : ""} resident
            of{" "}
            {patientInfo.length > 0
              ? `${patientInfo[0].street ? patientInfo[0].street : ""}, ${
                  patientInfo[0].city ? patientInfo[0].city : ""
                }`
              : ""}{" "}
            was seen on {Notify.createdAt(new Date().toLocaleString())}
          </Typography>
        </Box>

        <Box my={2}>
          <Typography>Diagnosis / Impression/s </Typography>

          <Typography
            suppressContentEditableWarning={true}
            contentEditable={printDialog}
          >
            - Sample Only, click here to change
          </Typography>
        </Box>

        <Box my={2}>
          <Typography> Recommendation : </Typography>
          <Typography
            suppressContentEditableWarning={true}
            contentEditable={printDialog}
          >
            - Sample reason, click here to change
          </Typography>
        </Box>
      </Box>

      <Box p={2}>
        {doctorsInfo.data.length > 0 && (
          <Box display="flex">
            <Box flexGrow={1} mt={5} display="flex">
              <Box mr={2}>
                <Button
                  variant="contained"
                  color="primary"
                  className={"d-print-none"}
                  onClick={() => {
                    printDialog ? window.print() : setPrintDialog(true);
                  }}
                >
                  {printDialog
                    ? "Print Medical Cert."
                    : "Generate Medical Cert."}
                </Button>
              </Box>

              <Box>
                {printDialog && (
                  <Button
                    variant="contained"
                    color="default"
                    className={"d-print-none"}
                    onClick={() => setPrintDialog(false)}
                  >
                    Cancel
                  </Button>
                )}
              </Box>
            </Box>
            <Box>
              <Box>
                <Box align="left">
                  {/* <img src="/sample-sign.png" alt="" width="120" style={{position: 'relative', top: 140}}/> */}
                  <img
                    src={
                      imageLocation +
                      "doctors/doctors_signatures/" +
                      doctorsInfo.data[0].image_signature
                    }
                    alt=""
                    width="120"
                    style={{ position: "relative", top: 30 }}
                  />
                </Box>

                <Typography variant="subtitle1">
                  <b className="text-capitalize">{doctorsInfo.data[0].name}</b>
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle2">
                  License No. {doctorsInfo.data[0].cil_umn}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default MedicalCert;
