import { Card, CardContent, Box, Grid } from '@material-ui/core';
import React from 'react';
import Container from 'src/layout/Container';
import BasicInfo from './BasicInfo';
import Username from './Username';
import Password from './Password';


const TeleradAccount = () =>{

    return( 
        <Container
            breadcrumbs={{
                enable: true,
                current: 'Account ',
                items:[
                    { name: 'Dashbaord', path: '/clinic/app/telerad' }
                ]
            }} 
            title={'Account Information'}
        >

            <Card elevation={2} component={Box} mb={2}>
                <CardContent> 
                    <BasicInfo /> 
                </CardContent>
            </Card>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Card elevation={2} component={Box} mb={2}>
                        <CardContent> 
                            <Username /> 
                        </CardContent>
                    </Card>  
                </Grid> 
                
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Card elevation={2} component={Box} mb={2}>
                        <CardContent> 
                            <Password /> 
                        </CardContent>
                    </Card>
                </Grid> 
            </Grid>
        </Container>
    )
}

export default TeleradAccount;