import React, { useCallback, useContext, useEffect, useState, Fragment } from 'react';
import { faCircleNotch, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import Box from '@material-ui/core/Box'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { PackageContext } from '../../PackageContext';
import { Grid, Typography, Button, Paper, TextField, Divider} from '@material-ui/core';
import Container from 'src/layout/Container'; 
import BMIComputation from 'src/utils/BMIComputation';

const PersonalVitals = () => {
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('token')
    const username = localStorage.getItem('username')
    const [isProcess, setIsProcess] = useState(false)
    const [enableEdit, setEnableEdit] = useState(false)
    const contextData = useContext(PackageContext)
    const [temp, settemp] = useState('');
    const [systolic, setsystolic] = useState('')
    const [diastolic, setdiastolic] = useState('')
    const [pulse, setpulse] = useState('')
    const [weight, setweight] = useState('')
    const [height, setHeight] = useState('') 
    const [resp, setresp] = useState('')
    const [gluc, setgluc] = useState('')
    const [uric, seturic] = useState('')
    const [choles, setcholes] = useState('')
    const [patientInfo, setPatientInfo] = useState([])
    const [patientInfoRdy, setPatientInfoRdy] = useState(false)  

    const fetchPatientInfo = useCallback(async () => {
        try {
            var params = {
                token: token,
                user_id: user_id, 
            }
            const request = await Axios.get('patient/get-personal-info-by-id', { params })
            setPatientInfo(request.data)
            setPatientInfoRdy(true)
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, user_id])

    const checker = (value) => {
        if (value !== null && parseInt(value.length) > 0) {
            return value;
        } else {
            return 'None'
        }
    }

    const resetForm = () => {
        settemp('')
        setsystolic('')
        setdiastolic('')
        setpulse('')
        setweight('')
        setHeight('')
        setresp('')
        setgluc('')
        seturic('')
        setcholes('')
    }

    const handleNewHealth = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        var error = [];
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('username', username);
        formdata.set('patient_id', patientInfo[0].patient_id);

        if (
            formdata.get('temperature').trim() === '' &&
            formdata.get('diastolic').trim() === '' &&
            formdata.get('systolic').trim() === '' &&
            formdata.get('pulse').trim() === '' &&
            formdata.get('weight').trim() === '' &&
            formdata.get('height').trim() === '' &&
            formdata.get('respiratory').trim() === '' &&
            formdata.get('glucose').trim() === '' &&
            formdata.get('uricacid').trim() === '' &&
            formdata.get('cholesterol').trim() === ''
        ) {
            Notify.customToast('No Field', 'Select field to update.')
            error = 'error';
        }

        if (formdata.get('systolic').trim() !== '' || formdata.get('diastolic').trim() !== '') {
            if (formdata.get('systolic').trim() === '') {
                Notify.fieldRequired('Systolic')
                error = 'error';
            }
            if (formdata.get('diastolic').trim() === '') {
                Notify.fieldRequired('diastolic')
                error = 'error';
            }
        }

        if (formdata.get('password').length === 0 || formdata.get('password').trim() === '') {
            Notify.fieldInvalid('password')
            error = 'error';
        }

        if (error.length > 0) {
            console.warn("Form has an error. ")
        } else {
            setIsProcess(true)
            Axios.post('patient/information/personal-health', formdata)
                .then((response) => {
                    const data = response.data
                    if (data === 'success') {
                        setEnableEdit(false)
                        resetForm()
                        Notify.successRequest("health update")
                        fetchPatientInfo()
                    }
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                    if (data === 'db-error') {
                        Notify.warnRequest('health update')
                    }
                }).catch((error) => {
                    Notify.requestError(error)
                }).finally(() => {
                    setIsProcess(false)
                })
        }
    }

    useEffect(() => {
        fetchPatientInfo()
    }, [fetchPatientInfo]) 

    return (
        <>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'vitals',
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' },
                        { name: 'Profile', path: '/clinic/app/patient/profile' }
                    ]
                }}
                title="My Vitals"
            >
                {
                    patientInfoRdy && (
                        parseFloat(patientInfo.length) > 0 && (
                            <Fragment>
                                <Box
                                    component={Paper}
                                    variant="outlined"
                                    p={2} 
                                    mb={3}
                                >
                                    <form onSubmit={handleNewHealth}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <Box display="flex">
                                                    <Box flexGrow={1} />
                                                    <Box>
                                                        <Button
                                                            color="primary"
                                                            hidden={contextData.__package === null ? true : contextData.__package.subscription !== 'standard' ? enableEdit ? true : false : true}
                                                            onClick={() => setEnableEdit(true)}
                                                            startIcon={<AddCircleOutlineIcon />}
                                                        >
                                                            Update
                                                        </Button>
                                                    </Box>
                                                </Box>

                                                <Box hidden={contextData.__package === null ? true : contextData.__package.subscription !== 'standard' ? true : false} >
                                                    <Typography variant="caption" color="secondary">
                                                        Your current subscription is not allowed to update your health monitoring.
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={12} className={enableEdit ? "" : "gtc-textfield-noborder"}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={4}>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={12} xs={12}>
                                                                <TextField
                                                                    label="Temperature (&#8451;)"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    name="temperature"
                                                                    value={!enableEdit ? checker(patientInfo[0].temperature) : temp}
                                                                    onChange={(e) => settemp(e.target.value)}
                                                                    type="number"
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            min: 1,
                                                                            max: 1000,
                                                                            step: '0.01',
                                                                            readOnly: !enableEdit
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>

                                                            <Grid item sm={12} xs={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={6} sm={6}>
                                                                        <TextField
                                                                            label="BP (systolic)"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            name="systolic"
                                                                            value={!enableEdit ? checker(patientInfo[0].blood_systolic) : systolic}
                                                                            onChange={(e) => setsystolic(e.target.value)}
                                                                            type="number"
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    min: 1,
                                                                                    max: 1000,
                                                                                    step: '0.01',
                                                                                    readOnly: !enableEdit
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6} sm={6}>
                                                                        <TextField
                                                                            label="BP (diastolic)"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            name="diastolic"
                                                                            value={!enableEdit ? checker(patientInfo[0].blood_diastolic) : diastolic}
                                                                            onChange={(e) => setdiastolic(e.target.value)}
                                                                            type="number"
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    min: 1,
                                                                                    max: 1000,
                                                                                    step: '0.01',
                                                                                    readOnly: !enableEdit
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item sm={12} xs={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item sm={6} xs={6}>
                                                                        <TextField
                                                                            label="Respiratory (bpm)"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            name="respiratory"
                                                                            value={!enableEdit ? checker(patientInfo[0].rispiratory) : resp}
                                                                            onChange={(e) => setresp(e.target.value)}
                                                                            type="number"
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    min: 1,
                                                                                    max: 1000,
                                                                                    step: '0.01',
                                                                                    readOnly: !enableEdit
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item sm={6} xs={6}>
                                                                        <TextField
                                                                            label="Pulse (bpm)"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            name="pulse"
                                                                            value={!enableEdit ? checker(patientInfo[0].pulse) : pulse}
                                                                            onChange={(e) => setpulse(e.target.value)}
                                                                            type="number"
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    min: 1,
                                                                                    max: 1000,
                                                                                    step: '0.01',
                                                                                    readOnly: !enableEdit
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid> 
                                                            </Grid>

                                                            <Grid item sm={12} xs={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={6} sm={6}>
                                                                        <Box>
                                                                            <TextField
                                                                                label="Weight (kg)"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                name="weight"
                                                                                value={!enableEdit ? checker(patientInfo[0].weight) : weight}
                                                                                onChange={(e) => setweight(e.target.value)}
                                                                                type="number"
                                                                                InputProps={{
                                                                                    inputProps: {
                                                                                        min: 1,
                                                                                        max: 1000,
                                                                                        step: '0.01',
                                                                                        readOnly: !enableEdit
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                    </Grid>

                                                                    <Grid item xs={6} sm={6}>
                                                                        <Box>
                                                                            <TextField
                                                                                label="Height (Ft)"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                name="height"
                                                                                value={!enableEdit ? checker(patientInfo[0].height) : height}
                                                                                onChange={(e) => setHeight(e.target.value)}
                                                                                type="number"
                                                                                InputProps={{
                                                                                    inputProps: {
                                                                                        min: 1,
                                                                                        max: 1000,
                                                                                        step: '0.01',
                                                                                        readOnly: !enableEdit
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid> 
                                                            </Grid>
                                                        </Grid>
                                                    </Grid> 

                                                    <Grid item xs={12} sm={12} md={4}>
                                                        <Grid container spacing={2}> 
                                                            <Grid item sm={12} xs={12}>
                                                                <TextField
                                                                    label="Glucose (mg/dL)"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    name="glucose"
                                                                    value={!enableEdit ? checker(patientInfo[0].glucose) : gluc}
                                                                    onChange={(e) => setgluc(e.target.value)}
                                                                    type="number"
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            min: 1,
                                                                            max: 1000,
                                                                            step: '0.01',
                                                                            readOnly: !enableEdit
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>

                                                            <Grid item sm={12} xs={12}>
                                                                <TextField
                                                                    label="Uric Acid  (mg/dL)"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    name="uricacid"
                                                                    value={!enableEdit ? checker(patientInfo[0].uric_acid) : uric}
                                                                    onChange={(e) => seturic(e.target.value)}
                                                                    type="number"
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            min: 1,
                                                                            max: 1000,
                                                                            step: '0.01',
                                                                            readOnly: !enableEdit
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>

                                                            <Grid item sm={12} xs={12}>
                                                                <TextField
                                                                    label="Cholesterol  (mg/dL)"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    name="cholesterol"
                                                                    value={!enableEdit ? checker(patientInfo[0].cholesterol) : choles}
                                                                    onChange={(e) => setcholes(e.target.value)}
                                                                    type="number"
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            min: 1,
                                                                            max: 1000,
                                                                            step: '0.01',
                                                                            readOnly: !enableEdit
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={4}>
                                                        <Grid container spacing={2}>
                                                            <Grid item sm={12} xs={12} hidden={patientInfo[0].hepatitis === null}>
                                                                <TextField
                                                                    label="Hepatitis"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    defaultValue={patientInfo[0].hepatitis}
                                                                    InputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                />
                                                            </Grid>

                                                            <Grid item sm={12} xs={12} hidden={patientInfo[0].dengue === null}>
                                                                <TextField
                                                                    label="Dengue"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                    defaultValue={patientInfo[0].dengue}
                                                                />
                                                            </Grid>

                                                            <Grid item sm={12} xs={12} hidden={patientInfo[0].tuberculosis === null}>
                                                                <Box>
                                                                    <TextField
                                                                        label="Tuberculosis"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputProps={{
                                                                            readOnly: true
                                                                        }}
                                                                        defaultValue={patientInfo[0].tuberculosis}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} hidden={!enableEdit}>
                                                        <TextField
                                                            label="Enter your password"
                                                            variant="outlined"
                                                            fullWidth
                                                            name="password"
                                                            type="password"
                                                        />
                                                    </Grid>
                                                    {
                                                        enableEdit && (
                                                            <Grid item xs={12} sm={12} md={12}>
                                                                <Box mb={1}>
                                                                    <Divider />
                                                                </Box>
                                                                <Box display="flex">
                                                                    <Box flexGrow={1} />
                                                                    <Button variant="contained" color="default" onClick={() => setEnableEdit(false)} startIcon={<FontAwesomeIcon icon={faTimesCircle} />}>  No </Button>
                                                                    <Box ml={2}>
                                                                        <Button
                                                                            variant="contained"
                                                                            type="submit"
                                                                            color="primary"
                                                                            disabled={isProcess}
                                                                            startIcon={
                                                                                <FontAwesomeIcon
                                                                                    icon={isProcess ? faCircleNotch : faCheckCircle}
                                                                                    spin={isProcess}
                                                                                />
                                                                            }
                                                                        >
                                                                            Save
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid> 
                                        </Grid>
                                    </form>
                                </Box>
                                
                                <Box
                                    component={Paper}
                                    variant="outlined"
                                    p={2} 
                                    mb={3}
                                >
                                    <BMIComputation
                                        weight = { patientInfo[0].weight }
                                        height  = { patientInfo[0].height }
                                    />
                                </Box>
                                
                            </Fragment>
                        )
                    )
                }
            </Container>
        </>
    )
}
export default PersonalVitals;