import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TableBody,
  Typography,
  IconButton,
  TableFooter,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Axios from "axios";
import React, { useState } from "react";
import { Trash } from "react-feather";
import Notify from "src/notification/Notify";

const ProductListInTemp = ({ tempProducts, getTempProductList }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const username = localStorage.getItem("username");
  const [remove, setRemove] = useState({ dialog: false, id: null });
  const [process, setProcess] = useState(false);
  const [processData, setProcessData] = useState({});

  const handleRemoveItem = async () => {
    var formdata = new FormData();
    formdata.append("token", token);
    formdata.append("remove_id", remove.id);
    let response = await Axios.post(
      "pharmacy/warehouse/inventory/newinvoice-removeItem",
      formdata
    );
    let result = response.data;
    if (result.message === "success") {
      getTempProductList();
      setRemove({ dialog: false, id: null });
      Notify.successRequest("item remove");
    }
  };

  const onInputchange = (e) => {
    setProcessData({ ...processData, [e.target.name]: e.target.value });
  };

  const handleProcessInvoice = async () => {
    var formdata = new FormData();
    formdata.append("token", token);
    formdata.append("user_id", user_id);
    formdata.append("username", username);
    formdata.append("management_id", management_id);
    formdata.append("invoice_number", processData.invoice_number);
    formdata.append("dr_date", processData.dr_date);
    formdata.append("dr_by", processData.dr_by);
    formdata.append("password", processData.password);

    let response = await Axios.post(
      "pharmacy/warehouse/inventory/process-unsaveitem",
      formdata
    );
    let result = response.data;

    if (result.message === "pass-invalid") {
      Notify.fieldInvalid("password");
    }

    if (result.message === "success") {
      Notify.successRequest("product save");
      setProcess(false);
      getTempProductList();
      setProcessData({});
    }
  };

  return (
    <Box>
      <Box>
        <Typography variant="h6" color="textSecondary">
          Unsave Invoice Product List
        </Typography>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> Produuct </TableCell>
              <TableCell> Generic </TableCell>
              <TableCell> Batch </TableCell>
              <TableCell> Mfg. Date </TableCell>
              <TableCell> Exp. Date </TableCell>
              <TableCell> Qty </TableCell>
              <TableCell> Action </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tempProducts.ready ? (
              tempProducts.data.length > 0 ? (
                tempProducts.data.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell> {data.product_name} </TableCell>
                    <TableCell> {data.product_generic} </TableCell>
                    <TableCell> {data.batch_number} </TableCell>
                    <TableCell> {data.manufactured_date} </TableCell>
                    <TableCell> {data.expiration_date} </TableCell>
                    <TableCell align="center"> {data.qty} </TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() =>
                          setRemove({ dialog: true, id: data.pwit_id })
                        }
                      >
                        <Trash />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Typography color="secondary">No product added.</Typography>
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography color="primary">
                    loading, please wait...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={7}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!Boolean(tempProducts.data.length)}
                    onClick={() => setProcess(true)}
                  >
                    Save Products
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {/* remove item in a list */}
      {remove.dialog && (
        <RemoveItemInUnsave
          open={remove.dialog}
          close={() => setRemove({ dialog: false, id: null })}
          actions={handleRemoveItem}
        />
      )}

      {process && (
        <ProcessUnsaveProducts
          open={process}
          close={() => setProcess(false)}
          actions={handleProcessInvoice}
          onInputchange={onInputchange}
        />
      )}
    </Box>
  );
};

export default ProductListInTemp;

const RemoveItemInUnsave = ({ open, close, actions }) => (
  <Dialog open={open} onClose={close}>
    <DialogContent>
      <Typography>Are you sure to remove this product?</Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="default" onClick={close}>
        No
      </Button>
      <Button variant="contained" color="secondary" onClick={() => actions()}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

const ProcessUnsaveProducts = ({ open, close, actions, onInputchange }) => (
  <Dialog open={open} onClose={close} maxWidth={"xs"}>
    <DialogTitle>Process Invoice Dialog</DialogTitle>
    <DialogContent dividers>
      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                label="Invoice Number"
                name="invoice_number"
                onChange={onInputchange}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                label="DR Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                name="dr_date"
                onChange={onInputchange}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                label="DR By"
                name="dr_by"
                onChange={onInputchange}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                label="Password"
                name="password"
                type="password"
                onChange={onInputchange}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button size="large" variant="contained" color="default" onClick={close}>
        No
      </Button>
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={() => actions()}
      >
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);
