import React, { Component, Fragment } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Notify from "../notification/Notify";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";
import MUIButton from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOff from "@material-ui/icons/HighlightOff";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import {
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Card,
  CardHeader,
  useTheme,
  TextField,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { Send, XCircle } from "react-feather";

const fileLocation = process.env.REACT_APP_API_IMAGE;

export default class OnlinechecupChat extends Component {
  _mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      _user_id: localStorage.getItem("user_id"),
      _token: localStorage.getItem("token"),
      _management_id: localStorage.getItem("management_id"),
      _type: localStorage.getItem("type"),
      _username: localStorage.getItem("username"),

      _is_send: false,

      _message: [],
      _message_ready: false,
      _last_messages_id: 0,

      _message_type: "text",
      _selecting_file_label: "none",
      _allow_permission: false,

      _selected_message_id: null,
      _new_message: "",
      _new_message_disabed: false,
    };

    this.interval = null;
  }

  scrollToBottom = () => {
    if (this.chatContainer) {
      this.chatContainer.scrollTop = this.chatContainer.scrollHeight;
    }
  };

  getMessages() {
    var formdata = new FormData();
    formdata.set("token", this.state._token);
    formdata.set("management_id", this.state._management_id);
    formdata.set("receivers_id", this.state._user_id);
    formdata.set("username", this.state._username);
    formdata.set("senders_id", this.props.client_id);
    formdata.set("connection", "online");

    axios
      .post("online/checkup/message/get-message-list", formdata)
      .then(
        function (response) {
          const data = response.data;
          if (this._mounted) {
            if (data.length > 0) {
              this.setState({
                _message: data,
                _message_ready: true,
                _last_messages_id: data[data.length - 1].id,
              });
            } else {
              this.setState({
                _message: [],
                _message_ready: true,
              });
            }
          }
        }.bind(this)
      )
      .catch((error) => {
        Notify.requestError(error);
      });
  }

  componentWillUnmount() {
    this._mounted = false;
    clearInterval(this.interval);
  }

  handleSendMsg(e) {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", this.state._token);
    formdata.set("senders_id", this.state._user_id);
    formdata.set("receivers_id", this.props.client_id);
    formdata.set("connection", "online");
    formdata.set("message", this.state._new_message);

    var error = [];

    if (
      formdata.get("message").length === 0 ||
      formdata.get("message").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("message");
    }

    if (error.length > 0) {
      Notify.consoleLog("send message");
    } else {
      this.setState({
        _is_send: true,
      });

      axios
        .post("online/checkup/message/send-message", formdata)
        .then(
          function (response) {
            this.setState({
              _is_send: false,
              _new_message: "",
              _new_message_disabed: false,
            });
            this.getMessages();
            e.target.reset();
          }.bind(this)
        )
        .catch((error) => {
          this.setState({
            _is_send: false,
          });
          Notify.requestError(error);
        });
    }
  }

  async setMessageAsRead() {
    var formdata = new FormData();
    formdata.set("token", this.state._token);
    formdata.set("management_id", this.state._management_id);
    formdata.set("user_id", this.state._user_id);
    formdata.set("receivers_id", this.props.client_id);
    formdata.set("account_type", this.state._type);
    formdata.set("connection", "online");

    await axios.post("online/checkup/message/set-message-asread", formdata);
  }

  getNewMessages() {
    var formdata = new FormData();
    formdata.set("token", this.state._token);
    formdata.set("management_id", this.state._management_id);
    formdata.set("user_id", this.state._user_id);
    formdata.set("receivers_id", this.props.client_id);
    formdata.set("account_type", this.state._type);
    formdata.set("_last_msg_id", this.state._last_messages_id);
    formdata.set("connection", "online");

    axios
      .post("online/checkup/message/get-message-new", formdata)
      .then(
        function (response) {
          const data = response.data;
          if (this._mounted) {
            if (data.length > 0) {
              this.getMessages();
            }
          }
        }.bind(this)
      )
      .catch((error) => {
        Notify.requestError(error);
      });
  }

  resetMessage = (e) => {
    if (e.target.value.trim().length < 1) {
      this.refs.message_attachment.value = null;
      this.setState({ _selecting_file_label: "none" });
    }
  };

  handleSelectFile = () => {
    this.refs.message_attachment.click();
  };

  onSelectFileChange = (e) => {
    if (e.target.files) {
      var file = e.target.files[0].name;
      var lastDot = file.lastIndexOf(".");
      var filename = file.substring(0, lastDot);
      // var ext = file.substring(lastDot + 1);

      this.setState({
        _new_message: filename,
        _selecting_file_label: "selected",
        _new_message_disabed: true,
      }); // upload icon set to archive
    } else {
      this.setState({
        _new_message_disabed: false,
      }); // upload icon set to archive
    }
  };

  closePermissionDialog = () => {
    this.setState({
      _allow_permission: false,
      _selected_message_id: null,
    });
  };

  handleSelectedPermission = (e) => {
    this.setState({
      _allow_permission: true,
      _selected_message_id: e.currentTarget.id,
    });
  };

  handleAllowPermission = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", this.state._token);
    formdata.set("user_id", this.state._user_id);
    formdata.set("username", this.state._username);
    formdata.set("doctors_id", this.props.client_id);
    formdata.set("id", this.state._selected_message_id);
    formdata.set("connection", "online");

    var error = [];
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      Notify.fieldRequired("password");
      error = "error";
    }
    if (error.length > 0) {
      console.warn("Unable to process, Form has an error.");
    } else {
      this.setState({ isprocessperms: true });
      axios
        .post("online/checkup/message/allow-profilepermission", formdata)
        .then(
          function (response) {
            const data = response.data;
            if (data === "success") {
              this.getMessages();
              this.closePermissionDialog();
              Notify.successRequest("permission");
            }
            if (data === "pass-invalid") {
              Notify.fieldInvalid("password");
            }
          }.bind(this)
        )
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          this.setState({ isprocessperms: false });
        });
    }
  };

  componentDidMount() {
    this._mounted = true;
    this.getMessages();

    setTimeout(() => {
      this.scrollToBottom();
      this.setMessageAsRead();
    }, 3000);

    this.interval = setInterval(() => {
      this.getNewMessages();
    }, 10000);
  }

  render() {
    return (
      <Fragment>
        <Card elevation={0} style={{ borderRadius: 0 }}>
          <ChatHeader close={this.props.close} />

          <CardContent>
            <Box
              className="onlineChatContainer"
              ref={(el) => (this.chatContainer = el)}
              style={{
                height: "calc(100vh - 225px)",
                overflow: "auto",
              }}
              p={2}
            >
              {this.state._message_ready ? (
                this.state._message.length > 0 ? (
                  this.state._message.map(
                    function (data, index) {
                      return (
                        <div key={index} className="clearfix">
                          {data.senders_id === this.state._user_id ? (
                            <div className="text-white bg-primary border border-primary p-2 mb-2 rounded float-right">
                              {data.message}
                              {data.type === "file" ? (
                                <a
                                  rel="noopener noreferrer"
                                  className="pointer text-white ml-2"
                                  target="_blank"
                                  href={
                                    fileLocation +
                                    "appointment/chat_attachment/" +
                                    data.message
                                  }
                                  download
                                >
                                  <FontAwesomeIcon icon={faDownload} />
                                </a>
                              ) : null}
                            </div>
                          ) : (
                            <div className="bg-light border p-2 mb-2 rounded float-left">
                              {data.message}
                              {data.type === "file" ? (
                                <a
                                  rel="noopener noreferrer"
                                  className="pointer text-white"
                                  target="_blank"
                                  href={
                                    fileLocation +
                                    "appointment/chat_attachment/" +
                                    data.message
                                  }
                                  download
                                >
                                  {" "}
                                  <FontAwesomeIcon icon={faDownload} />{" "}
                                </a>
                              ) : data.type === "permission" ? (
                                <Fragment>
                                  <p className="m-0">
                                    <Button
                                      variant="success float-right"
                                      size="sm"
                                      id={data.id}
                                      onClick={this.handleSelectedPermission}
                                    >
                                      {" "}
                                      Allow{" "}
                                    </Button>
                                  </p>
                                </Fragment>
                              ) : null}
                            </div>
                          )}
                        </div>
                      );
                    }.bind(this)
                  )
                ) : (
                  <p className="text-danger text-center">
                    No message recorded.
                  </p>
                )
              ) : (
                <div className="text-center text-primary">
                  <Spinner animation="border" /> <br />
                  Please wait...
                </div>
              )}
            </Box>
          </CardContent>
          <CardContent>
            <Box mt={2}>
              <Form onSubmit={this.handleSendMsg.bind(this)}>
                {/* hidden upload fiels */}
                <input
                  type="file"
                  name="message_attachment"
                  ref="message_attachment"
                  style={{ display: "none" }}
                  onChange={this.onSelectFileChange}
                />
                <Box>
                  <TextField
                    variant="outlined"
                    label="Message"
                    name="message"
                    fullWidth
                    value={this.state._new_message}
                    onChange={(e) =>
                      this.setState({
                        _new_message: e.target.value,
                      })
                    }
                    InputProps={{
                      readOnly: this.state._new_message_disabed,
                      startAdornment: (
                        <>
                          <IconButton
                            color="secondary"
                            onClick={this.handleSelectFile}
                          >
                            {this.state._selecting_file_label === "none" ? (
                              <CloudUploadIcon />
                            ) : (
                              <AttachFileIcon />
                            )}
                          </IconButton>
                        </>
                      ),
                      endAdornment: (
                        <IconButton
                          type="submit"
                          color="primary"
                          disabled={this.state._is_send}
                        >
                          {this.state._is_send ? (
                            <CircularProgress size={23} color="inherit" />
                          ) : (
                            <Send />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                </Box>
                {/* <FormGroup className="mb-0">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <Button
                        variant="secondary px-1"
                        onClick={this.handleSelectFile}
                      >
                        {this.state._selecting_file_label === "none" ? (
                          <CloudUploadIcon />
                        ) : (
                          <AttachFileIcon />
                        )}
                      </Button>
                      <input
                        type="file"
                        name="message_attachment"
                        ref="message_attachment"
                        style={{ display: "none" }}
                        onChange={this.onSelectFileChange}
                      />
                    </InputGroup.Prepend>

                    <FormControl
                      name="message"
                      autoComplete="off"
                      placeholder="Enter message"
                      ref="chat_message"
                      onChange={this.resetMessage}
                    />

                    <InputGroup.Append>
                      <Button
                        type="submit"
                        disabled={this.state._is_send ? true : false}
                      >
                        <FontAwesomeIcon
                          icon={this.state._is_send ? faSpinner : faPaperPlane}
                          spin={this.state._is_send ? true : false}
                        />{" "}
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </FormGroup> */}
              </Form>
            </Box>
          </CardContent>
        </Card>

        {/* allowpermission to view profile */}
        <Dialog
          open={this.state._allow_permission}
          onClose={this.closePermissionDialog}
          disableBackdropClick={true}
          TransitionComponent={Slide}
          transitionDuration={600}
        >
          <DialogTitle>Allow Permission</DialogTitle>
          <form onSubmit={this.handleAllowPermission}>
            <DialogContent dividers>
              <Box>
                <TextField
                  type="password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="password"
                  label="Enter your password"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <MUIButton
                variant="contained"
                color="default"
                onClick={this.closePermissionDialog}
                startIcon={<HighlightOff />}
              >
                {" "}
                No{" "}
              </MUIButton>
              <MUIButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={this.state.isprocessperms ? true : false}
                startIcon={
                  this.state.isprocessperms ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : (
                    <CheckCircleOutline />
                  )
                }
              >
                Allow
              </MUIButton>
            </DialogActions>
          </form>
        </Dialog>
      </Fragment>
    );
  }
}

const ChatHeader = ({ close }) => {
  const theme = useTheme();
  return (
    <CardHeader
      style={{
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>Chats</Box>
          <Box hidden={!close}>
            <IconButton color="inherit" onClick={close}>
              <XCircle />
            </IconButton>
          </Box>
        </Box>
      }
    />
  );
};
