import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound";
import GTCValidateLS from "src/notification/GTCValidateLS";
import BillingReceipt from "src/encoder/billing/BillingReceipt";
import Sidebar from "src/layout/Sidebar";
import {
  Calendar,
  DollarSign,
  Home as HomeIcon,
  Lock,
  Monitor,
  Users,
} from "react-feather";
import Billing from "src/encoder/billing/Billing";
import Patients from "src/encoder/Patients";
import LocalAppointment from "src/encoder/local-appointment";
import VirtualAppointment from "src/encoder/virtual-appointment";
import { Badge } from "@material-ui/core";
import OnlineAppRequest from "src/encoder/snackbar/OnlineAppRequest";
import Notify from "src/notification/Notify";
import CheckInternet from "src/utils/CheckInternet";
import axios from "axios";
import GTCNewsFullImage from "src/gtcnews/GTCNewsFullImage";
import EncoderAccount from "src/encoder/account";

const RouteEncoder = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [onlineappReq, setOnlineappReq] = useState({
    data: [],
    ready: false,
  });

  const [onlineCount, setonlineCount] = useState(0);
  const [localCount, setlocalCount] = useState(0);
  const [unpaidbill, setunpaidBill] = useState(0);
  const [unreadNotifCount, setUnreadNotfiCount] = useState(0);
  const [_online, set_online] = useState("checking");

  var interval = null;
  var countinterval = null;

  const getOnlineAppRequest = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);

    axios
      .post("encoder/appointment/online/getrequest-new", formdata)
      .then((response) => {
        const data = response.data;
        setOnlineappReq({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getUnreadCountnotif = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("connection", "online");
    formdata.set("user_id", user_id);

    axios
      .post("encoder/appointment/online/notification-unreadcount", formdata)
      .then((response) => {
        const data = response.data;
        setUnreadNotfiCount(data.length);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getOnlineAppointmentCount = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("connection", "online");

    axios
      .post("encoder/appointment/online/appcount", formdata)
      .then((response) => {
        const data = response.data;
        setonlineCount(data.length);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getLocalAppointmentCount = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);

    axios
      .post("encoder/appointment/local/appcount", formdata)
      .then((response) => {
        const data = response.data;
        setlocalCount(data.length);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getUnpaidBillCount = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);

    axios
      .post("encoder/billing/unpaid-count", formdata)
      .then((response) => {
        const data = response.data;
        setunpaidBill(data.length);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const CheckConnection = () => {
    countinterval = setInterval(() => {
      getLocalAppointmentCount();
      getUnpaidBillCount();
    }, 20000);

    if (navigator.onLine) {
      CheckInternet.online()
        .then(() => {
          getOnlineAppRequest();
          getOnlineAppointmentCount();
          getUnreadCountnotif();
          interval = setInterval(() => {
            getOnlineAppRequest();
            getOnlineAppointmentCount();
            getUnreadCountnotif();
          }, 20000);
        })
        .catch(() => {
          Notify.customToast(
            "Virtual Not Available",
            "No internet connection."
          );
          set_online("disconnected");
        });
    } else {
      clearInterval(countinterval);
      clearInterval(interval);
    }
  };

  useEffect(() => {
    GTCValidateLS.verifyLocalStorage();

    CheckConnection();

    return () => {
      clearInterval(countinterval);
      clearInterval(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_online]);

  const sidbarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <HomeIcon color="white" />,
      path: "/clinic/app/encoder",
    },

    {
      name: "Patients",
      show: true,
      icon: <Users color="white" />,
      path: "/clinic/app/encoder/patient",
    },

    {
      name: "Local Appointment",
      show: true,
      icon: (
        <Badge badgeContent={localCount} color="error">
          <Monitor color="white" />
        </Badge>
      ),
      path: "/clinic/app/encoder/local/appointment",
    },

    {
      name: "Virtual Appointment",
      show: true,
      icon: (
        <Badge badgeContent={onlineCount + unreadNotifCount} color="error">
          <Calendar color="white" />
        </Badge>
      ),
      path: "/clinic/app/encoder/virtual/appointment",
    },

    {
      name: "Billing",
      show: true,
      icon: (
        <Badge badgeContent={unpaidbill} color="error">
          <DollarSign color="white" />
        </Badge>
      ),
      path: "/clinic/app/encoder/billing",
    },

    {
      name: "Account",
      show: true,
      icon: <Lock color="white" />,
      path: "/clinic/app/encoder/account",
    },
  ];

  return (
    <Fragment>
      <BrowserRouter>
        <Sidebar
          notification={{
            enable: false,
            local: [],
            virtual: [],
            owner: "secretary",
          }}
          header={[]}
          routes={sidbarRoute}
          module={"secretary"}
        />

        <Switch>
          <Route
            exact
            path="/"
            component={() => <Redirect to="/clinic/app/encoder" />}
          />
          <Route
            exact
            path="/clinic/"
            component={() => <Redirect to="/clinic/app/encoder" />}
          />
          <Route
            exact
            path="/clinic/app"
            component={() => <Redirect to="/clinic/app/encoder" />}
          />
          <Route
            exact
            path="/clinic/app/encoder"
            component={GTCNewsFullImage}
          />
          <Route
            exact
            path="/clinic/app/encoder/patient"
            component={Patients}
          />
          <Route exact path="/clinic/app/encoder/billing" component={Billing} />
          <Route
            exact
            path="/clinic/app/encoder/local/appointment"
            component={LocalAppointment}
          />
          <Route
            exact
            path="/clinic/app/encoder/virtual/appointment"
            component={VirtualAppointment}
          />
          <Route
            exact
            path="/clinic/app/encoder/billing/receipt/:receipt_number"
            component={BillingReceipt}
          />
          <Route
            exact
            path="/clinic/app/encoder/account"
            component={EncoderAccount}
          />

          <Route exact path="/clinic/app/logout" component={Logout} />
          <Route render={() => <PageNotFound />} />
        </Switch>
      </BrowserRouter>

      {/* snackbar */}
      {onlineappReq.ready && (
        <OnlineAppRequest
          apprequest={onlineappReq}
          getOnlineAppRequest={getOnlineAppRequest}
        />
      )}
    </Fragment>
  );
};

export default RouteEncoder;
