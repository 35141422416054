import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  makeStyles,
  CircularProgress,
  Paper,
  InputBase,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import ClinicDetailsInquiryMessages from "./ClinicDetailsInquiryMessages";
import Axios from "axios";
import Notify from "../../notification/Notify";

const drawerWidth = 440;
const innerwidth = window.innerWidth;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: innerwidth > 600 ? drawerWidth : "100%",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const ClinicDetailsInquiryChatbox = ({ close, clinic_id, user_id }) => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const [process, setProcess] = useState(false);
  const [messages, setMessages] = useState({
    data: [],
    ready: false,
  });

  var newInquiryInterval = null;

  const handleInquiry = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("patient_id", user_id);
    formdata.set("clinic_id", clinic_id);
    formdata.set("send_by", "Patient");
    formdata.set("connection", "online");
    var error = [];

    if (
      formdata.get("message").length === 0 ||
      formdata.get("message").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("message");
    }

    if (error.length > 0) {
      console.warn("Form has an error.");
    } else {
      setProcess(true);
      Axios.post("patient/appointment/clinic/details/inquiry-send", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            e.target.reset();
            getInquiryMessage();
            Notify.successRequest("Inquiry sent!");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setProcess(false);
        });
    }
  };

  const getInquiryMessage = () => {
    var params = {
      token: token,
      patient_id: user_id,
      clinic_id: clinic_id,
      connection: "online",
    };
    Axios.get("patient/appointment/clinic/details/inquiry-getmessages", {
      params,
    })
      .then((response) => {
        const data = response.data;
        setMessages({ data: data, ready: true });
        getNewInquiryResponse(data.length > 0 ? data[data.length - 1].id : 0);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getNewInquiryResponse = (lastMsgId) => {
    newInquiryInterval = setInterval(() => {
      var params = {
        token: token,
        patient_id: user_id,
        clinic_id: clinic_id,
        lastmessage_id: lastMsgId,
      };
      Axios.get("patient/appointment/clinic/details/inquiry-getnewinquiry", {
        params,
      })
        .then((response) => {
          const data = response.data;
          if (data.length > 0) {
            getInquiryMessage();
            clearInterval(newInquiryInterval);
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        });
    }, 10000);
  };

  useEffect(() => {
    getInquiryMessage();
    return () => {
      clearInterval(newInquiryInterval);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box m={1} height={400}>
      <Box mb={1} height={400} p={1}>
        <ClinicDetailsInquiryMessages
          messages={messages}
          screen={"smallscreen"}
        />
      </Box>

      <Box mt={2}>
        <Paper
          variant="outlined"
          component="form"
          className={classes.root}
          onSubmit={handleInquiry}
        >
          <InputBase
            className={classes.input}
            placeholder="Write a message"
            id="message"
            name="message"
            required
            autoComplete="off"
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="send"
            disabled={process}
          >
            {process ? <CircularProgress size={20} /> : <SendIcon />}
          </IconButton>
        </Paper>
      </Box>
    </Box>
  );
};

export default ClinicDetailsInquiryChatbox;
