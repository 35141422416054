import React, { Component, Fragment } from "react";
import { Navbar, Nav } from "react-bootstrap";
import {
  BrowserRouter,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import PageNotFound from "../PageNotFound";
// import Registration from "../welcome/registration/Registration";
import Homepage from "../welcome/Homepage";
import Registration from "../welcome/registration/RegistrationPackages";
import RegistrationBasicInfo from "../welcome/registration/RegistrationBasicInfo";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Generatehashstring from "../welcome/GenerateHashString";
import GTCMvcall from "src/GTCMultipleVCall";
import VirtualCall from "../oncheckupchat/VirtualCall";
import GenerateQr from "src/GTCMultipleVCall/GenerateQr";
import CanvasDrawing from "src/GTCMultipleVCall/CanvasDrawing";
import PrivacyPolicy from "src/welcome/PrivacyPolicy";

export default class NavbarHome extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      _login_open: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <Fragment>
        <BrowserRouter>
          <Navbar
            fixed="top"
            style={{ backgroundColor: "#00adf0", padding: "20px" }}
            expand="lg"
          >
            {/* <Navbar.Brand href="/" className="text-white"> <b>G</b>lobal <b>T</b>elemedicine <b>C</b>orp </Navbar.Brand> */}

            <Nav className="mr-auto">
              {/* <Link to="/" className="nav-link text-white"> Home </Link>   */}
            </Nav>
            <Nav>
              <NavLink
                to="/clinic"
                className="nav-link-modified-navbar text-white"
                activeClassName="text-white"
                exact
                eventkey="link-2"
              >
                <FontAwesomeIcon style={{ fontSize: "1.5rem" }} icon={faHome} />{" "}
              </NavLink>
              {/* <NavLink to="/test/twopeer#start" className="nav-link text-white" activeClassName="bg-light text-danger" exact> HostPeer </NavLink>  */}
              {/* <NavLink to="/test/group-call" className="nav-link text-white" activeClassName="bg-light text-danger" exact> groupcall </NavLink>         */}
              {/* <NavLink to="/test/generate-qr" className="nav-link text-white" activeClassName="bg-light text-danger" exact> Generate QR </NavLink>         */}
              {/* <NavLink to="/test/canvas-drawing" className="nav-link text-white" activeClassName="bg-light text-danger" exact> Canvas drawing </NavLink>         */}
            </Nav>
          </Navbar>

          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/clinic/app" />}
            />
            <Route
              exact
              path="/clinic/"
              component={() => <Redirect to="/clinic/app" />}
            />
            <Route exact path="/clinic/app" component={Homepage} />
            <Route
              exact
              path="/clinic/app/registration/packages"
              component={Registration}
            />
            <Route
              exact
              path="/clinic/app/registration/basic-information/:package"
              component={RegistrationBasicInfo}
            />
            <Route
              exact
              path="/clinic/app/test/generate-password"
              component={Generatehashstring}
            />
            <Route
              exact
              path="/clinic/app/test/group-call"
              component={GTCMvcall}
            />
            <Route
              exact
              path="/clinic/app/test/generate-qr"
              component={GenerateQr}
            />
            <Route
              exact
              path="/clinic/app/test/twopeer"
              component={VirtualCall}
            />
            <Route
              exact
              path="/clinic/app/test/canvas-drawing"
              component={CanvasDrawing}
            />
            <Route
              exact
              path="/clinic/app/privacy-policy"
              component={PrivacyPolicy}
            />

            <Route render={() => <PageNotFound title="Page not found" />} />
          </Switch>
        </BrowserRouter>
      </Fragment>
    );
  }
}
