import React from "react";
import Axios from "axios";
import Notify from "./Notify";

class GTCValidateLS extends React.Component {
  static verifyLocalStorage() {
    var params = {
      token: localStorage.getItem("token"),
      user_id: localStorage.getItem("user_id"),
      type: localStorage.getItem("type"),
    };

    Axios.get("user/account/validate/8724g03lfs39", { params }).then(
      (response) => {
        const data = response.data;
        if (data.length > 0) {
          if (data[0].type !== localStorage.getItem("type")) {
            localStorage.setItem("type", data[0].type);
          }
          if (data[0].username !== localStorage.getItem("username")) {
            localStorage.setItem("username", data[0].username);
          }
          if (data[0].manage_by !== localStorage.getItem("manage_by")) {
            localStorage.setItem("management_id", data[0].manage_by);
          }
        } else {
          Notify.customToast("Unsual Activity", "System logging out.");
          setTimeout(() => {
            window.location.href = "/gtc-clnic/app/logout";
          }, 2000);
        }
      }
    );
  }
}

export default GTCValidateLS;
