import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  CircularProgress,
  Button,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@material-ui/core";

import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Notify from "../../notification/Notify";
import Axios from "axios";

const HealthPreLaboratoryNew = ({
  patient_id,
  cancel,
  prelaboratory,
  connection,
}) => {
  const [token] = useState(localStorage.getItem("token"));
  const [user_id] = useState(localStorage.getItem("user_id"));
  const [username] = useState(localStorage.getItem("username"));

  const [hepatitis, setHepatitis] = useState("");
  const [tuberculosis, setTuberculosis] = useState("");
  const [dengue, setDengue] = useState("");
  const [isProcess, setIsProcess] = useState(false);

  useEffect(() => {}, [patient_id]);

  const handleNewRecord = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("username", username);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", connection);
    var error = [];
    if (
      formdata.get("temperature").trim() === "" &&
      formdata.get("diastolic").trim() === "" &&
      formdata.get("systolic").trim() === "" &&
      formdata.get("pulse").trim() === "" &&
      formdata.get("weight").trim() === "" &&
      formdata.get("respiratory").trim() === "" &&
      formdata.get("glucose").trim() === "" &&
      formdata.get("uricacid").trim() === "" &&
      formdata.get("cholesterol").trim() === "" &&
      formdata.get("tuberculosis").trim() === "" &&
      formdata.get("hepatitis").trim() === "" &&
      formdata.get("dengue").trim() === ""
    ) {
      Notify.customToast("No Field", "Select field to update.");
      error = "error";
    }

    if (
      formdata.get("systolic").trim() !== "" ||
      formdata.get("diastolic").trim() !== ""
    ) {
      if (formdata.get("systolic").trim() === "") {
        Notify.fieldRequired("Systolic");
        error = "error";
      }
      if (formdata.get("diastolic").trim() === "") {
        Notify.fieldRequired("diastolic");
        error = "error";
      }
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      console.warn("Form has an error.");
    } else {
      setIsProcess(true);
      Axios.post("doctor/patient/prelaboratory/newprelaboratory", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            prelaboratory();
            cancel();
            Notify.successRequest("delete notes");
          }
          if (data === "db-error") {
            Notify.warnRequest("delete notes");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsProcess(false);
        });
    }
  };

  return (
    <form onSubmit={handleNewRecord}>
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <Box mb={3}>
            <TextField
              margin="normal"
              fullWidth
              autoFocus
              label={<span> Temperature (&#8451;) </span>}
              variant="outlined"
              name="temperature"
              type="number"
              InputProps={{
                inputProps: {
                  max: 600,
                  step: "0.01",
                },
              }}
            />
          </Box>

          <Box mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  autoFocus
                  label={"BP Systolic"}
                  variant="outlined"
                  name="systolic"
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 600,
                      step: "0.01",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  autoFocus
                  label={"Diastolic (mmHg)"}
                  variant="outlined"
                  name="diastolic"
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 600,
                      step: "0.01",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={3}>
            <TextField
              margin="normal"
              fullWidth
              autoFocus
              label={"Hearth Pulse (BPM)"}
              variant="outlined"
              name="pulse"
              type="number"
              InputProps={{
                inputProps: {
                  max: 600,
                  step: "0.01",
                },
              }}
            />
          </Box>

          <Box>
            <TextField
              margin="normal"
              fullWidth
              autoFocus
              label={"Respiratory (BPM)"}
              variant="outlined"
              name="respiratory"
              type="number"
              InputProps={{
                inputProps: {
                  max: 600,
                  step: "0.01",
                },
              }}
            />
          </Box>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box mb={3}>
            <TextField
              margin="normal"
              fullWidth
              autoFocus
              label={"Weight (kg)"}
              variant="outlined"
              name="weight"
              type="number"
              InputProps={{
                inputProps: {
                  max: 600,
                  step: "0.01",
                },
              }}
            />
          </Box>

          <Box mb={3}>
            <TextField
              margin="normal"
              fullWidth
              autoFocus
              label={"Glucose (mg/dL)"}
              variant="outlined"
              name="glucose"
              type="number"
              InputProps={{
                inputProps: {
                  max: 600,
                  step: "0.01",
                },
              }}
            />
          </Box>

          <Box mb={3}>
            <TextField
              margin="normal"
              fullWidth
              autoFocus
              label={"Uric Acid (mg/dL)"}
              variant="outlined"
              name="uricacid"
              type="number"
              InputProps={{
                inputProps: {
                  max: 600,
                  step: "0.01",
                },
              }}
            />
          </Box>

          <Box>
            <TextField
              margin="normal"
              fullWidth
              autoFocus
              label={"Cholesterol (mg/dL)"}
              variant="outlined"
              name="cholesterol"
              type="number"
              InputProps={{
                inputProps: {
                  max: 600,
                  step: "0.01",
                },
              }}
            />
          </Box>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box mb={3}>
            <FormControl margin="normal" fullWidth autoFocus variant="outlined">
              <InputLabel id="hepatitis"> Hepatitis </InputLabel>
              <Select
                labelId="hepatitis"
                label="Hepatitis"
                value={hepatitis}
                onChange={(e) => setHepatitis(e.target.value)}
                name="hepatitis"
              >
                <MenuItem value={"Negative"}> Negative </MenuItem>
                <MenuItem value={"Positive"}> Positive </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box mb={3}>
            <FormControl margin="normal" fullWidth autoFocus variant="outlined">
              <InputLabel id="hepatitis"> Tuberculosis </InputLabel>
              <Select
                labelId="hepatitis"
                label="Hepatitis"
                value={tuberculosis}
                name="tuberculosis"
                onChange={(e) => setTuberculosis(e.target.value)}
              >
                <MenuItem value={"Negative"}> Negative </MenuItem>
                <MenuItem value={"Positive"}> Positive </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box>
            <FormControl margin="normal" fullWidth autoFocus variant="outlined">
              <InputLabel id="hepatitis"> Dengue </InputLabel>
              <Select
                labelId="hepatitis"
                label="Hepatitis"
                value={dengue}
                onChange={(e) => setDengue(e.target.value)}
                name="dengue"
              >
                <MenuItem value={"Negative"}> Negative </MenuItem>
                <MenuItem value={"Positive"}> Positive </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Box>
            <Box>
              <TextField
                margin="normal"
                fullWidth
                autoFocus
                label={"Password"}
                variant="outlined"
                type="password"
                name="password"
              />
            </Box>
            <Box display="flex" mt={2}>
              <Button
                size="large"
                startIcon={
                  isProcess ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : (
                    <CheckCircleOutline />
                  )
                }
                disabled={isProcess}
                color="primary"
                variant="contained"
                type="submit"
              >
                Save
              </Button>
              <Box ml={2}>
                <Button
                  size="large"
                  startIcon={<HighlightOffIcon />}
                  color="default"
                  variant="contained"
                  onClick={cancel}
                >
                  No
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default HealthPreLaboratoryNew;
