import { Grid } from "@material-ui/core";
import React from "react";
import Container from "src/layout/Container";
import BasicInfo from "./BasicInfo";
import Password from "./Password";
import Username from "./Username";

const DoctorsAccount = () => {
  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Account",
          items: [{ name: "Dashboard", path: "/clinic/app/doctor" }],
        }}
        title={`Account Information`}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <BasicInfo />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Username />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Password />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default DoctorsAccount;
