import { Box, TextField, Typography, Button, CircularProgress  } from '@material-ui/core';
import React, { useState }  from 'react'; 
import axios from 'axios';
import Notify from 'src/notification/Notify';  



const Username = () =>{ 

    const user_id = localStorage.getItem('user_id') 
    const token = localStorage.getItem('token') 
    const [isprocess, setIsProcess] = useState(false) 

    const handleChangeUsername = (e) => {

        e.preventDefault();
        e.persist(); 

        var formdata = new FormData(e.target);
            formdata.set('token', token)   
            formdata.set('user_id', user_id)   
        var error = [];

        if(formdata.get('username').length === 0 || formdata.get('username').trim() === ''){
            error = 'error';
            Notify.fieldRequired('current username')
        }

        if(formdata.get('new_username').length === 0 || formdata.get('new_username').trim() === ''){
            error = 'error';
            Notify.fieldRequired('new username')
        }

        if(formdata.get('password').length === 0 || formdata.get('password').trim() === ''){
            error = 'error';
            Notify.fieldRequired('password')
        }

        if(error.length > 0){
            console.log("form has an error")
        }else{
            setIsProcess(true)
            axios.post(`telerad/account/change-username`, formdata)
            .then((response) =>{
                const data = response.data 
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data === 'success'){
                    e.target.reset()
                    Notify.successRequest('username updated, Page will reload') 
                    localStorage.setItem('username', formdata.get('new_username')) 
                }
            })
            .catch((error) =>{
                Notify.requestError(error)
            }).finally(() =>{
                setIsProcess(false)
            })
        }
 
    }


    return( 
        <>
            <Box mb={2}>
                <Typography variant="h6" color="textSecondary"> 
                    Username Information
                </Typography>
            </Box>

             <form onSubmit={ handleChangeUsername }> 
                 <Box className="gtc-textfield-noborder">
                    <TextField 
                        label=" Current Username "
                        fullWidth
                        variant="outlined"
                        value={ localStorage.getItem('username') }
                        InputProps={{ readOnly: true }}
                        name="username"
                    />
                 </Box>

                 <Box my={3}>
                    <TextField 
                        label=" New Username "
                        fullWidth
                        variant="outlined"
                        name="new_username"
                    />
                 </Box>

                 <Box my={3}>
                    <TextField 
                        label=" Enter your password "
                        fullWidth
                        variant="outlined"
                        name="password"
                        type="password"
                    />
                 </Box>
                 
                <Box display="flex" justifyContent={'flex-end'}>
                    <Box>
                        <Button size="large" variant={'contained'} color="default" type="reset">
                            Clear
                        </Button>
                    </Box>

                    <Box ml={3}>
                        <Button size="large" variant={'contained'} color="primary" type="submit" disabled={ isprocess } startIcon={ isprocess && <CircularProgress color="inherit" size={25} /> }>
                            Change Username
                        </Button>
                    </Box>
                </Box>
             </form>
        </>
    )
}

export default Username;