import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  FormHelperText,
  Card,
  CircularProgress,
  CardContent,
  Typography,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import Axios from "axios";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import { Formik } from "formik";
import * as Yup from "yup";
import CreatableSelect from "react-select/creatable";
import SelectStyle from "src/utils/SelectStyle";
import IsMountedRef from "src/utils/IsMountedRef";

const ReviewPatientFindings = ({
  imagingOrderId,
  getPatientsReview,
  resetDisplay,
  close,
}) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const selectStyle = SelectStyle();
  const [findings, setFindings] = useState(null);
  const [codes, setCodes] = useState([]);
  const mounted = IsMountedRef();

  const formdata = (object) => {
    const formData = new FormData();
    let px = [];

    findings.forEach((x) => {
      px.push(x.value);
    });

    formData.append("findings", px.join(", "));
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const handleSelectChange = (newValue) => {
    setFindings(newValue);
  };

  const getCodes = React.useCallback(async () => {
    let response = await Axios.get("telerad/codes/get-codelist", {
      params: {
        token: token,
      },
    });
    const data = response.data;
    if (mounted.current) {
      setCodes(data);
    }
  }, [mounted, token]);

  useEffect(() => {
    getCodes();
  }, [getCodes]);

  return (
    <>
      <Formik
        initialValues={{
          user_id: user_id,
          token: token,
          impressions: "",
          tuberculosis: "",
          imaging_id: imagingOrderId,
        }}
        validationSchema={Yup.object().shape({
          impressions: Yup.string().required(),
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          if (findings === null) {
            setErrors({ findings: "Finding is a required field." });
            return;
          }

          try {
            const request = await Axios.post(
              "telerad/order/findings/save-findings",
              formdata(values)
            );
            if (request.data === "success") {
              Notify.successRequest("finding added.");
              resetForm();
              getPatientsReview();
              resetDisplay();
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Card elevation={0}>
              <CardContent>
                <Box mb={3}>
                  <Typography variant="caption" color="textSecondary">
                    FINDINGS
                  </Typography>

                  <CreatableSelect
                    isMulti
                    isClearable={false}
                    options={codes}
                    styles={selectStyle}
                    name="findings"
                    onChange={(e) =>
                      handleSelectChange(e, setFieldValue, values.findings)
                    }
                  />

                  {errors.findings && (
                    <FormHelperText error>{errors.findings}</FormHelperText>
                  )}
                </Box>

                <Box mb={3}>
                  <TextField
                    error={Boolean(touched.impressions && errors.impressions)}
                    helperText={touched.impressions && errors.impressions}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.impressions}
                    fullWidth
                    name="impressions"
                    label="Impressions"
                    variant="outlined"
                    rows={3}
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    fullWidth
                    error={Boolean(touched.tuberculosis && errors.tuberculosis)}
                    helperText={touched.tuberculosis && errors.tuberculosis}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.tuberculosis}
                    name="tuberculosis"
                    label="Tuberculosis"
                    variant="outlined"
                  >
                    <option value={""}> Select </option>
                    <option value="negative"> Negative </option>
                    <option value="positive"> Positive </option>
                  </TextField>
                </Box>

                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Box display="flex" mt={2} justifyContent="flex-end">
                  <Box>
                    <Button
                      size="large"
                      variant="contained"
                      color="default"
                      onClick={() => {
                        resetForm();
                        close();
                      }}
                      startIcon={<ClearIcon />}
                      component={Box}
                      mr={2}
                    >
                      Clear
                    </Button>
                  </Box>
                  <Box ml={2}>
                    <Button
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <CheckIcon />
                        )
                      }
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ReviewPatientFindings;
