import { isMobile } from "react-device-detect";

import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import {
  Avatar,
  Paper,
  Zoom,
  Dialog,
  Badge,
  CircularProgress,
  Button,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { Send } from "@material-ui/icons";
import axios from "axios";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Cropper from "react-cropper";
import { XCircle } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const PatientDetailsHeader = ({ patient_id, getDoctorsPatient }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [info, setInfo] = useState({
    data: null,
    ready: false,
  });

  const [_uploadprocess, setUploadprocess] = useState(false);

  const [imgUploadTemp, setImgUploadTemp] = useState(null);
  const [openCropImage, setOpenCropImage] = useState(false);
  const [image, setImage] = useState(null);

  var profileimage = useRef();

  var cropperRef = useRef();

  const handleClickupload = () => {
    profileimage.click();
  };

  const getPatientInformation = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("patient_id", patient_id);

    axios
      .post("encoder/patient/patient-information", formdata)
      .then((response) => {
        const data = response.data;
        setInfo({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleUploadImage = (event) => {
    var file = event.target.files[0];
    if (file !== undefined) {
      var reader = new FileReader();
      reader.onloadend = function () {
        var b64 = reader.result;
        setImgUploadTemp(b64);
      };
      reader.readAsDataURL(file);
      setOpenCropImage(true);
    }
  };

  const handleUpdatePicture = () => {
    var formdata = new FormData();
    formdata.append("user_id", user_id);
    formdata.append("token", token);
    formdata.append("patient_id", patient_id);
    formdata.append("image", image === null ? "" : image);
    axios
      .post("encoder/update/patient-prof-pic", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("profile");
          getPatientInformation();
          setOpenCropImage(false);
          setImage(null);
          getDoctorsPatient();
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setUploadprocess(false);
      });
  };

  useEffect(() => {
    getPatientInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <>
      <Paper>
        <Box p={2}>
          {info.ready ? (
            info.data ? (
              <div className="clearfix">
                <div className="float-left">
                  <Badge
                    overlap="circle"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    badgeContent={
                      _uploadprocess ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <Box
                        // hidden={info.data.image !== null}
                        >
                          <CameraAltIcon
                            style={{
                              width: isMobile
                                ? 30
                                : window.innerWidth >= 899 &&
                                  window.innerWidth <= 959
                                ? 60
                                : window.innerWidth <= 898
                                ? 50
                                : 40,
                              height: isMobile
                                ? 30
                                : window.innerWidth >= 899 &&
                                  window.innerWidth <= 959
                                ? 60
                                : window.innerWidth <= 898
                                ? 50
                                : 40,
                              cursor: "pointer",
                            }}
                            onClick={handleClickupload}
                          />
                        </Box>
                      )
                    }
                  >
                    <Avatar
                      style={{
                        width: "120px",
                        height: "120px",
                        cursor: "pointer",
                      }}
                      alt="profile-pic"
                      src={
                        info.data.image !== null
                          ? imageLocation + "patients/" + info.data.image
                          : ""
                      }
                    >
                      {info.data.image === null ? "No Image" : ""}
                    </Avatar>
                    <input
                      type="file"
                      onChange={handleUploadImage}
                      className="d-none"
                      ref={(ref) => (profileimage = ref)}
                    />
                  </Badge>
                  {/* <Avatar
                  style={{
                    width: "120px",
                    height: "120px",
                  }}
                  src={
                    info.data.image !== null
                      ? imageLocation + "patients/" + info.data.image
                      : ""
                  }
                /> */}
                </div>
                <div className="ml-sm-4 float-left">
                  <p className="h5 header-label text-capitalize m-0 my-2 font-weight-bold">
                    {info.data.lastname}, {info.data.firstname}{" "}
                    {info.data.middle}
                  </p>
                  <p className="m-0 my-2">
                    <span className="text-muted gtc-small text-uppercase">
                      {" "}
                      Age:{" "}
                    </span>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    {Notify.calculateAge(info.data.birthday)}
                  </p>
                  <p className="m-0 my-2">
                    <span className="text-muted gtc-small text-uppercase">
                      {" "}
                      Gender:{" "}
                    </span>{" "}
                    &nbsp; {info.data.gender}
                  </p>
                  <p className="m-0 my-2">
                    <span className="text-muted gtc-small text-uppercase">
                      {" "}
                      Birthday:{" "}
                    </span>{" "}
                    {Notify.birthday(info.data.birthday)}
                  </p>
                </div>
              </div>
            ) : (
              Notify.noRecord()
            )
          ) : (
            Notify.loading()
          )}
        </Box>
      </Paper>

      {/* for copping */}
      <Dialog
        open={openCropImage}
        fullScreen
        onClose={() => setOpenCropImage(false)}
        TransitionComponent={Zoom}
        transitionDuration={900}
      >
        <Box
          display="flex"
          width="100%"
          height="80vh"
          justifyContent="center"
          alignItems="center"
        >
          <Cropper
            style={{ height: "100%", width: "100%" }}
            zoomTo={0.5}
            aspectRatio={1}
            src={image === null ? imgUploadTemp : image}
            viewMode={1}
            guides={false}
            background={false}
            responsive={true}
            dragMode={"move"}
            cropBoxResizable={true}
            checkOrientation={false}
            rotatable
            scalable
            ref={cropperRef}
          />
        </Box>
        <Box
          display="flex"
          width="100%"
          height={"10%"}
          justifyContent="center"
          alignItems="center"
        >
          <Box mr={2}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<Send />}
              disabled={imgUploadTemp === null}
              onClick={() => {
                const imageElement = cropperRef.current;
                const cropper = imageElement.cropper;
                setImage(cropper.getCroppedCanvas().toDataURL());
                setImgUploadTemp(null);
                // setOpenCropImage(false);
                // handleUpdatePicture();
              }}
            >
              Confirm
            </Button>
          </Box>

          <Box mr={2}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<Send />}
              disabled={image === null}
              onClick={handleUpdatePicture}
            >
              Save
            </Button>
          </Box>
          <Box mr={2}>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<XCircle />}
              onClick={() => {
                setOpenCropImage(false);
                setImgUploadTemp(null);
                setImage(null);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default PatientDetailsHeader;
