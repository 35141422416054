import { Grid, Paper, Typography, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import PharmacyWarehouseDelivery from "./dashboard/Delivery";
import PharmacyWarehouseDeliveryTempList from "./dashboard/TempList";
import Axios from "axios";

const PharmacyWarehouse = () => {
  const username = localStorage.getItem("username");
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const [tempProduct, setTempProduct] = useState({ data: [], ready: false });

  const getTempDrProductsList = React.useCallback(async () => {
    try {
      var formdata = new FormData();
      formdata.set("token", token);
      formdata.set("management_id", management_id);

      let response = await Axios.get(
        "pharmacy/warehouse/account/deliver/list-producttotemp",
        {
          params: {
            token: token,
            management_id: management_id,
          },
        }
      );

      let result = response.data;
      setTempProduct({ data: result, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [management_id, token]);

  useEffect(() => {
    getTempDrProductsList();
  }, [getTempDrProductsList]);

  return (
    <Container
      breadcrumbs={{
        enable: false,
        current: "SAMOPLPERLS PAERL PSE",
        items: [],
      }}
      title={
        <>
          <Typography variant={window.innerWidth > 500 ? "h4" : "h5"}>
            Dashboard
          </Typography>
          <Typography variant="subtitle2">
            Welcome back, {username}! 👋
          </Typography>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <PharmacyWarehouseDelivery
            getTempDrProductsList={() => getTempDrProductsList()}
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <Paper>
            <Box p={2}>
              <PharmacyWarehouseDeliveryTempList
                tempProduct={tempProduct}
                getTempDrProductsList={() => getTempDrProductsList()}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PharmacyWarehouse;
