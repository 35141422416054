import { Box, Typography } from "@material-ui/core";
import React from "react";
import Featured from "./Featured";
import List from "./List";
import NewsInDialog from "./NewsInDialog";

const GtcNews = ({ module }) => {
  return (
    <>
      <Box>
        <Typography variant={"subtitle1"} color="textSecondary">
          Featured News for {module}
        </Typography>
      </Box>

      <Box my={1}>
        {/* featured news with slider */}
        <Featured module={module} />
      </Box>

      <Box>
        {/* featured news with slider */}
        <List />
      </Box>

      <NewsInDialog />
    </>
  );
};

export default GtcNews;
