import React, { Fragment, useEffect, useState } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { TableContainer, Table, TableRow, TableCell, TableBody, TableHead, Box, TablePagination, Paper, TableFooter } from '@material-ui/core';

const Loadout = () => {
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('token')
    const management_id = localStorage.getItem('management_id')
    const [loadOut, setLoadOut] = useState([])
    const [loadOutRdy, setLoadOutRdy] = useState(false)
    const [totalLoadOut, setTotalLoadOut] = useState(0)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const getLoadout = () => {
        var params = {
            token: token,
            user_id: user_id,
            management_id: management_id,
        }
        Axios.get('patient/credits/credits-loadout', { params })
            .then((response) => {
                const data = response.data;
                setLoadOut(data);
                setLoadOutRdy(true);
                totalLoadout(data);
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const totalLoadout = (data) => {
        var total = 0;
        for (let index = 0; index < data.length; index++) {
            total += parseFloat(data[index].credit);
        }
        setTotalLoadOut(total)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        getLoadout();
        //eslint-disable-next-line
    }, [])

    return (
        <>
            {loadOutRdy && (
                <Fragment> 
                    <Box component={Paper} variant="outlined">
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"> Date </TableCell>
                                        <TableCell align="center"> Loadout # </TableCell>
                                        <TableCell align="center"> Trace # </TableCell>
                                        <TableCell align="center"> Account # </TableCell>
                                        <TableCell align="center"> Loaded By  </TableCell>
                                        <TableCell align="center"> Credit  </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? loadOut.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : loadOut
                                    ).map((data, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="right">{Notify.createdAt(data.purchase_on)}</TableCell>
                                            <TableCell align="right">{data.loadout_id.replace('loadout-', '')}</TableCell>
                                            <TableCell align="right">{data.trace_no.replace('gtc-', '')}</TableCell>
                                            <TableCell align="right">{data.account_no} </TableCell>
                                            <TableCell align="left">{data.process_by}</TableCell>
                                            <TableCell align="right">{data.credit}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                                <TableFooter>
                                    <TableCell colSpan={4} /> 
                                    <TableCell align="center"> Total  </TableCell>
                                    <TableCell align="right"> { Notify.convertToNumber(totalLoadOut) }  </TableCell>
                                </TableFooter>
                            </Table>

                            <TablePagination
                                component={"div"}
                                rowsPerPageOptions={[5, 10, 25]}
                                count={loadOut.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </Box>
                </Fragment>
            )}
        </>
    )
}

export default Loadout;