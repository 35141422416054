import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Box,
  TableBody,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ChemistryRef from "../references/Ref_Chemistry";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const ChemistryOrder = ({ formheader, orderDetails }) => {
  return (
    <>
      <Card>
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={formheader && formheader.name}
              subheader={formheader && formheader.address}
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={orderDetails} />
        </CardContent>

        <CardContent>
          <Box>
            <Typography variant="h6" align="center">
              <b> CLINICAL CHEMISTRY REPORT </b>
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="caption">
              <b> SPECIMEN: </b>
            </Typography>
            <span className="gtc-uppercase">{orderDetails.data.spicemen}</span>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <b> TEST REQUEST </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> RESULT </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> REFERENCE VALUES </b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderDetails.data.length > 0 ? (
                  orderDetails.data.map((data, key) => (
                    <Fragment key={key}>
                      {data.glucose && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              GLUCOSE
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.glucose}</Typography>
                          </TableCell>
                          <TableCell>{ChemistryRef.glucose()}</TableCell>
                        </TableRow>
                      )}
                      {data.creatinine && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              CREATININE
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.creatinine}</Typography>
                          </TableCell>
                          <TableCell>{ChemistryRef.creatinine()}</TableCell>
                        </TableRow>
                      )}
                      {data.uric_acid && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              URIC ACID
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.uric_acid}</Typography>
                          </TableCell>
                          <TableCell>{ChemistryRef.uric()}</TableCell>
                        </TableRow>
                      )}
                      {data.cholesterol && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              CHOLESTEROL
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.cholesterol}</Typography>
                          </TableCell>
                          <TableCell>{ChemistryRef.cholesterol()}</TableCell>
                        </TableRow>
                      )}
                      {data.triglyceride && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              TRIGLYCERIDE
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.triglyceride}</Typography>
                          </TableCell>
                          <TableCell>{ChemistryRef.triglyceride()}</TableCell>
                        </TableRow>
                      )}
                      {data.hdl_cholesterol && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              HDL CHOLESTEROL
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.hdl_cholesterol}</Typography>
                          </TableCell>
                          <TableCell>{ChemistryRef.hdlcholesterol()}</TableCell>
                        </TableRow>
                      )}
                      {data.ldl_cholesterol && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              LDL CHOLESTEROL
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.ldl_cholesterol}</Typography>
                          </TableCell>
                          <TableCell>{ChemistryRef.ldlcholesterol()}</TableCell>
                        </TableRow>
                      )}
                      {data.sgot && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              SGOT
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.sgot}</Typography>
                          </TableCell>
                          <TableCell>{ChemistryRef.sgot()}</TableCell>
                        </TableRow>
                      )}
                      {data.sgpt && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              SGPT
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.sgpt}</Typography>
                          </TableCell>
                          <TableCell>{ChemistryRef.sgpt()}</TableCell>
                        </TableRow>
                      )}

                      {data.fbs && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              fbs
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.fbs}</Typography>
                          </TableCell>
                          <TableCell>{/* {ChemistryRef.fbs()} */}</TableCell>
                        </TableRow>
                      )}

                      {data.bun && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              bun
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.bun}</Typography>
                          </TableCell>
                          <TableCell>{/* {ChemistryRef.fbs()} */}</TableCell>
                        </TableRow>
                      )}

                      {data.serum_uric_acid && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              SERUM URIC ACID
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.serum_uric_acid}</Typography>
                          </TableCell>
                          <TableCell>{/* {ChemistryRef.fbs()} */}</TableCell>
                        </TableRow>
                      )}

                      {data.alkaline_phosphatase && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              alkaline_phosphatase
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.alkaline_phosphatase}</Typography>
                          </TableCell>
                          <TableCell>{/* {ChemistryRef.fbs()} */}</TableCell>
                        </TableRow>
                      )}

                      {data.potassium && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              potassium
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.potassium}</Typography>
                          </TableCell>
                          <TableCell>{/* {ChemistryRef.fbs()} */}</TableCell>
                        </TableRow>
                      )}

                      {data.lipid_profile && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              Lipid Profile
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography>{data.lipid_profile}</Typography>
                          </TableCell>
                          <TableCell>{/* {ChemistryRef.fbs()} */}</TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box>
            <Footer formheader={formheader} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ChemistryOrder;
