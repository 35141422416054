import {
  Typography,
  Box,
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const UrinalysisOrder = ({ formheader, orderDetails }) => {
  return (
    <>
      <Card>
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={formheader && formheader.name}
              subheader={formheader && formheader.address}
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={orderDetails} />
        </CardContent>

        <CardContent>
          <Box>
            <Typography variant="h6" align="center">
              <b> URINALYSIS </b>
            </Typography>
          </Box>
          {orderDetails.data.length > 0 ? (
            orderDetails.data.map((data, index) => (
              <Fragment key={index}>
                {Boolean(parseInt(data.urinalysis)) && (
                  <Box>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">color :</span>
                            </Typography>
                          </Box>

                          <Box>
                            <TextField
                              fullWidth
                              required
                              margin="dense"
                              defaultValue={data.color}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                reaction :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              required
                              name="reaction[]"
                              margin="dense"
                              defaultValue={data.reaction}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                transparency :
                              </span>
                            </Typography>
                          </Box>

                          <Box>
                            <TextField
                              fullWidth
                              name="transparency[]"
                              margin="dense"
                              defaultValue={data.transparency}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                sp gravity :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="sp_gravity[]"
                              margin="dense"
                              defaultValue={data.sp_gravity}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                albumin (negative) :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="albumin[]"
                              margin="dense"
                              defaultValue={data.albumin}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                sugar (negative):
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="sugar[]"
                              margin="dense"
                              defaultValue={data.sugar}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                pus cells:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="pus_cell[]"
                              margin="dense"
                              defaultValue={data.pus_cell}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">R.B.C:</span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="rbc[]"
                              margin="dense"
                              defaultValue={data.rbc}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                epithelial cells :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="epithelial_cell[]"
                              margin="dense"
                              defaultValue={data.epithelial_cell}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                mucus threads :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="mucus_threads[]"
                              margin="dense"
                              defaultValue={data.mucus_threads}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                Color :renal cells :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="renal_cell[]"
                              margin="dense"
                              defaultValue={data.renal_cell}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                Color :yeast cells :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="yeast_cell[]"
                              margin="dense"
                              defaultValue={data.yeast_cell}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                {" "}
                                hyaline :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="hyaline[]"
                              margin="dense"
                              defaultValue={data.hyaline}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                rbc cast :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="rbc_cast[]"
                              margin="dense"
                              defaultValue={data.rbc_cast}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                wbc cast :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="wbc_cast[]"
                              margin="dense"
                              defaultValue={data.wbc_cast}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                coarse granular cast :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="coarse_granular_cast[]"
                              margin="dense"
                              defaultValue={data.coarse_granular_cast}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                fine granular cast :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="fine_granular_cast[]"
                              margin="dense"
                              defaultValue={data.fine_granular_cast}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                pus in clumps :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="pus_in_clumps[]"
                              margin="dense"
                              defaultValue={data.pus_in_clumps}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                rbc in clumps :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="rbc_in_clumps[]"
                              margin="dense"
                              defaultValue={data.rbc_in_clumps}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                calcium oxalate :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="calcium_oxalate[]"
                              margin="dense"
                              defaultValue={data.calcium_oxalate}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                uric acid :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              margin="dense"
                              defaultValue={data.uricacid}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                amorphous urate :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="amorphous_urate[]"
                              margin="dense"
                              defaultValue={data.amorphous_urate}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                amorphous phosphate :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="amorphous_phosphate[]"
                              margin="dense"
                              defaultValue={data.amorphous_phosphate}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                calcium carbonate :
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="calcium_carbonate[]"
                              margin="dense"
                              defaultValue={data.calcium_carbonate}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                ammonium biurate:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="ammonium_biurate[]"
                              margin="dense"
                              defaultValue={data.ammonium_biurate}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                triple phosphate:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="triple_phosphate[]"
                              margin="dense"
                              defaultValue={data.triple_phosphate}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                spermatozoa:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="spermatozoa[]"
                              margin="dense"
                              defaultValue={data.spermatozoa}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                trichomonas vaginalis:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="trichomonas_vaginalis[]"
                              margin="dense"
                              defaultValue={data.trichomonas_vaginalis}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                micral test:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="micral_test[]"
                              margin="dense"
                              defaultValue={data.micral_test}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box mt={1.5} mr={1} flexGrow={1}>
                            <Typography>
                              <span className="text-capitalize">
                                urine ketone:
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <TextField
                              fullWidth
                              name="urine_ketone[]"
                              margin="dense"
                              defaultValue={data.urine_ketone}
                              InputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box display="flex">
                          <Typography>
                            <strong> OTHER </strong>
                          </Typography>
                        </Box>

                        <Box>
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.others}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box display="flex">
                          <Typography>
                            <strong> RESULT: </strong>
                          </Typography>
                        </Box>

                        <Box>
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.remarks}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Fragment>
            ))
          ) : (
            <Box>
              <Typography variant="secondary">No record found.</Typography>
            </Box>
          )}

          <Box>
            <Footer formheader={formheader} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default UrinalysisOrder;
