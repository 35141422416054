import { useTheme } from "@material-ui/core";
import React from "react";
import { Line } from "react-chartjs-2";

const Female = ({ list }) => {
  const chartRef = React.useRef();
  const theme = useTheme();

  const label = [
    "Birth", // birth
    "2",
    "4",
    "6",
    "8",
    "10",

    "1 year", // 1year
    "2",
    "4",
    "6",
    "8",
    "10",

    "2 year", // 2 years
    "2",
    "4",
    "6",
    "8",
    "10",

    "3 year", // 3 years
    "2",
    "4",
    "6",
    "8",
    "10",
    "4 year",

    "2", // 4 years
    "4",
    "6",
    "8",
    "10",
    "5 year",
  ];

  const generateChildsGrowth = (list) => {
    let growth = [];

    if (list.length > 0) {
      growth.push(
        list[0]._birth_zero === null ? 0 : list[0]._birth_zero,
        list[0]._birth_two === null ? 0 : list[0]._birth_two,
        list[0]._birth_four === null ? 0 : list[0]._birth_four,
        list[0]._birth_six === null ? 0 : list[0]._birth_six,
        list[0]._birth_eight === null ? 0 : list[0]._birth_eight,

        list[0]._first_ten === null ? 0 : list[0]._first_ten,
        list[0]._first_zero === null ? 0 : list[0]._first_zero,
        list[0]._first_two === null ? 0 : list[0]._first_two,
        list[0]._first_four === null ? 0 : list[0]._first_four,
        list[0]._first_six === null ? 0 : list[0]._first_six,
        list[0]._first_eight === null ? 0 : list[0]._first_eight,

        list[0]._second_ten === null ? 0 : list[0]._second_ten,
        list[0]._second_zero === null ? 0 : list[0]._second_zero,
        list[0]._second_two === null ? 0 : list[0]._second_two,
        list[0]._second_four === null ? 0 : list[0]._second_four,
        list[0]._second_six === null ? 0 : list[0]._second_six,
        list[0]._second_eight === null ? 0 : list[0]._second_eight,

        list[0]._third_ten === null ? 0 : list[0]._third_ten,
        list[0]._third_zero === null ? 0 : list[0]._third_zero,
        list[0]._third_two === null ? 0 : list[0]._third_two,
        list[0]._third_four === null ? 0 : list[0]._third_four,
        list[0]._third_six === null ? 0 : list[0]._third_six,
        list[0]._third_eight === null ? 0 : list[0]._third_eight,

        list[0]._fourth_ten === null ? 0 : list[0]._fourth_ten,
        list[0]._fourth_zero === null ? 0 : list[0]._fourth_zero,
        list[0]._fourth_two === null ? 0 : list[0]._fourth_two,
        list[0]._fourth_four === null ? 0 : list[0]._fourth_four,
        list[0]._fourth_six === null ? 0 : list[0]._fourth_six,
        list[0]._fourth_eight === null ? 0 : list[0]._fourth_eight,
        list[0]._fourth_ten === null ? 0 : list[0]._fourth_ten,

        list[0]._fifth_zero === null ? 0 : list[0]._fifth_zero
      );
    } else {
      growth.push(0);
    }

    return growth;
  };

  const data = {
    labels: label,
    datasets: [
      {
        label: "Growth",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: `${theme.palette.primary.main}`,
        borderColor: `${theme.palette.primary.main}`,
        pointHoverRadius: 1,
        borderWidth: 2,
        data: generateChildsGrowth(list),
      },
      {
        label: "-3",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "#000",
        borderColor: "#000",
        pointHoverRadius: 1,
        borderWidth: 1,
        data: [
          43.8, // birth
          51,
          56,
          59,
          62,
          64,
          66,

          68, // first
          70,
          72,
          73.5,
          75,
          76.5,

          77.5, // second
          79,
          80,
          81,
          82,
          83.5,
          85, // third
          86,
          87,
          88,
          89,
          90,

          91, //  fourth
          91.8,
          92.5,
          93.5,
          94.3,
          95, // fifth
        ],
      },

      {
        label: "-2",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "red",
        borderColor: "red",
        pointHoverRadius: 1,
        borderWidth: 1,
        data: [
          45, // birth
          53,
          58,
          61,
          64,
          66.5,
          69,

          71, // first
          73,
          75,
          77,
          78.5,
          80,

          81, // second
          82,
          84,
          85,
          86,
          87.5,

          88.5, // third
          90,
          91,
          92,
          93,
          94,
          95, //  fourth

          96,
          97,
          98,
          99,
          100,
        ],
      },

      {
        label: "0",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "green",
        borderColor: "green",
        pointHoverRadius: 1,
        borderWidth: 1,
        data: [
          49, // birth
          57,
          62,
          66,
          69,
          71.5,
          74,

          76.5, // first
          78.5,
          81,
          82.5,
          84.5,
          86,

          87.5, // second
          89,
          90.7,
          92,
          93.5,
          95,

          96.5, // third
          98,
          99,
          100,
          101.5,
          102.5,
          103, //  fourth
          104,
          105,
          106,
          107,
          108,

          109, // fifth
        ],
      },

      {
        label: "-2",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "red",
        borderColor: "red",
        pointHoverRadius: 1,
        borderWidth: 1,
        data: [
          53, // birth
          61,
          66,
          70,
          73,
          76,
          79,

          82, // first
          84,
          85.5,
          87,
          88.5,
          91,
          93,

          94, // second
          96,
          98,
          99.5,
          101,
          102.5, // third

          104,
          106,
          107,
          108.5,
          110,
          111,

          112.5, //  fourth
          114,
          115,
          116.5,
          118,
          119, // fifth
        ],
      },

      {
        label: "3",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "#000",
        borderColor: "#000",
        pointHoverRadius: 1,
        borderWidth: 1,
        data: [
          55, // birth
          63,
          69,
          72.5,
          76,
          79,
          82,

          84.5, // first
          87,
          89.5,
          92.5,
          94,
          96,

          97.5, // second
          99.5,
          101,
          103,
          105,
          106.5,

          108, // third
          110,
          111,
          113.5,
          114,
          115.7,

          117, //  fourth
          118.5,
          120,
          121,
          122,
          124, // fifth
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    legend: {
      position: "bottom",
      cursor: "pointer",
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Length/Height(cm)",
          },
          min: 45,
          max: 125,
          ticks: {
            // forces step size to be 50 units
            stepSize: 5,
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Age(Completed months and years)",
          },
        },
      ],
    },
  };

  return <Line ref={chartRef} data={data} options={options} />;
};

export default Female;
