import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  AccessTime,
  MailOutline,
  Phone,
  Public,
  VerifiedUser,
} from "@material-ui/icons";

function Footer() {
  return (
    <div className="main-footer">
      <Col sm={12} className="py-5 ">
        <Row>
          <Col md={6} lg={3} className="text-center mb-md-5 mb-sm-3">
            <span className="text-primary">
              <AccessTime style={{ fontSize: 50 }} />
            </span>
            <br />
            <b className="icontext">WORKING HOURS</b>
            <h6 className="icontext">Mon-Sat (10am-5pm)</h6>
          </Col>
          <Col md={6} lg={3} className="text-center mb-md-5 mb-sm-3">
            <span className="text-primary">
              <Phone style={{ fontSize: 50 }} />
            </span>
            <br />
            <b className="icontext">CONTACT</b>
            <h6 className="icontext">(082) 287-5943</h6>
          </Col>
          <Col md={6} lg={3} className="text-center">
            <span className="text-primary">
              <MailOutline style={{ fontSize: 50 }} />
            </span>
            <br />
            <b className="icontext">EMAIL</b>
            <h6 className="icontext">globaltelemedicinecorp@gmail.com</h6>
          </Col>
          <Col md={6} lg={3} className="text-center">
            <span className="text-primary">
              <Public style={{ fontSize: 50 }} />
            </span>
            <br />
            <b className="icontext">WEB</b>
            <h6 className="icontext">globaltelemedicinecorp.net</h6>
          </Col>
          <Col md={6} lg={12} className="text-center">
            <span className="text-primary">
              <VerifiedUser style={{ fontSize: 50 }} />
            </span>
            <br />
            <b className="icontext">Privacy Policy</b>
            <h6 className="icontext">
              <a href="/clinic/app/privacy-policy"> Read our Privacy Policy </a>
            </h6>
          </Col>
        </Row>
      </Col>
      <div className="footer-bottom">
        <Col sm={12}>
          <Row className="gtctext text-small small mx-auto p-2">
            <Col sm={4} className="text-left">
              {/* <img src={`/clinic/gtc-logo.png`} style={{width:'130px'}} alt="" className="d-flex mx-auto pimglogo p-0"/> */}
              <img
                src={
                  process.env.REACT_APP_API.includes("https://api") ||
                  process.env.NODE_ENV === "development"
                    ? `/gtc-logo.png`
                    : `/clinic/gtc-logo.png`
                }
                style={{ width: "130px" }}
                alt=""
                className="d-flex mx-auto pimglogo p-0"
              />
            </Col>
            <Col sm={4} className="text-center p-0 mt-3">
              Copyright &copy; {new Date().getFullYear()}. All Right Reserved.
              Global Telemedicine Corp
            </Col>
            <Col sm={4} className="text-right p-0 mt-3">
              Created By: Global Telemedicine Corp
            </Col>
          </Row>
        </Col>
      </div>
    </div>
  );
}

export default Footer;
