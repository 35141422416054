
import { Box, Grid } from '@material-ui/core';
import React from 'react';
import GtcNews from 'src/gtcnews';
import AddNews from './AddNews';

const GTCNews = () => {
    return( 
        <>
            <Box m={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={7}> 
                        <Box>
                            <AddNews />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={5}> 
                        <Box>
                            <GtcNews />
                        </Box>
                    </Grid>
                </Grid>
            </Box> 
        </>
    )
}

export default GTCNews;