import React, { Component, Fragment } from 'react';  
import Notify from '../../notification/Notify';
import Axios from 'axios'; 
import { 
    Box,
    Typography, 
    Grid,
    TextField,  
    Button,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Slide,
    Collapse,
    Paper, 
} from '@material-ui/core'
  
import Utils from '../../utils/Utils'; 

import AddCircle from '@material-ui/icons/AddCircleOutline'; 
import HealthPreLaboratoryNew from './HealthPreLaboratoryNew';

export default class HealthPreLaboratory extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),
            _patient_id: this.props.patient_id,
            _health: [],
            _health_ready: false, 
            
            _hepatitis: null,
            _tuberculosis: null, 
            _dengue: null, 

            _is_process: false,
            _editenable: false
        }
    } 
    
    getHealthRecord(){ 

        var params = {
            token : this.state._token, 
            user_id : this.state._user_id,  
            management_id : this.state._management_id,
            patient_id : this.props.patient_id,   
            connection : this.props.connection, 
        }
        
        Axios.get('doctor/patient/patient-information', { params } )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                if(data.length > 0){
                    this.setState({
                        _health: data,
                        _health_ready: true,
                        _hepatitis: data[0].hepatitis, 
                        _tuberculosis: data[0].tuberculosis,
                        _dengue: data[0].dengue,
                    })
                } 
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    componentDidMount(){
        this._mounted = true;
        this.getHealthRecord();
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    componentDidUpdate(){
        if(this.state._patient_id !== this.props.patient_id){
            this.setState({
                _patient_id: this.props.patient_id,
                _health_ready: false,
                _editenable: false
            })
            this.componentDidMount();
        }
    }   

    handleNewHealth = () =>{
        this.setState({ _editenable: true }) 
    }

	render(){ 
		return(
            <Fragment>   
                <Box 
                    component={Paper}
                    elevation={2}
                    p={2}  
                >
                    <Box p={1} mb={2} display="flex">
                        <Box flexGrow={1}> 
                            <Typography variant="h6" color="textSecondary"> 
                                Medical Record
                            </Typography> 
                            <Typography variant="caption" color={`textSecondary`} className={`gtc-uppercase`} >
                                - { this.state._health.length > 0 ?   `last updated in ${ Notify.createdAt(this.state._health[0].updated_at) }` :null } 
                            </Typography>
                        </Box> 
                        <Box >
                            <Button 
                                startIcon={<AddCircle />} 
                                variant="text"
                                color="primary"
                                onClick={ this.handleNewHealth }
                                hidden = { this.state._editenable }
                            >
                                Update
                            </Button>
                        </Box>
                    </Box>   
                    
                    <Collapse 
                        in={ this.state._editenable } 
                        timeout={500}
                    >
                        <HealthPreLaboratoryNew 
                            patient_id ={ this.props.patient_id } 
                            cancel = { () => this.setState({ _editenable: false }) }  
                            prelaboratory = { this.getHealthRecord.bind(this) } 
                            connection =  { this.props.connection }
                        />
                    </Collapse>

                    { 
                        this.state._health_ready ?
                            this.state._health.length > 0 ?
                                <Slide hidden={ this.state._editenable } direction={'up'} in={ !this.state._editenable } timeout={500}>
                                    <Grid container spacing={3}>
                                        <Grid item sm={4} xs={12}>
                                            <Box mb={3}>   
                                                <TextField 
                                                    margin="normal"
                                                    fullWidth
                                                    autoFocus
                                                    label={ <span> Temperature (&#8451;) </span> } 
                                                    variant="outlined"
                                                    value={Utils.zeroChecker(this.state._health[0].temperature)}
                                                    inputProps={{ readOnly: true  }}
                                                /> 
                                            </Box>
                
                                            <Box mb={3}> 
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <TextField 
                                                            margin="normal"
                                                            fullWidth
                                                            autoFocus
                                                            label={'BP Systolic'} 
                                                            variant="outlined"
                                                            value={  Utils.zeroChecker(this.state._health[0].blood_systolic)  }
                                                            inputProps={{ readOnly: true  }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <TextField 
                                                            margin="normal"
                                                            fullWidth
                                                            autoFocus
                                                            label={'Diastolic (mmHg)'} 
                                                            variant="outlined"
                                                            value={ Utils.zeroChecker(this.state._health[0].blood_diastolic)  }
                                                            inputProps={{ readOnly: true  }}
                                                        />
                                                    </Grid>
                                                </Grid> 
                                            </Box>
                                            
                                            <Box mb={3}> 
                                                <TextField 
                                                    margin="normal"
                                                    fullWidth
                                                    autoFocus
                                                    label={'Hearth Pulse (BPM)'} 
                                                    variant="outlined"
                                                    value={ Utils.zeroChecker(this.state._health[0].pulse)  }
                                                    inputProps={{ readOnly: true  }}
                                                />  
                                            </Box>
                
                                            <Box mb={3}> 
                                                <TextField 
                                                    margin="normal"
                                                    fullWidth
                                                    autoFocus
                                                    label={'Respiratory (BPM)'} 
                                                    variant="outlined"
                                                    value={ Utils.zeroChecker(this.state._health[0].rispiratory) }
                                                    inputProps={{ readOnly: true  }}
                                                />
                                            </Box>  
                                        </Grid>

                                        <Grid item sm={4} xs={12}>   
                                            <Box mb={3}> 
                                                <TextField 
                                                    margin="normal"
                                                    fullWidth
                                                    autoFocus
                                                    label={'Weight (kg)'} 
                                                    variant="outlined"
                                                    value={ Utils.zeroChecker(this.state._health[0].weight) }
                                                    inputProps={{ readOnly: true  }}
                                                /> 
                                            </Box>

                                            <Box mb={3}> 
                                                <TextField 
                                                    margin="normal"
                                                    fullWidth
                                                    autoFocus
                                                    label={'Glucose (mg/dL)'} 
                                                    variant="outlined"
                                                    defaultValue={ Utils.zeroChecker(this.state._health[0].glucose) }
                                                    inputProps={{ readOnly: true  }}
                                                /> 
                                            </Box>
                                            
                                            <Box mb={3}> 
                                                <TextField 
                                                    margin="normal"
                                                    fullWidth
                                                    autoFocus
                                                    label={'Uric Acid (mg/dL)'} 
                                                    variant="outlined"
                                                    defaultValue={ Utils.zeroChecker(this.state._health[0].uric_acid) }
                                                    inputProps={{ readOnly: true  }}
                                                /> 
                                            </Box>

                                            <Box mb={3}> 
                                                <TextField 
                                                    margin="normal"
                                                    fullWidth
                                                    autoFocus
                                                    label={'Cholesterol (mg/dL)'} 
                                                    variant="outlined"
                                                    defaultValue={ Utils.zeroChecker(this.state._health[0].cholesterol) }
                                                    inputProps={{ readOnly: true  }}
                                                /> 
                                            </Box> 
                                        </Grid> 
                                        
                                        <Grid item sm={4} xs={12}>  
                                        <Box mb={3}>   
                                            <FormControl
                                                margin="normal"
                                                fullWidth
                                                autoFocus
                                                variant="outlined"
                                            >
                                                <InputLabel id="hepatitis"> Hepatitis </InputLabel>
                                                <Select 
                                                    labelId="hepatitis"
                                                    label="Hepatitis"
                                                    value={ this.state._hepatitis === null ? '' : this.state._hepatitis } 
                                                    inputProps={{ readOnly: true  }}
                                                >
                                                    <MenuItem value={'Positive'}> Positive </MenuItem>
                                                    <MenuItem value={'Negative'}> Negative </MenuItem> 
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Box mb={3}> 
                                            <FormControl
                                                margin="normal"
                                                fullWidth
                                                autoFocus
                                                variant="outlined"
                                            >
                                                <InputLabel id="hepatitis"> Tuberculosis </InputLabel>
                                                <Select 
                                                    labelId="hepatitis"
                                                    label="Hepatitis" 
                                                    value={ this.state._tuberculosis === null ? '' : this.state._tuberculosis } 
                                                    inputProps={{ readOnly: true  }}
                                                >
                                                    <MenuItem value={'Positive'}> Positive </MenuItem>
                                                    <MenuItem value={'Negative'}> Negative </MenuItem> 
                                                </Select>
                                            </FormControl> 
                                        </Box>

                                        <Box mb={3}> 
                                            <FormControl
                                                margin="normal"
                                                fullWidth
                                                autoFocus
                                                variant="outlined"
                                            >
                                                <InputLabel id="hepatitis"> Dengue </InputLabel>
                                                <Select 
                                                    labelId="hepatitis"
                                                    label="Hepatitis" 
                                                    value={ this.state._dengue === null ? '' : this.state._dengue } 
                                                    inputProps={{ readOnly: true  }}
                                                >
                                                    <MenuItem value={'Positive'}> Positive </MenuItem>
                                                    <MenuItem value={'Negative'}> Negative </MenuItem> 
                                                </Select>
                                            </FormControl> 
                                        </Box>
                                    </Grid>
                                    </Grid>
                                </Slide>
                            : Notify.noRecord()
                        : Notify.loading()
                    } 
                </Box>    
            </Fragment>
        )
	}
} 