import React from "react";
import {
  Avatar,
  Badge,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import Notify from "src/notification/Notify";

const RadiologistPatientForReview = ({
  patients,
  updateSelected,
  selectedPatient,
}) => {
  return (
    <Paper variant="outlined" component={Box} p={2}>
      <Box mb={2}>
        <Typography variant="h6" color="textSecondary">
          <Badge badgeContent={patients.data.length} color="secondary">
            Patient Listsdfsdf
          </Badge>
        </Typography>
      </Box>

      <List component="div">
        {patients.ready
          ? patients.data.length > 0
            ? patients.data.map((data, index) => {
                return (
                  <ListItem
                    button
                    key={index}
                    selected={data === selectedPatient}
                    onClick={() => updateSelected(data)}
                  >
                    <ListItemIcon>
                      <Avatar />
                    </ListItemIcon>
                    <ListItemText
                      primary={data.patient_name}
                      className={`gtc-capitalize`}
                    />
                  </ListItem>
                );
              })
            : Notify.noRecord()
          : Notify.loading()}
      </List>
    </Paper>
  );
};

export default RadiologistPatientForReview;
