import React, { Fragment, useEffect, useState } from "react";
import Axios from "axios";
import Notify from "../../notification/Notify";
import { TimePicker, DatePicker } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import {
  TextField,
  //  Select, MenuItem, handleChange,
  Typography,
  Button,
  Grid,
  CircularProgress,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import HighlightOff from "@material-ui/icons/HighlightOff";
import ReactQuill from "react-quill";

function PatientAppointmentLocalCreate(props) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [iscreateprocess, setiscreateprocess] = useState(false);

  const [token] = useState(localStorage.getItem("token"));
  const [user_id] = useState(localStorage.getItem("user_id"));
  const [username] = useState(localStorage.getItem("username"));

  const [setservices] = useState([]);
  const [patientinfo, setpatientinfo] = useState([]);
  const [patientinfoReady, setpatientinfoReady] = useState(false);

  const [editAllergies, setEditAllergies] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const getDoctorsServices = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("doctors_id", user_id);

    Axios.post("appointment/doctors/doctors-services", formdata)
      .then((response) => {
        const data = response.data;
        setservices(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleCreateAppointmentLocal = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("username", username);
    formdata.set("doctors_id", user_id);
    formdata.set("patient_id", props.patient_id);
    formdata.set("allergies", editAllergies.replace("<p><br></p>", ""));
    var error = [];
    if (
      formdata.get("app_date").length === 0 ||
      formdata.get("app_date").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("appointment date");
    }
    if (
      formdata.get("app_time").length === 0 ||
      formdata.get("app_time").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("appointment time");
    }
    if (
      formdata.get("app_reason").length === 0 ||
      formdata.get("app_reason").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("appointment reason");
    }
    if (
      formdata.get("medication").length === 0 ||
      formdata.get("medication").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("medication");
    }
    if (
      formdata.get("allergies").length === 0 ||
      formdata.get("allergies").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("allergies");
    }
    if (
      formdata.get("height").length === 0 ||
      formdata.get("height").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("height");
    }
    if (
      formdata.get("weight").length === 0 ||
      formdata.get("weight").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("weight");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }
    if (error.length > 0) {
      console.warn("Unable to process, Form has an error");
    } else {
      setiscreateprocess(true);
      Axios.post("appointment/doctors/local/createapppointment", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            props.closecreateDialog();
            props.getappointmentLocalRecord();
            Notify.successRequest("create appointment");
          }
          if (data === "has-appointment") {
            Notify.customToast(
              "active appointment",
              "Patient has an active appointment."
            );
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setiscreateprocess(false);
        });
    }
  };

  const getPatientInformation = () => {
    const params = {
      token,
      patient_id: props.patient_id,
    };

    Axios.get("doctor/patient/patient-information", { params })
      .then((response) => {
        const data = response.data;
        setpatientinfo(data);
        setpatientinfoReady(true);
        setEditAllergies(
          data.length > 0
            ? data[0].allergies === null
              ? ""
              : data[0].allergies
            : ""
        );
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getPatientInformation();
    getDoctorsServices();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.patient_id]);

  return (
    <Fragment>
      <form onSubmit={handleCreateAppointmentLocal}>
        {patientinfoReady ? (
          patientinfo.length > 0 ? (
            <Fragment>
              <DialogContent dividers>
                <Typography color="primary" variant="subtitle2">
                  APPOINTMENT INFORMATION
                </Typography>
                {/* oldrecord hidden files */}
                <TextField
                  hidden
                  name="old_medication"
                  defaultValue={patientinfo[0].medication}
                />

                <TextField
                  hidden
                  name="old_allergies"
                  defaultValue={patientinfo[0].allergies}
                />

                <TextField
                  hidden
                  name="old_height"
                  defaultValue={patientinfo[0].height}
                />

                <TextField
                  hidden
                  name="old_weight"
                  defaultValue={patientinfo[0].weight}
                />

                <TextField
                  hidden
                  name="old_reason"
                  defaultValue={patientinfo[0].remarks}
                />

                <TextField
                  hidden
                  name="old_barangay"
                  defaultValue={patientinfo[0].barangay}
                />

                <TextField
                  hidden
                  name="old_street"
                  defaultValue={patientinfo[0].street}
                />

                <TextField
                  hidden
                  name="old_city"
                  defaultValue={patientinfo[0].city}
                />

                <TextField
                  hidden
                  name="old_zip"
                  defaultValue={patientinfo[0].zip}
                />

                <TextField
                  hidden
                  name="old_occupation"
                  defaultValue={patientinfo[0].occupation}
                />

                <Box mb={1} mt={1}>
                  <DatePicker
                    disablePast
                    fullWidth
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date picker dialog"
                    format="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    inputVariant="outlined"
                    name="app_date"
                  />
                </Box>

                <Box mb={1}>
                  <TimePicker
                    fullWidth
                    margin="normal"
                    id="time-picker"
                    label="Time picker"
                    value={selectedDate}
                    inputVariant="outlined"
                    onChange={handleDateChange}
                    name="app_time"
                  />
                </Box>

                <Box mb={1}>
                  <TextField
                    fullWidth
                    label="Reason of Appointment"
                    margin="normal"
                    variant="outlined"
                    multiline
                    name="app_reason"
                  />
                </Box>

                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      label="Service"
                      margin="normal"
                      variant="outlined"
                      name="service"
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      label="service fee"
                      margin="normal"
                      variant="outlined"
                      name="service_fee"
                      type="number"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>

                <Box my={1}>
                  <Typography color="primary" variant="subtitle2">
                    PERSONAL INFORMATION
                  </Typography>
                </Box>

                <Box mb={1}>
                  <TextField
                    fullWidth
                    label="Medication"
                    margin="normal"
                    variant="outlined"
                    multiline
                    name="medication"
                    defaultValue={patientinfo[0].medication}
                  />
                </Box>

                <Box mb={1}>
                  {/* <TextField 
                                                    fullWidth
                                                    label="Allergies" 
                                                    margin="normal" 
                                                    variant="outlined"
                                                    multiline
                                                    name="allergies"
                                                    defaultValue={ patientinfo[0].allergies } 
                                                /> */}
                  <span className="gtc-formlabel text-uppercase">
                    {" "}
                    Allergies{" "}
                  </span>
                  <ReactQuill
                    name="allergies"
                    theme="snow"
                    value={editAllergies}
                    placeholder="Enter your allergies here."
                    onChange={(e) => setEditAllergies(e)}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      label="Height"
                      margin="normal"
                      variant="outlined"
                      name="height"
                      defaultValue={patientinfo[0].height}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      label="Weight"
                      margin="normal"
                      variant="outlined"
                      name="weight"
                      defaultValue={patientinfo[0].weight}
                    />
                  </Grid>
                </Grid>
                <Box mb={1}>
                  <TextField
                    type="password"
                    fullWidth
                    label="Enter password"
                    margin="normal"
                    variant="outlined"
                    name="password"
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  className="ml-2"
                  onClick={props.closecreateDialog}
                  startIcon={<HighlightOff />}
                >
                  {" "}
                  No{" "}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  startIcon={
                    iscreateprocess ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : (
                      <AddCircleOutline />
                    )
                  }
                >
                  {" "}
                  Create{" "}
                </Button>
              </DialogActions>
            </Fragment>
          ) : (
            Notify.noRecord()
          )
        ) : (
          Notify.loading()
        )}
      </form>
    </Fragment>
  );
}

export default PatientAppointmentLocalCreate;
