import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Axios from "axios";
import React, { useEffect, useState } from "react";

const AccountLockDialog = () => {
  const is_login = localStorage.getItem("is_login");
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");
  const user_id = localStorage.getItem("user_id");
  const [message, setMessage] = useState("");
  const [lock, setLock] = useState(false);
  const [loading, setLoading] = useState(false);
  var interval = null;

  const sessionCheck = async () => {
    try {
      await Axios.get("user/account/validate/8724g03lfs39", {
        params: {
          token,
          type,
          user_id,
        },
      });
    } catch (error) {
      if (error.message === "Request failed with status code 401") {
        setLock(true);
      }
    }
  };

  const relogin = async (e) => {
    e.preventDefault();
    e.persist();
    setLoading(true);
    var formdata = new FormData(e.target);
    try {
      const response = await Axios.post("welcome/login", formdata);
      if (response.data.msg === "account-invalid") {
        setMessage("Username or password invalid.");
      } else {
        localStorage.setItem("user_id", response.data[0].user_id);
        localStorage.setItem("type", response.data[0].type);
        localStorage.setItem("username", response.data[0].username);
        localStorage.setItem("token", response.data[0].token);
        localStorage.setItem("management_id", response.data[0].manage_by);
        localStorage.setItem("is_login", response.data[0].is_login);
        window.location.reload();
      }

      setLoading(false);
    } catch (error) {
      console.log("relogin error:", error);
    }
  };

  const initializeInterval = () => {
    interval = setInterval(() => {
      sessionCheck();
    }, 180000);
  };

  useEffect(() => {
    if (is_login) {
      initializeInterval();
      sessionCheck();
    }

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={lock}
      onClose={() => console.log("Dialog unclosable.")}
      maxWidth={"xs"}
    >
      <DialogTitle>Session Lock</DialogTitle>
      <form onSubmit={relogin}>
        <DialogContent dividers>
          {message && (
            <Box mb={2}>
              <Alert variant="standard" severity="error">
                Credentials Error!
              </Alert>
            </Box>
          )}

          <Box mb={2}>
            <TextField
              label="Username"
              fullWidth
              variant="outlined"
              name="username"
              InputProps={{
                readOnly: true,
              }}
              value={localStorage.getItem("username")}
            />
          </Box>

          <Box>
            <TextField
              required
              label="Password"
              fullWidth
              variant="outlined"
              name="password"
              type="password"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => (window.location.href = "/clinic/app/logout")}
          >
            Logout
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            startIcon={
              loading && <CircularProgress size={25} color="inherit" />
            }
          >
            Relogin
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AccountLockDialog;
