import React, { useEffect, useState, useContext } from "react";
import {
  Paper,
  Box,
  Typography,
  // TableContainer, Table, TableHead, TableRow, TableBody, TableCell,
  Collapse,
  IconButton,
  TablePagination,
  Badge,
  useTheme,
  //   Divider,
  Tooltip,
} from "@material-ui/core";
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowRight';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';
import Notify from "src/notification/Notify";
import axios from "axios";
import ClinicalMicroscopy from "../orderform/ClinicalMicroscopy";
import Hemathology from "../orderform/Hemathology";
import SerologyOrder from "../orderform/SerologyOrder";
import ChemistryOrder from "../orderform/ChemistryOrder";
import FecalOrder from "../orderform/FecalOrder";
import { PatientsUnRead } from "src/ContextAPI";
import {
  MinusCircle,
  PlusCircle as PlusCircleIcon,
  Printer as PrinterIcon,
} from "react-feather";
import PrintableOrder from "./PrintableOrder";
import UrinalysisOrder from "../orderform/UrinalysisOrder";

const PaidOrder = ({
  connection,
  patient_id,
  // getPatientsList, getPermission, permission
}) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");

  const theme = useTheme();
  const [paid, setPaid] = useState({ data: [], ready: false });

  const [selectedTracenumber, setSelectedTracenumber] = useState(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const unread = useContext(PatientsUnRead);

  const [printableOrder, setPrintableOrder] = useState({
    trace_number: null,
    labmngId: null,
  });

  const checkPatientUnreadNotif = (category, order_id) => {
    let xx = unread.unviewNotif;
    let count = 0;
    for (let i = 0; i < xx.length; i++) {
      if (
        patient_id === xx[i].patient_id &&
        category === xx[i].category &&
        order_id === xx[i].order_id
      ) {
        count += 1;
      }
    }

    return count;
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const getpaidLaboratoryOrder = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", connection);

    axios
      .post("doctor/patient/laboratory/order/paid-list", formdata)
      .then((res) => {
        const data = res.data;
        setPaid({ data, ready: true });
      })
      .catch((er) => Notify.requestError(er));
  };

  // const getpaidLaboratoryOrderDetails = (orderid) => {
  //     setSelectedTracenumber(selectedTracenumber === orderid ? null : orderid)
  //     var formdata = new FormData();
  //     formdata.set('token', token);
  //     formdata.set('user_id', user_id);
  //     formdata.set('patient_id', patient_id);
  //     formdata.set('order_id', orderid);
  //     formdata.set('connection', connection)

  //     axios.post('doctor/patient/doc-notif-update', formdata)
  //     .then((res) => {
  //         getPatientsList()
  //         getPermission()
  //         getpaidLaboratoryOrder()
  //     }).catch((er) => Notify.requestError(er))

  // }

  useEffect(() => {
    getpaidLaboratoryOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  const applyPagination = (order, page, limit) => {
    return order.slice(page * limit, page * limit + limit);
  };

  const paginatedOrder = applyPagination(paid.data, page, rowsPerPage);

  return (
    <>
      <Box mb={2}>
        <Typography variant="h6" color="textSecondary">
          Laboratory List
        </Typography>
      </Box>

      {paid.ready
        ? paid.data.length > 0
          ? paginatedOrder.map((data, index) => (
              <Paper elevation={2} key={index} component={Box} mb={2} p={2}>
                <Box display="flex">
                  <Box flexGrow={1} mt={1}>
                    <Typography variant="h6">
                      {Notify.dateTimeConvert(data.created_at)}
                    </Typography>
                  </Box>
                  <Box>
                    <Tooltip
                      title={
                        data.management_id === management_id
                          ? "Print Order"
                          : "Print Unavailable"
                      }
                    >
                      <span>
                        <IconButton
                          disabled={
                            data.management_id === management_id ? false : true
                          }
                          onClick={() =>
                            setPrintableOrder({
                              trace_number: data.trace_number,
                              labmngId: data.management_id,
                            })
                          }
                        >
                          <PrinterIcon color={theme.palette.primary.light} />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <IconButton
                      onClick={() =>
                        setSelectedTracenumber(
                          selectedTracenumber === data.trace_number
                            ? null
                            : data.trace_number
                        )
                      }
                    >
                      <Badge
                        invisible={
                          !Boolean(
                            parseInt(
                              checkPatientUnreadNotif(
                                "laboratory",
                                data.trace_number
                              )
                            )
                          )
                        }
                        variant="dot"
                        color="error"
                      >
                        {selectedTracenumber === data.trace_number ? (
                          <MinusCircle color={theme.palette.error.main} />
                        ) : (
                          <PlusCircleIcon color={theme.palette.primary.main} />
                        )}
                      </Badge>
                    </IconButton>
                  </Box>
                </Box>

                {/* collapse for order details */}
                <Collapse in={selectedTracenumber === data.trace_number}>
                  {selectedTracenumber === data.trace_number && (
                    <Box>
                      <Hemathology
                        trace_number={selectedTracenumber}
                        patient_id={patient_id}
                      />
                      <ClinicalMicroscopy
                        trace_number={selectedTracenumber}
                        patient_id={patient_id}
                      />
                      <SerologyOrder
                        trace_number={selectedTracenumber}
                        patient_id={patient_id}
                      />
                      <ChemistryOrder
                        trace_number={selectedTracenumber}
                        patient_id={patient_id}
                      />
                      <FecalOrder
                        trace_number={selectedTracenumber}
                        patient_id={patient_id}
                      />
                      <UrinalysisOrder
                        trace_number={selectedTracenumber}
                        patient_id={patient_id}
                      />
                    </Box>
                  )}
                </Collapse>
              </Paper>
            ))
          : Notify.noRecord()
        : Notify.loading()}

      <TablePagination
        labelRowsPerPage="List"
        rowsPerPageOptions={[5, 20, 50, 100]}
        component="div"
        count={paid.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {/* printable order by orderid and patient id */}
      {Boolean(printableOrder.trace_number) &&
        Boolean(printableOrder.labmngId) && (
          <PrintableOrder
            labownerid={printableOrder.labmngId}
            trace_number={printableOrder.trace_number}
            patient_id={patient_id}
            close={() =>
              setPrintableOrder({ trace_number: null, labmngId: null })
            }
          />
        )}
    </>
  );
};

export default PaidOrder;
