import React, { Component, Fragment } from 'react';  
import Notify from '../../../notification/Notify';
import Axios from 'axios'; 
import { Box, Card, CardContent, Typography } from '@material-ui/core';

export default class ImagingUnprocess extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _patient_id: this.props.patient_id,
            _imagingType: this.props.imagingType,

            _imaging: [],
            _imaging_ready: false,
        }
    } 
    
    componentDidMount(){
        this._mounted = true;
        this.getImagingOngoing();
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    getImagingOngoing(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id);    
        formdata.set('patient_id', this.props.patient_id);    
        formdata.set('connection', this.props.imagingType === 'virtual-imaging' ? 'online' : 'local')
        
        Axios.post('doctor/patient/imaging/imaging-unprocess', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _imaging: data,
                    _imaging_ready: true,
                }) 
            }
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    componentDidUpdate(){
        if(this.state._patient_id !== this.props.patient_id){
            this.setState({
                _patient_id: this.props.patient_id,
                _imagingType: this.props.imagingType,
                _imaging_ready: false,
            })
            this.componentDidMount()
        }
    }

	render(){ 
		return(
            <Fragment> 
                <Box>
                    {
                        this.state._imaging_ready ? 
                            this.state._imaging.length > 0 ?
                                this.state._imaging.map((data, index) =>{
                                    return(
                                        <Card 
                                            component={Box}
                                            borderLeft={5}
                                            borderColor={`#17a2b8`}
                                            key={index}  
                                            mb={2}
                                        >
                                            <CardContent>
                                                <Box>
                                                    <Typography variant="subtitle1">
                                                        { data.imaging_order }
                                                    </Typography> 
                                                </Box>

                                                <Box my={1}>
                                                    <Typography variant="subtitle2" color="text-uppercase">
                                                        { data.imaging_remarks }
                                                    </Typography> 
                                                </Box>
  
                                                <Box>
                                                    <Typography variant="subtitle2" className={`gtc-uppercase`} color="text-uppercase">
                                                        order on { Notify.createdAt(data.created_at) } 
                                                    </Typography> 
                                                </Box>
                                            </CardContent>
                                        </Card> 
                                    )
                                })
                            : <Card> <CardContent> { Notify.noRecord() } </CardContent> </Card>
                        : <Card> <CardContent> { Notify.loading() } </CardContent> </Card> 
                    } 
                </Box>
            </Fragment>
        )
	}
}
