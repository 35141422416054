
import React, { useCallback, useEffect, useState } from 'react'
import { Box, IconButton, Paper, Typography, Button, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Dialog, DialogTitle, DialogContent, TextField, DialogActions, CircularProgress } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Axios from 'axios';
import Notify from 'src/notification/Notify';
import CanceIcon from '@material-ui/icons/CancelOutlined'
import LikeIcon from '@material-ui/icons/ThumbUp'
import BlockIcon from '@material-ui/icons/Block';
import Label from 'src/utils/Label';

const ConfirmOrderFromVPharm = ({ selectedOrder, close }) => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')

    const [rxOrder, setRxOrder] = useState({
        data: [],
        ready: false
    })

    const [addOn, setAddOn] = useState({
        data: [],
        ready: false
    })

    const [unpaid, setUnpaid] = useState({
        data: [],
        ready: false
    })

    const [deliveryFee, setDeliveryFee] = useState(0);
    const [totalRx, setTotalRx] = useState(0);
    const [totalAddon, setTotalAddon] = useState(0);
    const [totalUnpaidConsu, setTotalUnpaidConsu] = useState(0);

    const [approveDialog, setApproveDialog] = useState(false)
    const [cancelDialog, setCancelDialog] = useState(false)

    const [orderMethod, setOrderMethod] = useState('pickup');
    const [approveProcess, setApproveProcess] = useState(false)
    const [cancelProcess, setCancelProcess] = useState(false)


    const getRxOrderDetails = useCallback(async () => {
        try {
            var params = {
                token: token,
                user_id: user_id,
                order_no: selectedOrder.order_no,
                is_rx: 1,
            }
            const request = await Axios.get('patient/cart/order/forapproval-itemdetails', { params })
            if (request.data.length > 0) {
                if (parseInt(request.data[0].delivery) === 1) {
                    setDeliveryFee(request.data[0].delivery_fee)
                    setOrderMethod('delivery')
                }
            }
            calculateTotal(request.data, 'rx')
            setRxOrder({
                ready: true,
                data: request.data
            })
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, user_id, selectedOrder])

    const getAddOnOrderDetails = useCallback(async () => {
        try {
            var params = {
                token: token,
                user_id: user_id,
                order_no: selectedOrder.order_no,
                is_rx: 0,
            }
            const request = await Axios.get('patient/cart/order/forapproval-itemdetails', { params })
            if (request.data.length > 0) {
                if (parseInt(request.data[0].delivery) === 1) {
                    setDeliveryFee(request.data[0].delivery_fee)
                    setOrderMethod('delivery')
                }
            }
            calculateTotal(request.data, 'addon')
            setAddOn({
                ready: true,
                data: request.data
            })
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, user_id, selectedOrder])

    const getPatientUnpaidConsultation = useCallback(async () => {
        try {
            var params = {
                token: token,
                user_id: user_id,
                order_no: selectedOrder.order_no,
            }
            const request = await Axios.get('patient/cart/order/forapproval-consultation-unpaid', { params })
            setTotalUnpaidConsu(request.data[0].TotalUnpaid)
            setUnpaid({
                ready: true,
                data: request.data
            })
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, user_id, selectedOrder])

    useEffect(() => {
        getRxOrderDetails()
        getAddOnOrderDetails()
        getPatientUnpaidConsultation()
    }, [getRxOrderDetails, getAddOnOrderDetails, getPatientUnpaidConsultation])


    const calculateTotal = (data, type) => {
        var total = 0
        if (type === 'rx') {
            for (let i = 0; i < data.length; i++) {
                total += parseInt(data[i].quantity) * parseFloat(data[i].price)
            }
            setTotalRx(total)
        } else {
            for (let i = 0; i < data.length; i++) {
                total += parseInt(data[i].quantity) * parseFloat(data[i].price)
            }
            setTotalAddon(total)
        }
    }


    const handleApprovedOrder = (e) => {
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target)
        formdata.append('token', token)
        formdata.append('user_id', user_id)
        formdata.append('username', username)
        formdata.append('order_no', selectedOrder.order_no)
        var error = []
        if (formdata.get('password').trim() === '' || formdata.get('password').length === 0) {
            error = 'error'
            Notify.fieldRequired('password')
        }

        if (error.length > 0) {
            console.warn('form has an error. checkout')
        } else {
            setApproveProcess(true)
            Axios.post('patient/cart/order/order-appproved', formdata)
                .then((response) => {
                    const data = response.data
                    if (data === 'success') {
                        close()
                        Notify.successRequest('order approved')
                    }
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                }).catch((error) => {
                    Notify.requestError(error)
                }).finally(() => {
                    setApproveProcess(false)
                })
        }
    }

    const handleCancelOrder = (e) => {
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target)
        formdata.append('token', token)
        formdata.append('user_id', user_id)
        formdata.append('username', username)
        formdata.append('order_no', selectedOrder.order_no)
        var error = []
        if (formdata.get('password').trim() === '' || formdata.get('password').length === 0) {
            error = 'error'
            Notify.fieldRequired('password')
        }

        if (error.length > 0) {
            console.warn('form has an error. checkout')
        } else {
            setCancelProcess(true)
            Axios.post('patient/cart/order/order-cancelled', formdata)
                .then((response) => {
                    const data = response.data
                    if (data === 'success') {
                        close()
                        Notify.successRequest('order cancelled')
                    }
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                }).catch((error) => {
                    Notify.requestError(error)
                }).finally(() => {
                    setCancelProcess(false)
                })
        }
    }

    return (
        <Paper
            component={Box}
            variant="outlined"
            p={2}
        >
            <Box display="flex" mb={2}>
                <Box flexGrow={1}>
                    <Box display="flex">
                        <IconButton
                            onClick={close}
                        >
                            <KeyboardBackspaceIcon />
                        </IconButton>
                        <Box mt={2}>
                            <Typography
                                variant="subtitle2"
                                className={'gtc-uppercase'}
                            >
                                Order For Confirmation Details
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box mt={2}>
                    <Label color={orderMethod === 'pickup' ? "primary" : "success"}> <Typography > <strong> {orderMethod} </strong> METHOD</Typography> </Label>
                </Box>
            </Box>

            <Box>
                <Typography
                    variant="subtitle2"
                    className={'gtc-uppercase'}
                    color="primary"
                >
                    Rx Item
                </Typography>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Item
                                </TableCell>
                                <TableCell align="center">
                                    Qty
                                </TableCell>
                                <TableCell align="center">
                                    Amount
                                </TableCell>
                                <TableCell align="center">
                                    Total
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                rxOrder.ready ?
                                    rxOrder.data.length > 0 ?
                                        rxOrder.data.map((data, index) => (
                                            <TableRow
                                                key={index}
                                                hover
                                            >
                                                <TableCell className="gtc-capitalize">
                                                    {data.product}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {data.quantity}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {Notify.convertToNumber(data.price)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {Notify.convertToNumber(parseInt(data.quantity) * parseFloat(data.price))}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        :
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Typography
                                                    color="secondary"
                                                >
                                                    No rx order found.
                                            </Typography>
                                            </TableCell>
                                        </TableRow>
                                    :
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            <Typography
                                                color="primary"
                                            >
                                                Please wait...
                                        </Typography>
                                        </TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="subtitle2"
                    className={'gtc-uppercase'}
                    color="primary"
                >
                    ADDITIONAL Item
                </Typography>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Item
                                </TableCell>
                                <TableCell align="center">
                                    Qty
                                </TableCell>
                                <TableCell align="center">
                                    Amount
                                </TableCell>
                                <TableCell align="center">
                                    Total
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                addOn.ready ?
                                    addOn.data.length > 0 ?
                                        addOn.data.map((data, index) => (
                                            <TableRow
                                                key={index}
                                                hover
                                            >
                                                <TableCell className="gtc-capitalize">
                                                    {data.product}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {data.quantity}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {Notify.convertToNumber(data.price)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {Notify.convertToNumber(parseInt(data.quantity) * parseFloat(data.price))}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        :
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Typography
                                                    color="secondary"
                                                >
                                                    No additional order found.
                                            </Typography>
                                            </TableCell>
                                        </TableRow>
                                    :
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            <Typography
                                                color="primary"
                                            >
                                                Please wait...
                                        </Typography>
                                        </TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="subtitle2"
                    className={'gtc-uppercase'}
                    color="primary"
                >
                    Order Total
                </Typography>

                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow
                                hover
                            >
                                <TableCell />
                                <TableCell />
                                <TableCell align="right">
                                    Total Amount :
                                </TableCell>
                                <TableCell align="right">
                                    <b> &#8369; {Notify.convertToNumber(parseFloat(totalRx) + parseFloat(totalAddon))} </b>
                                </TableCell>
                            </TableRow>

                            {
                                unpaid.data.length > 0 ?
                                    <TableRow
                                        hover
                                    >
                                        <TableCell />
                                        <TableCell />
                                        <TableCell align="right">
                                            Unpaid Consultation :
                                        </TableCell>
                                        <TableCell align="right">
                                            <b> &#8369; {Notify.convertToNumber(parseFloat(totalUnpaidConsu))} </b>
                                        </TableCell>
                                    </TableRow>
                                    : null
                            }

                            <TableRow
                                hover
                            >
                                <TableCell />
                                <TableCell />
                                <TableCell align="right">
                                    Delivery Fee :
                                </TableCell>
                                <TableCell align="right">
                                    <b> &#8369;  {Notify.convertToNumber(deliveryFee)} </b>
                                </TableCell>
                            </TableRow>

                            <TableRow
                                hover
                            >
                                <TableCell />
                                <TableCell />
                                <TableCell align="right">
                                    Overall Total:
                                </TableCell>
                                <TableCell align="right">
                                    <b> &#8369;  {Notify.convertToNumber((parseFloat(totalRx) + parseFloat(totalAddon)) + parseFloat(deliveryFee) + parseFloat(totalUnpaidConsu))} </b>
                                </TableCell>
                            </TableRow>

                        </TableBody>

                    </Table>
                </TableContainer>
            </Box>

            <Box mt={4} display="flex" justifyContent="flex-end">
                <Box mr={2}>
                    <Button
                        size="large"
                        variant="contained"
                        color="default"
                        onClick={() => window.print()}
                    >
                        Print Order
                    </Button>
                </Box>
                <Box mr={2}>
                    <Button
                        size="large"
                        variant="contained"
                        color="secondary"
                        onClick={() => setCancelDialog(true)}
                    >
                        Cancel Order
                    </Button>
                </Box>

                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => setApproveDialog(true)}
                >
                    approve order
                </Button>
            </Box>

            {/* dialog for approve order */}
            <Dialog
                open={approveDialog}
                onClose={() => setApproveDialog(false)}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle>
                    Are you sure to approve this order?
                </DialogTitle>
                <form
                    onSubmit={handleApprovedOrder}
                >
                    <DialogContent>
                        <TextField
                            label="Enter your password"
                            name="password"
                            type="password"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => setApproveDialog(false)}
                            startIcon={<CanceIcon />}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={approveProcess ? <CircularProgress size="20" color="inherit" /> : <LikeIcon />}
                            type="submit"
                            disabled={approveProcess}
                        >
                            Approve
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {/* dialog for cancel order */}
            <Dialog
                open={cancelDialog}
                onClose={() => setCancelDialog(false)}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle>
                    Are you sure to approve this order?
                </DialogTitle>
                <form
                    onSubmit={handleCancelOrder}
                >
                    <DialogContent>
                        <TextField
                            label="Enter your password"
                            name="password"
                            type="password"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => setCancelDialog(false)}
                            startIcon={<CanceIcon />}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={cancelProcess ? <CircularProgress size="20" color="inherit" /> : <BlockIcon />}
                            type="submit"
                            disabled={cancelProcess}
                        >
                            cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Paper>
    )
}

export default ConfirmOrderFromVPharm;