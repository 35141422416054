import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "src/layout/Container";
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  TextField,
  Button,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";
import { CheckSquare, Edit as EditIcon, Trash, XCircle } from "react-feather";
import EditService from "./EditService";
import RemoveService from "./RemoveService";

const token = localStorage.getItem("token");
const user_id = localStorage.getItem("user_id");
const username = localStorage.getItem("username");
const management_id = localStorage.getItem("management_id");

const Services = () => {
  const [list, setList] = useState({ data: [], ready: false });
  const mounted = IsMountedRef();
  const [dialog, setDialog] = useState({ data: null, open: false, type: "" });

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const getServiceList = React.useCallback(async () => {
    let response = await Axios.get("doctors/services/list-service", {
      params: {
        user_id,
        management_id,
        token,
      },
    });

    if (mounted.current) {
      setList({ data: response.data, ready: true });
    }
  }, [mounted]);

  useEffect(() => {
    getServiceList();
  }, [getServiceList]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Services",
        items: [{ path: "/clinic/app/doctor", name: "Dashboard" }],
      }}
      title={"Doctor Services"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Card>
            <CardHeader title="Services List" />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> Date </TableCell>
                      <TableCell> Services </TableCell>
                      <TableCell> Rate </TableCell>
                      <TableCell align="center"> Action </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {list.ready ? (
                      list.data.length > 0 ? (
                        list.data.map((data, key) => (
                          <TableRow key={key}>
                            <TableCell>
                              {Notify.createdAt(data.created_at)}
                            </TableCell>
                            <TableCell> {data.services} </TableCell>
                            <TableCell align="right"> {data.amount} </TableCell>
                            <TableCell align="center">
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  setDialog({
                                    data,
                                    open: true,
                                    type: "update",
                                  });
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                color="secondary"
                                onClick={() => {
                                  setDialog({
                                    data,
                                    open: true,
                                    type: "remove",
                                  });
                                }}
                              >
                                <Trash />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Typography color="secondary">
                              No record found.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography color="primary">
                            Loading, please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card>
            <CardHeader title="New Service" />
            <CardContent>
              <Formik
                initialValues={{
                  user_id: user_id,
                  token: token,
                  username: username,
                  management_id: management_id,
                  services: "",
                  rate: 0,
                  password: "",
                }}
                validationSchema={Yup.object().shape({
                  services: Yup.string().required(),
                  rate: Yup.number().min(1).max(9999).required(),
                  password: Yup.string().required(),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting, resetForm }
                ) => {
                  try {
                    const request = await Axios.post(
                      "doctors/services/new-service",
                      getFormData(values)
                    );

                    if (request.data.message === "success") {
                      Notify.successRequest("new service");
                      getServiceList();
                      resetForm();
                    }

                    if (request.data.message === "pass-invalid") {
                      setErrors({ password: "Password is invalid." });
                    }
                  } catch (error) {
                    const message = error.message || "Something went wrong";
                    setErrors({ submit: message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Box mb={2}>
                      <TextField
                        label="Service"
                        fullWidth
                        variant="outlined"
                        name="services"
                        InputProps={{
                          inputProps: {
                            min: 1,
                            max: 200,
                          },
                        }}
                        error={Boolean(touched.services && errors.services)}
                        helperText={touched.services && errors.services}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.services}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        label="Rate"
                        fullWidth
                        variant="outlined"
                        name="rate"
                        type="number"
                        error={Boolean(touched.rate && errors.rate)}
                        helperText={touched.rate && errors.rate}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.rate}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        label="Password"
                        fullWidth
                        variant="outlined"
                        name="password"
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        type="password"
                      />
                    </Box>

                    <Box>
                      <Button
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ marginRight: 10 }}
                        startIcon={
                          isSubmitting ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <CheckSquare />
                          )
                        }
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>

                      <Button
                        size="large"
                        variant="contained"
                        color="default"
                        startIcon={<XCircle />}
                      >
                        No
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* dialog update */}
      {dialog.open && (
        <Dialog
          open={dialog.open}
          onClose={() => setDialog({ data: null, open: false, type: "" })}
          scroll="body"
          maxWidth={"xs"}
        >
          {dialog.type === "update" && <DialogTitle>Edit Service</DialogTitle>}

          <DialogContent>
            {dialog.type === "update" && (
              <Box>
                <EditService
                  open={dialog.open}
                  details={dialog.data}
                  close={() => setDialog({ data: null, open: false, type: "" })}
                  getServiceList={() => getServiceList()}
                />
              </Box>
            )}

            {dialog.type === "remove" && (
              <Box>
                <RemoveService
                  open={dialog.open}
                  details={dialog.data}
                  close={() => setDialog({ data: null, open: false, type: "" })}
                  getServiceList={() => getServiceList()}
                />
              </Box>
            )}
          </DialogContent>
        </Dialog>
      )}

      {/* dialog remove */}
    </Container>
  );
};

export default Services;
