import React, { Fragment, useState, useEffect } from 'react';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Box, Grid, Button, Typography, TextField, Divider, Paper } from '@material-ui/core'; 
import TablePagination from '@material-ui/core/TablePagination';

function DoctorsInformationComment(props) {

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [isprocess, setisprocess] = useState(false)
    const [disabledbtn, setdisabledbtn] = useState(false)

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const [approvecoms, setapprovecoms] = useState([])

    const getapproveComments = () => {
        var params = {
            token: token,
            doctors_id: props.doctors_id,
        }
        Axios.get('patient/appointments/doctors/listcomments', { params })
            .then((response) => {
                const data = response.data;
                setapprovecoms(data)
            }).catch(error => {
                Notify.requestError(error);
            }).finally(() => {
                setisprocess(false)
            })
    }

    const handleSaveComment = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('doctors_id', props.doctors_id);
        formdata.set('connection', 'online')

        var error = [];
        if (formdata.get('comments').length === 0 || formdata.get('comments').trim() === '') {
            error = 'error';
            Notify.fieldRequired('comments');
        }

        if (error.length > 0) {
            console.warn("form has an error, request failed")
        } else {
            setisprocess(true)
            Axios.post('patient/appointments/doctors/sendcomments', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'success') {
                        e.target.reset()
                        setdisabledbtn(true)
                        Notify.successRequest('comment')
                    }
                }).catch(error => {
                    Notify.requestError(error);
                }).finally(() => {
                    setisprocess(false)
                })
        }
    }

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        getapproveComments()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Box 
                component={Paper}
                p={2}
            >
                <Box mb={1}>
                    <Typography variant={'subtitle2'} color="primary">
                        COMMENTS LIST
                    </Typography>
                </Box>

                <Box>
                    {
                        approvecoms.length > 0 ?
                            approvecoms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => {
                                return (
                                    <Box
                                        key={index} 
                                    >
                                        <Box my={2}>
                                            <Typography
                                                variant="h6" 
                                            >
                                                {data.patient_name}
                                            </Typography>

                                            <Typography
                                                variant="subtitle1" 
                                                color="textSecondary"
                                            >
                                                {data.comment}
                                            </Typography>

                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                style={{ textTransform: 'uppercase' }} 
                                            >
                                                {Notify.dateTimeConvert(data.updated_at)}
                                            </Typography>
                                        </Box>

                                        <Divider />
                                    </Box>
                                )
                            })
                            : <Typography variant="subtitle2" color="textSecondary" align="center">
                                No comment recorded yet.
                        </Typography>
                    }
                    <TablePagination
                        labelRowsPerPage="Comments"
                        rowsPerPageOptions={[5, 20, 50, 100, { value: -1, label: 'All' }]}
                        component="div"
                        count={approvecoms.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Box>

                <Box>
                    <form onSubmit={handleSaveComment}>
                        <Box my={3}>
                            <TextField
                                required
                                name="comments"
                                variant="outlined"
                                label="Enter your comment"
                                fullWidth
                            />
                        </Box>
                        <Grid container>
                            <Grid item xs={12} sm={10}>
                                <Box>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Your comment is subject for review by this doctor before it will show up in the comment section.
                                        Are you sure to continue saving this comment ?
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Button
                                    className="float-right"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isprocess ? true : disabledbtn ? true : false}
                                >
                                    {isprocess ? <FontAwesomeIcon icon={faCircleNotch} spin={true} /> : null} Comment
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Box>
        </Fragment>
    )
}

export default DoctorsInformationComment;
