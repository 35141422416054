import { Typography, Box, Grid } from "@material-ui/core";
import React, { useEffect, useState, Fragment } from "react";
import Notify from "src/notification/Notify";
import axios from "axios";
import Label from "src/utils/Label";

const FecalOrder = ({ trace_number, patient_id }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [details, setDetails] = useState([]);

  const getpaidLaboratoryOrderDetails = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", trace_number);
    formdata.set("table", "laboratory_fecal_analysis");
    formdata.set("connection", "local");

    axios
      .post("doctor/patient/laboratory/order/paid-detailsbytable", formdata)
      .then((res) => {
        const data = res.data;
        setDetails(data);
      })
      .catch((er) => Notify.requestError(er));
  };

  const checkResult = (order) => {
    return order === null ? (
      <Label color="error"> no result </Label>
    ) : (
      <Label color="success"> {order} </Label>
    );
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trace_number, patient_id]);

  return (
    <>
      {/* clinical microscopy */}
      {details.length > 0 &&
        details.map((data, key) => (
          <Fragment key={key}>
            <Box>
              {Boolean(parseInt(data.fecal_analysis)) &&
                (parseInt(data.fecal_analysis) === 2 ? (
                  <Box display={"flex"} mt={1}>
                    <Box>
                      <Typography variant="h6">
                        <strong> Fecal Analalysis Order </strong>
                      </Typography>
                    </Box>
                    <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                      <Label color="warning"> refund </Label>
                    </Box>
                  </Box>
                ) : (
                  <Box p={2}>
                    <Box>
                      <Typography variant="h6">
                        <strong> Fecal Analalysis Order </strong>
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6}>
                          <>
                            <Box>
                              <Typography variant="subtitle2">
                                <b> Cellular Elements </b>
                              </Typography>
                            </Box>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    <b> COLOR: </b>
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(data.cellular_elements_color)}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    <b> CONSISTENCY: </b>
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.cellular_elements_consistency
                                    )}
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    <b> PUS: </b>
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(data.cellular_elements_pus)}
                                  </Box>
                                  /HPF
                                </Box>
                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    <b> RBC: </b>
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(data.cellular_elements_rbc)}
                                  </Box>
                                  /HPF
                                </Box>
                              </Grid>
                            </Grid>
                          </>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Grid container>
                            <Grid item xs={3} sm={3} />
                            <Grid item xs={9} sm={9}>
                              <Box>
                                <Box>
                                  <Typography variant="subtitle2">
                                    <b> Others </b>
                                  </Typography>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    <b> FAT GLOBULES: </b>
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.cellular_elements_fat_globules
                                    )}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    <b> OCCULT BLOOD:: </b>
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.cellular_elements_occultblood
                                    )}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    <b> BACTERIA: </b>
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.cellular_elements_bacteria
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Box mt={1}>
                        <Typography variant="caption">
                          <b> RESULT: </b>
                        </Typography>

                        <Box mx={2} minWidth={100}>
                          <Typography
                            variant="subtitle1"
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.cellular_elements_result}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Fragment>
        ))}
    </>
  );
};

export default FecalOrder;
