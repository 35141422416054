import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { Alert } from "react-bootstrap";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Logout from "../welcome/Logout";
import EnterRoom from "../patients/onlinecheckup/EnterRoom";
import PageNotFound from "../PageNotFound";
import DoctorsInformation from "../patients/appointment/DoctorsInformation";
import Axios from "axios";
import Notify from "../notification/Notify";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Zoom,
  TextField,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  CircularProgress,
  Grid,
  Typography,
  IconButton,
  Badge,
} from "@material-ui/core";
import GTCValidateLS from "../notification/GTCValidateLS";
import StreamError from "../StreamError";
import SearchAppointment from "../patients/onlinecheckup/SeachAppointment";
import ClinicDetails from "../patients/onlinecheckup/ClinicDetails";
import EnterRoomOld from "src/patients/onlinecheckup/EnterRoomOld";
import { ForPatientsNotif } from "src/ContextAPI";
import PersonalBasic from "src/patients/information/PersonalBasic";
import Subscription from "src/patients/subscription/Subscription";
import Laboratory from "src/patients/laboratory/Laboratory";
import Imaging from "src/patients/imaging/Imaging";
import Credits from "src/patients/Credits/Credits";
import Permissions from "src/patients/permissions/Permissions";
import Cart from "src/patients/cart/Cart";
import MyQRCode from "src/patients/qrcode/MyQRCode";
import {
  AddToQueue,
  CancelOutlined,
  CardMembership,
  DoneAll as DoneAllIcon,
} from "@material-ui/icons";
import { PackageContext } from "../PackageContext";
import PersonalVitals from "src/patients/information/PersonalVitals";
import PersonalAllergies from "src/patients/information/PersonalAllergies";
import PersonalDiagnosis from "src/patients/information/PersonalDiagnosis";
import PersonalMedication from "src/patients/information/PersonalMedication";
import PersonalDiet from "src/patients/information/PersonalDiet";
import PersonalHistoryFamily from "src/patients/information/PersonalHistoryFamily";
import PersonalHistory from "src/patients/information/PersonalHistory";
import BodyPain from "src/patients/vitalsgraph/BodyPain";
import Temperature from "src/patients/vitalsgraph/Temperature";
import BloodPressure from "src/patients/vitalsgraph/BloodPressure";
import Pulse from "src/patients/vitalsgraph/Pulse";
import Respiratory from "src/patients/vitalsgraph/Respiratory";
import Weight from "src/patients/vitalsgraph/Weight";
import Glucose from "src/patients/vitalsgraph/Glucose";
import Cholesterol from "src/patients/vitalsgraph/Cholesterol";
import UricAcid from "src/patients/vitalsgraph/Uricacid";
import Calcium from "src/patients/vitalsgraph/Calcium";
import Chloride from "src/patients/vitalsgraph/Chloride";
import Creatinine from "src/patients/vitalsgraph/Creatinine";
import HDL from "src/patients/vitalsgraph/HDL";
import LDL from "src/patients/vitalsgraph/LDL";
import Lithium from "src/patients/vitalsgraph/Lithium";
import Magnesium from "src/patients/vitalsgraph/Magnesium";
import Potasium from "src/patients/vitalsgraph/Potasium";
import Protein from "src/patients/vitalsgraph/Protein";
import Sodium from "src/patients/vitalsgraph/Sodium";
import Appointment from "src/patients/appointment/Appointment";
import AppointmentNotificationMsg from "src/patients/appointment/AppointmentNotificationMsg";
import ProfileDropdown from "src/patients/carddropdown/ProfileDropdown";
import AppointmentDropdown from "src/patients/carddropdown/AppointmentDropdown";
import GraphDropdown from "src/patients/carddropdown/GraphDropdown";
import SnackbarNextAppointment from "src/patients/snackbar/SnackbarNextAppointment";
import SidebarPatients from "src/layout/SidebarPatients";
import {
  Home,
  User,
  Heart,
  Image,
  PieChart,
  BookOpen,
  Bookmark,
  CreditCard as CreditIcon,
  Lock,
  ShoppingCart as ShoppingCartIcon,
} from "react-feather";
import GraphHemathology from "src/patients/laboratory/graph/GraphHemathology";
import GraphClinicalChemistry from "src/patients/laboratory/graph/GraphClinicalChemistry";
import GraphFecalAnalysis from "src/patients/laboratory/graph/GraphFecalAnalysis";
import GraphClinicalMicroscopy from "src/patients/laboratory/graph/GraphClinicalMicroscopy";
import NotificationAppBar from "src/patients/notification/NotificationAppBar";
// import GTCMap from "src/patients/Map/GTCMap";
import GTCMapping from "src/patients/Map/GTCMapping";
import GTCNewsFullImage from "src/gtcnews/GTCNewsFullImage";

const RoutePatients = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const username = localStorage.getItem("username");
  const [unViewedNotification, setUnViewedNotification] = useState([]);
  const [unViewedNotificationRdy, setUnViewedNotificationRdy] = useState(false);
  const [_issubscribe_status, set_issubscribe_status] = useState(false);
  const [_selected_package, set_selected_package] = useState([]);
  const [_issubscribe_month, set_issubscribe_month] = useState(1);
  const [_subscriptcount, set_subscriptcount] = useState(0);
  const [_amount_topay, set_amount_topay] = useState(1);
  const [_amount_topay_subscription, set_amount_topay_subscription] =
    useState(0);
  const [_is_process, set_is_process] = useState(false);
  const [_choosenpackage, set_choosenpackage] = useState("");
  const [patientNotifBadge, setPatientNotifBadge] = useState(0);
  const [showMyQr, setShowMyQr] = useState(false);
  const [_currentSubscription, set_currentSubscription] = useState(null);
  const [appointmentNotif, setAppointmentNotif] = useState([]);
  const [nextAppointment, setNextAppointment] = useState(null);
  const [sidebarHeader, setSidebarHeader] = useState(false);
  const [closeIndication, setCloseIndication] = useState(false);

  const [incoming, setIncoming] = useState({
    data: [],
    ready: false,
    open: false,
  });

  let interval = null;
  const callerBeepSrc = "/tones/answer_beep.mp3";
  const callerBeepRef = useRef(null);

  const getSidebarHeaderInfo = async () => {
    try {
      var params = {
        token: token,
        user_id: user_id,
      };
      const response = await Axios.get("patient/sidebar/header-infomartion", {
        params,
      });
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("unable to get sidebar header info.", error);
    }
  };

  const packagesAmount = (pkge) => {
    return pkge === "standard"
      ? 100
      : pkge === "premium"
      ? 200
      : pkge === "elite"
      ? 10000
      : 0;
  };

  const handleChangeMonth = (e) => {
    if (e.target.value > 1) {
      set_issubscribe_month(e.target.value);
      set_amount_topay_subscription(
        parseFloat(e.target.value) * packagesAmount(_choosenpackage)
      );
    } else {
      set_issubscribe_month(1);
      set_amount_topay_subscription(200);
    }
  };

  const saveSubscription = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("username", username);
    formdata.set("user_id", user_id);
    formdata.set("token", token);

    var error = [];

    if (
      formdata.get("package").length === 0 ||
      formdata.get("package").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("package");
    }

    if (
      formdata.get("month_length").length === 0 ||
      formdata.get("month_length").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("subscription length");
    }

    if (
      formdata.get("amount").length === 0 ||
      formdata.get("amount").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("subscription amount");
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      console.warn("form has an error. unable to process");
    } else {
      set_is_process(true);
      Axios.post("/patient/subscription/save-subscription", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            checkaccountConfirmation();
            Notify.successRequest("subscription");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          set_is_process(false);
        });
    }
  };

  const handlePaymentLink = (e) => {
    window.open(e.currentTarget.getAttribute("paylink"), "_blank");
  };

  const updatePatientNotif = (data) => {
    setPatientNotifBadge(data);
  };

  const handleRenderInfo = () => {
    getSidebarHeaderInfo();
  };

  const rerunChecker = () => {
    setTimeout(() => {
      checkaccountConfirmation();
    }, 5000);
  };

  const getAppointmentNotification = () => {
    var params = {
      token: token,
      user_id: user_id,
      connection: "online",
    };
    Axios.get("patient/appointment/unread-notif", { params })
      .then((response) => {
        const data = response.data;
        setAppointmentNotif(data);
        setNextAppointment(data[0].appointment_date);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getLocalNotifUnread = () => {
    var params = {
      token: token,
      user_id: user_id,
      connection: "online",
    };
    Axios.get("patient/unview/notification-unview", { params })
      .then((response) => {
        const data = response.data;
        setUnViewedNotification(data);
        setUnViewedNotificationRdy(true);
      })
      .catch((error) => {
        rerunChecker();
        Notify.requestError(error);
      });
  };

  const checkaccountConfirmation = () => {
    var params = {
      token: token,
      management_id: management_id,
      user_id: user_id,
    };
    Axios.get("patient/information/account/checksubscription", { params })
      .then((response) => {
        const data = response.data;
        if (data.length > 0) {
          set_issubscribe_status(
            data[0].issubscribe_status === "approved" ? true : false
          );
          set_selected_package(data);
          set_amount_topay(
            data[0].package_selected === "standard"
              ? 100
              : data[0].package_selected === "premium"
              ? 200
              : data[0].package_selected === "elite"
              ? 10000
              : 0
          );
          set_subscriptcount(
            data[0].issubscribe_status !== "approved" ? data[0].issubscribe : 0
          );
          set_choosenpackage(
            data[0].package_selected === null ? "" : data[0].package_selected
          );
        }
      })
      .catch((error) => {
        rerunChecker();
        Notify.requestError(error);
      });
  };

  const getCurrentSubscription = () => {
    var params = {
      token: token,
      user_id: user_id,
    };
    Axios.get("user/account/subscription/validate/8724g03lfs59839rts4245", {
      params,
    })
      .then((res) => {
        const data = res.data;
        set_currentSubscription(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const createdRoom = useCallback(() => {
    var params = {
      token: token,
      user_id: user_id,
    };
    Axios.get("appointment/patient/online/created-roombydoctor", { params })
      .then((response) => {
        const data = response.data;
        if (data.length > 0) {
          setIncoming({
            data: data,
            ready: true,
            open: true,
          });
        } else {
          if (data.length > 0) {
            setIncoming({
              data: [],
              ready: false,
              open: false,
            });
          }
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, user_id]);

  const initializeInterval = () => {
    interval = setInterval(() => {
      getLocalNotifUnread();
      getAppointmentNotification();
      createdRoom();
    }, 10000);
  };

  useEffect(() => {
    GTCValidateLS.verifyLocalStorage();

    getCurrentSubscription();
    checkaccountConfirmation();
    getSidebarHeaderInfo();

    initializeInterval();
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <Home color="white" />,
      path: "/clinic/app/patient",
      subitem: [],
    },
    {
      name: "Profile",
      show: true,
      icon: <User color="white" />,
      path: "/clinic/app/patient/account",
      subitem: [
        {
          name: "Vitals",
          show: true,
          icon: <Typography style={{ color: "white" }}>Vi</Typography>,
          path: "/clinic/app/patient/profile/vitals",
        },
        {
          name: "Allergies",
          show: true,
          icon: <Typography style={{ color: "white" }}>Al</Typography>,
          path: "/clinic/app/patient/profile/allergies",
        },
        {
          name: "Diagnosis",
          show: true,
          icon: <Typography style={{ color: "white" }}>Dia</Typography>,
          path: "/clinic/app/patient/profile/diagnosis",
        },
        {
          name: "Medication",
          show: true,
          icon: <Typography style={{ color: "white" }}>Me</Typography>,
          path: "/clinic/app/patient/profile/medication",
        },
        {
          name: "Diet",
          show: true,
          icon: <Typography style={{ color: "white" }}>Di</Typography>,
          path: "/clinic/app/patient/profile/diet",
        },
        {
          name: "Family History",
          show: true,
          icon: <Typography style={{ color: "white" }}>FH</Typography>,
          path: "/clinic/app/patient/profile/family-history",
        },
        {
          name: "History",
          show: true,
          icon: <Typography style={{ color: "white" }}>Hi</Typography>,
          path: "/clinic/app/patient/profile/history",
        },
      ],
    },
    {
      name: "Laboratory",
      show: true,
      icon: (
        <Fragment>
          {parseFloat(appointmentNotif.length) > 0 ? (
            <Badge
              badgeContent={appointmentNotif[0].countLaboratoryNotif}
              color="secondary"
              invisible={
                appointmentNotif[0].countLaboratoryNotif > 0 ? false : true
              }
            >
              <Heart color="white" />
            </Badge>
          ) : (
            <Heart color="white" />
          )}
        </Fragment>
      ),
      path: "/clinic/app/patient/laboratory",
      subitem: [],
    },
    {
      name: "Imaging",
      show: true,
      icon: (
        <Fragment>
          {parseFloat(appointmentNotif.length) > 0 ? (
            <Badge
              badgeContent={appointmentNotif[0].countImagingNotif}
              color="secondary"
              invisible={
                appointmentNotif[0].countImagingNotif > 0 ? false : true
              }
            >
              <Image color="white" />
            </Badge>
          ) : (
            <Image color="white" />
          )}
        </Fragment>
      ),
      path: "/clinic/app/patient/imaging",
      subitem: [],
    },
    {
      name: "Health Graph",
      show: true,
      icon: <PieChart color="white" />,
      path: "/clinic/app/patient/graph",
      subitem: [],
    },
    {
      name: "Appointment",
      show: true,
      icon: (
        <Fragment>
          {parseFloat(appointmentNotif.length) > 0 ? (
            <Badge
              badgeContent={"!"}
              color="secondary"
              invisible={
                appointmentNotif[0].appointment_date !== null ||
                parseFloat(appointmentNotif[0].countAppointNotif) > 0
                  ? false
                  : true
              }
            >
              <BookOpen color="white" />
            </Badge>
          ) : (
            <BookOpen color="white" />
          )}
        </Fragment>
      ),
      path: "/clinic/app/patient/appointment/make-appointment",
      subitem: [
        {
          name: "Appointment List",
          show: true,
          icon: (
            <Fragment>
              {parseFloat(appointmentNotif.length) > 0 ? (
                <Badge
                  badgeContent={appointmentNotif[0].countAppointmentApproved}
                  color="secondary"
                  invisible={
                    appointmentNotif[0].countAppointmentApproved > 0
                      ? false
                      : true
                  }
                >
                  <Typography style={{ color: "white" }}>AL</Typography>
                </Badge>
              ) : (
                <Typography style={{ color: "white" }}>AL</Typography>
              )}
            </Fragment>
          ),
          path: "/clinic/app/patient/appointment/appointment-list",
        },
        {
          name: "Appointment Notif",
          show: true,
          icon: (
            <Fragment>
              {parseFloat(appointmentNotif.length) > 0 ? (
                <Badge
                  badgeContent={appointmentNotif[0].countAppointNotif}
                  color="secondary"
                  invisible={
                    appointmentNotif[0].countAppointNotif > 0 ? false : true
                  }
                >
                  <Typography style={{ color: "white" }}>AN</Typography>
                </Badge>
              ) : (
                <Typography style={{ color: "white" }}>AN</Typography>
              )}
            </Fragment>
          ),
          path: "/clinic/app/patient/appointment/appointment-notification",
        },
        {
          name: "Make Appointment",
          show: true,
          icon: <AddToQueue style={{ fill: "#fff" }} />,
          path: "/clinic/app/patient/appointment/make-appointment",
        },
      ],
    },
    {
      name: "Subscription",
      show: true,
      icon: <Bookmark color="white" />,
      path: "/clinic/app/patient/subscription",
      subitem: [],
    },
    {
      name: "Credits",
      show: true,
      icon: <CreditIcon color="white" />,
      path: "/clinic/app/patient/credits",
      subitem: [],
    },
    {
      name: "Permission",
      show: true,
      icon: <Lock color="white" />,
      path: "/clinic/app/patient/permission",
      subitem: [],
    },
    {
      name: "Cart",
      show: true,
      icon: <ShoppingCartIcon color="white" />,
      path: "/clinic/app/patient/cart",
      subitem: [],
    },
  ];

  return (
    <Fragment>
      <ForPatientsNotif.Provider
        value={{
          patientNotif: patientNotifBadge,
          updatePatientNotif: updatePatientNotif,
          unViewedNotification: unViewedNotification,
          unViewedNotificationRdy: unViewedNotificationRdy,
          nextAppointment: nextAppointment,
          closeIndication: closeIndication,
          setNextAppointmentNull: () => setNextAppointment(null),
          setCloseIndicationTrue: () => setCloseIndication(true),
          renderPatientInfo: handleRenderInfo,
        }}
      >
        <BrowserRouter>
          <SnackbarNextAppointment
            nextAppointment={nextAppointment}
            closeIndication={closeIndication}
            close={() => {
              setNextAppointment(null);
              setCloseIndication(true);
            }}
          />
          <SidebarPatients
            notification={{
              enable: true,
              virtual: unViewedNotification,
              owner: "patient",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"patient"}
            render={() => getLocalNotifUnread()}
          />
          <PackageContext.Provider
            value={{
              __package: _currentSubscription,
            }}
          >
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to="/clinic/app/patient" />}
              />
              <Route
                exact
                path="/clinic/"
                component={() => <Redirect to="/clinic/app/patient" />}
              />
              <Route
                exact
                path="/clinic/app"
                component={() => <Redirect to="/clinic/app/patient" />}
              />
              <Route
                exact
                path="/clinic/app/patient"
                component={GTCNewsFullImage}
              />
              {/* <Route exact path="/clinic/app/patient/appointment" component={DoctorsList}/> */}
              <Route
                exact
                path="/clinic/app/patient/appointment/clinic/:clinic_id"
                component={ClinicDetails}
              />
              <Route
                exact
                path="/clinic/app/patient/appointment/doctor/:doctor_id"
                component={DoctorsInformation}
              />
              {/* <Route exact path="/virtual/create/room/v-call/:patient_id/:appointment_id/:room_id" component={VirtualCall} />  */}

              {/* <Route exact path="/clinic/app/patient/map" component={GTCMap} /> */}
              <Route
                exact
                path="/clinic/app/patient/map"
                component={GTCMapping}
              />

              <Route exact path="/clinic/app/logout" component={Logout} />
              <Route
                exact
                path="/clinic/app/streamerror"
                component={StreamError}
              />
              <Route
                exact
                path="/clinic/app/patient/online/checkup/enter-room/:doctors_id/:room_number"
                component={EnterRoom}
              />
              {/* <Route exact path="/clinic/app/patient/online/checkup/enter-room/:room_id" component={EnterRoom} /> */}
              <Route
                exact
                path="/clinic/app/patient/appointment/room/:ref_number/:room_number"
                component={EnterRoomOld}
              />

              {/* new 1234567890 */}
              {/* new category */}
              <Route
                exact
                path="/clinic/app/patient/account"
                component={PersonalBasic}
              />
              <Route
                exact
                path="/clinic/app/patient/subscription"
                component={Subscription}
              />
              <Route
                exact
                path="/clinic/app/patient/laboratory"
                component={Laboratory}
              />
              <Route
                exact
                path="/clinic/app/patient/imaging"
                component={Imaging}
              />
              <Route
                exact
                path="/clinic/app/patient/credits"
                component={Credits}
              />
              <Route
                exact
                path="/clinic/app/patient/permission"
                component={Permissions}
              />
              <Route exact path="/clinic/app/patient/cart" component={Cart} />

              {/* profile */}
              <Route
                exact
                path="/clinic/app/patient/profile/vitals"
                component={PersonalVitals}
              />
              <Route
                exact
                path="/clinic/app/patient/profile/allergies"
                component={PersonalAllergies}
              />
              <Route
                exact
                path="/clinic/app/patient/profile/diagnosis"
                component={PersonalDiagnosis}
              />
              <Route
                exact
                path="/clinic/app/patient/profile/medication"
                component={PersonalMedication}
              />
              <Route
                exact
                path="/clinic/app/patient/profile/diet"
                component={PersonalDiet}
              />
              <Route
                exact
                path="/clinic/app/patient/profile/family-history"
                component={PersonalHistoryFamily}
              />
              <Route
                exact
                path="/clinic/app/patient/profile/history"
                component={PersonalHistory}
              />

              {/* graph */}
              <Route
                exact
                path="/clinic/app/patient/graph/pain"
                component={BodyPain}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/temperature"
                component={Temperature}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/blood-pressure"
                component={BloodPressure}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/pulse"
                component={Pulse}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/respiratory"
                component={Respiratory}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/weight"
                component={Weight}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/glucose"
                component={Glucose}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/cholesterol"
                component={Cholesterol}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/uric-acid"
                component={UricAcid}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/calcium"
                component={Calcium}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/chloride"
                component={Chloride}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/creatinine"
                component={Creatinine}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/hdl"
                component={HDL}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/ldl"
                component={LDL}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/lithium"
                component={Lithium}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/magnesium"
                component={Magnesium}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/potassium"
                component={Potasium}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/protein"
                component={Protein}
              />
              <Route
                exact
                path="/clinic/app/patient/graph/sodium"
                component={Sodium}
              />

              {/* appointment */}
              <Route
                exact
                path="/clinic/app/patient/appointment/make-appointment"
                component={SearchAppointment}
              />
              <Route
                exact
                path="/clinic/app/patient/appointment/appointment-list"
                component={Appointment}
              />
              <Route
                exact
                path="/clinic/app/patient/appointment/appointment-notification"
                component={AppointmentNotificationMsg}
              />

              {/* dropdowns */}
              <Route
                exact
                path="/clinic/app/patient/profile"
                component={ProfileDropdown}
              />
              <Route
                exact
                path="/clinic/app/patient/appointment"
                component={AppointmentDropdown}
              />
              <Route
                exact
                path="/clinic/app/patient/graph"
                component={GraphDropdown}
              />

              {/* graph on laboratory redirection */}
              <Route
                exact
                path="/clinic/app/patient/laboratory/hemathology"
                component={GraphHemathology}
              />
              <Route
                exact
                path="/clinic/app/patient/laboratory/clinical-chemistry"
                component={GraphClinicalChemistry}
              />
              <Route
                exact
                path="/clinic/app/patient/laboratory/fecal-analysis"
                component={GraphFecalAnalysis}
              />
              <Route
                exact
                path="/clinic/app/patient/laboratory/clinical-microscopy"
                component={GraphClinicalMicroscopy}
              />

              <Route
                exact
                path="/clinic/app/patient/notification"
                component={NotificationAppBar}
              />

              {/* <Route exact path="/clinic/app/patient/twopeer" component={TwoPeer} /> */}
              <Route
                render={() => (
                  <PageNotFound title="Page not found in patient routes." />
                )}
              />
            </Switch>
          </PackageContext.Provider>
        </BrowserRouter>
      </ForPatientsNotif.Provider>
      {_selected_package.length > 0 ? (
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          TransitionComponent={Zoom}
          open={!_issubscribe_status}
          keepMounted
          disableBackdropClick={true}
          transitionDuration={1000}
        >
          <DialogTitle>Subscription</DialogTitle>
          <DialogContent dividers>
            <Box p={1}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  {_subscriptcount > 0 ? (
                    <Fragment>
                      {_selected_package.length > 0 ? (
                        _selected_package[0].issubscribe_status ===
                        "pending" ? (
                          <Fragment>
                            <Alert variant="danger">
                              <span className="header-label text-uppercase small">
                                {" "}
                                <strong>
                                  {" "}
                                  Your subscription is pending!{" "}
                                </strong>{" "}
                              </span>{" "}
                              <br />
                              <Button
                                onClick={() => window.location.reload()}
                                fullWidth
                                variant="text"
                                color="primary"
                              >
                                Reload
                              </Button>
                              <Button
                                color="secondary"
                                fullWidth
                                variant="text"
                                onClick={() =>
                                  (window.location.href = "/clinic/app/logout")
                                }
                              >
                                Logout
                              </Button>
                            </Alert>

                            <p className="m-0 my-2">
                              Please wait while your subscription is being
                              process and the payment link being prepare.
                            </p>
                            <p className="m-0  text-muted header-label">
                              If you have any question please dont hesitate to
                              contact tru the information given.
                            </p>
                          </Fragment>
                        ) : _selected_package[0].issubscribe_status ===
                          "forpayment" ? (
                          <Fragment>
                            <Box my={2}>
                              <Typography color="textSecondary">
                                Please click the clink to pay your subscription
                                and start using your account.
                              </Typography>
                            </Box>
                            <Button
                              fullWidth
                              paylink={_selected_package[0].payment_link}
                              onClick={handlePaymentLink}
                              variant="contained"
                              color="primary"
                            >
                              {" "}
                              Pay Subscription{" "}
                            </Button>
                            <Box mt={2}>
                              <Button
                                onClick={() => window.location.reload()}
                                fullWidth
                                variant="text"
                                color="secondary"
                              >
                                Reload
                              </Button>
                            </Box>
                          </Fragment>
                        ) : null
                      ) : null}
                    </Fragment>
                  ) : (
                    <form onSubmit={saveSubscription}>
                      <FormControl fullWidth variant="outlined" margin="dense">
                        <InputLabel> Subscription Package </InputLabel>
                        <Select
                          name="package"
                          label="Subscription Package"
                          value={_choosenpackage}
                          onChange={(e) => {
                            set_choosenpackage(e.target.value);
                            set_amount_topay(packagesAmount(e.target.value));
                            set_amount_topay_subscription(
                              packagesAmount(e.target.value)
                            );
                            set_issubscribe_month(1);
                          }}
                        >
                          <MenuItem value={"standard"}>Standard</MenuItem>
                          <MenuItem value={"premium"}>Premium</MenuItem>
                          <MenuItem value={"elite"} disabled>
                            Elite
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <Box my={1}>
                        <TextField
                          inputProps={{ readOnly: true }}
                          name="amount_monthly"
                          fullWidth
                          type="number"
                          variant="outlined"
                          margin="dense"
                          label="Subscription Monthly "
                          value={_amount_topay}
                        />
                      </Box>

                      <Box my={1}>
                        <FormControl
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        >
                          <InputLabel>
                            {" "}
                            Subscription Length in Month{" "}
                          </InputLabel>
                          <Select
                            name="month_length"
                            label="Subscription Length in Month"
                            value={_issubscribe_month}
                            onChange={handleChangeMonth}
                          >
                            <MenuItem value="" disabled>
                              Months
                            </MenuItem>
                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                              16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
                              28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
                              40,
                            ].map((data, index) => {
                              return (
                                <MenuItem key={index} value={data}>
                                  {" "}
                                  {data}{" "}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>

                      <Box my={1}>
                        <TextField
                          inputProps={{ readOnly: true }}
                          name="amount"
                          fullWidth
                          type="number"
                          variant="outlined"
                          margin="dense"
                          label="Subscription Amount in PHP"
                          value={
                            _amount_topay_subscription === 0
                              ? _amount_topay
                              : _amount_topay_subscription
                          }
                        />
                      </Box>

                      <Box my={1}>
                        <TextField
                          name="password"
                          fullWidth
                          type="password"
                          variant="outlined"
                          margin="dense"
                          label="Enter your password"
                        />
                      </Box>

                      <Button
                        startIcon={
                          _is_process ? (
                            <CircularProgress color="inherit" size={15} />
                          ) : (
                            <CardMembership />
                          )
                        }
                        disabled={_is_process}
                        color="primary"
                        variant="contained"
                        type="submit"
                      >
                        Subscribe
                      </Button>

                      <Button
                        color="secondary"
                        variant="text"
                        onClick={() =>
                          (window.location.href = "/clinic/app/logout")
                        }
                      >
                        Logout
                      </Button>
                    </form>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box px={2}>
                    <p className="text-dark header-label small">
                      {" "}
                      For subscription assistance please contact:{" "}
                    </p>
                    <p className="m-0 my-1">
                      <span className="header-label text-uppercase small">
                        {" "}
                        MOBILE:{" "}
                      </span>{" "}
                      <br /> <strong> *********************** </strong>
                    </p>
                    <p className="m-0 my-2">
                      <span className="header-label text-uppercase small">
                        {" "}
                        Telephone:{" "}
                      </span>{" "}
                      <br /> <strong> *********************** </strong>
                    </p>
                    <p className="m-0">
                      <span className="header-label text-uppercase small">
                        {" "}
                        Gmail:{" "}
                      </span>{" "}
                      <br /> <strong> infimax.dennis@gmail.com </strong>
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      ) : null}

      {!window.location.href.includes(
        "/clinic/app/patient/appointment/room/"
      ) && ( // disable while you are in a call page
        <Dialog
          open={
            incoming.open &&
            !window.location.href.includes(
              "/clinic/app/patient/appointment/room/"
            )
          }
          onClose={() => setIncoming({ data: [], ready: false, open: true })}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth={"xs"}
          fullWidth
        >
          {incoming.ready && incoming.data.length > 0 && (
            <DialogContent>
              {/* audio caller */}
              <audio ref={callerBeepRef} autoPlay loop>
                <source src={callerBeepSrc} type="audio/ogg" />
                <source src={callerBeepSrc} type="audio/mpeg" />
                Your browser does not support the audio.
              </audio>

              <Typography>
                Doctor {incoming.data[0].doctors_name} created a room for your
                appointment with reference no {incoming.data[0].ref_number}
              </Typography>

              <Box flexGrow={1}>
                <Typography variant="caption" color="textSecondary">
                  Room created on{" "}
                  {Notify.dateTimeConvert(incoming.data[0].created_at)}
                </Typography>
              </Box>

              <Box display={"flex"} mt={2}>
                <Box flexGrow={1} />
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<DoneAllIcon />}
                    onClick={() => {
                      callerBeepRef.current.pause();
                      window.location.href = `/clinic/app/patient/appointment/room/${
                        incoming.data[0].ref_number
                      }/${Notify.randomizeString(incoming.data[0].ref_number)}`;
                    }}
                  >
                    Join
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          )}
        </Dialog>
      )}

      <Dialog
        TransitionComponent={Zoom}
        transitionDuration={800}
        maxWidth="xs"
        fullWidth
        open={showMyQr}
        onClose={() => setShowMyQr(false)}
      >
        <Box display="flex">
          <Box flexGrow={1} />
          <Box>
            <IconButton color="secondary" onClick={() => setShowMyQr(false)}>
              <CancelOutlined />
            </IconButton>
          </Box>
        </Box>
        <DialogContent>
          <MyQRCode />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default RoutePatients;
