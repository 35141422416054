import { Box, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Container from "src/layout/Container";
import PharmacyWarehouseNewInvoiceFrom from "./PharmacyWarehouseNewInvoiceForm";
import ProductListInTemp from "./ProductListInTemp";
import IsMountedRef from "src/utils/IsMountedRef";
import Axios from "axios";

const PharmacyWarehouseNewInvoice = () => {
  const mounted = IsMountedRef();
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const [tempProducts, setTempProducts] = useState({ data: [], ready: false });

  const getTempProductList = React.useCallback(async () => {
    let response = await Axios.get(
      "pharmacy/warehouse/inventory/temp-productlist",
      {
        params: {
          token: token,
          management_id: management_id,
        },
      }
    );

    let result = response.data;
    if (mounted.current) {
      setTempProducts({ data: result, ready: true });
    }
  }, [mounted, management_id, token]);

  useEffect(() => {
    getTempProductList();
  }, [getTempProductList]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "New Invoice",
        items: [
          { name: "Dashboard", path: "/clinic/app" },
          { name: "Inventory", path: "/clinic/app/warehouse/inventory" },
        ],
      }}
      title={"New Invoice"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Paper component={Box} p={2}>
            <PharmacyWarehouseNewInvoiceFrom
              getTempProductList={() => getTempProductList()}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Paper component={Box} p={2}>
            <ProductListInTemp
              tempProducts={tempProducts}
              getTempProductList={() => getTempProductList()}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PharmacyWarehouseNewInvoice;
