import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Trash } from "react-feather";
import Notify from "src/notification/Notify";
import Select from "react-select";
import ScrollBar from "react-perfect-scrollbar";

const PharmacyWarehouseDeliveryTempList = ({
  tempProduct,
  getTempDrProductsList,
}) => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const username = localStorage.getItem("username");

  const [remove, setRemove] = useState({ dialog: false, id: null });
  const [process, setProcess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});

  let total = 0;

  const calculateTotal = (qty, srp) => {
    total += parseInt(qty) * parseFloat(srp);

    return parseInt(qty) * parseFloat(srp);
  };

  const handleRemoveProductItem = async () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("remove_id", remove.id);

    let response = await Axios.post(
      "pharmacy/warehouse/inventory/product/item-removeintemp",
      formdata
    );

    let result = response.data;

    if (result.message === "success") {
      getTempDrProductsList();
      setRemove({ dialog: false, id: null });
      Notify.successRequest("item remove.");
    }
  };

  const getAccountList = React.useCallback(async () => {
    let response = await Axios.get(
      "pharmacy/warehouse/account/getaccount-list",
      {
        params: {
          token,
          management_id,
        },
      }
    );

    let result = response.data;
    setAccountList(result);
  }, [token, management_id]);

  const handleProcessProducts = async (e) => {
    e.preventDefault();
    setLoading(true);
    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("management_id", management_id);
    formdata.set("username", username);
    formdata.set("dr_account", selectedAccount.pwdr_id);
    formdata.set("dr_totalamount", total);
    formdata.set("dr_accountname", selectedAccount.pharmacy_name);
    formdata.set("dr_accountaddress", selectedAccount.pharmacy_address);

    let response = await Axios.post(
      "pharmacy/warehouse/inventory/product/process-tempproducts",
      formdata
    );

    let result = response.data;

    if (result.message === "pass-invalid") {
      Notify.fieldInvalid("password");
    }

    if (result.message === "success") {
      setProcess(false);
      getTempDrProductsList();
      Notify.successRequest("delivery");
    }

    setLoading(false);
  };

  useEffect(() => {
    getAccountList();
  }, [getAccountList]);

  return (
    <Box>
      <Box>
        <Typography variant="h6" color="textSecondary">
          Product To Deliver Unprocess
        </Typography>
      </Box>

      <TableContainer>
        <ScrollBar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Name </TableCell>
                <TableCell> Generic </TableCell>
                <TableCell> Unit </TableCell>
                <TableCell> Batch </TableCell>
                <TableCell> Exp. Date </TableCell>
                <TableCell> Mfg. Date </TableCell>
                <TableCell> Dr. Qty </TableCell>
                <TableCell> Srp </TableCell>
                <TableCell> Amount </TableCell>
                <TableCell> Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tempProduct.ready ? (
                tempProduct.data.length > 0 ? (
                  tempProduct.data.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell> {data.product_name} </TableCell>
                      <TableCell> {data.product_generic} </TableCell>
                      <TableCell> {data.unit} </TableCell>
                      <TableCell> {data.batch_number} </TableCell>
                      <TableCell> {data.expiration_date} </TableCell>
                      <TableCell> {data.manufactured_date} </TableCell>
                      <TableCell align="right"> {data.qty} </TableCell>
                      <TableCell align="right"> {data.srp} </TableCell>
                      <TableCell align="center">
                        {calculateTotal(data.qty, data.srp)}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            setRemove({ dialog: true, id: data.id })
                          }
                        >
                          <Trash />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Typography color="primary">
                      loading, please wait...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell colSpan={7} />
                <TableCell align="center"> Total </TableCell>
                <TableCell align="right"> {total} </TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </ScrollBar>
      </TableContainer>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => setProcess(true)}
          disabled={tempProduct.data.length === 0}
        >
          Process Delivery
        </Button>
      </Box>

      {/* dialog remove item */}
      <Dialog
        open={remove.dialog}
        onClose={() => setRemove({ dialog: false, id: null })}
      >
        <DialogContent>
          <Box mb={2}>
            <Typography>Are you sure to remove this item?</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box>
              <Button
                variant="contained"
                color="default"
                onClick={() => setRemove({ dialog: false, id: null })}
              >
                No
              </Button>
            </Box>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleRemoveProductItem()}
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={process} onClose={() => setProcess(false)}>
        <DialogTitle>Deliver Products</DialogTitle>
        <form onSubmit={handleProcessProducts}>
          <DialogContent dividers>
            <Box>
              <Box mb={2}>
                <TextField
                  label="DR Number"
                  name="dr_number"
                  fullWidth
                  variant="outlined"
                />
              </Box>

              <Box mb={2}>
                <Select
                  options={accountList}
                  onChange={(value) => {
                    setSelectedAccount(value);
                  }}
                  placeholder="Select Product"
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      color: "black",
                    }),
                    control: (base) => ({
                      ...base,
                      minHeight: 55,
                    }),
                  }}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  label="Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  name="password"
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setProcess(false)}
            >
              No
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              startIcon={
                loading && <CircularProgress size={20} color="inherit" />
              }
            >
              Yes
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default PharmacyWarehouseDeliveryTempList;
