import React, { useEffect } from 'react';  
import Notify from '../../../notification/Notify'; 
import { Box, TextField } from '@material-ui/core';
import Axios from 'axios';

const ImagingProcessedDetails = ({ details, imagingType }) => {

    const token = localStorage.getItem('token') 

    const setNotifAsView = async() => {
        var fd = new FormData()
            fd.append('token', token) 
            fd.append('order_id', details.imaging_center_id)
            fd.append('connection', imagingType === 'local-imaging' ? 'local' : 'online')

        await Axios.post('/doctor/setnotification/as-read', fd)
    }
    

    useEffect( () =>{
        
        setNotifAsView()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <Box
            className={'gtc-textfield-noborder'}
        >
            <Box mb={2}>
                <TextField 
                    label="Order"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        readOnly: true
                    }}
                    value={ details.imaging_order } 
                    multiline
                />
            </Box>

            <Box mb={2}>
                <TextField 
                    label="Order Remarks "
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        readOnly: true
                    }}
                    value={ details.imaging_remarks } 
                    multiline
                />
            </Box>

            <Box mb={2}>
                <TextField 
                    label="Order On "
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        readOnly: true
                    }}
                    value={ Notify.createdAt(details.created_at) } 
                />
            </Box>

            <Box mb={2}>
                <TextField 
                    label="Result"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        readOnly: true
                    }}
                    value={ details.imaging_result === null ? 'None' : details.imaging_result }
                    multiline
                />
            </Box>

            <Box mb={2}>
                <TextField 
                    label="Result Remarks"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        readOnly: true
                    }}
                    value={ details.imaging_results_remarks === null ? 'None' : details.imaging_results_remarks }
                    multiline
                />
            </Box>

            <Box mb={2}>
                <TextField 
                    label="Result Added On"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        readOnly: true
                    }}
                    value={ details.end_time === null ? 'None' : Notify.createdAt(details.end_time) }
                />
            </Box>
        </Box>
    )
}

export default ImagingProcessedDetails;
 