import { Box, Typography } from "@material-ui/core";
import React from "react";
import Notify from "src/notification/Notify";

const Header = ({ details }) => {
  return (
    <>
      {/* paitent information */}
      <Box display="flex">
        <Box flexGrow={1} mb={2}>
          <Box mb={2}>
            <Typography>
              <Typography variant="caption" className="font-weight-bold">
                NAME:
              </Typography>{" "}
              <span className="text-capitalize">
                {" "}
                {`${details.data[0].firstname} ${details.data[0].lastname}`}{" "}
              </span>
            </Typography>
          </Box>

          <Box display="flex" mb={2}>
            <Box>
              <Typography>
                <Typography variant="caption" className="font-weight-bold">
                  AGE:
                </Typography>{" "}
                {details.data[0].birthday === null
                  ? "None"
                  : Notify.calculateAge(details.data[0].birthday)}
              </Typography>
            </Box>
            <Box ml={5}>
              <Typography>
                <Typography variant="caption" className="font-weight-bold">
                  SEX:
                </Typography>{" "}
                {details.data[0].gender === null
                  ? "None"
                  : details.data[0].gender}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography>
              <Typography variant="caption" className="font-weight-bold">
                ADDRESS:
              </Typography>{" "}
              <span className="text-capitalize">
                {" "}
                {`${details.data[0].street} ${details.data[0].barangay} ${details.data[0].city} `}{" "}
              </span>
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box mb={2}>
            <Typography>
              <Typography variant="caption" className="font-weight-bold">
                DATE:
              </Typography>{" "}
              {new Date().toLocaleString()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Header;
