import React, { Fragment, useEffect, useState, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import PharmacyDashboard from "src/pharmacy_new/PharmacyDashboard";

import axios from "axios";
import {
  Home,
  Users,
  Monitor,
  DollarSign,
  FileText,
  Archive,
  GitPullRequest,
  Clipboard,
} from "react-feather";

import LoadingScreen from "src/utils/LoadingScreen";

const Receipt = React.lazy(() => import("src/pharmacy_new/receipt/Receipt"));
const ReceiptPrint = React.lazy(() =>
  import("src/pharmacy_new/receipt/ReceiptPrint")
);
const Stock = React.lazy(() => import("src/pharmacy_new/stock/Stock"));
const Sales = React.lazy(() => import("src/pharmacy_new/sales/Sales"));
const Inventory = React.lazy(() =>
  import("src/pharmacy_new/inventory/Inventory")
);
const Logs = React.lazy(() => import("src/pharmacy_new/logs/Logs"));
const Account = React.lazy(() => import("src/pharmacy_new/account/Account"));
const BasicInfo = React.lazy(() =>
  import("src/pharmacy_new/account/BasicInfo")
);
const PharmacyQRCode = React.lazy(() =>
  import("src/pharmacy_new/account/PharmacyQRCode")
);
const LoginInfo = React.lazy(() =>
  import("src/pharmacy_new/account/LoginInfo")
);
const Virtual = React.lazy(() => import("src/pharmacy_new/virtual/Virtual"));
const LocalOrder = React.lazy(() => import("src/pharmacy_new/localorder"));
const Logout = React.lazy(() => import("src/welcome/Logout"));
const Sidebar = React.lazy(() => import("src/layout/Sidebar"));
const PharmacyProducts = React.lazy(() => import("src/pharmacy_new/products"));
const PharmacySecretaryAccount = React.lazy(() =>
  import("src/pharmacy_new/account/PharmacySecretaryAccount")
);

const RoutePharmacyNew = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  var interval = null;

  const [sidebarHeader, setSidebarHeader] = useState([]);
  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: user_id, token: token };
      const response = await axios.get("pharmacy/sidebar/header-infomartion", {
        params,
      });

      setSidebarHeader(response.data);
      localStorage.setItem("pharmacy_id", response.data.pharmacy_id);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const fetchRole = async () => {
    try {
      var params = {
        user_id: user_id,
        token: token,
        management_id: management_id,
      };

      const response = await axios.get("pharmacy/get-spec-role", { params });
      if (Object.keys(response.data).length > 0) {
        localStorage.setItem("pharmacy_id", response.data.pharmacy_id);
        localStorage.setItem("role", response.data.role);
        localStorage.setItem(
          "pharmacy_category",
          response.data.pharmacy_category
        );
      }
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <Home color="white" />,
      path: "/clinic/app",
      subitem: [],
    },

    {
      name: "Products",
      show: true,
      icon: <Clipboard color="white" />,
      path: "/clinic/app/pharmacy/products",
      subitem: [],
    },

    {
      name: "Inventory",
      show: true,
      icon: <Archive color="white" />,
      path: "/clinic/app/inventory",
      subitem: [],
    },
    {
      name: "Receipt",
      show: true,
      icon: <FileText color="white" />,
      path: "/clinic/app/receipt",
      subitem: [],
    },
    {
      name: "Stock",
      show: true,
      icon: <Monitor color="white" />,
      path: "/clinic/app/stock",
      subitem: [],
    },
    {
      name: "Logs",
      show: true,
      icon: <GitPullRequest color="white" />,
      path: "/clinic/app/logs",
      subitem: [],
    },
    {
      name: "Sales",
      show: true,
      icon: <DollarSign color="white" />,
      path: "/clinic/app/sales",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: <Users color="white" />,
      path: "/clinic/app/account",
      subitem: [],
    },
  ];

  useEffect(() => {
    getSidebarHeaderInfo();
    fetchRole();

    return () => {
      clearInterval(interval);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Suspense fallback={<LoadingScreen />}>
        <BrowserRouter>
          <Sidebar
            notification={{
              enable: true,
              local: [],
              virtual: [],
              owner: "pharmacy",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"pharmacy"}
          />

          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/clinic/app" />}
            />
            <Route exact path="/clinic/app" component={PharmacyDashboard} />
            <Route
              exact
              path="/clinic/app/pharmacy/products"
              component={PharmacyProducts}
            />

            <Route exact path="/clinic/app/inventory" component={Inventory} />
            <Route exact path="/clinic/app/receipt" component={Receipt} />
            <Route
              exact
              path="/clinic/app/receipt/:receipt_number"
              component={ReceiptPrint}
            />
            <Route exact path="/clinic/app/stock" component={Stock} />
            <Route exact path="/clinic/app/logs" component={Logs} />
            <Route exact path="/clinic/app/sales" component={Sales} />
            <Route exact path="/clinic/app/account" component={Account} />

            {/* Virtual */}
            <Route exact path="/clinic/app/virtual" component={Virtual} />
            <Route exact path="/clinic/app/local" component={LocalOrder} />

            {/* account Redirect */}
            <Route
              exact
              path="/clinic/app/account/basic-info"
              component={BasicInfo}
            />
            <Route
              exact
              path="/clinic/app/account/login-info"
              component={LoginInfo}
            />
            <Route
              exact
              path="/clinic/app/account/pharmacy-qr"
              component={PharmacyQRCode}
            />
            <Route
              exact
              path="/clinic/app/account/pharmacy-secretary"
              component={PharmacySecretaryAccount}
            />

            <Route exact path="/clinic/app/logout" component={Logout} />
            <Route render={() => <PageNotFound title="Page not found" />} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </Fragment>
  );
};

export default RoutePharmacyNew;
