import React, { Fragment, useContext, useState } from "react";
import {
  BrowserRouter,
  NavLink,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Dashboard from "../administrator/Dashboard";
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound";
import Credits from "../administrator/credits/Credits";
import Subscribes from "../administrator/subscribes/Subscribes";
import Doctor from "../administrator/doctor/Doctor";
import { ThemeContext } from "../ContextAPI";
import DarkIcon from "@material-ui/icons/Brightness4";
import LightIcon from "@material-ui/icons/Brightness7";
import {
  AppBar,
  Box,
  Drawer,
  Hidden,
  IconButton,
  List,
  Toolbar,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import HomeIcon from "@material-ui/icons/Home";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Clinics from "src/administrator/clinic";
import GTCNews from "src/administrator/gtc_news";
import Management from "src/administrator/management";

const RouteAdministrator = () => {
  const themeContext = useContext(ThemeContext);
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Fragment>
      <BrowserRouter>
        <AppBar
          position="static"
          color={themeContext.gtcThemeDark ? "default" : "primary"}
          className={`gtc-appbar`}
        >
          <Toolbar>
            {/* desktop navigation bar */}
            <Hidden smDown>
              {/* mdup large appbar */}
              <Box display="flex" width={"100%"} className={`appbarLink`}>
                <Box flexGrow={1}>
                  <Box display="flex">
                    <Box m={2}>
                      <Typography className={`text-white`}>
                        GTC ADMIN
                      </Typography>
                    </Box>
                    <Box m={2}>
                      <NavLink
                        to="/clinic/app/administrator"
                        exact
                        activeClassName="text-white"
                      >
                        Home
                      </NavLink>
                    </Box>

                    <Box m={2}>
                      <NavLink
                        to="/clinic/app/administrator/credits"
                        exact
                        activeClassName="text-white"
                      >
                        Credits
                      </NavLink>
                    </Box>

                    <Box m={2}>
                      <NavLink
                        to="/clinic/app/administrator/subscribes"
                        exact
                        activeClassName="text-white"
                      >
                        Subscription
                      </NavLink>
                    </Box>

                    <Box m={2}>
                      <NavLink
                        to="/clinic/app/administrator/doctors"
                        exact
                        activeClassName="text-white"
                      >
                        Doctors
                      </NavLink>
                    </Box>

                    <Box m={2}>
                      <NavLink
                        to="/clinic/app/administrator/clinics"
                        exact
                        activeClassName="text-white"
                      >
                        Clinics
                      </NavLink>
                    </Box>

                    <Box m={2}>
                      <NavLink
                        to="/clinic/app/administrator/news"
                        exact
                        activeClassName="text-white"
                      >
                        News
                      </NavLink>
                    </Box>

                    <Box m={2}>
                      <NavLink
                        to="/clinic/app/administrator/management"
                        exact
                        activeClassName="text-white"
                      >
                        Management
                      </NavLink>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Hidden>

            {/* mobile navigation bar */}
            <Hidden mdUp>
              {/* smdown appbar drawer */}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setOpenDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Box display="flex" width={"100%"}>
                <Box flexGrow={1}>
                  {/* drawer for mobile view */}
                  <Drawer
                    anchor={`left`}
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                  >
                    <Box p={2}>
                      <Typography>GTC ADMIN</Typography>
                      <List className={`appbarLink`}>
                        <ListItem
                          component={NavLink}
                          exact
                          to="/clinic/app/administrator"
                          activeClassName={
                            themeContext.gtcThemeDark
                              ? "text-white"
                              : "gtc-text-purple"
                          }
                        >
                          <ListItemIcon>
                            {" "}
                            <HomeIcon />{" "}
                          </ListItemIcon>
                          <ListItemText primary={"Home"} />
                        </ListItem>

                        <ListItem
                          component={NavLink}
                          exact
                          to="/clinic/app/administrator/credits"
                          activeClassName={
                            themeContext.gtcThemeDark
                              ? "text-white"
                              : "gtc-text-purple"
                          }
                        >
                          <ListItemIcon>
                            {" "}
                            <CreditCardIcon />{" "}
                          </ListItemIcon>
                          <ListItemText primary={"Credits"} />
                        </ListItem>

                        <ListItem
                          component={NavLink}
                          exact
                          to="/clinic/app/administrator/subscribes"
                          activeClassName={
                            themeContext.gtcThemeDark
                              ? "text-white"
                              : "gtc-text-purple"
                          }
                        >
                          <ListItemIcon>
                            {" "}
                            <InsertLinkIcon />{" "}
                          </ListItemIcon>
                          <ListItemText primary={"Subscription"} />
                        </ListItem>

                        <ListItem
                          component={NavLink}
                          exact
                          to="/clinic/app/administrator/doctors"
                          activeClassName={
                            themeContext.gtcThemeDark
                              ? "text-white"
                              : "gtc-text-purple"
                          }
                        >
                          <ListItemIcon>
                            {" "}
                            <RecentActorsIcon />{" "}
                          </ListItemIcon>
                          <ListItemText primary={"Doctors"} />
                        </ListItem>

                        <ListItem
                          component={NavLink}
                          exact
                          to="/clinic/app/administrator/clinics"
                          activeClassName={
                            themeContext.gtcThemeDark
                              ? "text-white"
                              : "gtc-text-purple"
                          }
                        >
                          <ListItemIcon>
                            {" "}
                            <RecentActorsIcon />{" "}
                          </ListItemIcon>
                          <ListItemText primary={"Clinics"} />
                        </ListItem>

                        <ListItem
                          component={NavLink}
                          exact
                          to="/clinic/app/administrator/news"
                          activeClassName={
                            themeContext.gtcThemeDark
                              ? "text-white"
                              : "gtc-text-purple"
                          }
                        >
                          <ListItemIcon>
                            {" "}
                            <RecentActorsIcon />{" "}
                          </ListItemIcon>
                          <ListItemText primary={"News"} />
                        </ListItem>
                      </List>
                    </Box>
                  </Drawer>
                </Box>
              </Box>
            </Hidden>

            <Box>
              <Box display="flex" width={180}>
                <Box m={2} className={`pointer`}>
                  <Typography
                    onClick={() => {
                      themeContext.updategtcTheme(
                        themeContext.gtcThemeDark ? "light" : "dark"
                      );
                    }}
                  >
                    {" "}
                    {themeContext.gtcThemeDark ? (
                      <DarkIcon />
                    ) : (
                      <LightIcon />
                    )}{" "}
                  </Typography>
                </Box>

                <Box m={2}>
                  <NavLink
                    to="/clinic/app/logout"
                    exact
                    style={{ color: "#c5c5c5", textDecoration: "none" }}
                  >
                    Logout <ExitToAppIcon />
                  </NavLink>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Switch>
          <Route
            exact
            path="/"
            component={() => <Redirect to="/clinic/app/administrator" />}
          />
          <Route
            exact
            path="/clinic/"
            component={() => <Redirect to="/clinic/app/administrator" />}
          />
          <Route
            exact
            path="/clinic/app"
            component={() => <Redirect to="/clinic/app/administrator" />}
          />
          <Route exact path="/clinic/app/administrator" component={Dashboard} />
          <Route
            exact
            path="/clinic/app/administrator/credits"
            component={Credits}
          />
          <Route
            exact
            path="/clinic/app/administrator/subscribes"
            component={Subscribes}
          />
          <Route
            exact
            path="/clinic/app/administrator/doctors"
            component={Doctor}
          />
          <Route
            exact
            path="/clinic/app/administrator/clinics"
            component={Clinics}
          />
          <Route
            exact
            path="/clinic/app/administrator/news"
            component={GTCNews}
          />

          <Route
            exact
            path="/clinic/app/administrator/management"
            component={Management}
          />

          <Route exact path="/clinic/app/logout" component={Logout} />
          <Route render={() => <PageNotFound />} />
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

export default RouteAdministrator;
