import React, { useCallback, useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import Notify from 'src/notification/Notify';
import axios from 'axios' 


const BillingReceipt = () =>{
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id') 
    const { receipt_number } = useParams();

    const [doctorsInfo, setDoctorsInfo] = useState([])
    const [doctorsInfoRd, setDoctorsInfoRd] = useState(false)

    const [details, setDetails] = useState({
        data: [],
        ready: false
    })

    const history = useHistory()

    const getDoctorsInfo = useCallback(() => { 

        const params ={
            token, user_id
        }

        axios.get('encoder/receipt/printable/doctorsdetails', { params })
        .then((response) =>{
            const data = response.data
            setDoctorsInfo(data)
            setDoctorsInfoRd(true) 
        }).catch((error) =>{
            Notify.requestError(error)
        })
    } , [token, user_id] );

    const getReceiptDetails = useCallback( () => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);         
        formdata.set('receipt_number', receipt_number);         
        
        axios.post('encoder/pateint/billing/receipt/details', formdata )
        .then( (response) => { 
            const data = response.data;     
            setDetails({
                data: data,
                ready: true
            })   
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    },[token, user_id, receipt_number]) 

    useEffect(() =>{

        getReceiptDetails()
        getDoctorsInfo()

    },[getReceiptDetails, getDoctorsInfo])

    return(
        <Dialog
            open ={ true }
            onClose = { () => console.log('dialog cannot be close') }
            fullScreen
        >
            <DialogContent>
                <Box m={2}>
                    <Box display="flex" justifyContent="center" align="center" mb={1}>
                        <Box>
                            <Box mb={1}> 
                                <Typography variant="h4">
                                    <strong> PAYMENT RECEIPT </strong>
                                </Typography>    
                            </Box>

                            {
                                doctorsInfoRd ? 
                                    doctorsInfo.length > 0 ?
                                        <Box align="center" mb={1}>
                                            <Box>
                                                <Typography variant="h6" className={`gtc-uppercase`}>
                                                    <b> {doctorsInfo[0].header} </b>
                                                </Typography> 
                                            </Box>
                
                                            <Box>
                                                <Typography variant="subtitle1"  className={`gtc-uppercase`} color="textPrimary">
                                                    {doctorsInfo[0].sub_header}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {doctorsInfo[0].location}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {doctorsInfo[0].contact_no}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {doctorsInfo[0].days_open}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    :   <Box> 
                                            { Notify.noRecord() }
                                            <Typography>
                                                Doctors Information Not Found.
                                            </Typography>
                                        </Box>
                                : Notify.loading()
                            }  
                        </Box>
                    </Box>

                    <Box mb={1}>
                        <Typography> Name: { details.data.length > 0 && `${details.data[0].fname} ${details.data[0].lname}` }</Typography> 
                    </Box>

                    <Box mb={1}>
                        <Typography> Address: { details.data.length > 0 && `${details.data[0].street} ${details.data[0].barangay} ${details.data[0].city }` } </Typography>  
                    </Box>

                    <Box mb={1}>
                        <Typography> Date: { details.data.length > 0 && Notify.dateTimeConvert(details.data[0].created_at) } </Typography>  
                    </Box>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> <b> Description </b> </TableCell>
                                    <TableCell> <b> Amount </b> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    details.ready ? 
                                        details.data.length > 0 ?
                                            details.data.map((data, index) =>(
                                                <TableRow key={index} hover>
                                                    <TableCell> 
                                                        <Typography variant={'caption'} gtc={`gtc-uppercase`}>
                                                            { data.bill_name }
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right"> { data.bill_amount } </TableCell>
                                                </TableRow>
                                            ))  
                                        : <TableRow>
                                            <TableCell colSpan={2}> 
                                                <Typography color="secondary">  
                                                    No record found.
                                                </Typography>
                                            </TableCell> 
                                        </TableRow>
                                    :
                                    <TableRow>
                                        <TableCell colSpan={2}> 
                                            <Typography color="primary">  
                                                please wait...
                                            </Typography>
                                        </TableCell> 
                                    </TableRow>
                                }
                            </TableBody>
                            <TableBody>
                                <TableRow> 
                                    <TableCell align="right"> <b> Total </b> </TableCell>
                                    <TableCell align="right"> 
                                        <b> { details.data.length > 0 && Notify.convertToNumber(details.data[0].bill_total) }  </b>
                                    </TableCell>
                                </TableRow>

                                <TableRow> 
                                    <TableCell align="right"> <b> Payment </b>  </TableCell>
                                    <TableCell align="right"> 
                                        <b> { details.data.length > 0 && Notify.convertToNumber(details.data[0].bill_payment) }   </b>
                                    </TableCell>
                                </TableRow>

                                <TableRow> 
                                    <TableCell align="right"> <b> Change </b>  </TableCell>
                                    <TableCell align="right"> 
                                        <b> { details.data.length > 0 && Notify.convertToNumber(parseFloat(details.data[0].bill_payment) - parseFloat(details.data[0].bill_total) ) } </b>   
                                    </TableCell>
                                </TableRow>

                                <TableRow className={`d-print-none`}>
                                    <TableCell colSpan={2} align="right">  
                                        <Button
                                            variant="contained"
                                            color="default"
                                            onClick={ ()=> history.push('/clinic/app')}
                                        >
                                            Back To Home
                                        </Button>

                                        <Button
                                            component={Box}
                                            ml={2}
                                            variant="contained"
                                            color="primary"
                                            onClick={ () => window.print() }
                                        >
                                            Print
                                        </Button>
                                    </TableCell> 
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </DialogContent>
        </Dialog>
    ) 
}

export default BillingReceipt;