import { Box, Grid, Paper, Typography, TextField, Button, Tooltip, CardMedia } from '@material-ui/core' 
import React, { useEffect } from 'react'  
import axios from 'axios'
import Notify from 'src/notification/Notify';  
import Label from 'src/utils/Label';
  
import AccessAlarmIcon from '@material-ui/icons/InsertInvitation';
import { useHistory } from 'react-router-dom';
import Reschedule  from './Reschedule'
import { Calendar, User, Video } from 'react-feather';
import IsMountedRef from 'src/utils/IsMountedRef';

const imageLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL

const DetailsVirtual = ({ appid }) =>{   
    const ismounted = IsMountedRef()
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id') 
    const [details, setDetails] = React.useState({ data: [] , ready: false }) 
   

    const getAppointmentDetails = async () => {
        try {  
            var formdata = new FormData() 
                formdata.set('appointment_id', appid);     
                formdata.set('user_id', user_id);     
                formdata.set('token', token);       
                formdata.set('connection', 'online')

            const response = await axios.post('doctor/appointment/fullcalendar/virtual/appointment-details', formdata)
            if(ismounted.current){
                setDetails({ data: response.data, ready: true }) 
            }
        } catch (error) { 
            Notify.requestError(error)
        }
    }

    useEffect(() =>{ 
        
        getAppointmentDetails()  

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ismounted])

    return(
        <> 
            <Grid container spacing={2}>
                <Box clone order={{ xs: 2, sm: 2, md: 1}}>
                    <Grid item xs={12} sm={12} md={9} lg={8}>   
                        <Details details={details} getAppointmentDetails ={ getAppointmentDetails } />
                    </Grid> 
                </Box> 

                <Box clone order={{ xs: 1, sm: 1, md: 2}}>
                    <Grid item xs={12} sm={12} md={3} lg={4}> 
                        <Box align="center"> 
                            { details.data.length > 0 && (
                                <>
                                    <Box mb={2}>
                                        <AccessAlarmIcon style={{ fontSize: window.innerWidth > 500 ? 100 : 60 }} />
                                    </Box>

                                    <Box mb={2}>
                                        {
                                            Boolean(parseInt(details.data[0].is_reschedule)) ? 
                                                <Typography variant={window.innerWidth > 500 ? 'h4' : 'h5'}> { Notify.dateTimeConvert(details.data[0].is_reschedule_date)  } </Typography>
                                            : 
                                                <Typography variant={window.innerWidth > 500 ? 'h4' : 'h5'}> {  Notify.dateTimeConvert(details.data[0].appointment_date)  } </Typography>
                                        }
                                    </Box>

                                    <Box>
                                        { details.data[0].appointment_status === 'successful' && ( <Label color="success"> APPOINTMENT COMPLETED </Label>  ) } 
                                        { details.data[0].appointment_status === 'new' && ( <Label color="warning"> NEW APPOINTMENT </Label> ) } 
                                        { details.data[0].appointment_status === 'approved' && ( <Label color="primary"> APPOINTMENT APPROVED </Label> ) }  
                                    </Box>
                                    
                                    {/*   
                                        ****
                                            * attachnement from online if they have added
                                        ****
                                    */}
                                    { Boolean(details.data[0].attachment) && (
                                        <Box mt={3}> 
                                            <CardMedia 
                                                style={{ maxWidth: 250, maxHeight: 250 }}
                                                component={`img`} 
                                                src={`${imageLocation}appointment/${details.data[0].attachment}`}
                                                title="GTC Appointment Attachment"
                                            />
                                            <Typography variant="caption" > <Label color="primary"> ATTACHMENT </Label> </Typography>
                                        </Box>
                                    )}
                                    
                                </>
                            )}  
                        </Box>  
                    </Grid>
                </Box>

            </Grid>
        </>
    )
}

export default DetailsVirtual;


const Details = ({ details, getAppointmentDetails }) => {

    const history = useHistory()
    const [isreschedule, setIsReschedule] = React.useState(false)

    return(
        <>  
            <Box 
                component={Paper} 
                p={2} 
                className="gtc-textfield-noborder"
                elevation={2}
            >
                <Box mb={3}>
                    <Typography color="textSecondary" variant="h6">
                        Appointment Details
                    </Typography>
                </Box>  
                
                { 
                    details.ready ? 
                        details.data.length > 0 ? 
                            <>   
                                <Box mb={3}> 
                                    <TextField 
                                        label={'Patient'}
                                        variant={'outlined'} 
                                        fullWidth
                                        value={`${details.data[0].lastname}, ${details.data[0].firstname}`}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Box> 
                                
                                <Box mb={3}>
                                    <TextField 
                                        label={'Service'}
                                        variant={'outlined'} 
                                        fullWidth
                                        value={details.data[0].doctors_service}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Box>

                                <Box mb={3}>
                                    <TextField 
                                        label={'Rate'}
                                        variant={'outlined'} 
                                        fullWidth
                                        value={details.data[0].doctors_service_amount}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Box>

                                <Box mb={3}>
                                    <TextField 
                                        label={'Reason'}
                                        variant={'outlined'} 
                                        fullWidth
                                        value={ details.data[0].appointment_reason === null ? 'None' : details.data[0].appointment_reason }
                                        InputProps={{ readOnly: true }}
                                        multiline
                                    />
                                </Box>   

                                <Box mb={3}>
                                    <TextField 
                                        label={'Request Appointment Date'}
                                        variant={'outlined'} 
                                        fullWidth
                                        value={details.data[0].appointment_date === null ? 'None' : Notify.createdAt(details.data[0].appointment_date)}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Box>

                                <Box mb={3}>
                                    <TextField 
                                        label={'Request Appointment Time'}
                                        variant={'outlined'} 
                                        fullWidth
                                        value={details.data[0].appointment_date === null ? 'None' : Notify.createdTime(details.data[0].appointment_date) }
                                        InputProps={{ readOnly: true }}
                                    />
                                </Box> 

                                <Box mb={3}>
                                    <TextField 
                                        label={'Reschedule'}
                                        variant={'outlined'} 
                                        fullWidth
                                        value={Boolean(parseInt(details.data[0].is_reschedule)) ? 'Yes' : 'No'}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Box>

                                { Boolean(parseInt(details.data[0].is_reschedule)) && (
                                    <>
                                        <Box mb={3}>
                                            <TextField 
                                                label={'New Appointment Date'}
                                                variant={'outlined'} 
                                                fullWidth
                                                value={details.data[0].is_reschedule_date === null ? 'None' : Notify.createdAt(details.data[0].is_reschedule_date)}
                                                InputProps={{ readOnly: true }}
                                            />
                                        </Box>

                                        <Box mb={3}>
                                            <TextField 
                                                label={'New Appointment Time'}
                                                variant={'outlined'} 
                                                fullWidth
                                                value={details.data[0].is_reschedule_date === null ? 'None' :  Notify.createdTime(details.data[0].is_reschedule_date)}
                                                InputProps={{ readOnly: true }}
                                            />
                                        </Box> 

                                        <Box mb={3}>
                                            <TextField 
                                                label={'New Appointment Reason'}
                                                variant={'outlined'} 
                                                fullWidth
                                                value={details.data[0].is_reschedule_reason === null ? 'None' : details.data[0].is_reschedule_reason }
                                                InputProps={{ readOnly: true }}
                                                multiline
                                            />
                                        </Box> 
                                    </>
                                )} 


                                <Box mb={3}>
                                    <TextField 
                                        label={'Appointment Status'}
                                        variant={'outlined'} 
                                        fullWidth
                                        value={ 
                                           details.data[0].appointment_status === 'successful' ? 'Completed' :
                                           details.data[0].appointment_status === 'new' ? 'New' : 
                                           details.data[0].appointment_status === 'approved' ? 'Approved' : 'Status'
                                        }
                                        InputProps={{ readOnly: true }}
                                    />
                                </Box>


                                <Box display={ window.innerWidth > 1000 ? 'flex' : 'column' } justifyContent="flex-end"> 
                                    <Box> 
                                        <Button 
                                            fullWidth
                                            size={'large'}
                                            variant="outlined"
                                            onClick={ () => history.push(`/clinic/app/doctor/patient/details/${details.data[0].patientold_id}`)}
                                            startIcon={ <User /> }
                                            color="primary"
                                        > Profile </Button>
                                    </Box> 
                                    <Box my={window.innerWidth > 1000 ? 0 : 1}  mx={window.innerWidth > 1000 ? 1 : 0}>
                                        <Tooltip
                                            arrow 
                                            title={ details.data[0].appointment_status === 'successful' ? 'Appointment is completed' : 'Change appointment date'}
                                        >
                                            <Box>
                                                <Button 
                                                    fullWidth
                                                    size={'large'}
                                                    variant="outlined"
                                                    disabled={ details.data[0].appointment_status === 'successful' } 
                                                    color="primary"
                                                    onClick={ () =>{
                                                        setIsReschedule(true)
                                                    }}
                                                    startIcon={ <Calendar /> }
                                                > Reschedule </Button>
                                            </Box>
                                        </Tooltip>
                                    </Box> 
                                    <Box> 
                                        <Tooltip 
                                            arrow
                                            title={
                                                details.data[0].appointment_status === 'successful' ? 'Appointment successful' : 
                                                details.data[0].appointment_status === 'new' ? 'Appointment is newly added and not yet approved' : 
                                                details.data[0].appointment_status === 'approved' ? 'Appointment is approved' : 'Appointment'
                                            }
                                            >
                                            <Box>
                                                <Button 
                                                    fullWidth
                                                    size={'large'}
                                                    disabled={ details.data[0].appointment_status === 'successful' || details.data[0].appointment_status === 'new'} 
                                                    variant="contained" 
                                                    color="primary"
                                                    endIcon={<Video /> } 
                                                    onClick={ () => history.push(`/clinic/app/doctor/appointment/room/${details.data[0].reference_no}/${Notify.randomizeString(details.data[0].reference_no)}`) } 
                                                > Video Call </Button>
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                </Box>

                                <Reschedule  
                                    open={ isreschedule }
                                    closeDialog ={ () => setIsReschedule(false) }
                                    appdate={ Boolean(parseInt(details.data[0].is_reschedule)) ? details.data[0].is_reschedule_date : details.data[0].appointment_date }
                                    appid={ details.data[0].appointment_id }
                                    getAppointmentDetails ={ getAppointmentDetails }
                                    connection={ 'online' }
                                />
                            </>
                        : Notify.noRecord()
                    : Notify.loading()
                }
            </Box>
        </>
    )
}