import {
  Box,
  Grid,
  makeStyles,
  Tabs,
  Tab,
  Paper,
  Badge,
} from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
import LaboratoryOrder from "./LaboratoryOrder";

import React, { useEffect, useState, useContext } from "react";
import UnpaidOrder from "./orderlist/UnpaidOrderList";
import PaidOrder from "./orderlist/PaidOrder";
import { PatientsUnRead } from "src/ContextAPI";
import LaboratorySharedImages from "../laboratory/LaboratorySharedImages";

const useStyle = makeStyles({
  indicatorLeft: {
    left: "0px",
  },
});

const Laboratory = ({
  connection,
  patient_id,
  getPermission,
  getPatientsList,
  permission,
}) => {
  const classes = useStyle();
  const [category, setCategory] = useState("order");

  const unread = useContext(PatientsUnRead);

  const checkPatientUnreadNotif = (category) => {
    let xx = unread.unviewNotif;
    let count = 0;
    for (let i = 0; i < xx.length; i++) {
      if (patient_id === xx[i].patient_id && category === xx[i].category) {
        count += 1;
      }
    }

    return count;
  };

  const handleChange = (event, cat) => {
    event.persist();
    setCategory(cat);
  };

  useEffect(() => {}, [patient_id]);

  return (
    <Grid container spacing={2}>
      <Box clone order={{ xs: 2, sm: 2, md: 1 }}>
        <Grid item xs={12} sm={12} md={10}>
          <TabPanel value={category} index={"order"}>
            <LaboratoryOrder patient_id={patient_id} connection={connection} />
          </TabPanel>

          <TabPanel value={category} index={"order-unpaid"}>
            <UnpaidOrder patient_id={patient_id} connection={connection} />
          </TabPanel>

          <TabPanel value={category} index={"order-paid"}>
            <PaidOrder
              patient_id={patient_id}
              connection={connection}
              getPatientsList={() => getPatientsList()}
              getPermission={() => getPermission()}
              permission={permission}
            />
          </TabPanel>

          <TabPanel value={category} index={"shared-image"}>
            <LaboratorySharedImages
              patient_id={patient_id}
              connection={connection}
            />
          </TabPanel>
        </Grid>
      </Box>

      <Box clone order={{ xs: 1, sm: 1, md: 2 }}>
        <Grid item xs={12} sm={12} md={2}>
          <Box component={Paper} elevation={2} mb={2}>
            <Tabs
              value={category}
              onChange={handleChange}
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              orientation={window.innerWidth > 959 ? "vertical" : "horizontal"}
              classes={{
                indicator: classes.indicatorLeft,
              }}
            >
              <Tab wrapped value="order" label="Create Order" />

              <Tab wrapped value="order-unpaid" label=" Unpaid Order " />

              <Tab
                wrapped
                value="order-paid"
                label={
                  <Badge
                    color="secondary"
                    badgeContent={checkPatientUnreadNotif("laboratory")}
                    invisible={
                      !Boolean(parseInt(checkPatientUnreadNotif("laboratory")))
                    }
                  >
                    Paid Order
                  </Badge>
                }
              />

              <Tab wrapped value="shared-image" label=" SharedImage" />
            </Tabs>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Laboratory;
