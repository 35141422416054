import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import Notify from "src/notification/Notify";
import Axios from "axios";

const AddNews = () => {
  const token = localStorage.getItem("token");
  const [files, setFiles] = useState([]);
  const [isprocess, setIsProcess] = useState(false);
  const handleChange = (files) => {
    setFiles(files);
  };

  const handleAddNews = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.append("thumbnail", files.length > 0 ? files[0] : []);

    var error = [];

    if (
      formdata.get("title").length === 0 ||
      formdata.get("title").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("title");
    }

    if (formdata.get("tag").length === 0 || formdata.get("tag").trim() === "") {
      error = "error";
      Notify.fieldRequired("tag");
    }

    if (
      formdata.get("news_for").length === 0 ||
      formdata.get("news_for").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("news_for");
    }

    if (
      formdata.get("is_featured").length === 0 ||
      formdata.get("is_featured").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("is_featured");
    }
    if (files.length === 0) {
      error = "error";
      Notify.fieldRequired("thumbnail");
    }

    if (formdata.get("url").length === 0 || formdata.get("url").trim() === "") {
      error = "error";
      Notify.fieldRequired("url");
    }

    if (error.length > 0) {
      console.log("form has an error");
    } else {
      setIsProcess(true);
      Axios.post(`gtcadmin/news/add-news`, formdata)
        .then((response) => {
          const data = response.data;

          if (data === "success") {
            e.target.reset();
            Notify.successRequest("new added");
            setFiles([]);
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsProcess(false);
        });
    }
  };

  return (
    <Box>
      <Paper>
        <Box p={2}>
          <Box mb={2}>
            <Typography variant="h6" color="textSecondary">
              Add News
            </Typography>
          </Box>

          <Box>
            <form onSubmit={handleAddNews}>
              <Box mb={3}>
                <TextField
                  label="Title"
                  fullWidth
                  variant="outlined"
                  name="title"
                />
              </Box>

              <Box mb={3}>
                <TextField
                  label="Tag"
                  fullWidth
                  variant="outlined"
                  name="tag"
                />
              </Box>

              <Box mb={3}>
                <TextField
                  label="News For"
                  fullWidth
                  variant="outlined"
                  name="news_for"
                />
              </Box>

              <Box mb={3}>
                <TextField
                  label="Featured"
                  fullWidth
                  variant="outlined"
                  name="is_featured"
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value=""> -- Select -- </option>
                  <option value={0}> News In Dashboard List </option>
                  <option value={1}> News In Dashboard Featured </option>
                  <option value={2}> News In Dashboard Dialog List </option>
                  <option value={3}> News In Dashboard Dialog Featured </option>
                </TextField>
              </Box>

              <Box mb={3}>
                <DropzoneArea
                  onChange={handleChange}
                  acceptedFiles={["image/jpeg", "image/png"]}
                  maxFileSize={5000000}
                  filesLimit={1}
                />
              </Box>

              <Box mb={3}>
                <TextField
                  label="URL"
                  fullWidth
                  variant="outlined"
                  name="url"
                />
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Box mr={2}>
                  <Button variant="contained" size="large" color="default">
                    {" "}
                    Clear{" "}
                  </Button>
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isprocess}
                  startIcon={
                    isprocess && <CircularProgress color="inherit" size={25} />
                  }
                >
                  {" "}
                  Save{" "}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddNews;
