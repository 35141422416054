import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Axios from "axios";
import Label from "src/utils/Label";
import { Eye } from "react-feather";

const BodyPain = ({ patient_id, connection }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const maincanvasRef = useRef(null);
  const [ctx, setCTX] = useState(null);
  const [painList, setPainList] = useState([]);
  const [facing, setFacing] = useState("front");
  const [selectedPain, setSelectedPain] = useState(null);

  let image = new Image();
  let pain = new Image();
  var painsize = 20;
  var height = 600;
  var width = 250;

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(7);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const applyPagination = (list, page, limit) => {
    return list.slice(page * limit, page * limit + limit);
  };

  const initializeCanvas = (data) => {
    image.src =
      data[0].gender === "Female"
        ? facing === "front"
          ? "/female_skeleton_front.png"
          : "/female_skeleton_back.png"
        : facing === "front"
        ? "/male_skeleton_front.png"
        : "/male_skeleton_back.png";

    pain.src = "/skeleton-pain.png";

    maincanvasRef.current.height = height;
    maincanvasRef.current.width = width;

    image.onload = () => {
      ctx.drawImage(image, 0, 0, width, height);
      setTimeout(() => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].facing === facing) {
            ctx.drawImage(
              pain,
              parseFloat(data[i].pain_position_x),
              parseFloat(data[i].pain_position_y),
              painsize,
              painsize
            );
            ctx.fillStyle = "#fff";
            ctx.fillText(
              data[i].pain_level,
              parseFloat(data[i].pain_position_x) + 5,
              parseFloat(data[i].pain_position_y) + 13
            );
          }
        }
      }, 1000);
    };
  };

  const getPainList = async () => {
    try {
      var formdata = new FormData();
      formdata.append("token", token);
      formdata.append("user_id", user_id);
      formdata.append("patient_id", patient_id);
      formdata.append("connection", connection);

      const request = await Axios.post(
        "doctor/patient/vitals/graph/get-bodypain",
        formdata
      );
      const data = request.data;
      initializeCanvas(data);
      setPainList(data);
    } catch (error) {
      Notify.requestError(error);
    }
  };

  const resetImage = useCallback(() => {
    image.src = "";
    maincanvasRef.current.height = 0;
    maincanvasRef.current.height = 0;

    if (ctx) {
      ctx.fillRect(0, 0, width, height);
    }

    setPainList([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  useEffect(() => {
    setCTX(maincanvasRef.current.getContext("2d"));

    if (ctx) {
      getPainList();
    } else {
      console.log("ctx not set.... ");
    }

    resetImage();

    // eslint-disable-next-line
  }, [ctx, facing, resetImage]);

  const showPainInCanvas = (data) => {
    if (selectedPain && selectedPain.id === data.id) {
      console.log("pain is currently selected.");
      return;
    }

    image.src =
      data.gender === "Female"
        ? facing === "front"
          ? "/female_skeleton_front.png"
          : "/female_skeleton_back.png"
        : facing === "front"
        ? "/male_skeleton_front.png"
        : "/male_skeleton_back.png";

    pain.src = "/skeleton-pain.png";

    maincanvasRef.current.height = height;
    maincanvasRef.current.width = width;

    image.onload = () => {
      ctx.drawImage(image, 0, 0, width, height);
      if (data.facing === facing) {
        ctx.drawImage(
          pain,
          parseFloat(data.pain_position_x),
          parseFloat(data.pain_position_y),
          painsize,
          painsize
        );
        ctx.fillStyle = "#fff";
        ctx.fillText(
          data.pain_level,
          parseFloat(data.pain_position_x) + 5,
          parseFloat(data.pain_position_y) + 13
        );
      }
    };

    setSelectedPain(data);
  };

  let filteredPain = painList.filter((x) => x.facing === facing);
  const paginatePain = applyPagination(filteredPain, page, limit);

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h6" color="textSecondary">
            Pain Monitoring
          </Typography>
        </Box>
        <Box>
          <Box justifyContent="center" display="flex">
            <Box mr={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setFacing("back");
                }}
                hidden={facing === "back"}
                startIcon={<NavigateBeforeIcon />}
              >
                Back
              </Button>
            </Box>

            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setFacing("front");
                }}
                hidden={facing === "front"}
                endIcon={<NavigateNextIcon />}
              >
                Front
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={painList.length > 0 ? 7 : 12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> Date </TableCell>
                  <TableCell> Description </TableCell>
                  <TableCell align="center"> Pain Level </TableCell>
                  <TableCell align="center"> Type </TableCell>
                  <TableCell align="center"> Action </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatePain.length > 0 ? (
                  paginatePain.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {Notify.dateTimeConvert(data.created_at)}
                      </TableCell>
                      <TableCell align="center"> {data.description} </TableCell>
                      <TableCell align="right"> {data.pain_level} </TableCell>
                      <TableCell align="right">
                        <Label
                          color={
                            data.facing === "front" ? "primary" : "success"
                          }
                        >
                          {data.facing}
                        </Label>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color={
                            selectedPain && selectedPain.id === data.id
                              ? "secondary"
                              : "primary"
                          }
                          size="small"
                          onClick={() => showPainInCanvas(data)}
                        >
                          <Eye />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography color="error">
                        No record found in {facing}.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <TablePagination
              component={Box}
              count={paginatePain.length}
              labelRowsPerPage="List"
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[7, 25, 50]}
            />
          </TableContainer>
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          <Box display="flex" justifyContent="center" overflow="auto">
            <canvas ref={maincanvasRef} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BodyPain;
