import React, { useEffect, useState, useCallback } from 'react'
import { Box, Button, Grid, IconButton, Paper, Typography, TextField, FormHelperText, TableContainer, Table, TableCell, TableRow, TableBody, TableHead } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import Label from 'src/utils/Label';
import * as Yup from 'yup';
import { Formik } from 'formik';
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import getPathLength from 'geolib/es/getPathLength';
import { Fragment } from 'react';

import Location from 'src/patients/location/Location';

const ChangeMethod = ({ selectedOrder, close, getOrderByGroup }) => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')
    const [deliveryFee, setDeliveryFree] = useState(null)
    const [mylatitude, setMyLatitude] = useState(null)
    const [mylongitude, setMyLongitude] = useState(null)
    const [items, setItems] = useState(null)
    const [itemsReady, setItemsReady] = useState(null)
    const [userInfoReady, setUserInfoReady] = useState(false);

    const getUserInfo = useCallback(async () => {
        try {
            var params = {
                token: token,
                user_id: user_id,
                order_no: selectedOrder.order_no,
                connection: 'online',
            }
            const request = await Axios.get('patient/cart/order/user-info', { params })
            const data = request.data;
            if (data.length > 0) {
                setMyLatitude(parseFloat(data[0].latitude));
                setMyLongitude(parseFloat(data[0].longitude));
                calculateDistance(parseFloat(data[0].latitude), parseFloat(data[0].longitude), parseFloat(data[0].vpharLat), parseFloat(data[0].vpharLng));
                setUserInfoReady(true)
            }
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, user_id, selectedOrder])


    const getAllOrdersById = useCallback(async () => {
        try {
            var params = {
                token: token,
                user_id: user_id,
                order_no: selectedOrder.order_no,
                connection: 'online',
            }
            const request = await Axios.get('patient/cart/order/get-items-by-id', { params })
            const data = request.data;
            if (data.length > 0) {
                setItems(data);
                setItemsReady(true);
            }
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, user_id, selectedOrder])


    const calculateDistance = (plat, plng, vplat, vplng) => {
        var newTotal = 0;
        var calculate = getPathLength([
            { latitude: plat, longitude: plng },
            { latitude: vplat, longitude: vplng }
        ]);
        if (Math.round(parseFloat(calculate) / 1000) <= 0) {
            newTotal = 20;
        }
        else {
            newTotal = Math.round(parseFloat(calculate) / 1000) * 20;
        }
        setDeliveryFree(parseFloat(newTotal).toFixed(2));
        return newTotal;
    };

    const convertToFormdata = (object) => {
        const formData = new FormData();
        formData.append('token', token)
        formData.append('username', username)
        formData.append('user_id', user_id)
        formData.append('order_no', selectedOrder.order_no)
        formData.append('deliveryFee', deliveryFee)
        formData.append('connection', 'online')
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    useEffect(() => {
        getUserInfo()
        getAllOrdersById()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserInfo, getAllOrdersById, selectedOrder])

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Box display="flex" mb={2}>
                        <Box flexGrow={1}>
                            <Box display="flex">
                                <IconButton
                                    onClick={close}
                                >
                                    <KeyboardBackspaceIcon />
                                </IconButton>
                                <Box mt={2}>
                                    <Typography
                                        variant="subtitle2"
                                        className={'gtc-uppercase'}
                                    >
                                        Order Finalized
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box mt={2}>
                            <Label color={parseFloat(selectedOrder.delivery) === 0 ? "success" : "primary"}> <Typography > <strong> {parseFloat(selectedOrder.delivery) === 0 ? 'Pick up' : 'Delivery'} </strong> SERVICE</Typography> </Label>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={4} sm={4}>
                    <Formik
                        initialValues={{
                            delivery: parseFloat(selectedOrder.delivery),
                            service: '',
                            password: '',
                        }}
                        validationSchema={Yup.object().shape({
                            service: Yup.string().required(),
                            password: Yup.string().required(),
                        })}
                        onSubmit={async (values, {
                            setErrors,
                            setStatus,
                            setSubmitting,
                            resetForm
                        }) => {
                            try {
                                const request = await Axios.post('patient/cart/order/update_trans-method', convertToFormdata(values))
                                if (request.data === 'pass-invalid') {
                                    setErrors({ submit: 'Password is invalid.' });
                                    Notify.fieldInvalid('password')
                                }
                                if (request.data === 'success') {
                                    resetForm()
                                    setSubmitting(true);
                                    getOrderByGroup()
                                    close()
                                    Notify.successRequest('update service')
                                }
                            } catch (error) {
                                const message = error.message || 'Something went wrong';
                                setStatus({ success: false });
                                setErrors({ submit: message });
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values
                        }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                            >
                                <Box mb={2}>
                                    <Typography
                                        color="primary"
                                        variant="h6"
                                    >
                                        Update service
                                    </Typography>
                                </Box>
                                {
                                    userInfoReady && (
                                        mylatitude === null && mylongitude === null && (
                                            <Box mb={1}>
                                                <Location mapHeight={150} />
                                            </Box>
                                        )
                                    )
                                }
                                <Box mb={2}>
                                    <TextField
                                        select
                                        fullWidth
                                        required
                                        error={Boolean(touched.service && errors.service)}
                                        helperText={touched.service && errors.service}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.service}
                                        name="service"
                                        label="Service"
                                        variant="outlined"
                                        SelectProps={{ native: true }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <option value={''}> Select </option>
                                        <option disabled={parseFloat(selectedOrder.delivery) === 0} value={0}> Pick Up </option>
                                        <option disabled={mylatitude === null || parseFloat(selectedOrder.delivery) === 1} value={1}> Delivery </option>
                                    </TextField>
                                </Box>

                                <Box mb={2}>
                                    <TextField
                                        fullWidth
                                        required
                                        error={Boolean(touched.password && errors.password)}
                                        helperText={touched.password && errors.password}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                        name="password"
                                        label="Password"
                                        variant="outlined"
                                        type="password"
                                    />
                                </Box>

                                {errors.submit && (
                                    <Box mt={3}>
                                        <FormHelperText error>
                                            {errors.submit}
                                        </FormHelperText>
                                    </Box>
                                )}

                                <Box
                                    display="flex"
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<CheckIcon />}
                                        size="large"
                                        disabled={isSubmitting}
                                    >
                                        Update
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Grid>

                <Grid item xs={8} sm={8}>
                    <Box mb={2}>
                        <Typography
                            color="primary"
                            variant="h6"
                        >
                            Doctor's Prescription
                         </Typography>
                    </Box>
                    {
                        itemsReady && (
                            <Box mb={2}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center"> Product </TableCell>
                                                <TableCell align="center"> Qty </TableCell>
                                                <TableCell align="center"> Price </TableCell>
                                                <TableCell align="center"> Amount </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {items.length > 0 && (
                                                items.map((data, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell> {data.product} </TableCell>
                                                            <TableCell align="right"> {data.quantity} </TableCell>
                                                            <TableCell align="right"> {Notify.numberFormat(data.price)} </TableCell>
                                                            <TableCell align="right"> {Notify.numberFormat(parseFloat(data.quantity) * parseFloat(data.price))} </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            )}
                                            {
                                                items.length > 0 && (
                                                    <Fragment>
                                                        <TableRow >
                                                            <TableCell align="right" colSpan={3}>
                                                                Total Order:
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {Notify.numberFormat(items[0].totalAmount)}
                                                            </TableCell>
                                                        </TableRow>
                                                        {parseFloat(items[0].TotalUnpaid) > 0 && (
                                                            <TableRow >
                                                                <TableCell align="right" colSpan={3}>
                                                                    Unpaid Consulation:
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {Notify.numberFormat(items[0].TotalUnpaid)}
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                        {parseFloat(items[0].delivery) === 1 && (
                                                            <Fragment>
                                                                <TableRow >
                                                                    <TableCell align="right" colSpan={3}>
                                                                        Delivery Fee:
                                                                        </TableCell>
                                                                    <TableCell align="right">
                                                                        {Notify.numberFormat(deliveryFee)}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </Fragment>
                                                        )}
                                                        {parseFloat(items[0].delivery) === 1 || parseFloat(items[0].TotalUnpaid) > 0 ?
                                                            <TableRow >
                                                                <TableCell align="right" colSpan={3}>
                                                                    Total Cost:
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {Notify.numberFormat(
                                                                        parseFloat(items[0].totalAmount) + parseFloat(items[0].TotalUnpaid + (parseFloat(items[0].delivery) === 1 ? parseFloat(deliveryFee) : 0))
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                            : null}
                                                    </Fragment>
                                                )
                                            }
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </Box>
                        )
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default ChangeMethod;