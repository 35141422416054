import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Notify from "src/notification/Notify";
import Report from "./Report";
import BillingDetails from "./BillingDetails";
import Container from "src/layout/Container";

const Billing = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [unpaid, setUnpaid] = useState({
    data: [],
    ready: false,
  });

  const [selectedUnpaid, setSelectedUnpaid] = useState({
    data: null,
    ready: false,
  });

  const getUnpaidOrder = useCallback(() => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);

    axios
      .post("encoder/pateint/billing", formdata)
      .then((response) => {
        const data = response.data;
        setUnpaid({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, user_id]);

  useEffect(() => {
    getUnpaidOrder();
  }, [getUnpaidOrder]);

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "billing",
          items: [{ name: "Dashboard", path: "/clinic/app/encoder" }],
        }}
        title={"Billing"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <Box component={Paper} variant="outlined" p={2}>
              <Box mb={2}>
                <Typography
                  color="primary"
                  variant="subtitle2"
                  className={`gtc-uppercase`}
                >
                  Unpaid Bill
                </Typography>
              </Box>

              <Box>
                <List component="div">
                  {unpaid.ready
                    ? unpaid.data.length > 0
                      ? unpaid.data.map((data, index) => (
                          <ListItem
                            button
                            key={index}
                            selected={
                              selectedUnpaid.data &&
                              selectedUnpaid.data.id === data.id
                            }
                            onClick={() => {
                              setSelectedUnpaid({
                                data: data,
                                ready: true,
                              });
                            }}
                          >
                            <ListItemText
                              className={`gtc-capitalize`}
                              primary={`${data.fname} ${data.lname}`}
                              secondary={Notify.dateTimeConvert(
                                data.created_at
                              )}
                            />
                          </ListItem>
                        ))
                      : Notify.noRecord()
                    : Notify.loading()}
                </List>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={9}>
            {selectedUnpaid.ready && selectedUnpaid.data && (
              <BillingDetails
                details={selectedUnpaid.data}
                getUnpaidOrder={getUnpaidOrder}
              />
            )}
            {!selectedUnpaid.ready && !selectedUnpaid.data && <Report />}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Billing;
