import {
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
  CardMedia,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Edit } from "react-feather";
import Notify from "src/notification/Notify";
import IsMountedRef from "src/utils/IsMountedRef";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const FirstPhoto = ({ info, patient_id, connection }) => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const user_id = localStorage.getItem("user_id");
  const [selectedImage, setSelectedImage] = useState(null);

  const mounted = IsMountedRef();
  const [details, setDetails] = useState({ ready: false, data: [] });

  const [checkbox, setCheckbox] = useState({});
  const [data, setData] = useState({});
  const [enableEdit, setEnableEdit] = useState(false);
  const [isProcess, setIsProcess] = useState(false);

  const getFirstPhotoRecord = React.useCallback(async () => {
    let response = await Axios.get(
      "doctors/patients/childs/firstphoto-details",
      {
        params: {
          token,
          patient_id,
          connection,
        },
      }
    );

    const data = response.data;

    if (mounted.current) {
      setDetails({ data, ready: true });
      if (data.length > 0) {
        setData(data[0]);
      }
    }
  }, [mounted, patient_id, token, connection]);

  const handleFirstPhoto = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("management_id", management_id);
    formdata.set("patient_id", patient_id);
    formdata.set(
      "selected_image",
      selectedImage === null ? null : selectedImage
    );

    formdata.set("preterm", checkbox.preterm ? "yes" : "no");
    formdata.set("term", checkbox.term ? "yes" : "no");
    formdata.set("postterm", checkbox.postterm ? "yes" : "no");
    formdata.set("sga", checkbox.sga ? "yes" : "no");
    formdata.set("aga", checkbox.aga ? "yes" : "no");
    formdata.set("lga", checkbox.lga ? "yes" : "no");

    var error = [];

    if (formdata.get("current_image").trim() === "") {
      if (selectedImage === null) {
        error = "image is a required";
        Notify.fieldRequired("image");
      }
    }

    if (formdata.get("mother_name").trim() === "") {
      error = "monther name required";
      Notify.fieldRequired("mothers name");
    }

    if (formdata.get("father_name").trim() === "") {
      error = "father name required";
      Notify.fieldRequired("mothers name");
    }

    if (error.length > 0) {
      console.log("form has an error.");
    } else {
      setIsProcess(true);

      Axios.post("doctors/patients/childs/firstphoto-new", formdata)
        .then((response) => {
          let data = response.data;

          if (data.message === "success") {
            getFirstPhotoRecord();
            setEnableEdit(false);
            Notify.successRequest("first photo");
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setIsProcess(false);
        });
    }
  };

  const checkIfNull = (value) => {
    return value === null ? "" : value;
  };

  const handleSelectedImage = (e) => {
    if (e.currentTarget.value.length > 0) {
      // setSelectedImage(e.currentTarget.files[0].name);

      const fileExtension = e.currentTarget.files[0].name.split(".").pop();

      const allowedExtensions = ["jpg", "png"];

      if (!allowedExtensions.includes(fileExtension)) {
        Notify.customToast("File Invalid", "File format is invalid.");
        setSelectedImage(null);
        return;
      } else {
        setSelectedImage(e.currentTarget.files[0]);
      }
    } else {
      setSelectedImage(null);
      return;
    }
  };

  useEffect(() => {
    getFirstPhotoRecord();
  }, [getFirstPhotoRecord]);

  return (
    <form onSubmit={handleFirstPhoto}>
      <Paper component={Box} mt={2} p={2}>
        {details.ready ? (
          <>
            <Grid container direction="column" alignItems="center">
              <Grid item xs={12} sm={7} md={6} lg={5}>
                <Box align="center">
                  <CardMedia
                    component="img"
                    src={
                      details.data.length > 0
                        ? `${imageLocation}patients/${details.data[0].first_image}`
                        : "/no-image.jpg"
                    }
                    alt=""
                    style={{
                      borderRadius: 10,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box align="center" my={3}>
              <Typography variant="h5">
                {info.length > 0 && `${info[0].lastname}, ${info[0].firstname}`}
              </Typography>
              <Typography variant="subtitle1">
                {info.length > 0 && `${Notify.birthday(info[0].birthday)}`}
              </Typography>

              <Box hidden={enableEdit}>
                <Button
                  color="primary"
                  startIcon={<Edit />}
                  onClick={() => setEnableEdit(true)}
                >
                  Edit
                </Button>
              </Box>
            </Box>

            <Box mb={2}>
              <TextField
                label="Current Image"
                fullWidth
                variant="outlined"
                name="current_image"
                hidden
                value={
                  details.data.length > 0
                    ? checkIfNull(details.data[0].first_image)
                    : ""
                }
                InputProps={{
                  readOnly: !enableEdit,
                }}
              />
            </Box>

            <Box mb={2} hidden={!enableEdit}>
              <TextField
                label="Image"
                fullWidth
                variant="outlined"
                type="file"
                name="selected_image"
                InputLabelProps={{ shrink: true }}
                onChange={handleSelectedImage}
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <TextField
                    label="Mother's Name"
                    fullWidth
                    variant="outlined"
                    name="mother_name"
                    InputProps={{
                      readOnly: !enableEdit,
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setData({ mother_name: e.target.value })}
                    value={
                      Object.keys(data).length > 0
                        ? checkIfNull(data.mother_name)
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <TextField
                    label="Father's Name"
                    fullWidth
                    variant="outlined"
                    name="father_name"
                    InputProps={{
                      readOnly: !enableEdit,
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setData({ father_name: e.target.value })}
                    value={
                      Object.keys(data).length > 0
                        ? checkIfNull(data.father_name)
                        : ""
                    }
                  />
                </Box>
              </Grid>
            </Grid>

            <Box my={1}>
              <Typography variant="h6">Birth Information</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box mb={2} display="flex">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          Object.keys(checkbox).length && enableEdit
                            ? checkbox.preterm
                              ? true
                              : false
                            : checkbox.preterm ||
                              (Object.keys(data).length > 0 &&
                                checkIfNull(data.preterm) === "yes")
                        }
                        InputLabelProps={{ shrink: true }}
                        onChange={() =>
                          setCheckbox({
                            ...checkbox,
                            preterm: !checkbox.preterm,
                          })
                        }
                      />
                    }
                    label="Preterm"
                    name="preterm"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          Object.keys(checkbox).length && enableEdit
                            ? checkbox.term
                              ? true
                              : false
                            : checkbox.term ||
                              (Object.keys(data).length > 0 &&
                                checkIfNull(data.term) === "yes")
                        }
                        InputLabelProps={{ shrink: true }}
                        onChange={() =>
                          setCheckbox({ ...checkbox, term: !checkbox.term })
                        }
                      />
                    }
                    label="Term"
                    name="term"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          Object.keys(checkbox).length && enableEdit
                            ? checkbox.postterm
                              ? true
                              : false
                            : Object.keys(data).length > 0 &&
                              checkIfNull(data.posterm) === "yes"
                        }
                        InputLabelProps={{ shrink: true }}
                        onChange={() =>
                          setCheckbox({
                            ...checkbox,
                            postterm: !checkbox.postterm,
                          })
                        }
                      />
                    }
                    label="Postterm"
                    name="postterm"
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <TextField
                    label="Type of Delivery"
                    fullWidth
                    variant="outlined"
                    name="type_of_delivery"
                    InputProps={{
                      readOnly: !enableEdit,
                    }}
                    onChange={(e) =>
                      setData({ type_of_delivery: e.target.value })
                    }
                    InputLabelProps={{ shrink: true }}
                    value={
                      Object.keys(data).length > 0
                        ? checkIfNull(data.type_of_delivery)
                        : ""
                    }
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <TextField
                    label="APGAR Score"
                    fullWidth
                    variant="outlined"
                    name="apgar"
                    InputProps={{
                      readOnly: !enableEdit,
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setData({ apgar_score: e.target.value })}
                    value={
                      Object.keys(data).length > 0
                        ? checkIfNull(data.apgar_score)
                        : ""
                    }
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="Birth Weight"
                    fullWidth
                    variant="outlined"
                    name="weight"
                    InputProps={{
                      readOnly: !enableEdit,
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setData({ birth_weight: e.target.value })}
                    value={
                      Object.keys(data).length > 0
                        ? checkIfNull(data.birth_weight)
                        : ""
                    }
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="Birth Height"
                    fullWidth
                    variant="outlined"
                    name="height"
                    InputProps={{
                      readOnly: !enableEdit,
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setData({ birth_length: e.target.value })}
                    value={
                      Object.keys(data).length > 0
                        ? checkIfNull(data.birth_length)
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <TextField
                    label="Head Circumference"
                    fullWidth
                    variant="outlined"
                    name="head"
                    InputProps={{
                      readOnly: !enableEdit,
                    }}
                    onChange={(e) =>
                      setData({ head_circumference: e.target.value })
                    }
                    InputLabelProps={{ shrink: true }}
                    value={
                      Object.keys(data).length > 0
                        ? checkIfNull(data.head_circumference)
                        : ""
                    }
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="Abdominal Circumference"
                    fullWidth
                    variant="outlined"
                    name="abdominal"
                    InputProps={{
                      readOnly: !enableEdit,
                    }}
                    onChange={(e) =>
                      setData({ abdominal_circumference: e.target.value })
                    }
                    InputLabelProps={{ shrink: true }}
                    value={
                      Object.keys(data).length > 0
                        ? checkIfNull(data.abdominal_circumference)
                        : ""
                    }
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="Chest Circumference"
                    fullWidth
                    variant="outlined"
                    name="chest"
                    InputProps={{
                      readOnly: !enableEdit,
                    }}
                    onChange={(e) =>
                      setData({ chest_circumference: e.target.value })
                    }
                    InputLabelProps={{ shrink: true }}
                    value={
                      Object.keys(data).length > 0
                        ? checkIfNull(data.chest_circumference)
                        : ""
                    }
                  />
                </Box>
              </Grid>
            </Grid>

            <Box mb={2} display="flex">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      Object.keys(checkbox).length && enableEdit
                        ? checkbox.sga
                          ? true
                          : false
                        : checkbox.sga ||
                          (Object.keys(data).length > 0 &&
                            checkIfNull(data.sga) === "yes")
                    }
                    InputLabelProps={{ shrink: true }}
                    onChange={() =>
                      setCheckbox({ ...checkbox, sga: !checkbox.sga })
                    }
                  />
                }
                label="SGA"
                name="sga"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      Object.keys(checkbox).length && enableEdit
                        ? checkbox.aga
                          ? true
                          : false
                        : checkbox.aga ||
                          (Object.keys(data).length > 0 &&
                            checkIfNull(data.aga) === "yes")
                    }
                    InputLabelProps={{ shrink: true }}
                    onChange={() =>
                      setCheckbox({ ...checkbox, aga: !checkbox.aga })
                    }
                  />
                }
                label="AGA"
                name="aga"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      Object.keys(checkbox).length && enableEdit
                        ? checkbox.lga
                          ? true
                          : false
                        : checkbox.lga ||
                          (Object.keys(data).length > 0 &&
                            checkIfNull(data.lga) === "yes")
                    }
                    InputLabelProps={{ shrink: true }}
                    onChange={() =>
                      setCheckbox({ ...checkbox, lga: !checkbox.lga })
                    }
                  />
                }
                label="LGA"
                name="lga"
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <TextField
                    label="Blood Type"
                    fullWidth
                    variant="outlined"
                    name="blood_type"
                    InputProps={{
                      readOnly: !enableEdit,
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setData({ bloodtype: e.target.value })}
                    value={
                      Object.keys(data).length > 0
                        ? checkIfNull(data.bloodtype)
                        : ""
                    }
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="New Born Screening Test"
                    fullWidth
                    variant="outlined"
                    name="screening"
                    InputProps={{
                      readOnly: !enableEdit,
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setData({ new_born_screening: e.target.value })
                    }
                    value={
                      Object.keys(data).length > 0
                        ? checkIfNull(data.new_born_screening)
                        : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <TextField
                    label="New Born Hearing Test "
                    fullWidth
                    variant="outlined"
                    name="hearing"
                    InputProps={{
                      readOnly: !enableEdit,
                    }}
                    onChange={(e) =>
                      setData({ new_born_hearing: e.target.value })
                    }
                    InputLabelProps={{ shrink: true }}
                    value={
                      Object.keys(data).length > 0
                        ? checkIfNull(data.new_born_hearing)
                        : ""
                    }
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    label="Nursery Stay"
                    fullWidth
                    variant="outlined"
                    name="nursery"
                    InputProps={{
                      readOnly: !enableEdit,
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setData({ nursery: e.target.value })}
                    value={
                      Object.keys(data).length > 0
                        ? checkIfNull(data.nursery)
                        : ""
                    }
                  />
                </Box>
              </Grid>
            </Grid>

            <Box display="flex" hidden={!enableEdit}>
              <Box mr={2}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                  startIcon={
                    isProcess ? (
                      <CircularProgress size={23} color="inherit" />
                    ) : (
                      <CheckCircleOutline />
                    )
                  }
                >
                  Save
                </Button>
              </Box>
              <Box>
                <Button
                  size="large"
                  variant="contained"
                  color="default"
                  onClick={() => setEnableEdit(false)}
                  startIcon={<Cancel />}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <Box m={2}>{Notify.loading()}</Box>
        )}
      </Paper>
    </form>
  );
};

export default FirstPhoto;
