import {
  Box,
  Dialog,
  TextField,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Grid,
  CardMedia,
} from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "src/utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import { CheckSquare, XCircle } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const UrinalysisDetails = ({
  formheader,
  order,
  getLabStoolTestOrder,
  resetDisplay,
}) => {
  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState({
    data: [],
    ready: false,
  });
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");

  const [pendingDialog, setPendingDialog] = useState(false);
  const [processDialog, setProcessDialog] = useState(false);

  const [pendingSubmitting, setPendingSubmitting] = useState(false);
  const [processSubmitting, setProcessSubmitting] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);
  const [savebtnDisabled, setSavebtnDisabled] = useState(true);

  const saveButtonRef = React.useRef();
  const [confirmDialog, setConfirmDialog] = useState(false);

  const getUrinalysisOrderDetails = () => {
    var params = { token, user_id, trace_number: order.trace_number };
    Axios.get("laboratory/order/ordernew-urinalysis/details", { params })
      .then((response) => {
        const data = response.data;
        setOrderDetails({
          data: data,
          ready: true,
        });

        checkIfSaveBtnIsEnabled(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const checkIfSaveBtnIsEnabled = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (Boolean(data[i].is_processed)) {
        setSavebtnDisabled(false);
      }
    }
  };

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("trace_number", order.trace_number);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setResultSubmitting(true);
      Axios.post(
        "laboratory/order/ordernew-urinalysis/save-process-result",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            setSavebtnDisabled(true);
            Notify.successRequest("order result added.");
            setTimeout(() => {
              history.push(
                `/clinic/app/laboratory/record/print/order/${order.trace_number}`
              );
            }, 5000);
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        });
    }
  };

  const handlePendingOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("username", username);
    formdata.set("order_id", order.order_id);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setPendingSubmitting(true);
      Axios.post("laboratory/order/ordernew-sorology/save-setpending", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            getLabStoolTestOrder();
            resetDisplay();
            setPendingDialog(false);
            Notify.successRequest("order pending");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setPendingSubmitting(false));
    }
  };

  const handleProcessOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("username", username);
    formdata.set("order_id", order.order_id);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setProcessSubmitting(true);
      Axios.post(
        "laboratory/order/ordernew-urinalysis/save-setprocessing",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            getUrinalysisOrderDetails();
            setProcessDialog(false);
            Notify.successRequest("order pending");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setProcessSubmitting(false));
    }
  };

  useEffect(() => {
    getUrinalysisOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <>
      <form onSubmit={handleSaveResult}>
        <Card elevation={0}>
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={formheader && formheader.name}
              subheader={formheader && formheader.address}
            />
          </Box>

          <CardContent>
            {/* paitent information */}
            <Box display="flex">
              <Box flexGrow={1} mb={2}>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      NAME:
                    </Typography>
                    {`${order.firstname} ${order.lastname}`}
                  </Typography>
                </Box>

                <Box display="flex" mb={2}>
                  <Box>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        AGE:
                      </Typography>
                      {order.birthday === null
                        ? "none"
                        : Notify.calculateAge(order.birthday)}
                    </Typography>
                  </Box>
                  <Box ml={5}>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        SEX:
                      </Typography>
                      {order.gender === null ? "none" : order.gender}
                    </Typography>
                  </Box>
                </Box>

                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      ADDRESS:
                    </Typography>
                    {`${order.street} ${order.barangay} ${order.city} ${order.zip} `}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="caption" className="font-weight-bold">
                    PATIENT CONDITION:
                  </Typography>
                  {order.patient_condition === null
                    ? " none"
                    : order.patient_condition}
                </Box>
              </Box>

              <Box>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      DATE:
                    </Typography>
                    {Notify.createdAt(new Date().toLocaleString())}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>

          <CardContent>
            {/* order details */}
            {orderDetails.ready
              ? orderDetails.data.length > 0
                ? orderDetails.data.map((data, index) => (
                    <Fragment key={index}>
                      {/* urinalysis order */}
                      <Box hidden={!Boolean(parseInt(data.urinalysis))}>
                        <Box mb={2} display="flex">
                          <Box flexGrow={1}>
                            <Typography variant="h6">
                              <b> Urinalysis </b>
                            </Typography>
                          </Box>

                          <Box>
                            <Button
                              disabled={Boolean(parseInt(data.is_processed))}
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => setProcessDialog(true)}
                            >
                              Process
                            </Button>
                          </Box>
                        </Box>

                        <Box hidden={!Boolean(parseInt(data.is_processed))}>
                          {/* hidden inputs */}
                          <Box>
                            <TextField
                              margin="dense"
                              fullWidth
                              name="order_id[]"
                              label="order_id"
                              value={data.order_id}
                              hidden
                            />
                            <TextField
                              margin="dense"
                              fullWidth
                              name="patient_id"
                              label="patient_id"
                              value={data.patient_id}
                              hidden
                            />

                            <TextField
                              margin="dense"
                              fullWidth
                              name="doctors_id"
                              label="doctor_id"
                              value={
                                data.doctor_id === null ? "" : data.doctor_id
                              }
                              hidden
                            />
                          </Box>

                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      color :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    required
                                    name="color[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      reaction :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    required
                                    name="reaction[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      transparency :
                                    </span>
                                  </Typography>
                                </Box>

                                <Box>
                                  <TextField
                                    fullWidth
                                    name="transparency[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      sp gravity :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="sp_gravity[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      albumin (negative) :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="albumin[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      sugar (negative):
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="sugar[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      pus cells:
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="pus_cell[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      R.B.C:
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="rbc[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      epithelial cells :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="epithelial_cell[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      mucus threads :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="mucus_threads[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      renal cells :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="renal_cell[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      yeast cells :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="yeast_cell[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      hyaline :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="hyaline[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      rbc cast :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="rbc_cast[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      wbc cast :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="wbc_cast[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      coarse granular cast :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="coarse_granular_cast[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      fine granular cast :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="fine_granular_cast[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      pus in clumps :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="pus_in_clumps[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      rbc in clumps :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="rbc_in_clumps[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      calcium oxalate :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="calcium_oxalate[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      uric acid :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="uric_acid[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      amorphous urate :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="amorphous_urate[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      amorphous phosphate :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="amorphous_phosphate[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      calcium carbonate :
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="calcium_carbonate[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      ammonium biurate:
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="ammonium_biurate[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      triple phosphate:
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="triple_phosphate[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      spermatozoa:
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="spermatozoa[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      trichomonas vaginalis:
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="trichomonas_vaginalis[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      micral test:
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="micral_test[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex">
                                <Box mt={1.5} mr={1} flexGrow={1}>
                                  <Typography>
                                    <span className="text-capitalize">
                                      urine ketone:
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <TextField
                                    fullWidth
                                    name="urine_ketone[]"
                                    margin="dense"
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                          <Box mt={2}>
                            <TextField
                              fullWidth
                              label="Others"
                              name="others[]"
                              variant="outlined"
                              multiline
                            />
                          </Box>

                          <Box mt={2}>
                            <TextField
                              fullWidth
                              label="Remarks"
                              name="remarks[]"
                              variant="outlined"
                              multiline
                            />
                          </Box>
                        </Box>
                      </Box>
                      {/* urinalysis order end */}
                    </Fragment>
                  ))
                : Notify.noRecord()
              : Notify.loading()}
          </CardContent>
          <Box display="flex" m={1}>
            <Box flexGrow={1} />
            <Box>
              <CardActions>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  hidden
                  ref={saveButtonRef}
                  disabled={!confirmDialog}
                >
                  Save Result Button
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => setConfirmDialog(true)}
                  disabled={savebtnDisabled}
                >
                  Save Result
                </Button>
              </CardActions>
            </Box>
          </Box>
        </Card>
      </form>

      {/* set as pending dialog */}
      <Dialog
        open={pendingDialog}
        onClose={() => setPendingDialog(false)}
        disableBackdropClick
        PaperComponent={DraggableDialog}
      >
        <DialogTitle id="draggable-handle">Set as pending</DialogTitle>
        <form onSubmit={handlePendingOrder}>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                rows={5}
                fullWidth
                name="reason"
                label={`Pending Reason`}
                variant="outlined"
                multiline
              />
            </Box>

            <Box>
              <TextField
                fullWidth
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setPendingDialog(false)}
              startIcon={<HighlightOffIcon />}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={pendingSubmitting}
              startIcon={
                pendingSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <ErrorOutlineIcon />
                )
              }
            >
              pending
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* set as process dialog */}
      <Dialog
        open={processDialog}
        onClose={() => setProcessDialog(false)}
        disableBackdropClick
        PaperComponent={DraggableDialog}
      >
        <DialogTitle id="draggable-handle">Set as processing</DialogTitle>
        <form onSubmit={handleProcessOrder}>
          <DialogContent dividers>
            <Box>
              <TextField
                fullWidth
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setProcessDialog(false)}
              startIcon={<HighlightOffIcon />}
            >
              no
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={processSubmitting}
              startIcon={
                processSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckCircleIcon />
                )
              }
            >
              process
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* confirmation dialog */}
      <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogContent>
          <Typography>Are you sure to continue and save result?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            startIcon={<XCircle />}
            onClick={() => setConfirmDialog(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={resultSubmitting}
            startIcon={
              resultSubmitting ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <CheckSquare />
              )
            }
            onClick={() => saveButtonRef.current.click()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UrinalysisDetails;
