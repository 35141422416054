import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  Dialog,
  Zoom,
  DialogTitle,
  IconButton,
  DialogContent,
  FormHelperText,
} from "@material-ui/core";
import { CancelOutlined, ImageAspectRatioOutlined } from "@material-ui/icons";
import axios from "axios";
import React, { Fragment, useCallback } from "react";
import Notify from "src/notification/Notify";
import { DropzoneArea } from "material-ui-dropzone";
import * as Yup from "yup";
import { Formik } from "formik";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const OrderDetails = ({
  patient_id,
  allLocalRad,
  allTeleRad,
  onClose,
  getAllPatientForImaging,
}) => {
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const username = localStorage.getItem("username");

  const [openDialog, setOpenDialog] = React.useState(false);
  const [radiologist_click, set_radiologist_click] = React.useState(null);
  const [telerad_click, set_telerad_click] = React.useState(null);
  const [selectedImagingId, setSelectedImagingId] = React.useState(null);
  const [selectedImagingOrder, setSelectedImagingOrder] = React.useState(null);
  const [orderDetails, setOrderDetails] = React.useState({
    data: [],
    ready: false,
  });

  const [image, setImage] = React.useState([]);

  const getPatientInformation = useCallback(async () => {
    try {
      var params = {
        token,
        user_id: user_id,
        management_id: management_id,
        patient_id: patient_id,
      };
      const response = await axios.get("imaging/patients/information", {
        params,
      });
      setOrderDetails({ data: response.data, ready: true });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [patient_id, token, user_id, management_id]);

  // const handleImageChange = (id, files) => {
  //     if (files.length > 0) {
  //         const formData = new FormData();
  //         formData.append('imaging_center_id', id)
  //         for (let i = 0; i < files.length; i++) {
  //             formData.append('image[]', files[i]);
  //         }

  //         var request = axios.post('imaging/upload-imaging-attachment', formData)
  //         try {
  //             if (request.data === 'success') {
  //                 getAllPatientForImaging();
  //                 Notify.successRequest('attach image')
  //             }
  //         }
  //         catch (error) {
  //             Notify.requestError(error)
  //         }
  //     }
  // }

  const convertToFormdata = (object) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("management_id", management_id);
    formData.append("username", username);
    formData.append("patient_id", patient_id);
    formData.append("imaging_center_id", selectedImagingId);

    for (let i = 0; i < image.length; i++) {
      formData.append("image[]", image[i]);
    }

    // Object.keys(orderDetails.data).forEach(key => {
    //     formData.append(`id[]`, orderDetails.data[key].id)
    //     formData.append(`imaging_order[]`, orderDetails.data[key].imaging_order)
    // });

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const handleCustomOnchange = (setFieldValue, value) => {
    setFieldValue("radiologist_type", value === null ? "" : value);
    if (value === "In-House") {
      set_radiologist_click(true);
      set_telerad_click(false);
    } else {
      set_radiologist_click(false);
      set_telerad_click(true);
    }
  };

  const handleImageChangeNew = (files) => {
    setImage(files);
  };

  const handleAddAttachment = (e) => {
    setSelectedImagingId(e.currentTarget.id);
    setSelectedImagingOrder(e.currentTarget.getAttribute("order"));
    setOpenDialog(true);
  };

  React.useEffect(() => {
    getPatientInformation();
  }, [getPatientInformation]);

  return (
    <Fragment>
      <Paper component={Box}>
        <Box p={2} borderRadius={4}>
          <Box display="flex" justifyContent="center">
            <Box flexGrow={1}>
              <Typography noWrap color="textSecondary" variant="h6">
                Order Details
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box m={2}>
          {orderDetails.ready &&
            (orderDetails.data.length > 0 ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box mb={2}>
                    <TextField
                      required
                      fullWidth
                      label="Fullname"
                      name="fullname"
                      variant="outlined"
                      defaultValue={`${orderDetails.data[0].fname} ${orderDetails.data[0].lname}`}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box mb={2}>
                    <TextField
                      required
                      fullWidth
                      label="Contact"
                      name="contact"
                      variant="outlined"
                      defaultValue={
                        orderDetails.data[0].mobile !== null
                          ? orderDetails.data[0].mobile
                          : orderDetails.data[0].telephone !== null
                          ? orderDetails.data[0].telephone
                          : "none"
                      }
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box mb={2}>
                    <TextField
                      required
                      fullWidth
                      label="Gender"
                      name="gender"
                      variant="outlined"
                      defaultValue={orderDetails.data[0].gender}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box mb={2}>
                    <TextField
                      required
                      fullWidth
                      label="Age"
                      name="age"
                      variant="outlined"
                      defaultValue={
                        new Date().toLocaleString("en-US", {
                          year: "numeric",
                        }) -
                          new Date(
                            orderDetails.data[0].birthday
                          ).toLocaleString("en-US", { year: "numeric" }) >
                        0
                          ? new Date().toLocaleString("en-US", {
                              year: "numeric",
                            }) -
                            new Date(
                              orderDetails.data[0].birthday
                            ).toLocaleString("en-US", { year: "numeric" }) +
                            " years old"
                          : "months"
                      }
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box mb={2}>
                    <TextField
                      required
                      fullWidth
                      label="Address"
                      name="address"
                      variant="outlined"
                      defaultValue={`${orderDetails.data[0].barangay}, ${orderDetails.data[0].city}`}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box mb={2}>
                    <TextField
                      required
                      fullWidth
                      label="Order Date"
                      name="order_date"
                      variant="outlined"
                      defaultValue={Notify.dateTimeConvert(
                        orderDetails.data[0].updated_at
                      )}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Grid>

                {orderDetails.data[0].stitch_order_request === null && (
                  <>
                    {orderDetails.data.map((data, index) => {
                      return (
                        <Grid item xs={12} sm={12} key={index}>
                          <Box>
                            <Typography variant="subtitle1">
                              <strong> ORDER # {index + 1} </strong>
                            </Typography>
                          </Box>
                          <Box p={2} display="flex">
                            <Box flexGrow={1}>
                              <Typography>
                                &bull; {data.imaging_order}
                              </Typography>
                            </Box>
                            <Button
                              id={data.imaging_center_id}
                              order={data.imaging_order}
                              variant="outlined"
                              color="primary"
                              onClick={handleAddAttachment}
                              startIcon={<ImageAspectRatioOutlined />}
                            >
                              ATTACH ORDER # {index + 1}
                            </Button>
                          </Box>
                        </Grid>
                      );
                    })}
                  </>
                )}

                <Dialog
                  maxWidth="sm"
                  fullWidth
                  open={openDialog}
                  TransitionComponent={Zoom}
                  transitionDuration={800}
                >
                  <Box display="flex">
                    <Box flexGrow={1}>
                      <DialogTitle>
                        <Typography color="primary">
                          ATTACH IMAGE FOR RADIOLOGIST
                        </Typography>
                      </DialogTitle>
                    </Box>
                    <IconButton
                      color="secondary"
                      onClick={() => setOpenDialog(!openDialog)}
                    >
                      <CancelOutlined fontSize={"large"} />
                    </IconButton>
                  </Box>
                  <DialogContent>
                    <Formik
                      initialValues={{
                        imaging_order: selectedImagingOrder,
                        token: token,
                        username: username,
                        user_id: user_id,
                        radiologist_type: "",
                        radiologist: "",
                        password: "",
                      }}
                      validationSchema={Yup.object().shape({
                        radiologist_type: Yup.string().trim().required(),
                        radiologist: Yup.string().trim().required(),
                        password: Yup.string().required(),
                      })}
                      onSubmit={async (
                        values,
                        { setErrors, setStatus, setSubmitting, resetForm }
                      ) => {
                        if (image.length < 1) {
                          setErrors({ submit: "Image attachment is required" });
                        } else {
                          try {
                            const request = await axios.post(
                              "imaging/patient/order/addresult",
                              convertToFormdata(values)
                            );
                            if (request.data === "pass-invalid") {
                              setErrors({ submit: "Password is invalid." });
                              Notify.fieldInvalid("password");
                            }
                            if (request.data === "success") {
                              setSelectedImagingId(null);
                              setSelectedImagingOrder(null);
                              setImage([]);
                              getPatientInformation();
                              resetForm();
                              setSubmitting(true);
                              setOpenDialog(false);
                              getAllPatientForImaging();
                              Notify.successRequest("new attachment");
                              if (orderDetails.data.length < 1) {
                                onClose();
                              }
                            }
                          } catch (error) {
                            const message =
                              error.message || "Something went wrong";
                            setStatus({ success: false });
                            setErrors({ submit: message });
                            setSubmitting(false);
                          }
                        }
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <Box mb={2}>
                            <DropzoneArea
                              onChange={handleImageChangeNew}
                              acceptedFiles={["image/jpeg", "image/png"]}
                              dropzoneText={selectedImagingOrder}
                              maxFileSize={300000}
                            />
                          </Box>

                          <Box mb={2}>
                            <TextField
                              multiline
                              fullWidth
                              required
                              error={Boolean(
                                touched.case_file && errors.case_file
                              )}
                              helperText={touched.case_file && errors.case_file}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.case_file}
                              name="case_file"
                              label="Case File"
                              variant="outlined"
                              hidden
                            />
                          </Box>

                          <Box mb={2}>
                            <TextField
                              error={Boolean(
                                touched.radiologist_type &&
                                  errors.radiologist_type
                              )}
                              helperText={
                                touched.radiologist_type &&
                                errors.radiologist_type
                              }
                              onBlur={handleBlur}
                              onChange={(e) =>
                                handleCustomOnchange(
                                  setFieldValue,
                                  e.target.value
                                )
                              }
                              value={values.radiologist_type}
                              fullWidth
                              required
                              name="radiologist_type"
                              label="Select Radiologist Type"
                              variant="outlined"
                              select
                              SelectProps={{
                                native: true,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value={""}>Select</option>
                              <option value={"In-House"}>In-House</option>
                              <option value={"Telerad"}>Telerad</option>
                            </TextField>
                          </Box>

                          {radiologist_click && (
                            <Box mb={2}>
                              <TextField
                                error={Boolean(
                                  touched.radiologist && errors.radiologist
                                )}
                                helperText={
                                  touched.radiologist && errors.radiologist
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.radiologist}
                                fullWidth
                                required
                                name="radiologist"
                                label="Select Radiologist"
                                variant="outlined"
                                select
                                SelectProps={{
                                  native: true,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              >
                                <option value={""}>Select</option>
                                {allLocalRad.data.length > 0 ? (
                                  allLocalRad.data.map((data1, index1) => {
                                    return (
                                      <option key={index1} value={data1.values}>
                                        {data1.label}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option value={""}>No result</option>
                                )}
                              </TextField>
                            </Box>
                          )}

                          {telerad_click && (
                            <Box mb={2}>
                              <TextField
                                error={Boolean(
                                  touched.radiologist && errors.radiologist
                                )}
                                helperText={
                                  touched.radiologist && errors.radiologist
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.radiologist}
                                fullWidth
                                required
                                name="radiologist"
                                label="Select Tele-Radiologist"
                                variant="outlined"
                                select
                                SelectProps={{
                                  native: true,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              >
                                <option value={""}>Select</option>
                                {allTeleRad.data.length > 0 ? (
                                  allTeleRad.data.map((data2, index2) => {
                                    return (
                                      <option key={index2} value={data2.values}>
                                        {data2.label}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option value={""}>No result</option>
                                )}
                              </TextField>
                            </Box>
                          )}

                          <Box mb={2}>
                            <TextField
                              fullWidth
                              required
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              helperText={touched.password && errors.password}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.password}
                              name="password"
                              label="Password"
                              variant="outlined"
                              type="password"
                            />
                          </Box>

                          {errors.submit && (
                            <Box mt={3}>
                              <FormHelperText error>
                                {errors.submit}
                              </FormHelperText>
                            </Box>
                          )}

                          <Box display="flex">
                            <Button
                              variant="contained"
                              color="default"
                              startIcon={<ClearIcon />}
                              size="large"
                              type="reset"
                            >
                              Clear
                            </Button>
                            <Box ml={2}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                startIcon={<CheckIcon />}
                                size="large"
                                disabled={isSubmitting}
                              >
                                Save
                              </Button>
                            </Box>
                          </Box>
                        </form>
                      )}
                    </Formik>
                    <Box></Box>
                  </DialogContent>
                </Dialog>
              </Grid>
            ) : (
              Notify.noRecord()
            ))}
        </Box>
      </Paper>
    </Fragment>
  );
};

export default OrderDetails;
