import React from 'react'; 
import clsx from 'clsx'; 
import makeStyles from '@material-ui/styles/makeStyles';  
import Box from '@material-ui/core/Box';  
import Typography from '@material-ui/core/Typography';  
import Breadcrumbs from '@material-ui/core/Breadcrumbs';  
import Link from '@material-ui/core/Link';  
import Button from '@material-ui/core/Button';  
import { ThemeContext } from 'src/ContextAPI';
import { useHistory } from 'react-router-dom'; 

const useStyles = makeStyles((theme) => ({  
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1), 
      ...theme.mixins.toolbar,
    },
    
    content: {  
        flexGrow: 1,
        padding: theme.spacing(3),  
        paddingTop: theme.spacing( window.innerWidth > 500 ? 3 : 5),      
    }, 

    content_noscroll: {
        height: 'calc(100vh - 90px)', 
        overflow: 'hidden'
    },
    with_margin: { marginLeft: 280 }, 
})); 

const Container = ({ children, title, breadcrumbs }) => { 

    const classes = useStyles() 
    const history = useHistory() 
    const themeContext = React.useContext(ThemeContext)
 
    return (
        <React.Fragment>   
            <main 
                className={clsx(
                    window.innerWidth > 601 ?  classes.content : 
                    themeContext.sidebar.open ? classes.content_noscroll  
                    : classes.content, 
                {
                    [classes.with_margin]: themeContext.sidebar.open,
                })}
            >
                <div className={classes.toolbar} />  

                <Box mb={2}>
                    <Typography   
                        component={Box}
                        color="textSecondary"  
                        className={'gtc-capitalize'}
                        variant={ window.innerWidth > 500 ? 'h4' : 'h5'}
                    >
                        { title }
                    </Typography>

                    { breadcrumbs.enable &&(
                        <Breadcrumbs aria-label="breadcrumb">
                            { breadcrumbs.items.map((data, index) =>(
                                <Link 
                                    key={index} 
                                    component={Button} 
                                    size="small"
                                    onClick= { () => history.push(`${data.path}`)}
                                >
                                    { data.name }
                                </Link>
                            ))}  
                            <Typography color="textPrimary" variant={'caption'} className={'gtc-uppercase'}>{ breadcrumbs.current }</Typography>
                        </Breadcrumbs>
                    )} 

                </Box>

                <Box>
                    { children }
                </Box>
            </main>  
        </React.Fragment>
    )
}

export default Container; 
  