import React, { Fragment, useEffect } from "react"
import { Navbar, Nav } from "react-bootstrap";  
import { BrowserRouter, Link, Redirect, Route, Switch } from 'react-router-dom'; 
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound";
import ClinicSecretary from "../clinicsecretary/ClinicSecretary";
import GTCValidateLS from "../notification/GTCValidateLS";

const RouteClinicSecretary = () =>{
    
    useEffect(() =>{
        GTCValidateLS.verifyLocalStorage()
    }, [])
 
    return(
        <Fragment>
            <BrowserRouter>
                <Navbar bg="primary" expand="lg">
                    <Navbar.Brand href="/" className="text-white"> GTC Clinic Secretary </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Link to="/clinic/app/clinicsecretary" className="nav-link text-white"> Home </Link>  
                        </Nav> 
                        <Nav>  
                            <Link to="/clinic/app/logout" className="nav-link text-white"> Logout </Link>   
                        </Nav> 
                    </Navbar.Collapse>
                </Navbar>

                <Switch>
                    <Route exact path="/" component={() => <Redirect to="/clinic/app/clinicsecretary" /> } /> 
                    <Route exact path="/clinic/" component={() => <Redirect to="/clinic/app/clinicsecretary" /> } /> 
                    <Route exact path="/clinic/app" component={() => <Redirect to="/clinic/app/clinicsecretary" /> } />   
                    <Route exact path="/clinic/app/clinicsecretary" component={ClinicSecretary}/>  
                    <Route exact path="/clinic/app/logout" component={Logout} />
                    <Route render={
                        () => <PageNotFound />
                    }/> 
                </Switch>

            </BrowserRouter>
        </Fragment> 
    ) 
}

export default RouteClinicSecretary