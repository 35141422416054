import React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  CardMedia,
  TextField,
} from "@material-ui/core";
import Icon from "@material-ui/icons/ArrowRightAlt";
import { useHistory } from "react-router-dom";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const ClinicDoctorsList = ({ doctorsList }) => {
  const history = useHistory();

  return (
    <>
      <Box m={1}>
        <Typography variant={"subtitle2"} color="textPrimary">
          CLINIC DOCTORS LIST
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {doctorsList.map((data, index) => (
          <Grid key={index} item xs={6} sm={4} md={3}>
            <Card elevation={0}>
              <CardContent>
                <Box align="center" mb={2}>
                  <CardMedia
                    alt={"gtc-doctor"}
                    component="img"
                    style={{ borderRadius: "50%" }}
                    src={
                      data.doctors_image === null
                        ? "/no-image.jpg"
                        : `${imageLocation}doctors/${data.doctors_image}`
                    }
                  />
                </Box>

                <Box my={1}>
                  <TextField
                    label="Name"
                    value={data.doctors_name}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                </Box>

                <Box>
                  <TextField
                    label="Specialization"
                    value={
                      data.doctors_spicialty === null
                        ? "None"
                        : data.doctors_spicialty
                    }
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                </Box>
              </CardContent>

              <CardActions>
                <Button
                  fullWidth
                  onClick={() =>
                    history.push(
                      `/clinic/app/patient/appointment/doctor/${data.doctor_userid}`
                    )
                  }
                  variant="text"
                  color="primary"
                  endIcon={<Icon />}
                >
                  Details
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ClinicDoctorsList;
