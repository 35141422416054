import {
  Box,
  Grid,
  List,
  Paper,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Badge,
  Drawer,
  IconButton,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import OnlineAppointmentDetails from "./OnlineAppointmentDetails";
import Report from "./Report";
import Notifications from "./notification/Notifications";
import Axios from "axios";
import { ArrowLeft, Bell } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL;

const OnlineAppointment = ({
  appointment,
  appointmentRd,
  getOnlineAppointment,
  openReport,
}) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [selectedApp, setSelectedApp] = useState(null);
  const [notifDrawer, setNotifDrawer] = useState(false);
  const [unreadNotifCount, setUnreadNotfiCount] = useState(0);

  const getUnreadCountnotif = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("connection", "online");

    Axios.post("encoder/appointment/online/notification-unreadcount", formdata)
      .then((response) => {
        const data = response.data;
        setUnreadNotfiCount(data.length);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getUnreadCountnotif();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (openReport) {
    return <Report />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Box component={Paper} p={1} mb={2} display="flex" bgcolor="red">
          <Box flexGrow={1} mt={1.5} ml={3}>
            <Typography variant="subtitle1">Notification</Typography>
          </Box>

          <Box mx={2}>
            <IconButton onClick={() => setNotifDrawer(true)} color="primary">
              <Badge badgeContent={unreadNotifCount} color="error">
                <Bell />
              </Badge>
            </IconButton>
          </Box>
        </Box>

        <Box>
          <Card>
            <CardHeader
              title={
                <Box display="flex">
                  <Badge badgeContent={appointment.length} color="error">
                    Appointment
                  </Badge>
                </Box>
              }
            />
            <CardContent>
              <List component="div">
                {appointmentRd
                  ? appointment.length > 0
                    ? appointment.map((data, index) => (
                        <ListItem
                          button
                          key={index}
                          selected={selectedApp && selectedApp.id === data.id}
                          onClick={() => setSelectedApp(data)}
                        >
                          <ListItemAvatar>
                            <Avatar
                              src={`${imageLocation}patients/${data.image}`}
                            ></Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            className={`gtc-capitalize`}
                            primary={`${data.fname} ${data.lname}`}
                            secondary={Notify.dateTimeConvert(
                              data.appointment_dateonline
                            )}
                          />
                        </ListItem>
                      ))
                    : Notify.noRecord()
                  : Notify.loading()}
              </List>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item xs={12} md={8}>
        {selectedApp && (
          <OnlineAppointmentDetails
            app={selectedApp}
            getOnlineAppointment={getOnlineAppointment}
          />
        )}
      </Grid>

      {/* drawer */}
      <Drawer
        open={notifDrawer}
        onClose={() => setNotifDrawer(false)}
        anchor="right"
      >
        <Box m={2} width={window.innerWidth > 600 ? 600 : window.innerWidth}>
          <Box mb={2}>
            <Typography variant="h6">
              <IconButton color="primary" onClick={() => setNotifDrawer(false)}>
                <ArrowLeft />
              </IconButton>
              Notifications List
            </Typography>
          </Box>

          <Notifications />
        </Box>
      </Drawer>
    </Grid>
  );
};

export default OnlineAppointment;
