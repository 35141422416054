import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TablePagination,
  InputAdornment,
  Paper,
  Dialog,
  Zoom,
  IconButton,
  DialogTitle,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import Axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import PrintIcon from "@material-ui/icons/Print";
import SalesReportPrint from "./SalesReportPrint";

function Sales({ details }) {
  const token = localStorage.getItem("token");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sales, setSales] = useState([]);
  const [salesReady, setSalesReady] = useState(false);
  const [search, setSearch] = useState("");
  const [printSalesDiag, setPrintSalesDialog] = useState(false);
  const [totalSalesssuu, setTotalSalesssuu] = useState(0);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const calculateTotalSales = (data) => {
    var total = 0;
    for (let i = 0; i < data.length; i++) {
      total +=
        parseFloat(data[i].sum_all_total_quantity) *
        parseFloat(data[i].productSrp);
    }
    return total;
  };

  const fetchSales = React.useCallback(() => {
    var params = {
      token: token,
      pharmacy_id: details.pharmacy_id,
    };
    Axios.get("doctors/management/pharmacy/get-sales-list", { params })
      .then((response) => {
        const data = response.data;
        setSales(data);
        setTotalSalesssuu(calculateTotalSales(data));
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setSalesReady(true));
  }, [token, details]);

  useEffect(() => {
    fetchSales();
  }, [fetchSales]);

  const searchable = sales.filter((data) => {
    return data.product.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <Fragment>
      <Grid container>
        <Grid item sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper component={Box} p={2}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="h5">
                      Overall Sales:
                      <strong>
                        {" "}
                        {sales.length > 0
                          ? Notify.convertToNumber(totalSalesssuu)
                          : "0.00"}{" "}
                      </strong>
                    </Typography>
                  </Box>

                  <Box>
                    <TextField
                      className={"d-print-none"}
                      label="Search brand"
                      variant="outlined"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  <Box ml={1}>
                    <IconButton
                      title="Print"
                      color="primary"
                      onClick={() => setPrintSalesDialog(true)}
                    >
                      <PrintIcon fontSize="large" />
                    </IconButton>
                  </Box>
                </Box>

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell> Date </TableCell>
                        <TableCell> Brand </TableCell>
                        <TableCell> Generic </TableCell>
                        <TableCell> Sales From </TableCell>
                        <TableCell> Qty </TableCell>
                        <TableCell> Amount </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {salesReady ? (
                        sales.length > 0 ? (
                          searchable
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((data, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {Notify.dateTimeConvert(data.created_at)}
                                </TableCell>
                                <TableCell> {data.product} </TableCell>
                                <TableCell>{data.description}</TableCell>
                                <TableCell align="center">
                                  {data.sales_from}
                                </TableCell>
                                <TableCell align="right">
                                  {data.sum_all_total_quantity}
                                </TableCell>
                                <TableCell align="right">
                                  {Notify.convertToNumber(
                                    data.sum_spec_total_quantity
                                  )}
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6} align="center">
                              <Typography variant="subtitle2" color="secondary">
                                No sale found
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            <Typography variant="subtitle2" color="primary">
                              Loading...
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                  <TablePagination
                    className={"d-print-none"}
                    component={"div"}
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={sales.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={printSalesDiag}
        TransitionComponent={Zoom}
        transitionDuration={500}
        fullScreen
      >
        <DialogTitle>Sales Report</DialogTitle>
        <SalesReportPrint
          details={details}
          close={() => setPrintSalesDialog(false)}
        />
      </Dialog>
    </Fragment>
  );
}

export default Sales;
