import React, { Fragment } from 'react';
import { Card, CardHeader, Divider, CardContent, Box, Typography } from '@material-ui/core';

function GTCContactInfo() {
    return (
        <Fragment>
            <Card>
                <CardHeader
                    // title="Looking for help?"
                    subheader="Looking for help?"
                />
                <Divider light />
                <CardContent>
                    <Box mb={2}>
                        <Typography variant="body1" color="primary" component="p">
                            Dont hesitate to contact us directly, we are here to listen and help assisting your concern.
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        <Typography style={{ textTransform: 'uppercase', fontWeight: 'bolder' }} variant="body2" color="inherit">
                            Mobile:
                        </Typography>
                        <Typography variant="body2" color="inherit" component="p">
                            +6390-378-3234
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        <Typography style={{ textTransform: 'uppercase', fontWeight: 'bolder' }} variant="body2" color="inherit">
                            Telephone:
                        </Typography>
                        <Typography variant="body2" color="inherit" component="p">
                            083-234-222
                        </Typography>
                    </Box>
                    <Box>
                        <Typography style={{ textTransform: 'uppercase', fontWeight: 'bolder' }} variant="body2" color="inherit">
                            Email:
                        </Typography>
                        <Typography variant="body2" color="inherit" component="p">
                            Globaltelemedicinecorp@gmail.com
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Fragment>
    )
}

export default GTCContactInfo;
