import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  IconButton,
  CardMedia,
  Grid,
  Card,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import axios from "axios";
import Container from "src/layout/Container";
import { ArrowRight } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const RadiologistPatientReviewed = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [details, setDetails] = useState(null);
  const [records, setRecords] = React.useState({
    data: [],
    ready: false,
  });

  const getPatientRead = React.useCallback(async () => {
    try {
      var formdata = new FormData();
      formdata.append("token", token);
      formdata.append("user_id", user_id);
      const request = await axios.post(
        "radiologist/patients/getpatients-read",
        formdata
      );
      setRecords({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      Notify.requestError(error);
    }
  }, [token, user_id]);

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getPatientRead();
  }, [getPatientRead]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "report",
        items: [{ name: "Dashboard", path: "/clinic/app/radiologist" }],
      }}
      title={"Reviewed Patients"}
    >
      <Paper variant="outlined" component={Box} p={2}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Date </TableCell>
                <TableCell> Patient </TableCell>
                <TableCell> Order </TableCell>
                <TableCell> Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.ready ? (
                records.data.length > 0 ? (
                  (rowsPerPage > 0
                    ? records.data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : records.data
                  ).map((data, index) => (
                    <TableRow key={index} hover>
                      <TableCell>
                        {Notify.dateTimeConvert(data.created_at)}
                      </TableCell>
                      <TableCell>{data.patient_name}</TableCell>
                      <TableCell>{data.imaging_order}</TableCell>

                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => setDetails(data)}
                        >
                          <ArrowRight />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography color="error">No record found.</Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography color="textPrimary">please wait...</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          labelRowsPerPage="list"
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={records.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      {/* order information dialog */}
      {details && (
        <DetailsRender details={details} close={() => setDetails(null)} />
      )}
    </Container>
  );
};

export default RadiologistPatientReviewed;

const DetailsRender = ({ details, close }) => {
  return (
    <Dialog open={true} onClose={close} maxWidth="xs" fullWidth>
      <DialogTitle>Order Information</DialogTitle>
      <DialogContent dividers>
        <Box>
          <Box mb={3}>
            <TextField
              fullWidth
              label="Patient"
              variant="outlined"
              value={details.patient_name}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>

          <Box mb={3}>
            <TextField
              fullWidth
              label="Gender"
              variant="outlined"
              value={
                details.patient_gender === null
                  ? "None"
                  : details.patient_gender
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>

          <Box mb={3}>
            <TextField
              fullWidth
              label="Birthday"
              variant="outlined"
              value={
                details.patient_birthday === null
                  ? "None"
                  : Notify.birthday(details.patient_birthday)
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>

          <Box mb={3}>
            <TextField
              fullWidth
              label="Address"
              variant="outlined"
              value={
                details.patient_address === null
                  ? "None"
                  : details.patient_address
              }
              InputProps={{
                readOnly: true,
              }}
              multiline
            />
          </Box>

          <Box mb={3}>
            <TextField
              fullWidth
              label="Imaging Order"
              variant="outlined"
              value={details.imaging_order}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>

          <Box mb={3}>
            <TextField
              fullWidth
              label="Imaging Result"
              variant="outlined"
              value={details.imaging_result}
              InputProps={{
                readOnly: true,
              }}
              multiline
            />
          </Box>

          <Box>
            <Typography variant="caption" color="textSecondary">
              Attachments
            </Typography>
            <Grid container>
              {details.imaging_result_attachment
                .split(",")
                .map((image, index) => {
                  return (
                    <Grid key={index} item xs={6} sm={6} md={4} lg={4}>
                      <Card>
                        <Box>
                          <CardMedia
                            component="img"
                            height={150}
                            src={`${imageLocation}imaging/${image}`}
                            alt={image}
                          />
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => close()}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
