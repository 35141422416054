import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";

const ManagementImaging = ({ details }) => {
  const token = localStorage.getItem("token");
  const [imagingInfo, setImagingInfo] = useState([]);
  const [dialog, setDialog] = useState(false);

  const handleNewImaging = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("management_id", details.management_id);

    let response = await Axios.post(
      "gtcadmin/management/new-imaging",
      formdata
    );
    let result = response.data;

    if (result.message === "success") {
      Notify.successRequest("new imaging");
    }
  };

  const getImagingDetails = async () => {
    let response = await Axios.get("gtcadmin/management/imaging-list", {
      params: {
        token: token,
        management_id: details.management_id,
      },
    });
    let result = response.data;
    setImagingInfo(result);
  };

  const handleUpdateImaging = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    let response = await Axios.post(
      "gtcadmin/management/imaging-update",
      formdata
    );
    let result = response.data;
    if (result.message === "success") {
      getImagingDetails();
      Notify.successRequest("imaging updated");
    }
  };

  useEffect(() => {
    getImagingDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  return (
    <Box>
      <Box>
        <Paper component={Box} p={2}>
          <Box mb={2}>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography variant="h6" color="textSecondary">
                  Imaging Information
                </Typography>
              </Box>

              <Box>
                <Button color="primary" onClick={() => setDialog(true)}>
                  New Imaging
                </Button>
              </Box>
            </Box>
            <Typography
              variant="subtitle1"
              color={imagingInfo.length > 0 ? "primary" : "secondary"}
            >
              {imagingInfo.length > 0
                ? "Fields is ready to update."
                : "Imaging is not set."}
            </Typography>
          </Box>
          <Box>
            <form onSubmit={handleUpdateImaging}>
              {imagingInfo.length > 0 && (
                <Box>
                  <Box mb={2}>
                    <TextField
                      label="Imaging User Id"
                      variant="outlined"
                      fullWidth
                      defaultValue={
                        imagingInfo.length > 0 ? imagingInfo[0].user_id : ""
                      }
                      InputProps={{ readOnly: true }}
                      name="imaging_userid"
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      fullWidth
                      defaultValue={
                        imagingInfo.length > 0 ? imagingInfo[0].name : ""
                      }
                      name="name"
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      label="Gender"
                      variant="outlined"
                      fullWidth
                      defaultValue={
                        imagingInfo.length > 0 ? imagingInfo[0].gender : ""
                      }
                      name="gender"
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      label="Birthday"
                      variant="outlined"
                      fullWidth
                      defaultValue={
                        imagingInfo.length > 0 ? imagingInfo[0].birthday : ""
                      }
                      name="birthday"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      label="Address"
                      variant="outlined"
                      fullWidth
                      defaultValue={
                        imagingInfo.length > 0 ? imagingInfo[0].address : ""
                      }
                      name="address"
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      label="Imaging username"
                      variant="outlined"
                      fullWidth
                      name="username"
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      label="Imaging Password"
                      variant="outlined"
                      fullWidth
                      name="password"
                    />
                  </Box>

                  <Box display="flex" justifyContent="flex-end">
                    <Box mr={2}>
                      <Button variant="contained" color="default" size="large">
                        No
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
            </form>
          </Box>
        </Paper>
      </Box>
      {/* new imaging dialog */}
      {dialog && (
        <NewImagingInManagement
          handleNewImaging={handleNewImaging}
          open={dialog}
          close={() => setDialog(false)}
        />
      )}
    </Box>
  );
};

export default ManagementImaging;

const NewImagingInManagement = ({ handleNewImaging, open, close }) => {
  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="xs">
      <DialogTitle> New Imaging </DialogTitle>
      <form onSubmit={handleNewImaging}>
        <DialogContent dividers>
          <Box mb={2}>
            <TextField label="Name" variant="outlined" fullWidth name="name" />
          </Box>

          <Box mb={2}>
            <TextField
              label="Gender"
              variant="outlined"
              fullWidth
              name="gender"
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Birthday"
              variant="outlined"
              fullWidth
              name="birthday"
              InputLabelProps={{ shrink: true }}
              type="date"
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              name="address"
            />
          </Box>

          <Box mb={2}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              name="username"
            />
          </Box>

          <Box>
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              name="password"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="default" onClick={close}>
            No
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
