import React, { Fragment, useEffect, useState, useContext } from "react";
import { Box, Paper, Tabs, Tab, Badge, Grid } from "@material-ui/core";
import TabPanel from "src/utils/TabPanel";
import ImagingHeader from "./ImagingHeader";
import { PatientsUnRead } from "src/ContextAPI";
import Axios from "axios";
import Notify from "src/notification/Notify";
import NoInternetConnection from "src/NoInternetConnection";

const ImagingOrder = ({ patient_id }) => {
  const [tab, setTabs] = useState("local");
  const [online, setOnline] = useState("checking");

  const unread = useContext(PatientsUnRead);

  const checkinternet = () => {
    setOnline("checking");
    Axios.get("check/internet/connection-status", {
      params: {
        connection: "online",
      },
    })
      .then((response) => {
        const data = response.data;
        setOnline(data.message);
      })
      .catch((error) => {
        setOnline("disconnected");
        console.log("error request:", error.message);
      });
  };

  const checkPatientUnreadNotif = (notifFrom, category) => {
    let xx = unread.unviewNotif;
    let yy = unread.unviewNotifVirtual;
    let count = 0;
    if (notifFrom === "local") {
      for (let i = 0; i < xx.length; i++) {
        if (patient_id === xx[i].patient_id && category === xx[i].category) {
          count += 1;
        }
      }
    } else {
      for (let i = 0; i < yy.length; i++) {
        if (patient_id === yy[i].patient_id && category === yy[i].category) {
          count += 1;
        }
      }
    }

    return count;
  };

  useEffect(() => {
    checkinternet();
  }, [patient_id]);

  return (
    <>
      <Fragment>
        <Grid container justify="flex-end">
          <Grid item xs={12} md={5} lg={4}>
            <Paper elevation={1}>
              <Tabs
                value={tab}
                onChange={(e, tab) => setTabs(tab)}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="on"
                variant="fullWidth"
              >
                <Tab
                  value="local"
                  label={
                    Boolean(
                      parseInt(checkPatientUnreadNotif("local", "imaging"))
                    ) ? (
                      <Badge
                        color="secondary"
                        badgeContent={parseInt(
                          checkPatientUnreadNotif("local", "imaging")
                        )}
                      >
                        {" "}
                        Local Order{" "}
                      </Badge>
                    ) : (
                      "Local Order"
                    )
                  }
                  wrapped
                />
                <Tab
                  value="virtual"
                  label={
                    Boolean(
                      parseInt(checkPatientUnreadNotif("virtual", "imaging"))
                    ) ? (
                      <Badge
                        color="secondary"
                        badgeContent={parseInt(
                          checkPatientUnreadNotif("virtual", "imaging")
                        )}
                      >
                        {" "}
                        Virtual Order{" "}
                      </Badge>
                    ) : (
                      "Virtual Order"
                    )
                  }
                  wrapped
                />
              </Tabs>
            </Paper>
          </Grid>
        </Grid>

        <Box mt={2}>
          <TabPanel value={tab} index="local">
            <ImagingHeader
              imagingType="local-imaging"
              patient_id={patient_id}
            />
          </TabPanel>

          <TabPanel value={tab} index="virtual">
            {online === "connected" && (
              <ImagingHeader
                imagingType="virtual-imaging"
                patient_id={patient_id}
              />
            )}

            {online === "checking" && Notify.loading()}
            {online === "disconnected" && (
              <NoInternetConnection xheight={"calc(50vh - 120px)"} />
            )}
          </TabPanel>
        </Box>
      </Fragment>
    </>
  );
};

export default ImagingOrder;
