import {
  Card,
  CardContent,
  Box,
  Paper,
  Tabs,
  Tab,
  Grid,
  TextField,
  IconButton,
  Typography,
  Tooltip,
} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import React, { useState } from "react";
import TabPanel from "src/utils/TabPanel";
import Sales from "./sales/Sales";
import Stock from "./Stock";

const PharmacyDetails = ({ details, close }) => {
  const [category, setCategory] = useState("sales");

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  return (
    <Box>
      <Box mb={2}>
        <Card>
          <CardContent className="gtc-textfield-noborder">
            <Box display={"flex"}>
              <Tooltip arrow title="close">
                <IconButton onClick={close} color="secondary">
                  <ArrowBack />
                </IconButton>
              </Tooltip>
              <Box flexGrow={1} mt={1}>
                <Typography variant="h5">Pharmacy Details</Typography>
              </Box>
            </Box>

            <Box my={2}>
              <TextField
                label="Pharmacy"
                fullWidth
                value={details ? details.name : ""}
                InputProps={{ readOnly: true, disableUnderline: true }}
              />
            </Box>

            <Box my={2}>
              <TextField
                label="Address"
                fullWidth
                value={details ? details.address : "None"}
                InputProps={{ readOnly: true, disableUnderline: true }}
              />
            </Box>

            <Box my={2} className="gtc-textfield-noborder">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      label="Email"
                      fullWidth
                      value={details ? details.email : "None"}
                      InputProps={{ readOnly: true, disableUnderline: true }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      label="TIN Number"
                      fullWidth
                      value={details ? details.contact : "None"}
                      InputProps={{ readOnly: true, disableUnderline: true }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      label="Contact"
                      fullWidth
                      value={details ? details.contact : "None"}
                      InputProps={{ readOnly: true, disableUnderline: true }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Box my={2}>
        <Paper>
          <Tabs
            value={category}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="on"
            variant="scrollable"
            orientation={`horizontal`}
          >
            <Tab value="sales" label="Sales" />
            <Tab value="stocks" label="Stocks" />
          </Tabs>
        </Paper>
      </Box>

      <Box my={2}>
        <TabPanel value={category} index={"sales"}>
          <Sales details={details} />
        </TabPanel>

        <TabPanel value={category} index={"stocks"}>
          <Stock details={details} />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default PharmacyDetails;
