import { useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React from "react";
import { Line } from "react-chartjs-2";

const Male = ({ list }) => {
  const chartRef = React.useRef();
  const theme = useTheme();

  const label = [
    "Birth", // birth
    "2",
    "4",
    "6",
    "8",
    "10",

    "1 year", // 1year
    "2",
    "4",
    "6",
    "8",
    "10",

    "2 year", // 2 years
    "2",
    "4",
    "6",
    "8",
    "10",

    "3 year", // 3 years
    "2",
    "4",
    "6",
    "8",
    "10",
    "4 year",

    "2", // 4 years
    "4",
    "6",
    "8",
    "10",
    "5 year",
  ];

  const generateChildsGrowth = (list) => {
    let growth = [];

    if (list.length > 0) {
      growth.push(
        list[0]._birth_zero === null ? 0 : list[0]._birth_zero,
        list[0]._birth_two === null ? 0 : list[0]._birth_two,
        list[0]._birth_four === null ? 0 : list[0]._birth_four,
        list[0]._birth_six === null ? 0 : list[0]._birth_six,
        list[0]._birth_eight === null ? 0 : list[0]._birth_eight,

        list[0]._first_ten === null ? 0 : list[0]._first_ten,
        list[0]._first_zero === null ? 0 : list[0]._first_zero,
        list[0]._first_two === null ? 0 : list[0]._first_two,
        list[0]._first_four === null ? 0 : list[0]._first_four,
        list[0]._first_six === null ? 0 : list[0]._first_six,
        list[0]._first_eight === null ? 0 : list[0]._first_eight,

        list[0]._second_ten === null ? 0 : list[0]._second_ten,
        list[0]._second_zero === null ? 0 : list[0]._second_zero,
        list[0]._second_two === null ? 0 : list[0]._second_two,
        list[0]._second_four === null ? 0 : list[0]._second_four,
        list[0]._second_six === null ? 0 : list[0]._second_six,
        list[0]._second_eight === null ? 0 : list[0]._second_eight,

        list[0]._third_ten === null ? 0 : list[0]._third_ten,
        list[0]._third_zero === null ? 0 : list[0]._third_zero,
        list[0]._third_two === null ? 0 : list[0]._third_two,
        list[0]._third_four === null ? 0 : list[0]._third_four,
        list[0]._third_six === null ? 0 : list[0]._third_six,
        list[0]._third_eight === null ? 0 : list[0]._third_eight,

        list[0]._fourth_ten === null ? 0 : list[0]._fourth_ten,
        list[0]._fourth_zero === null ? 0 : list[0]._fourth_zero,
        list[0]._fourth_two === null ? 0 : list[0]._fourth_two,
        list[0]._fourth_four === null ? 0 : list[0]._fourth_four,
        list[0]._fourth_six === null ? 0 : list[0]._fourth_six,
        list[0]._fourth_eight === null ? 0 : list[0]._fourth_eight,
        list[0]._fourth_ten === null ? 0 : list[0]._fourth_ten,

        list[0]._fifth_zero === null ? 0 : list[0]._fifth_zero
      );
    } else {
      growth.push(0);
    }

    return growth;
  };

  const data = {
    labels: label,
    datasets: [
      {
        label: "Growth",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: `${theme.palette.primary.main}`,
        borderColor: `${theme.palette.primary.main}`,
        pointHoverRadius: 1,
        borderWidth: 2,
        data: generateChildsGrowth(list),
      },
      {
        label: "-3",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "#000000d4",
        borderColor: "#000000d4",
        pointHoverRadius: 1,
        borderWidth: 1,
        data: [
          44, // birth
          52,
          57,
          61,
          64,
          66.5,
          68.5, // 1 year
          70.5,
          72.5,
          74,
          75.5,
          77,
          78.5, // 2year
          79.5,
          80.5,
          81.5,
          82.7,
          84,
          85, // 3year
          86,
          87,
          88,
          89,
          90,
          90.7, // 4year
          91.7,
          92.4,
          93.3,
          94,
          95,
          96, // 5year
        ],
      },

      {
        label: "-2",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "#ff00007a",
        borderColor: "#ff00007a",
        pointHoverRadius: 1,
        borderWidth: 1,
        data: [
          46, //birth
          54.5,
          59.5,
          64.2,
          66.7,
          69,
          71, // 1 year
          73,
          75,
          77,
          78.5,
          80,
          81.5, // 2 year
          82.5,
          83.5,
          85,
          86.5,
          87.5,
          88.5, // 3year
          90,
          91,
          92,
          93,
          94,
          95, // 4year
          96,
          97,
          98,
          99,
          100,
          101, // 5year
        ],
      },

      {
        label: "0",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "#168a16d6",
        borderColor: "#168a16d6",
        pointHoverRadius: 1,
        borderWidth: 1,
        data: [
          50, //birth
          58,
          64,
          68,
          70.5,
          73,
          75.5, // 1 year
          78,
          80,
          82,
          84,
          86,
          87.5, // 2 year
          89,
          90.5,
          92,
          94,
          95,
          96, // 3year
          97,
          98.5,
          100,
          102, // 4year
          103.5,
          104.5,
          105.5,
          106,
          107,
          108,
          109,
          110, // 5year
        ],
      },

      {
        label: "2",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "#ff00007a",
        borderColor: "#ff00007a",
        pointHoverRadius: 1,
        borderWidth: 1,
        data: [
          54, //birth
          62,
          68,
          71.8,
          75,
          77.7, // 1year
          80.5,
          83,
          85,
          87.5,
          90,
          92,
          93, // 2 year
          95,
          97,
          98.5,
          100,
          102,
          103.5, // 3 year
          105,
          106,
          108,
          109,
          110,
          112, // 4year
          113,
          114,
          115.5,
          117,
          118,
          119, // 5year
        ],
      },

      {
        label: "3",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: "#000000d4",
        borderColor: "#000000d4",
        pointHoverRadius: 1,
        borderWidth: 1,
        data: [
          56, //birth
          64.5,
          70,
          74,
          77,
          80,
          83, //1 year
          85.5,
          88,
          90,
          92.5,
          95,
          97, // 2year
          98,
          100,
          102,
          104,
          105.5,
          107, // 3year
          110,
          112,
          113,
          114.5,
          115,
          116, // 4year
          117,
          118.5,
          120,
          121,
          122.5,
          124, //5year
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    legend: {
      position: "bottom",
      cursor: "pointer",
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Length/Height(cm)",
          },
          min: 45,
          max: 125,
          ticks: {
            // forces step size to be 50 units
            stepSize: 5,
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Age(Completed months and years)",
          },
        },
      ],
    },
  };

  return (
    <Box>
      <Line ref={chartRef} data={data} options={options} />
    </Box>
  );
};

export default Male;
