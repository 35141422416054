import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import Notify from "../notification/Notify";
import axios from "axios";
import LoginChecker from "./LoginChecker";
import "./Login.css";
import { Link } from "react-router-dom";
import {
  TextField,
  Button,
  InputAdornment,
  Box,
  CircularProgress,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";
import { Alert, AlertTitle } from "@material-ui/lab";
import { grey } from "@material-ui/core/colors";
import { Formik } from "formik";
import * as Yup from "yup";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  button: {
    padding: theme.spacing(1.5),
    fontSize: "15px",
    background: "#007bff",
    "&:hover": {
      background: "#007bff",
    },
  },
}));

const ValidationTextField = withStyles({
  root: {
    "& label": {
      color: "#007bff",
    },
    "& label.Mui-focused": {
      color: "#007bff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007bff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#007bff",
      },
      "&:hover fieldset": {
        borderColor: "#007bff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
        borderLeftWidth: 6,
      },
    },
  },
})(TextField);

const Login = () => {
  const classes = useStyles();

  const IsFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  return (
    <Fragment>
      {/* enable loading */}
      {/* <Backdrop className={classes.backdrop} open={isprocess}>
                <CircularProgress color="inherit" />
            </Backdrop> */}

      <Col sm={9} className="mx-auto mb-2 p-0 ">
        <Box
          style={{
            backgroundColor: "rgb(255 255 255 / 70%)",
            border: "1px solid rgb(255 255 255 / 50%)",
          }}
          p={3}
          borderRadius={10}
          py={7}
        >
          <Box mb={4}>
            <Row className="mb-3">
              <Col xs={6} sm={6} className="text-right sign-in-box">
                <h5 className="text-dark"> Sign In </h5>
              </Col>
              <Col xs={6} sm={6} className="text-left">
                <Link
                  to="/clinic/app/registration/packages"
                  className="text-primary pointer h5"
                >
                  Register
                </Link>
              </Col>
            </Row>
          </Box>

          <Formik
            initialValues={{
              username: "",
              password: "",
              status: 1,
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().required(),
              password: Yup.string().required(),
            })}
            onSubmit={async (
              values,
              { setErrors, setSubmitting, resetForm }
            ) => {
              try {
                const response = await axios.post(
                  "welcome/login",
                  IsFormData(values)
                );
                if (response.data.msg === "account-invalid") {
                  const message = "Username or password invalid.";
                  setErrors({ submit: message });
                  Notify.customToast(
                    "Credentials Invalid",
                    "Check your username and password."
                  );
                } else {
                  localStorage.setItem("user_id", response.data[0].user_id);
                  localStorage.setItem("type", response.data[0].type);
                  localStorage.setItem("username", response.data[0].username);
                  localStorage.setItem("token", response.data[0].token);
                  localStorage.setItem(
                    "management_id",
                    response.data[0].manage_by
                  );
                  localStorage.setItem(
                    "general_management_id",
                    response.data[0].main_mgmt_id
                  );

                  localStorage.setItem("is_login", response.data[0].is_login);
                  localStorage.setItem("page", "Dashboard");
                  LoginChecker(response.data[0].type);
                  window.location.href = "/clinic/app/";
                }
              } catch (error) {
                const message = error.message || "Something went wrong";
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {/* show error if any */}
                {errors.submit && (
                  <Box my={3}>
                    <Alert severity="error">
                      <AlertTitle>Credentials Error</AlertTitle>
                      {errors.submit}
                    </Alert>
                  </Box>
                )}

                <Box>
                  <ValidationTextField
                    name="username"
                    label={` Username `}
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Enter your username"
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    fullWidth
                    InputProps={{
                      style: { color: grey[800] },
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle style={{ color: "007bff" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box my={3}>
                  <ValidationTextField
                    name="password"
                    label={` Password `}
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Enter your password"
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    type="password"
                    fullWidth
                    InputProps={{
                      style: { color: grey[800] },
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon style={{ color: "007bff" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting && (
                        <CircularProgress color="inherit" size={30} />
                      )
                    }
                    type="submit"
                    size="large"
                    fullWidth
                    className={classes.button}
                  >
                    Sign In
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Col>
    </Fragment>
  );
};

export default Login;
