import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  Button,
  // Dialog, DialogActions, DialogContent, Zoom,
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Divider,
} from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";
import Print from "@material-ui/icons/Print";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrescription } from "@fortawesome/free-solid-svg-icons";
import Notify from "src/notification/Notify";
import { useParams } from "react-router";
import { grey } from "@material-ui/core/colors";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const DonascoRx = () => {
  const token = localStorage.getItem("token");

  const [doctorsInfo, setDoctorsInfo] = useState([]);
  const [doctorsInfoRd, setDoctorsInfoRd] = useState(false);

  const [prescDetails, setPrescDetails] = useState([]);
  const [prescDetailsRd, setPrescDetailsRd] = useState(false);

  const [patientInfo, setPatientInfo] = useState([]);
  const [patientInfoRd, setPatientInfoRd] = useState(false);

  const { claim_id, patient_id, doctors_id } = useParams();

  const getDoctorsInfo = useCallback(() => {
    const params = {
      doctors_id,
      token,
    };

    Axios.get("patients/rx/printable/doctorsdetails", { params })
      .then((response) => {
        const data = response.data;
        setDoctorsInfo(data);
        setDoctorsInfoRd(true);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, doctors_id]);

  const getPatientInfo = useCallback(() => {
    const params = {
      token,
      patient_id,
    };

    Axios.get("doctor/patient/patient-information", { params })
      .then((response) => {
        const data = response.data;
        setPatientInfo(data);
        setPatientInfoRd(true);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, patient_id]);

  const getPrescriptionDetails = useCallback(() => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("claim_id", claim_id);
    formdata.set("patient_id", patient_id);
    formdata.set("user_id", doctors_id);

    Axios.post("patients/rx/printable/prescriptiondetails", formdata)
      .then((response) => {
        const data = response.data;
        // setPrescDetails(
        //   groupRxByTwoForDocDonasco(data).filter((x) => x.length !== 0)
        // ); //remove empty array
        setPrescDetails(data);
        setPrescDetailsRd(true);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, claim_id, patient_id, doctors_id]);

  useEffect(() => {
    getDoctorsInfo();
    getPrescriptionDetails();
    getPatientInfo();
  }, [getDoctorsInfo, getPrescriptionDetails, getPatientInfo]);

  return (
    <Fragment>
      {/* dialog for print rx */}
      <Box
        zIndex={9999999}
        bgcolor="white"
        position="relative"
        display="block"
        width={"100%"}
        p={3}
        className="docdonascoPageBreak"
      >
        {prescDetailsRd ? (
          prescDetails.length > 0 ? (
            // ? prescDetails.map((rxitems, rxindex) => {
            //     return (
            //       <div key={rxindex}>
            <>
              {/* doctors information */}
              {doctorsInfoRd ? (
                doctorsInfo.length > 0 ? (
                  <>
                    <Box align="center" mb={2}>
                      <Box>
                        <Typography variant="h4" className={`gtc-uppercase`}>
                          <b> {doctorsInfo[0].header} </b>
                        </Typography>
                      </Box>

                      <Box>
                        {/* <Typography
                                variant="subtitle1"
                                className={`gtc-uppercase`}
                                color="textPrimary"
                              >
                                {doctorsInfo[0].sub_header}
                              </Typography> */}

                        <Box
                          fontSize={18}
                          color={grey[700]}
                          dangerouslySetInnerHTML={{
                            __html: doctorsInfo[0].sub_header,
                          }}
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box align="center" mb={1} flexGrow={1}>
                        <Box>
                          <Typography variant="h6" color="textSecondary">
                            {doctorsInfo[0].location}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6" color="textSecondary">
                            {doctorsInfo[0].contact_no}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6" color="textSecondary">
                            {doctorsInfo[0].days_open}
                          </Typography>
                        </Box>
                      </Box>
                      {doctorsInfo[0].affiliated_hospital && (
                        <Box>
                          <Typography color="textSecondary" variant="h6">
                            Affiliated Hospital:
                          </Typography>
                          <Box
                            fontSize={17}
                            color={grey[500]}
                            dangerouslySetInnerHTML={{
                              __html: doctorsInfo[0].affiliated_hospital,
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </>
                ) : (
                  <Box>
                    {Notify.noRecord()}
                    <Typography>Doctors Information Not Found.</Typography>
                  </Box>
                )
              ) : (
                Notify.loading()
              )}

              <Box my={1}>
                <Divider />
              </Box>

              <Box mt={1}>
                {/* patients information */}

                {patientInfoRd ? (
                  patientInfo.length > 0 ? (
                    <>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box mb={1}>
                            <b> NAME: </b>
                            <span
                              className="text-capitalize"
                              style={{ fontSize: 17 }}
                            >
                              {patientInfo[0].firstname}{" "}
                              {patientInfo[0].middle === null
                                ? ""
                                : patientInfo[0].middle}{" "}
                              {patientInfo[0].lastname}
                            </span>
                          </Box>
                          <Box mb={1}>
                            <b> ADDRESS: </b>
                            <span
                              className="text-capitalize"
                              style={{ fontSize: 17 }}
                            >
                              {patientInfo[0].street === null
                                ? ""
                                : patientInfo[0].street}{" "}
                              {patientInfo[0].barangay === null
                                ? ""
                                : patientInfo[0].barangay}{" "}
                              {patientInfo[0].city}
                            </span>
                          </Box>
                          <Box>
                            <b> AGE | GENDER: </b>{" "}
                            <span style={{ fontSize: 17 }}>
                              {Notify.calculateAge(patientInfo[0].birthday)} |{" "}
                              {patientInfo[0].gender}{" "}
                            </span>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box>
                            <b> DATE: </b>
                            <span style={{ fontSize: 17 }}>
                              {" "}
                              {prescDetails.length > 0
                                ? Notify.dateTimeConvert(
                                    new Date().toLocaleDateString()
                                  )
                                : "No date found."}{" "}
                            </span>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    Notify.noRecord()
                  )
                ) : (
                  Notify.loading()
                )}

                {/* RX ICON */}
                <Box mt={2}>
                  <FontAwesomeIcon icon={faPrescription} size="5x" />
                </Box>

                <Box mb={4}>
                  <Table className="gtc-table-borderless" padding={"checkbox"}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>
                            {" "}
                            <span style={{ fontSize: 17 }}>
                              {" "}
                              Products{" "}
                            </span>{" "}
                          </b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {" "}
                            <span style={{ fontSize: 17 }}> Quantity </span>
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {prescDetails.map((data, index) => {
                        return (
                          <Fragment key={index}>
                            <TableRow>
                              <TableCell>
                                <Box>
                                  <Typography variant="h6" noWrap={false}>
                                    <span className="text-uppercase">
                                      {data.product_name} {data.type}
                                      {data.dosage}
                                    </span>
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant="h6" noWrap={false}>
                                    <b> Sig. {data.remarks} </b>
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell align="center">
                                <Box>
                                  <Typography variant="h6" noWrap={false}>
                                    {data.quantity}
                                  </Typography>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
              <br />
              <br />
              <br />
              {/* signs and licencess */}
              {doctorsInfoRd ? (
                doctorsInfo.length > 0 ? (
                  <Box display="flex">
                    <Box flexGrow={1} />
                    <Box>
                      <Box>
                        <Box align="left">
                          {/* <img src="/sample-sign.png" alt="" width="120" style={{position: 'relative', top: 140}}/> */}
                          <img
                            src={
                              imageLocation +
                              "doctors/doctors_signatures/" +
                              doctorsInfo[0].image_signature
                            }
                            alt=""
                            width="120"
                            style={{ position: "relative", top: 30 }}
                          />
                        </Box>

                        <Typography variant="h6">
                          <b className="text-capitalize">
                            {doctorsInfo[0].name}
                          </b>
                        </Typography>
                      </Box>

                      <Box>
                        <Typography variant="h6">
                          PRC No. {doctorsInfo[0].cil_umn}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6">PTR No.</Typography>
                      </Box>
                      {/* <Box>
                        <Typography variant="h6">TIN No.</Typography>
                      </Box> */}
                      <Box>
                        <Typography variant="h6">S2 No.</Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  Notify.noRecord()
                )
              ) : (
                Notify.loading()
              )}

              <div className="breakerpage" />
              {/*  force to next page on print  */}
            </>
          ) : (
            Notify.noRecord()
          )
        ) : (
          Notify.loading()
        )}

        <Box
          className="d-print-none"
          mt={3}
          display="flex"
          justifyContent="flex-end"
        >
          <Box mr={2}>
            <Button
              onClick={() => window.close()}
              startIcon={<Cancel />}
              size="large"
              variant="contained"
              color="default"
            >
              Close
            </Button>
          </Box>

          <Box>
            <Button
              onClick={() => window.print()}
              startIcon={<Print />}
              size="large"
              variant="contained"
              color="primary"
            >
              Print
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default DonascoRx;

// const groupRxByTwoForDocDonasco = (data) => {
//   return data.reduce((acc, val, ind) => {
//     if (ind < 2) {
//       acc.push([]);
//       acc[0].splice(1, 0, data[ind]);
//       acc[0].join();
//       return acc;
//     }

//     if (ind >= 2 && ind < 4) {
//       acc.push([]);
//       acc[1].splice(1, 0, data[ind]);
//       acc[1].join();
//       return acc;
//     }

//     if (ind >= 4 && ind < 6) {
//       acc.push([]);
//       acc[2].splice(1, 0, data[ind]);
//       acc[2].join();
//       return acc;
//     }

//     return acc;
//   }, []);
// };
