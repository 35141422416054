import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import Axios from "axios";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Paper, TextField } from "@material-ui/core";
import IsMountedRef from "src/utils/IsMountedRef";

const PersonalInfo = ({ patient_id, connection }) => {
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const ismounted = IsMountedRef();
  const [info, setInfo] = useState({ data: [], ready: false });

  const getPatientInformation = () => {
    const params = {
      token,
      user_id,
      patient_id,
      connection,
    };

    Axios.get("doctor/patient/patient-information", { params })
      .then((response) => {
        const data = response.data;
        if (ismounted.current) {
          setInfo({ data: data, ready: true });
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getPatientInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <>
      <Box component={Paper} elevation={2} p={2}>
        <Box mb={3}>
          <Typography variant="h6" color="textSecondary">
            {" "}
            Personal Information{" "}
          </Typography>
        </Box>

        {info.ready ? (
          info.data.length > 0 ? (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Box>
                  <TextField
                    label="Birth Place"
                    variant="outlined"
                    fullWidth
                    value={
                      info.data[0].birthplace === null
                        ? "None"
                        : info.data[0].birthplace
                    }
                    InputProps={{ readOnly: true }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Occupation"
                        variant="outlined"
                        fullWidth
                        value={
                          info.data[0].occupation === null
                            ? "None"
                            : info.data[0].occupation
                        }
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Blood Type"
                        variant="outlined"
                        fullWidth
                        value={
                          info.data[0].blood_type === null
                            ? "None"
                            : info.data[0].blood_type
                        }
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Civil Status"
                        variant="outlined"
                        fullWidth
                        value={
                          info.data[0].civil_status === null
                            ? "None"
                            : info.data[0].civil_status
                        }
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Religion"
                        variant="outlined"
                        fullWidth
                        value={
                          info.data[0].religion === null
                            ? "None"
                            : info.data[0].religion
                        }
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Mobile"
                        variant="outlined"
                        fullWidth
                        value={
                          info.data[0].mobile === null
                            ? "None"
                            : info.data[0].mobile
                        }
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        label="Telephone"
                        variant="outlined"
                        fullWidth
                        value={
                          info.data[0].telephone === null
                            ? "None"
                            : info.data[0].telephone
                        }
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box>
                  <TextField
                    label="Address"
                    variant="outlined"
                    fullWidth
                    value={`${
                      info.data[0].street === null ? "" : info.data[0].street
                    }, ${
                      info.data[0].barangay === null
                        ? ""
                        : info.data[0].barangay
                    }, ${info.data[0].city === null ? "" : info.data[0].city}`}
                    InputProps={{ readOnly: true }}
                  />
                </Box>
              </Grid>
            </Grid>
          ) : (
            Notify.noRecord()
          )
        ) : (
          Notify.loading()
        )}
      </Box>
    </>
  );
};

export default PersonalInfo;
