import React, { useState, useEffect, Fragment } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../utils/TabPanel";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import PrescriptionSidebar from "./PrescriptionSidebar";
import CheckingConnection from "src/CheckInternet";
import Axios from "axios";
import NoInternetConnection from "src/NoInternetConnection";

const Prescription = ({ patient_id }) => {
  const [category, setCategory] = useState(0);

  const [online, setOnline] = useState("checking");

  const handleChange = (event, newcategory) => {
    event.persist();
    setCategory(newcategory);
  };

  const checkinternet = () => {
    setOnline("checking");
    Axios.get("check/internet/connection-status", {
      params: {
        connection: "online",
      },
    })
      .then((response) => {
        const data = response.data;
        setOnline(data.message);
      })
      .catch((error) => {
        setOnline("disconnected");
        console.log("error request:", error.message);
      });
  };

  useEffect(() => {
    checkinternet();
  }, [patient_id]);

  return (
    <Fragment>
      <Grid container justify="flex-end">
        <Grid item xs={12} md={5} lg={4}>
          <Paper elevation={2}>
            <Tabs
              value={category}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="on"
              variant="scrollable"
            >
              <Tab wrapped label="Local Prescription" />
              <Tab wrapped label="Virtual Prescription" />
            </Tabs>
          </Paper>
        </Grid>
      </Grid>

      <Box mt={2}>
        <TabPanel value={category} index={0}>
          <PrescriptionSidebar
            patient_id={patient_id}
            prescType={"local-prescription"}
          />
        </TabPanel>

        <TabPanel value={category} index={1}>
          {online === "checking" && <CheckingConnection />}
          {online === "disconnected" && (
            <NoInternetConnection xheight={"calc(50vh - 120px)"} />
          )}
          {online === "connected" && (
            <PrescriptionSidebar
              patient_id={patient_id}
              prescType={"virtual-prescription"}
            />
          )}
        </TabPanel>
      </Box>
    </Fragment>
  );
};

export default Prescription;
