import {
  Avatar,
  Card,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import React, { Fragment } from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useHistory } from "react-router-dom";
import Container from "src/layout/Container";

const AppointmentDropdown = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "appointment",
          items: [{ name: "Dashboard", path: "/clinic/app/patient" }],
        }}
        title="Appointment"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Card className={classes.root}>
              <CardHeader
                avatar={
                  <Avatar aria-label="appointments" className={classes.avatar}>
                    MA
                  </Avatar>
                }
                action={
                  <IconButton
                    aria-label="appointmentsettings"
                    onClick={() => {
                      history.push(
                        "/clinic/app/patient/appointment/make-appointment"
                      );
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
                title="Make Appointment"
              />
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Card className={classes.root}>
              <CardHeader
                avatar={
                  <Avatar aria-label="appointments" className={classes.avatar}>
                    AL
                  </Avatar>
                }
                action={
                  <IconButton
                    aria-label="appointmentsettings"
                    onClick={() => {
                      history.push(
                        "/clinic/app/patient/appointment/appointment-list"
                      );
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
                title="Appointment List"
              />
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Card className={classes.root}>
              <CardHeader
                avatar={
                  <Avatar aria-label="appointments" className={classes.avatar}>
                    AN
                  </Avatar>
                }
                action={
                  <IconButton
                    aria-label="appointmentsettings"
                    onClick={() => {
                      history.push(
                        "/clinic/app/patient/appointment/appointment-notification"
                      );
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
                title="Appointment Notif"
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default AppointmentDropdown;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
  },
  avatar: {
    backgroundColor: blue[600],
    color: "#fff",
  },
}));
