import React, { useEffect, useState } from "react";
import { Box, Button } from "@material-ui/core";
import OnlineAppointment from "./OnlineAppointment";
import axios from "axios";
import Notify from "src/notification/Notify";
import Container from "src/layout/Container";
import NoInternetConnection from "src/NoInternetConnection";
import { ArrowBack } from "@material-ui/icons";
import { FileText } from "react-feather";

const VirtualAppointment = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [online, setOnline] = useState("checking");
  const [incomplete, setIncomplete] = useState([]);
  const [incompleteRd, setIncompleteRd] = useState(false);
  const [openReport, setOpenReport] = useState(false);

  const getOnlineAppointment = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("connection", "online");

    axios
      .post("encoder/appointment/online/incomplete-list", formdata)
      .then((response) => {
        const data = response.data;
        setIncomplete(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => setIncompleteRd(true));
  };

  const checkinternet = () => {
    setOnline("checking");
    axios
      .get("check/internet/connection-status", {
        params: {
          connection: "online",
        },
      })
      .then((response) => {
        const data = response.data;
        setOnline(data.message);
      })
      .catch((error) => {
        setOnline("disconnected");
        console.log("error request:", error.message);
      });
  };

  useEffect(() => {
    checkinternet();
    getOnlineAppointment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Virtual Appointment",
          items: [{ name: "Dashboard", path: "/clinic/app/encoder" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>Virtual Appointment</Box>
            <Box>
              <Button
                variant="contained"
                color={openReport ? "secondary" : "primary"}
                onClick={() => setOpenReport(!openReport)}
                startIcon={openReport ? <ArrowBack /> : <FileText />}
              >
                {openReport ? "Back" : "Report"}
              </Button>
            </Box>
          </Box>
        }
      >
        {online === "checking" && Notify.loading()}
        {online === "connected" && (
          <>
            <OnlineAppointment
              appointment={incomplete}
              appointmentRd={incompleteRd}
              getOnlineAppointment={getOnlineAppointment}
              openReport={openReport}
            />
          </>
        )}

        {online === "disconnected" && (
          <NoInternetConnection xheight={"calc(80vh - 120px)"} />
        )}
      </Container>
    </>
  );
};

export default VirtualAppointment;
