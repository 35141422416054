import { Paper, Box, CardMedia, Typography } from "@material-ui/core";
import React from "react";

const GTCInitialDisplay = () => {
  return (
    <Paper component={Box} p={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={`calc(40vh - 150px)`}
      >
        <Box width={200}>
          <CardMedia component="img" alt="wwq" src={"/gtc-logo.png"} />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h4">{"👈"}</Typography>
        <Typography variant="h4" color="primary" style={{ marginTop: 10 }}>
          <strong> SELECT PATIENT TO DISPLAY</strong>
        </Typography>
      </Box>
    </Paper>
  );
};

export default GTCInitialDisplay;
