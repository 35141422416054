import React from 'react'
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ScheduleIcon from '@material-ui/icons/Schedule';
import Notify from "src/notification/Notify";

const SnackbarNextAppointment = ({ nextAppointment, closeIndication, close }) => {
    return (
        <Snackbar
            open={nextAppointment !== null && !closeIndication}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <Alert
                onClose={close}
                severity="success"
                variant="filled"
                icon={<ScheduleIcon />}
            >
                Next Appointment On  {nextAppointment !== null ? Notify.dateTimeConvert(nextAppointment) : ''}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarNextAppointment;