import React, { Fragment } from 'react';  
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const PageNotFound  = ()  => { 
    const history = useHistory();
    return(
        <Fragment>
            <Modal backdropClassName="pagenotfound" size="sm" centered show={true} keyboard={false} backdrop="static">
                <Modal.Body>
                    <b> Page Not Found </b><br />
                    <small style={{
                        fontSize: '.7rem'
                    }}> Looks like  you've followed a broken link or entered a URL that doens't exist on this site</small> <br />
                    <small className="text-info pointer" onClick={() => history.push('/clinic/app')}> Back to our site </small> 
                </Modal.Body>
            </Modal>
        </Fragment>
    ); 
}

export default PageNotFound;