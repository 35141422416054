
import React, { Fragment, useEffect, useState } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Col, Row } from 'react-bootstrap';
import Box from '@material-ui/core/Box'
import { Badge, Grid, Paper, TextField } from '@material-ui/core';
import Label from 'src/utils/Label';
import Container from 'src/layout/Container';

const PersonalHistory = () => {
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id');
    const management_id = localStorage.getItem('management_id');
    const [history, setHistory] = useState([]);
    const [historyReady, setHistoryReady] = useState(false)

    const getPatientHistory = () => {
        var params = {
            token: token,
            user_id: user_id,
            management_id: management_id,
        }
        Axios.get('patient/information/personal-history', { params })
            .then((response) => {
                const data = response.data;
                setHistory(data);
                setHistoryReady(true);
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {
        getPatientHistory();
        //eslint-disable-next-line
    }, [])

    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'history',
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' },
                        { name: 'Profile', path: '/clinic/app/patient/profile' }
                    ]
                }}
                title="My History"
            >
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                        <Box
                            component={Paper}
                            variant="outlined"
                            p={2}
                        >
                            {
                                historyReady ?
                                    history.length > 0 ?
                                        history.map((data, index) => {
                                            return (
                                                <Row key={index} className="py-2 gtc-textfield-noborder">
                                                    <Col sm={12}>

                                                        <Box mb={2} display="flex" justifyContent="flex-start">
                                                            <Badge color="error" badgeContent={index + 1} > <Label color="primary"> visit count </Label> </Badge>
                                                        </Box>

                                                        <Row className="mb-3">
                                                            <Col sm={6}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Weight (kg)"
                                                                    variant="outlined"
                                                                    value={data.weight === null ? 'None' : data.weight}
                                                                    InputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                />
                                                            </Col>

                                                            <Col sm={6}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Height (ft)"
                                                                    variant="outlined"
                                                                    value={data.height === null ? 'None' : data.height}
                                                                    InputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={6}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Occupation"
                                                                    variant="outlined"
                                                                    value={data.occupation === null ? 'None' : data.occupation}
                                                                    InputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                />
                                                            </Col>

                                                            <Col sm={6}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Zip"
                                                                    variant="outlined"
                                                                    value={data.zip === null ? 'None' : data.zip}
                                                                    InputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-3">
                                                            <Col sm={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Addresss"
                                                                    variant="outlined"
                                                                    value={data.city === null ? 'None' : ` ${data.street}  ${data.barangay} ${data.city}`}
                                                                    InputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                        :
                                        Notify.noRecord()
                                    :
                                    Notify.loading()
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}

export default PersonalHistory;
