import React, { useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  Button,
  Box,
  CardHeader,
} from "@material-ui/core";
import LaboratoryReport from "./laboratory_report";
import Container from "src/layout/Container";
import { ArrowLeft, Package } from "react-feather";
import ServicesReport from "./services_report";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExchangeAlt,
  faPrescriptionBottleAlt,
  faVial,
  faXRay,
} from "@fortawesome/free-solid-svg-icons";
import ClinicPrescriptionReport from "./prescription_report";
import ImagingReport from "./imaging";

const Report = () => {
  const [displayDefault, setDisplayDefault] = useState("");
  const [showRefundLab, setShowRefundLab] = useState(0);
  const [showRefundImaging, setShowRefundImaging] = useState(0);

  return (
    <>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Report",
          items: [{ name: "Dashboard", path: "/clinic/app/doctor" }],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>Income Report</Box>

            <Box>
              {displayDefault === "laboratory" && (
                <Button
                  color={showRefundLab === 1 ? "secondary" : "primary"}
                  variant="contained"
                  startIcon={
                    showRefundLab === 1 ? (
                      <ArrowLeft />
                    ) : (
                      <FontAwesomeIcon icon={faExchangeAlt} />
                    )
                  }
                  onClick={() => {
                    let d = Boolean(parseInt(showRefundLab)) ? 0 : 1;
                    setShowRefundLab(d);
                  }}
                >
                  {showRefundLab === 1 ? "Back" : "Show Refund"}
                </Button>
              )}

              {displayDefault === "imaging" && (
                <Button
                  color={showRefundImaging === 1 ? "secondary" : "primary"}
                  variant="contained"
                  startIcon={
                    showRefundImaging === 1 ? (
                      <ArrowLeft />
                    ) : (
                      <FontAwesomeIcon icon={faExchangeAlt} />
                    )
                  }
                  onClick={() => {
                    let d = Boolean(parseInt(showRefundImaging)) ? 0 : 1;
                    setShowRefundImaging(d);
                  }}
                >
                  {showRefundImaging === 1 ? "Back" : "Show Refund"}
                </Button>
              )}
            </Box>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <CardHeader title="Menu" />
              <CardContent>
                <List>
                  <ListItem
                    button
                    onClick={() => {
                      setDisplayDefault("laboratory");
                    }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faVial} style={{ fontSize: 20 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Laboratory"}
                      primaryTypographyProps={{
                        style: {
                          paddingTop: 5,
                          paddingLeft: 5,
                        },
                      }}
                    />
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => {
                      //   setOpen(false);
                      setDisplayDefault("imaging");
                    }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faXRay} style={{ fontSize: 20 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Imaging"}
                      primaryTypographyProps={{
                        style: {
                          paddingTop: 5,
                          paddingLeft: 5,
                        },
                      }}
                    />
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => setDisplayDefault("prescription")}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        icon={faPrescriptionBottleAlt}
                        style={{ fontSize: 20 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Prescription"}
                      primaryTypographyProps={{
                        style: {
                          paddingTop: 5,
                          paddingLeft: 5,
                        },
                      }}
                    />
                  </ListItem>

                  {/* <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() => setDisplayDefault("prescription")}
                      >
                        <ListItemIcon>
                          <WifiOffIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Local"} />
                      </ListItem>

                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() =>
                          setDisplayDefault("prescription-virtual")
                        }
                      >
                        <ListItemIcon>
                          <WifiIcon />
                        </ListItemIcon>
                        <ListItemText variant="primary" primary={"Virtual"} />
                      </ListItem>
                    </List>
                  </Collapse> */}

                  <ListItem
                    button
                    onClick={() => {
                      //   setOpen(false);
                      setDisplayDefault("services");
                    }}
                  >
                    <ListItemIcon>
                      <Package />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Services"}
                      primaryTypographyProps={{
                        style: {
                          paddingTop: 5,
                          paddingLeft: 5,
                        },
                      }}
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            {displayDefault === "laboratory" && (
              <LaboratoryReport showRefundLab={showRefundLab} />
            )}

            {displayDefault === "imaging" && (
              <ImagingReport showRefundImaging={showRefundImaging} />
            )}

            {displayDefault === "prescription" && <ClinicPrescriptionReport />}

            {/* {displayDefault === "prescription-local" && (
              <LocalPrescriptionReport />
            )}
            {displayDefault === "prescription-virtual" && (
              <VirtualPrescriptionReport />
            )} */}
            {displayDefault === "services" && <ServicesReport />}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Report;
