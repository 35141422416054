import React, { Fragment, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "../telerad/Dashboard";
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound";
import Drawer from "src/telerad/messages/Drawer";
import axios from "axios";
import GTCValidateLS from "src/notification/GTCValidateLS";
import { Database, Home, Lock, Users } from "react-feather";
import Sidebar from "src/layout/Sidebar";
import ReviewedPatient from "src/telerad/ReviewedPatient";
import TeleradAccount from "src/telerad/accounts";
import TeleradPatients from "src/telerad/patients";
import { Badge } from "@material-ui/core";

const RouteTelerad = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [open, setOpen] = useState(false);

  const [sidebarHeader, setSidebarHeader] = useState([]);

  const [patientForReview, setPatientsForReview] = useState([]);

  var interval = null;

  const getSidebarHeaderInfo = async () => {
    try {
      const params = {
        token,
        user_id,
      };
      const response = await axios.get("telerad/sidebar/header-infomartion", {
        params,
      });
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("unable to get sdiebar header info.", error);
    }
  };

  const getPatientsReview = async () => {
    try {
      const params = {
        token,
        user_id,
      };
      const request = await axios.get("telerad/patients/getpatient-toview", {
        params,
      });
      setPatientsForReview(request.data);
    } catch (error) {
      console.log("unabble to get new patient for review telerad");
    }
  };

  const initializeInterval = () => {
    setInterval(() => {
      getPatientsReview();
    }, 10000);
  };

  React.useEffect(() => {
    getSidebarHeaderInfo();
    initializeInterval();

    GTCValidateLS.verifyLocalStorage();

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidbarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <Home color="white" />,
      path: "/clinic/app/telerad",
    },
    {
      name: "Patients",
      show: true,
      icon: (
        <Badge color="error" badgeContent={patientForReview.length}>
          <Users color="white" />
        </Badge>
      ),
      path: "/clinic/app/telerad/patients/all",
    },
    {
      name: "Account",
      show: true,
      icon: <Lock color="white" />,
      path: "/clinic/app/telerad/account",
    },
    {
      name: "Report",
      show: true,
      icon: <Database color="white" />,
      path: "/clinic/app/telerad/report",
    },
  ];

  return (
    <Fragment>
      <BrowserRouter>
        <Sidebar
          notification={{
            enable: true,
            local: [],
            virtual: patientForReview,
            owner: "telerad",
          }}
          header={sidebarHeader}
          routes={sidbarRoute}
          module={"telerad"}
        />

        <Switch>
          <Route
            exact
            path="/"
            component={() => <Redirect to="/clinic/app/telerad" />}
          />
          <Route
            exact
            path="/clinic"
            component={() => <Redirect to="/clinic/app/telerad" />}
          />
          <Route
            exact
            path="/clinic/app"
            component={() => <Redirect to="/clinic/app/telerad" />}
          />
          <Route exact path="/clinic/app/telerad" component={Dashboard} />
          <Route
            exact
            path="/clinic/app/telerad/patients/:imagingorder_id"
            component={TeleradPatients}
          />
          <Route
            exact
            path="/clinic/app/telerad/account"
            component={TeleradAccount}
          />
          <Route
            exact
            path="/clinic/app/telerad/report"
            component={ReviewedPatient}
          />
          <Route exact path="/clinic/app/logout" component={Logout} />
          <Route render={() => <PageNotFound />} />
        </Switch>
      </BrowserRouter>

      {/* messages drawer */}
      {open && <Drawer open={open} close={() => setOpen(false)} />}
    </Fragment>
  );
};

export default RouteTelerad;
