import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Divider, Grid, IconButton, Paper, TextField, Typography, Backdrop, CircularProgress, Dialog, DialogTitle, DialogContent, ButtonGroup, makeStyles, Tooltip } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/CancelOutlined'
import CheckIcon from '@material-ui/icons/CheckCircle'
import AdditionalItem from './AdditionalItem';
import Select from 'react-select/';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import Location from 'src/patients/location/Location';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        color: '#fff',
    },
}));

const ProcessOrder = ({ selectedOrder, close }) => {
    const classes = useStyles();
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')

    const [editRx, setEditRx] = useState(false)
    const [editAddOn, setEditAddOn] = useState(false)

    const [vpharmacy, setVPharmacy] = useState([])

    const [isremoveId, setIsRemoveId] = useState(null);
    const [isupdate, setIsUpdate] = useState(false);
    const [items, setItems] = useState({
        ready: false,
        data: []
    })

    const [itemsAddon, setItemsAddon] = useState({
        ready: false,
        data: []
    })

    const [selectedPharm, setSelectedPharm] = useState(null);
    const [selectedPharmName, setSelectedPharmName] = useState(null);
    const [openSendToDialog, setOpenSendToDialog] = useState(false)

    const [issenttoProcess, setIsSenttoProcess] = useState(false)
    const [locationReady, setLocationReady] = useState(false)

    const getCurrentLocation = useCallback(async () => {
        try {
            var params = {
                token: token,
                user_id: user_id,
            }
            const request = await Axios.get('patient/information/location/getpatient-location', { params })
            if (request.data.length > 0) { setLocationReady(true) }
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, user_id])

    const getVPharmacy = useCallback(async () => {
        try {
            var params = {
                token: token,
                user_id: user_id,
            }
            const request = await Axios.get('patient/cart/order/getvpharmacay-list', { params })
            setVPharmacy(request.data)
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, user_id])

    const getOrderRxItems = useCallback(async () => {
        try {
            var params = {
                token: token,
                user_id: user_id,
                order_no: selectedOrder.order_no,
                is_rx: 1
            }
            const request = await Axios.get('patient/cart/order/getitem-ordernumber', { params })
            setItems({ ready: true, data: request.data })
        } catch (error) {
            Notify.requestError(error)
        }
    }, [selectedOrder, token, user_id])


    const getOrderAddOnItems = useCallback(async () => {
        try {
            var params = {
                token: token,
                user_id: user_id,
                order_no: selectedOrder.order_no,
                is_rx: 0,
            }
            const request = await Axios.get('patient/cart/order/getitem-ordernumber', { params })
            setItemsAddon({ ready: true, data: request.data })
        } catch (error) {
            Notify.requestError(error)
        }
    }, [selectedOrder, token, user_id])

    const handleRemoveAddOnItem = async (removeData) => {
        try {
            var formdata = new FormData()
            formdata.append('token', token)
            formdata.append('user_id', user_id)
            formdata.append('product_id', removeData.product_id)
            formdata.append('id', removeData.id)
            setIsRemoveId(removeData.id)
            const request = await Axios.post('patient/cart/order/remove-addonitem', formdata)
            if (request.data === 'success') {
                getOrderAddOnItems()
                setIsRemoveId(null)
            }
        } catch (error) {
            Notify.requestError(error)
        }
    }

    const handleUpdateCartQry = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target);
        formdata.append('token', token)
        formdata.append('user_id', user_id)
        formdata.append('username', username)
        formdata.append('order_no', selectedOrder.order_no)
        setIsUpdate(true)
        Axios.post('patient/cart/order/update-cartQty', formdata)
            .then((response) => {
                const data = response.data;
                if (data === 'success') {
                    getOrderRxItems()
                    getOrderAddOnItems()
                    Notify.successRequest('cart updated')
                }
                if (data === 'pass-invalid') {
                    Notify.fieldInvalid('password')
                }
            }).catch((error) => {
                Notify.requestError(error)
            }).finally(() => {
                setIsUpdate(false)
                setEditAddOn(false)
                setEditRx(false)
            })
    }

    const handleSendToWithMethod = (method) => {

        var formdata = new FormData();
        formdata.append('token', token)
        formdata.append('user_id', user_id)
        formdata.append('username', username)
        formdata.append('vpharm_id', selectedPharm)
        formdata.append('order_no', selectedOrder.order_no)
        formdata.append('delivery', method === 'delivery' ? 1 : 0)
        setIsSenttoProcess(true)
        Axios.post('patient/cart/order/sentto-vpharmacy', formdata)
            .then((response) => {
                const data = response.data;
                if (data === 'success') {
                    close()
                    setOpenSendToDialog(false)
                    Notify.successRequest(`order sent to ${selectedPharm}`)
                }
            }).catch((error) => {
                Notify.requestError(error)
            }).finally(() => {
                setIsSenttoProcess(false)
            })
    }

    useEffect(() => {

        getOrderRxItems()
        getOrderAddOnItems()
        getVPharmacy()
        getCurrentLocation()

    }, [getOrderRxItems, getOrderAddOnItems, getVPharmacy, getCurrentLocation])

    return (
        <Grid
            container
            spacing={2}
            justify="flex-start"
        >
            <Box
                clone
                order={{ xs: 2, sm: 2, md: 1 }}
            >
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={8}
                >
                    <Paper
                        variant="outlined"
                        component={Box}
                        p={2}
                    >
                        <Box
                            display="flex"
                        >
                            <Box>
                                <IconButton
                                    onClick={close}
                                >
                                    <KeyboardBackspaceIcon />
                                </IconButton>
                            </Box>
                            <Box
                                pt={2}
                            >
                                <Typography
                                    variant="subtitle2"
                                    className={'gtc-uppercase'}
                                >
                                    Process Order
                                </Typography>
                            </Box>
                        </Box>

                        {/* select pharmacy */}
                        <Box mb={2}>
                            <Box mb={1}>
                                <Typography
                                    color="primary"
                                    className={`gtc-uppercase`}
                                    variant={'subtitle2'}
                                > Pharmacy </Typography>
                            </Box>

                            <Select
                                options={vpharmacy}
                                onChange={(e) => {
                                    setSelectedPharm(e.value);
                                    setSelectedPharmName(e.label);
                                }}
                            />
                        </Box>

                        <Box mb={2}> {/* Rx ITEM */}
                            <Box display="flex" mb={2}>
                                <Box flexGrow={1}>
                                    <Typography
                                        color="primary"
                                        className={`gtc-uppercase`}
                                        variant={'subtitle2'}
                                    > Rx Item </Typography>
                                </Box>
                                <Box>
                                    <Button
                                        hidden={editRx}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<EditIcon />}
                                        onClick={() => setEditRx(!editRx)}
                                    >
                                        Edit
                                    </Button>
                                </Box>
                            </Box>
                            <form onSubmit={handleUpdateCartQry}>
                                {
                                    items.ready ?
                                        items.data.length > 0 ?
                                            items.data.map((item, index) => {
                                                return (
                                                    <Box
                                                        mb={2}
                                                        key={index}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={8}
                                                                className={`gtc-textfield-noborder`}
                                                            >
                                                                {/* hidden id */}
                                                                <TextField
                                                                    margin="dense"
                                                                    hidden
                                                                    InputProps={{ readOnly: true }}
                                                                    fullWidth
                                                                    name="id[]"
                                                                    defaultValue={item.id}
                                                                    label="Id"
                                                                    variant="outlined"
                                                                />
                                                                <TextField
                                                                    margin="dense"
                                                                    InputProps={{ readOnly: true }}
                                                                    fullWidth
                                                                    defaultValue={item.product}
                                                                    label="Item"
                                                                    multiline
                                                                    variant="outlined"
                                                                />
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={4}
                                                            >
                                                                <TextField
                                                                    required
                                                                    margin="dense"
                                                                    fullWidth
                                                                    name="quantity[]"
                                                                    defaultValue={item.quantity}
                                                                    label="Qty"
                                                                    variant="outlined"
                                                                    type="number"
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            readOnly: !editRx,
                                                                            min: 1,
                                                                            max: item.original_qty,
                                                                            step: 1
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                )
                                            })
                                            : Notify.noRecord()
                                        : Notify.loading()
                                }
                                <Box hidden={!editRx} >

                                    <Box mb={2}>
                                        <TextField
                                            readOnly
                                            hidden
                                            name="is_rx"
                                            label="is Rx"
                                            margin="dense"
                                            value={1}
                                            fullWidth
                                            required
                                        />
                                        <TextField
                                            variant="outlined"
                                            name="password"
                                            type="password"
                                            label="Password"
                                            margin="dense"
                                            fullWidth
                                            required
                                        />
                                    </Box>

                                    <Box mt={2} display="flex">
                                        <Box flexGrow={1} />
                                        <Box mr={2}>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                startIcon={<CancelIcon />}
                                                onClick={() => setEditRx(false)}
                                            > Close </Button>
                                        </Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            startIcon={isupdate ? <CircularProgress color="inherit" size={15} /> : <CheckIcon />}
                                            disabled={isupdate}
                                        > Save </Button>
                                    </Box>
                                </Box>
                            </form>
                        </Box>

                        <Divider />

                        <Box mb={2}> {/* ADDITIONAL ITEM */}
                            <Box display="flex" my={2}>
                                <Box flexGrow={1}>
                                    <Typography
                                        color="primary"
                                        className={`gtc-uppercase`}
                                        variant={'subtitle2'}
                                    >
                                        ADDITIONAL ITEM
                                    </Typography>
                                </Box>
                                <Box>
                                    {
                                        itemsAddon.data.length > 0 && (
                                            <Button
                                                hidden={editAddOn}
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                startIcon={<EditIcon />}
                                                onClick={() => setEditAddOn(!editAddOn)}
                                            >
                                                Edit
                                            </Button>
                                        )
                                    }
                                </Box>
                            </Box>

                            {/* addon form */}
                            <form onSubmit={handleUpdateCartQry}>
                                {
                                    itemsAddon.ready ?
                                        itemsAddon.data.length > 0 ?
                                            itemsAddon.data.map((data, index) => (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    key={index}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={8}
                                                        className={`gtc-textfield-noborder`}
                                                    >
                                                        {/* hidden id */}
                                                        <TextField
                                                            hidden
                                                            InputProps={{ readOnly: true }}
                                                            fullWidth
                                                            name="id[]"
                                                            defaultValue={data.id}
                                                            label="Id"
                                                            variant="outlined"
                                                            margin="dense"
                                                        />

                                                        <TextField
                                                            InputProps={{ readOnly: true }}
                                                            fullWidth
                                                            defaultValue={data.product}
                                                            label="Item"
                                                            variant="outlined"
                                                            multiline
                                                            margin="dense"
                                                        />
                                                    </Grid>

                                                    {/* <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                        lg={3}
                                                    >
                                                        <TextField 
                                                            className={`gtc-textfield-noborder`}
                                                            InputProps={{ readOnly: true }}
                                                            fullWidth 
                                                            defaultValue={data.price}
                                                            label="Price"
                                                            variant="outlined" 
                                                            margin="dense"
                                                        />   
                                                    </Grid>  */}

                                                    <Grid
                                                        item
                                                        sm={3}
                                                        md={2}
                                                        xs={6}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            defaultValue={data.quantity}
                                                            label="Quantity"
                                                            variant="outlined"
                                                            name="quantity[]"
                                                            type="number"
                                                            margin="dense"
                                                            InputProps={{
                                                                inputProps: {
                                                                    readOnly: !editAddOn,
                                                                    min: 1,
                                                                    max: 1000,
                                                                    step: 1
                                                                }
                                                            }}
                                                        />
                                                    </Grid>

                                                    {/* <Grid
                                                        item 
                                                        sm={6}
                                                        md={4}
                                                        lg={2}
                                                        xs={6}
                                                    >
                                                        <TextField  
                                                            className={`gtc-textfield-noborder`}
                                                            InputProps={{ readOnly: true }}
                                                            fullWidth 
                                                            value={parseFloat(data.price) * parseInt(data.quantity)} 
                                                            variant="outlined"   
                                                            margin="dense" 
                                                            label="Total"
                                                        />   
                                                    </Grid>  */}

                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={3}
                                                        md={2}
                                                    >
                                                        <Box align="center">
                                                            <IconButton
                                                                onClick={() => handleRemoveAddOnItem(data)}
                                                                color="secondary"
                                                                disabled={isremoveId === data.id}
                                                            >
                                                                {isremoveId === data.id ? <CircularProgress size={20} /> : <DeleteIcon fontSize="large" />}
                                                            </IconButton>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ))
                                            : <Box>
                                                <Typography color="textSecondary" variant="subtitle2">
                                                    No additional item
                                                </Typography>
                                            </Box>
                                        : Notify.loading()
                                }

                                <Box hidden={!editAddOn} >

                                    <Box mb={2}>
                                        <TextField
                                            readOnly
                                            hidden
                                            name="is_rx"
                                            label="is Rx"
                                            margin="dense"
                                            value={0}
                                            fullWidth
                                            required
                                        />
                                        <TextField
                                            variant="outlined"
                                            name="password"
                                            type="password"
                                            label="Password"
                                            margin="dense"
                                            fullWidth
                                            required
                                        />
                                    </Box>

                                    <Box mt={2} display="flex">
                                        <Box flexGrow={1} />
                                        <Box mr={2}>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                startIcon={<CancelIcon />}
                                                onClick={() => setEditAddOn(false)}
                                            > Close </Button>
                                        </Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            startIcon={isupdate ? <CircularProgress color="inherit" size={15} /> : <CheckIcon />}
                                            disabled={isupdate}
                                        > Save </Button>
                                    </Box>
                                </Box>
                            </form>
                        </Box>

                        <Divider />

                        {
                            selectedPharm && (
                                <Box mt={2} display="flex" justifyContent="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={() => setOpenSendToDialog(true)}
                                    >
                                        SEND ORDER TO {selectedPharmName}
                                    </Button>
                                </Box>
                            )
                        }
                    </Paper>
                </Grid>
            </Box>
            <Box
                clone
                order={{ xs: 1, sm: 1, md: 2 }}
            >
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}

                >
                    {/* your location */}

                    <Paper
                        component={Box}
                        p={2}
                        mb={3}
                    >
                        <Box mb={2}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                            >
                                YOUR CURRENT LOCATION
                            </Typography>
                        </Box>

                        <Box
                            height={150}
                        >
                            <Location mapHeight={150} />
                        </Box>
                    </Paper>

                    <Paper
                        component={Box}
                        p={2}
                    >
                        <Box mb={2}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                            >
                                ITEMS FOR ADD ON
                            </Typography>
                        </Box>

                        {selectedPharm === null && (<Typography color="secondary" align="center"> Select pharmacy to view products.</Typography>)}
                        {selectedPharm && <AdditionalItem getOrderAddOnItems={getOrderAddOnItems} vpharmacy={selectedPharm} orderno={selectedOrder.order_no} />}
                    </Paper>
                </Grid>
            </Box>

            {/* dialog sendto  */}
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={openSendToDialog}
                onClose={() => setOpenSendToDialog(false)}
            >
                <DialogTitle disableTypography>
                    <Box display="flex">
                        <Box flexGrow={1} mt={1}>
                            <Typography
                                variant="subtitle2"
                            >
                                TRANSACTION METHOD
                            </Typography>
                        </Box>
                        <IconButton
                            size="small"
                            color="secondary"
                            onClick={() => setOpenSendToDialog(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent dividers>
                    <Box>
                        <ButtonGroup
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            <Button
                                startIcon={<ThumbsUpDownIcon />}
                                onClick={() => handleSendToWithMethod('pickup')}
                            >
                                PICKUP
                            </Button>

                            <Tooltip open={!locationReady} title="Set location to enable this method" placement="top" arrow>
                                <Button
                                    startIcon={<LocalShippingIcon />}
                                    onClick={() => handleSendToWithMethod('delivery')}
                                    disabled={!locationReady}
                                >
                                    DELIVERY
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Box>
                </DialogContent>
            </Dialog>

            {/* sendto loading backdrop */}
            <Backdrop className={classes.backdrop} open={issenttoProcess}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}

export default ProcessOrder;

