import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import NoInternet from "./NoInternet";

const NoInternetConnection = ({ xheight }) => {
  return (
    <>
      <Box
        height={xheight}
        display="flex"
        justifyContent="center"
        justifyItems="center"
        justifySelf="center"
        alignContent="center"
        alignItems="center"
        alignSelf="center"
      >
        <Paper>
          <Box p={2}>
            <Typography color="secondary">INTERNET CONNECTION ERROR</Typography>

            <Typography color="secondary">
              Unable to establish virtual connection due to internet error. Try
              again later.
            </Typography>
          </Box>
        </Paper>
      </Box>
      <NoInternet />
    </>
  );
};

export default NoInternetConnection;
