import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Collapse,
  TableFooter,
  IconButton,
  Box,
  Card,
  CardHeader,
  CardContent,
  TablePagination,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import React, { useEffect, useState, Fragment } from "react";
import Container from "src/layout/Container";
import Axios from "axios";
import IsMountedRef from "src/utils/IsMountedRef";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Notify from "src/notification/Notify";
import { useParams } from "react-router-dom";
import Search from "@material-ui/icons/Search";

const DrProductByAccount = () => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const mounted = IsMountedRef();
  const [drProducts, setDRProducts] = useState({ data: [], ready: false });
  const [drProductsDetails, setDRProductsDetails] = useState({
    data: [],
    ready: false,
  });
  const [selectedProduct, setSelectedAccount] = useState({});
  const { account_id } = useParams();
  let totalDrAmount = 0;

  const [query, setQuery] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const getDRProductsByDrNumber = React.useCallback(async () => {
    let response = await Axios.get(
      "pharmacy/warehouse/account/dr-productbyaccount",
      {
        params: {
          management_id: management_id,
          token: token,
          account_id: account_id,
        },
      }
    );

    let result = response.data;
    if (mounted.current) {
      setDRProducts({ data: result, ready: true });
    }
  }, [management_id, token, mounted, account_id]);

  const getDRNumberProductDetails = async (data) => {
    if (selectedProduct.id === data.id) {
      console.log("selected item duplicated");
      return;
    }

    let response = await Axios.get(
      "pharmacy/warehouse/delivered/drnumber-details",
      {
        params: {
          management_id: management_id,
          token: token,
          dr_number: data.dr_number,
        },
      }
    );

    let result = response.data;
    if (mounted.current) {
      setDRProductsDetails({ data: result, ready: true });
    }
  };

  const calculateTotal = (total) => {
    totalDrAmount += parseFloat(total);
    return total;
  };

  useEffect(() => {
    getDRProductsByDrNumber();
  }, [getDRProductsByDrNumber]);

  const handleSearch = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const filteredProducts = searchDrProduct(drProducts, query);
  const paginatedProducts = applyPagination(filteredProducts, page, limit);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "delivery",
        items: [
          { name: "Dashboard", path: "/clinic/app" },
          { name: "Accounts", path: "/clinic/app/warehouse/accounts" },
        ],
      }}
      title={` Accounts Delivery `}
    >
      <Card>
        <Box display="flex">
          <Box flexGrow={1}>
            <CardHeader
              title={
                drProducts.data.length > 0
                  ? drProducts.data[0].dr_accountname
                  : ""
              }
              subheader={
                drProducts.data.length > 0
                  ? drProducts.data[0].dr_accountaddress
                  : ""
              }
            />
          </Box>

          <Box p={2}>
            <TextField
              fullWidth
              label="Search DR Number"
              variant="outlined"
              onChange={handleSearch}
              value={query}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Search color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell> Date </TableCell>
                  <TableCell> DR Number </TableCell>
                  <TableCell> Product </TableCell>
                  <TableCell> Generic </TableCell>
                  <TableCell> Unit </TableCell>
                  <TableCell> Total </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {drProducts.ready ? (
                  paginatedProducts.length > 0 ? (
                    paginatedProducts.map((data, index) => (
                      <Fragment key={index}>
                        <TableRow>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                setSelectedAccount(data);
                                getDRNumberProductDetails(data);
                              }}
                              color={"primary"}
                            >
                              {selectedProduct.id === data.id ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            {Notify.createdAt(data.dr_date)}
                          </TableCell>
                          <TableCell> {data.dr_number} </TableCell>
                          <TableCell> {data.product_name} </TableCell>
                          <TableCell> {data.product_generic} </TableCell>
                          <TableCell> {data.unit} </TableCell>
                          <TableCell align="right">
                            {Notify.convertToNumber(
                              calculateTotal(data.dr_totalamount)
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={9} style={{ border: 0 }}>
                            <Collapse
                              in={selectedProduct.id === data.id}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  component={Box}
                                  flexGrow={1}
                                  color="textSecondary"
                                >
                                  Batches List
                                </Typography>

                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell> Product </TableCell>
                                      <TableCell> Unit </TableCell>
                                      <TableCell> Mfg Date </TableCell>
                                      <TableCell> Exp. Date </TableCell>
                                      <TableCell> Batch Number </TableCell>
                                      <TableCell> Qty </TableCell>
                                      <TableCell> Srp </TableCell>
                                      <TableCell> Amount </TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {drProductsDetails.ready ? (
                                      drProductsDetails.data.length > 0 ? (
                                        drProductsDetails.data.map(
                                          (xdata, xindex) => (
                                            <TableRow key={xindex}>
                                              <TableCell>
                                                {xdata.product_name}
                                              </TableCell>
                                              <TableCell>
                                                {xdata.unit}
                                              </TableCell>
                                              <TableCell>
                                                {xdata.expiration_date}
                                              </TableCell>
                                              <TableCell>
                                                {xdata.manufactured_date}
                                              </TableCell>
                                              <TableCell>
                                                {xdata.dr_number}
                                              </TableCell>
                                              <TableCell align="center">
                                                {xdata.qty}
                                              </TableCell>
                                              <TableCell align="center">
                                                {xdata.srp}
                                              </TableCell>
                                              <TableCell align="center">
                                                {xdata.srp * xdata.qty}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )
                                      ) : (
                                        <TableRow>
                                          <TableCell colSpan={8}>
                                            <Typography color="secondary">
                                              No record found.
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    ) : (
                                      <TableRow>
                                        <TableCell colSpan={8}>
                                          <Typography color="primary">
                                            please wait...
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Typography color="primary">
                        loading, please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TableCell colSpan={5} />
                  <TableCell align="center"> Total DR Amount: </TableCell>
                  <TableCell align="right">
                    {Notify.convertToNumber(totalDrAmount)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>

            <TablePagination
              component={"div"}
              count={filteredProducts.length}
              labelRowsPerPage="List"
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 50, 100]}
            />
          </TableContainer>
        </CardContent>
      </Card>
    </Container>
  );
};

export default DrProductByAccount;

const searchDrProduct = (delivery, query) => {
  return delivery.data.filter((xdata) => {
    let matches = true;

    if (query) {
      let containsQuery = false;

      if (xdata.dr_number.toLowerCase().includes(query.toLowerCase())) {
        containsQuery = true;
      }

      if (!containsQuery) {
        matches = false;
      }
    }
    return matches;
  });
};

const applyPagination = (clinic, page, limit) => {
  return clinic.slice(page * limit, page * limit + limit);
};
