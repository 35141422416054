import React, { Fragment, useEffect, useState } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { TableContainer, Table, TableRow, TableCell, TableBody, TableHead, Box, TablePagination, Paper } from '@material-ui/core';


const Others = () => {
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('token')
    const management_id = localStorage.getItem('management_id')
    const [others, setOthers] = useState([])
    const [othersRdy, setOthersRdy] = useState(false)
    const [totalOthers, setTotalOthers] = useState(0)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const getOthers = () => {
        var params = {
            token: token,
            user_id: user_id,
            management_id: management_id,
        }
        Axios.get('patient/credits/credits-others', { params })
            .then((response) => {
                const data = response.data;
                setOthers(data);
                setOthersRdy(true);
                totalLoadout(data);
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const totalLoadout = (data) => {
        var total = 0;
        for (let index = 0; index < data.length; index++) {
            total += parseFloat(data[index].unpaid_consultation);
        }
        setTotalOthers(total)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        getOthers()
        //eslint-disable-next-line
    }, [])

    return (
        <>
            {othersRdy && (
                <Fragment>  
                    <Box component={Paper} variant="outlined">
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"> Date </TableCell>
                                        <TableCell align="center"> Order # </TableCell>
                                        <TableCell align="center"> Payment </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? others.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : others
                                    ).map((data, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="right">{Notify.createdAt(data.created_at)}</TableCell>
                                            <TableCell align="right">{data.order_no}</TableCell>
                                            <TableCell align="right">{data.unpaid_consultation} </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                                <TableRow>
                                    <TableCell />
                                    <TableCell align="center"> Total </TableCell>
                                    <TableCell align="right"> 
                                        {Notify.convertToNumber(totalOthers)}
                                    </TableCell>
                                </TableRow>
                            </Table>

                            <TablePagination
                                component={"div"}
                                rowsPerPageOptions={[5, 10, 25]}
                                count={others.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </Box>
                </Fragment>
            )}
        </>
    )
}

export default Others;