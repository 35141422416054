import { Box, Tabs, Tab, Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import PatientDetailsHeader from "./PatientDetailsHeader";
import TabPanel from "src/utils/TabPanel";
import PatientDetailsAppointment from "./PatientDetailsAppointment";
import PatientDetailsProfile from "./PatientDetailsProfile";
import { Calendar, Lock, User } from "react-feather";
import PatientDetailsSentAccount from "./PatientDetailsSentAccount";

const PatientDetails = ({ patient_id, getDoctorsPatient }) => {
  const [category, setCategory] = useState("appointment");

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  useEffect(() => {}, [patient_id]);

  return (
    <>
      <Box mb={2}>
        <PatientDetailsHeader
          getDoctorsPatient={() => getDoctorsPatient()}
          patient_id={patient_id}
        />
      </Box>

      <Paper>
        <Tabs
          value={category}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab
            wrapped
            value="appointment"
            label="Appointment"
            icon={<Calendar size={22} />}
          />

          <Tab
            wrapped
            value="profile"
            label="Profile"
            icon={<User size={22} />}
          />
          <Tab
            wrapped
            value="send-account"
            label="Send Account"
            icon={<Lock size={22} />}
          />
        </Tabs>
      </Paper>

      <TabPanel value={category} index="appointment">
        <PatientDetailsAppointment patient_id={patient_id} />
      </TabPanel>

      <TabPanel value={category} index="profile">
        <PatientDetailsProfile patient_id={patient_id} />
      </TabPanel>

      <TabPanel value={category} index="send-account">
        <PatientDetailsSentAccount patient_id={patient_id} />
      </TabPanel>
    </>
  );
};

export default PatientDetails;
