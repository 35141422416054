import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  IconButton,
  Card,
  CardMedia,
  Grid,
  Button,
} from "@material-ui/core";
import React from "react";
import { XCircle } from "react-feather";
import Notify from "src/notification/Notify";
import ZoomableImage from "src/utils/ZoomableImage";

const imageLocationOnline = process.env.REACT_APP_API_IMAGE_VIRTUAL;

const ReviewedPatientMoreDetails = ({ open, close, imagingorder }) => {
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [imageToEnlarge, setImageToEnlarge] = React.useState(null);

  return (
    <Dialog
      open={open}
      onClose={close}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
    >
      <Box display="flex">
        <Box flexGrow={1}>
          <DialogTitle>Imaging Order Information</DialogTitle>
        </Box>

        <Box mr={2} mt={1}>
          <IconButton color="secondary" onClick={() => close()}>
            <XCircle />
          </IconButton>
        </Box>
      </Box>

      <DialogContent dividers className="gtc-textfield-noborder">
        <Box mb={2}>
          <TextField
            label="Patient"
            InputProps={{ readOnly: true }}
            fullWidth
            variant="outlined"
            value={
              imagingorder.patient_name === null
                ? "None"
                : imagingorder.patient_name
            }
          />
        </Box>

        <Box mb={2}>
          <TextField
            label="Order"
            InputProps={{ readOnly: true }}
            fullWidth
            variant="outlined"
            value={
              imagingorder.imaging_order === null
                ? "None"
                : imagingorder.imaging_order
            }
            multiline
          />
        </Box>

        <Box mb={2}>
          <TextField
            label="Result"
            InputProps={{ readOnly: true }}
            fullWidth
            variant="outlined"
            value={
              imagingorder.imaging_result === null
                ? "None"
                : imagingorder.imaging_result
            }
            multiline
          />
        </Box>

        <Box mb={2}>
          <TextField
            label="Hospital"
            InputProps={{ readOnly: true }}
            fullWidth
            variant="outlined"
            value={
              imagingorder.hospital_name === null
                ? "None"
                : imagingorder.hospital_name
            }
            multiline
          />
        </Box>

        <Box mb={2}>
          <TextField
            label="Date"
            InputProps={{ readOnly: true }}
            fullWidth
            variant="outlined"
            value={
              imagingorder.created_at === null
                ? "None"
                : Notify.dateTimeConvert(imagingorder.created_at)
            }
            multiline
          />
        </Box>

        <Box mb={2}>
          <Typography variant="caption">ATTACHMENT</Typography>

          <Box mt={2}>
            <Grid container spacing={2}>
              {imagingorder.imaging_result_attachment === null ? (
                <Typography variant="subtitle"> None </Typography>
              ) : (
                imagingorder.imaging_result_attachment
                  .split(",")
                  .map((data, index) => {
                    return (
                      <Grid item xs={4} key={index}>
                        <Card variant="outlined">
                          <CardMedia
                            component={"img"}
                            src={`${imageLocationOnline}imaging/${data}`}
                            style={{ maxHeight: 120 }}
                            alt=""
                          />
                          <Box>
                            <Button
                              onClick={() => {
                                setOpenLightBox(true);
                                setImageToEnlarge(data);
                              }}
                              fullWidth
                              color="primary"
                            >
                              view
                            </Button>
                          </Box>
                        </Card>
                      </Grid>
                    );
                  })
              )}
            </Grid>

            <ZoomableImage
              open={openLightbox}
              close={() => setOpenLightBox(false)}
              title={`Imaging Attachment`}
              image={`${imageLocationOnline}imaging/${imageToEnlarge}`}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ReviewedPatientMoreDetails;
