import React, { useCallback, useEffect, useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TableFooter, TablePagination, IconButton, Tooltip } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios'; 
import { ExternalLink } from 'react-feather';
import { useHistory } from 'react-router';
import Scrollbar from 'react-perfect-scrollbar'

const ReviewedPatientToday = () => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [list, setList] = useState([])
    const [listRd, setListRd] = useState(false) 
    const history = useHistory();

    let totalPF = 0;

    // const [orderCount , serOrderCount ] = useState()

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getReviewedList = useCallback(async () => {
        try { 
            const params = {
                token, user_id
            } 
            const request = await Axios.get('telerad/patients/getreviewed-list-today', { params })
            setList(request.data)
            setListRd(true)
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, user_id])

    useEffect(() => {

        getReviewedList()

    }, [getReviewedList])

    
    const calculatePF = (amount) => {
        totalPF += amount;

        return Notify.convertToNumber(amount);
    }

    return (
         <Paper component={Box} p={2}>
            <Box mb={2} display="flex">
                <Box flexGrow={1}>
                    <Typography
                        variant="h6"
                        color="textSecondary"
                    > 
                        Reviewed Patient Today
                    </Typography>
                </Box>

                <Box>
                    <Tooltip title="Display All">
                        <IconButton
                            color="primary"
                            onClick={() => history.push('/clinic/app/telerad/report')}
                        >
                            <ExternalLink />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

            <Box>
                <TableContainer>
                    <Scrollbar>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> Date </TableCell>
                                    <TableCell> Patient </TableCell>
                                    <TableCell> Order  </TableCell>
                                    <TableCell> Shots  </TableCell>
                                    <TableCell> Hospital  </TableCell>
                                    <TableCell> PF  </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    listRd ?
                                        list.length > 0 ?
                                            (rowsPerPage > 0 ? list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : list)
                                                .map((data, index) => {
                                                    return (
                                                        <TableRow
                                                            key={index}
                                                        >
                                                            <TableCell> {Notify.dateTimeConvert(data.created_at)} </TableCell>
                                                            <TableCell> {data.patient_name} </TableCell>
                                                            <TableCell> {data.imaging_order} </TableCell>
                                                            <TableCell align="right"> {data.number_shots} </TableCell>
                                                            <TableCell align="right"> Sanoke Hospital </TableCell>
                                                            <TableCell align="right"> 
                                                                { calculatePF(parseFloat(data.number_shots) * 50) }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            : <TableRow>
                                                <TableCell colSpan={6} > <Typography color="error"> No patient recorded. </Typography> </TableCell>
                                            </TableRow>
                                        : <TableRow>
                                            <TableCell colSpan={6} > <Typography color="primary"> please wait... </Typography> </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell> Total </TableCell>
                                    <TableCell align="right"> {totalPF} </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table> 
                    </Scrollbar>
                    
                    <TablePagination
                        labelRowsPerPage="List"
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        component="div"
                        count={list.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer> 
            </Box>
        </Paper> 
    )
}

export default ReviewedPatientToday;