import React, { useEffect, useState, Fragment } from "react";
import {
  Paper,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Collapse,
  IconButton,
  TablePagination,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowDown";
import Notify from "src/notification/Notify";
import axios from "axios";

const UnpaidOrderList = ({ patient_id, connection }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [unpaid, setUnpaid] = useState([]);
  const [unpaidDetails, setUnpaidDetails] = useState([]);

  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const getUnpaidLaboratoryOrder = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", connection);

    axios
      .post("doctor/patient/laboratory/order/unpaid-list", formdata)
      .then((res) => {
        const data = res.data;
        setUnpaid(data);
      })
      .catch((er) => Notify.requestError(er));
  };

  const getUnpaidLaboratoryOrderDetails = (orderid) => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("order_id", orderid);
    formdata.set("connection", connection);

    axios
      .post("doctor/patient/laboratory/order/unpaid-listdetails", formdata)
      .then((res) => {
        const data = res.data;
        setUnpaidDetails(data);
        setSelectedOrderId(orderid);
      })
      .catch((er) => Notify.requestError(er));
  };

  useEffect(() => {
    getUnpaidLaboratoryOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyPagination = (order, page, limit) => {
    return order.slice(page * limit, page * limit + limit);
  };

  const paginatedOrder = applyPagination(unpaid, page, rowsPerPage);

  return (
    <>
      <Paper component={Box} elevation={2} p={2} mb={2}>
        <Box mb={2}>
          <Typography variant="h6" color="textSecondary">
            Unpaid Order List
          </Typography>
        </Box>

        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="center">
                    <b> Date </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> Order Id </b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unpaid.length > 0 ? (
                  paginatedOrder.map((data, index) => (
                    <Fragment key={index}>
                      <TableRow>
                        <TableCell align="center">
                          <IconButton
                            onClick={() =>
                              getUnpaidLaboratoryOrderDetails(data.order_id)
                            }
                          >
                            {selectedOrderId === data.order_id ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          {Notify.dateTimeConvert(data.created_at)}
                        </TableCell>
                        <TableCell align="center"> {data.order_id} </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            in={selectedOrderId === data.order_id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box m={1}>
                              <Typography variant="caption" gutterBottom>
                                <b> ORDER DETAILS </b>
                              </Typography>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      <b> Department </b>
                                    </TableCell>
                                    <TableCell>
                                      <b> Order </b>
                                    </TableCell>
                                    <TableCell>
                                      <b> Rate </b>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {unpaidDetails.length > 0 ? (
                                    <>
                                      {unpaidDetails.map((data, index) => (
                                        <TableRow key={index} hover>
                                          <TableCell>
                                            {data.bill_department}
                                          </TableCell>
                                          <TableCell>
                                            {data.bill_name}
                                          </TableCell>
                                          <TableCell align="right">
                                            {data.bill_amount}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                      <TableRow>
                                        <TableCell colSpan={4}>
                                          <Typography variant="caption">
                                            <b> REMARKS </b>
                                          </Typography>
                                          <Typography>
                                            {unpaidDetails[0].remarks}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={4} align="center">
                                        <Typography
                                          color="secondary"
                                          variant="subtitle2"
                                        >
                                          Order details not found.
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <Typography color="secondary" variant="subtitle2">
                        No unpaid order
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            labelRowsPerPage="List"
            rowsPerPageOptions={[5, 20, 50, 100]}
            component="div"
            count={unpaid.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    </>
  );
};

export default UnpaidOrderList;
