import React, { Fragment, useState, useEffect } from "react";
import Notify from "src/notification/Notify";
import Axios from "axios";
import InboxIcon from "@material-ui/icons/Inbox";
import SendIcon from "@material-ui/icons/Send";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Trash } from "react-feather";

function Notifications() {
  const [token] = useState(localStorage.getItem("token"));
  const [user_id] = useState(localStorage.getItem("user_id"));
  const [notification, setnotification] = useState([]);
  const [notificationrd, setnotificationrd] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const getAppointmentDetails = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("connection", "online");

    Axios.post("encoder/appointment/online/notification", formdata)
      .then((response) => {
        const data = response.data;
        setnotification(data);
        setnotificationrd(true);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleRemoveNotification = (id) => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("connection", "online");
    formdata.set("remove_id", id);
    setSelectedId(id);
    Axios.post("encoder/appointment/notication/remove-msg", formdata)
      .then((response) => {
        const data = response.data;
        if (data.message === "success") {
          getAppointmentDetails();
          Notify.successRequest("remove");
        }
      })
      .catch((error) => {
        console.log("error: ", error.message);
      });
  };

  useEffect(() => {
    getAppointmentDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <List>
        {notificationrd
          ? notification.length > 0
            ? notification.map((data, index) => {
                return (
                  <ListItem
                    key={index}
                    className={
                      data.notification_type === "inbox"
                        ? data.is_read
                          ? "text-muted"
                          : "text-primary"
                        : "text-muted"
                    }
                    notifid={data.notif_id}
                    notifpatientname={data.patients_name}
                    notifisread={data.is_read}
                    // onClick={handleSelectednotifId}
                  >
                    <ListItemIcon>
                      <Avatar
                        className={
                          data.notification_type === "inbox"
                            ? data.is_read
                              ? "bg-secondary"
                              : "bg-primary"
                            : "bg-secondary"
                        }
                      >
                        {data.notification_type === "sent" ? (
                          <SendIcon style={{ fontSize: "17px" }} />
                        ) : (
                          <InboxIcon style={{ fontSize: "17px" }} />
                        )}
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Box>
                              <Typography
                                color="primary"
                                variant="subtitle2"
                                className="gtc-uppercase"
                              >
                                {data.patients_name}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography
                                className="gtc-uppercase"
                                variant="caption"
                              >
                                {Notify.dateTimeConvert(data.created_at)}
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <IconButton
                              color="secondary"
                              onClick={() => handleRemoveNotification(data.id)}
                              disabled={selectedId === data.id}
                            >
                              <Trash />
                            </IconButton>
                          </Box>
                        </Box>
                      }
                      secondary={
                        <Box>
                          <Typography color="textPrimary">
                            {data.notification_msg}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                );
              })
            : Notify.noRecord()
          : Notify.loading()}
      </List>
    </Fragment>
  );
}

export default Notifications;
