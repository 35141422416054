import React, { useState, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { DatePicker } from "@material-ui/pickers";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Notify from "../../notification/Notify";
import Axios from "axios";
import Container from "src/layout/Container";
import { PlusCircle, XCircle } from "react-feather";

const NewPatient = () => {
  const [user_id] = useState(localStorage.getItem("user_id"));
  const [token] = useState(localStorage.getItem("token"));
  const [username] = useState(localStorage.getItem("username"));

  const [gender, setgender] = useState("");
  const [civil, setcivil] = useState("");
  const [birthday, setbirthday] = useState(new Date());

  const [isprocessave, setisprocessave] = useState(false);

  const saveNewPatient = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("username", username);

    var error = [];
    if (
      formdata.get("lastname").length === 0 ||
      formdata.get("lastname").trim() === ""
    ) {
      Notify.fieldRequired("lastname");
      error = "error";
    }
    if (
      formdata.get("firstname").length === 0 ||
      formdata.get("firstname").trim() === ""
    ) {
      Notify.fieldRequired("firstname");
      error = "error";
    }

    if (
      formdata.get("gender").length === 0 ||
      formdata.get("gender").trim() === ""
    ) {
      Notify.fieldRequired("gender");
      error = "error";
    }
    if (
      formdata.get("gender").length === 0 ||
      formdata.get("gender").trim() === ""
    ) {
      Notify.fieldRequired("gender");
      error = "error";
    }
    if (
      formdata.get("birthday").length === 0 ||
      formdata.get("birthday").trim() === ""
    ) {
      Notify.fieldRequired("birthday");
      error = "error";
    }
    if (
      formdata.get("birthplace").length === 0 ||
      formdata.get("birthplace").trim() === ""
    ) {
      Notify.fieldRequired("birthplace");
      error = "error";
    }
    if (
      formdata.get("street").length === 0 ||
      formdata.get("street").trim() === ""
    ) {
      Notify.fieldRequired("street");
      error = "error";
    }
    if (
      formdata.get("barangay").length === 0 ||
      formdata.get("barangay").trim() === ""
    ) {
      Notify.fieldRequired("barangay");
      error = "error";
    }
    if (
      formdata.get("city").length === 0 ||
      formdata.get("city").trim() === ""
    ) {
      Notify.fieldRequired("city");
      error = "error";
    }
    if (
      formdata.get("religion").length === 0 ||
      formdata.get("religion").trim() === ""
    ) {
      Notify.fieldRequired("religion");
      error = "error";
    }

    if (
      formdata.get("email").length === 0 ||
      formdata.get("email").trim() === ""
    ) {
      Notify.fieldRequired("email");
      error = "error";
    } else {
      if (!Notify.isvalidEmail(formdata.get("email"))) {
        Notify.fieldInvalid("email");
        error = "error";
      }
    }

    if (error.length > 0) {
      console.warn("Form is invalid. check your entry.");
    } else {
      setisprocessave(true);
      Axios.post("doctor/patient/save-patientlocal", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "email-exist") {
            Notify.fieldInvalid("email alredy taken");
          }
          if (data === "success") {
            e.target.reset();
            Notify.successRequest("patient already added.");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setisprocessave(false);
        });
    }
  };

  // const handleSelectedImage = (e) =>{
  //     if(e.currentTarget.value.length > 0){
  //         setselectedimage(e.currentTarget.files[0].name)
  //     }else{
  //         setselectedimage(null)
  //     }
  // }

  return (
    <Fragment>
      <Container
        title={`New Patient`}
        breadcrumbs={{
          enable: true,
          current: "New",
          items: [
            { name: "Dashboard", path: "/clinic/app/doctor" },
            { name: "Patient", path: "/clinic/app/doctor/patient/list" },
          ],
        }}
      >
        <Paper elevation={2} component={Box} p={2}>
          <form onSubmit={saveNewPatient} encType="multipart/form-data">
            <Box mb={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Last Name"
                    name="lastname"
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="First Name"
                    name="firstname"
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Middle Name"
                    name="middle"
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mb={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                  >
                    <InputLabel>Gender</InputLabel>
                    <Select
                      label="Gender"
                      name="gender"
                      value={gender}
                      autoComplete="off"
                      onChange={(e) => setgender(e.target.value)}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <DatePicker
                    fullWidth
                    disableFuture
                    name="birthday"
                    // openTo="year"
                    format="dd/MM/yyyy"
                    label="Date of birth"
                    views={["year", "month", "date"]}
                    value={birthday}
                    onChange={(date) => setbirthday(date)}
                    autoComplete="off"
                    inputVariant="outlined"
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Birth Place"
                    name="birthplace"
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mb={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Street"
                    name="street"
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Barangay"
                    name="barangay"
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="City"
                    name="city"
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mb={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Occupation"
                    name="occupation"
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel>Civil Status</InputLabel>
                    <Select
                      label="Civil Status"
                      name="civil"
                      value={civil}
                      autoComplete="off"
                      onChange={(e) => setcivil(e.target.value)}
                    >
                      <MenuItem value={"Single"}> Single </MenuItem>
                      <MenuItem value={"Married"}> Married </MenuItem>
                      <MenuItem value={"Divorced "}> Divorced </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Religion"
                    name="religion"
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mb={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Mobile"
                    name="mobile"
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Telephone"
                    name="telephone"
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Email"
                    name="email"
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </Box>

            {/* <Box mb={0}>
                            <Grid item xs={12} sm={12}> 
                                <input
                                    accept="image/*" 
                                    style={{ display: 'none' }}
                                    id="raised-button-file" 
                                    type="file"
                                    name="patient_image"
                                    onChange={ handleSelectedImage }
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="contained" component="span" fullWidth color="default" startIcon={<Backup />}>
                                        { selectedimage === null ? " Upload Patient Image " : selectedimage } 
                                    </Button>
                                </label>

                            </Grid> 
                        </Box> */}

            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                margin="normal"
                type="password"
                variant="outlined"
                label="Password"
                name="password"
                autoComplete="off"
              />
            </Grid>

            <Box my={3}>
              <Typography color="textSecondary" style={{ marginBottom: "6px" }}>
                {" "}
                Are you sure to continue adding this patient?{" "}
              </Typography>

              <Button
                startIcon={
                  isprocessave ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : (
                    <PlusCircle size={25} />
                  )
                }
                disabled={isprocessave}
                color="primary"
                variant="contained"
                type="submit"
              >
                Add
              </Button>
              <Button
                variant="contained"
                color="default"
                style={{ marginLeft: "10px" }}
                startIcon={<XCircle size={25} />}
                type="reset"
              >
                {" "}
                No{" "}
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </Fragment>
  );
};

export default NewPatient;
