import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import Label from "src/utils/Label";
import Notify from "src/notification/Notify";
import PatientDetailsAppointmentResched from "src/encoder/PatientDetailsAppointmentResched";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PatientDetailsHeader from "src/encoder/PatientDetailsHeader";

const LocalAppDetails = ({ app, getIncompleteList }) => {
  const [reschedDialog, setReschedDialog] = useState({
    data: null,
    open: false,
  });

  return (
    <>
      <PatientDetailsHeader patient_id={app.patients_id} />

      <Box component={Paper} variant="outlined" p={2} mt={2}>
        <Box display="flex">
          <Box flexGrow={1} mt={1}>
            <Typography
              variant="subtitle2"
              className={`gtc-uppercase`}
              color="primary"
            >
              Appointment Details
            </Typography>
          </Box>

          <Box>
            <Button
              color="primary"
              onClick={() =>
                setReschedDialog({
                  data: app,
                  open: true,
                })
              }
              startIcon={<ScheduleIcon />}
            >
              Reschedule
            </Button>
          </Box>
        </Box>
        <Box className={`gtc-textfield-noborder`}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box mt={2}>
                <TextField
                  fullWidth
                  label={"Appointment Date"}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  value={Notify.dateTimeConvert(app.app_date)}
                />
              </Box>

              <Box mt={2}>
                <TextField
                  fullWidth
                  label={"Appointment Reasosssssn"}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  value={app.app_reason === null ? "None" : app.app_reason}
                  multiline
                />
              </Box>

              <Box mt={2}>
                <TextField
                  fullWidth
                  label={"Appointment Service"}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  value={app.services}
                />
              </Box>

              <Box mt={2}>
                <TextField
                  fullWidth
                  label={"Appointment Fee"}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  value={app.amount}
                />
              </Box>
            </Grid>

            {Boolean(app.is_reschedule) && (
              <>
                <Box my={1}>
                  <Label
                    color={Boolean(app.is_reschedule) ? "success" : "primary"}
                  >
                    RESCHEDULE : {Boolean(app.is_reschedule) ? "Yes" : "No"}
                  </Label>
                </Box>

                <Grid item xs={12} sm={12}>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      label={"Reschedule Date"}
                      InputProps={{ readOnly: true }}
                      variant="outlined"
                      value={Notify.dateTimeConvert(app.is_reschedule_date)}
                    />
                  </Box>

                  <Box mt={2}>
                    <TextField
                      fullWidth
                      label={"Reschedule Reason"}
                      InputProps={{ readOnly: true }}
                      variant="outlined"
                      value={app.is_reschedule_reason}
                      multiline
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>

        {/* appointment reschedule */}
        <Dialog
          open={reschedDialog.open}
          onClose={() => setReschedDialog({ data: null, open: false })}
          disableBackdropClick
          maxWidth={"sm"}
          fullWidth
        >
          <DialogTitle>Appointment Reschedule</DialogTitle>
          {reschedDialog.data && (
            <PatientDetailsAppointmentResched
              app={reschedDialog.data}
              close={() => setReschedDialog({ data: null, open: false })}
              reload={getIncompleteList}
            />
          )}
        </Dialog>
      </Box>
    </>
  );
};

export default LocalAppDetails;
