import React, { useEffect, useState, useContext } from 'react';  
import Notify from '../../../notification/Notify';
import Axios from 'axios'; 
import Badge from '@material-ui/core/Badge'
import { PatientsUnRead } from 'src/ContextAPI';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Card, CardContent, Typography, Button } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ImagingProcessedDetails from './ImagingProcessedDetails';


const ImagingPending = ({ patient_id, imagingType }) =>{

    const user_id =  localStorage.getItem('user_id')
    const token =  localStorage.getItem('token') 

    const [ imaging, setImaging] = useState({ data:[], ready: false }) 
    
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [selectedDialog, setSelectedDialog] = useState('details') 
    
    const unread = useContext(PatientsUnRead);

    const checkPatientUnreadNotif = ( category, department, order_id ) =>{
 
        let xx = unread.unviewNotif;
        let yy = unread.unviewNotifVirtual;
        let count = 0;


        console.log('xx', xx)

        if(imagingType === 'local-imaging'){
            for (let i = 0; i < xx.length; i++) {
                if( patient_id === xx[i].patient_id && category === xx[i].category && department === xx[i].department && order_id === xx[i].order_id){
                    count += 1;
                }
            }
        }else{
            for (let i = 0; i < yy.length; i++) {
                if( patient_id === yy[i].patient_id && category === yy[i].category && department === yy[i].department && order_id === yy[i].order_id){
                    count += 1;
                }
            }
        }
        
        return count;
    }

    const getLaboratoryOngoing = () => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('patient_id', patient_id);    
        formdata.set('connection', imagingType === 'virtual-imaging' ? 'online' : 'local')
        
        Axios.post('doctor/patient/imaging/imaging-pending', formdata )
        .then( (response) => { 
            const data = response.data;   
            setImaging({ data, ready: true })
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const closeDialog = () =>{
        setSelectedOrder(null)
        setDialogOpen(false)  
    }


    useEffect(() =>{

        getLaboratoryOngoing()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[patient_id, imagingType])


    return(
        <> 
            <Box> 
                {
                    imaging.ready ? 
                        imaging.data.length > 0 ?
                            imaging.data.map((data, index) =>{
                                return(
                                    <Card 
                                        component={Box}
                                        borderLeft={5}
                                        borderColor={`#dc3545`}
                                        key={index}  
                                        mb={2}
                                    > 
                                        <CardContent>
                                            <Box>
                                                <Typography  variant="subtitle1">
                                                    <Badge 
                                                        color="secondary"
                                                        badgeContent={
                                                            Boolean(parseInt(checkPatientUnreadNotif('imaging', 'pending', data.imaging_center_id))) ? 'new' : 0
                                                        }
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right'
                                                        }} 
                                                    >
                                                        { data.imaging_order } 
                                                    </Badge>
                                                </Typography>
                                            </Box>
                                            
                                            <Box my={1}>
                                                <Typography variant="subtitle2" color="textSecondary"> 
                                                    { data.pending_reason }
                                                </Typography>
                                            </Box> 
                                            
                                            <Box display="flex">
                                                <Box>
                                                    <Button  
                                                        className="text-primary pointer"  
                                                        imagingid={data.imaging_center_id} 
                                                        onClick={ ()=> {
                                                            setSelectedOrder(data)
                                                            setSelectedDialog('details')
                                                            setDialogOpen(true)  
                                                        }}
                                                    >
                                                        details 
                                                    </Button>  
                                                </Box>  

                                                <Box mt={1} ml={2}>
                                                    <Typography variant="caption" className={`gtc-uppercase`}> {`order on ${ Notify.createdAt(data.created_at) }`} </Typography>  
                                                </Box>
                                            </Box> 
                                        </CardContent>
                                    </Card> 
                                )
                            })
                        : <Card> <CardContent> { Notify.noRecord() } </CardContent> </Card>
                    : <Card> <CardContent> { Notify.loading() } </CardContent> </Card>
                } 
            </Box> 

            {/* Details Dialog */}
            <Dialog 
                open={ dialogOpen } 
                onClose={ closeDialog }
                fullWidth
                maxWidth="sm"
            >
                <Box display="flex">
                    <Box flexGrow={1}> 
                        <DialogTitle> 
                            ORDER INFORMATION 
                        </DialogTitle>
                    </Box>
                    <Box mr={2} mt={1}>
                        <IconButton 
                            onClick={ closeDialog }
                            color="secondary"
                        >
                            <HighlightOffIcon />
                        </IconButton>
                    </Box>
                </Box>
                <DialogContent dividers>
                    {  selectedOrder !==null && selectedDialog === 'details' && ( <ImagingProcessedDetails imagingType ={ imagingType } details = { selectedOrder } /> ) } 
                </DialogContent>
            </Dialog> 
        </>
    )
}

export default ImagingPending; 