import {
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TextField,
  InputAdornment,
  TablePagination,
} from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import React from "react";

const List = ({ product }) => {
  const [query, setQuery] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);

  const handleSearch = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const filteredProduct = searchDrProduct(product, query);
  const paginatedProduct = applyPagination(filteredProduct, page, limit);

  return (
    <Paper>
      <Box p={2}>
        <Box display="flex" justifyContent="flex-end" mb={1}>
          <Box>
            <TextField
              fullWidth
              label="Search product"
              variant="outlined"
              onChange={handleSearch}
              value={query}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Search color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Product </TableCell>
                <TableCell> Generic </TableCell>
                <TableCell> Unit </TableCell>
                <TableCell> Msrp </TableCell>
                <TableCell> Srp </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {product.ready ? (
                paginatedProduct.length > 0 ? (
                  paginatedProduct.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell> {data.product_name} </TableCell>
                      <TableCell> {data.product_generic} </TableCell>
                      <TableCell> {data.unit} </TableCell>
                      <TableCell align="right"> {data.msrp} </TableCell>
                      <TableCell align="right"> {data.srp} </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography color="secondary">
                        no product added.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography color="primary"> processing... </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <TablePagination
            component={"div"}
            count={filteredProduct.length}
            labelRowsPerPage="List"
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[10, 50, 100]}
          />
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default List;

const searchDrProduct = (inventory, query) => {
  return inventory.data.filter((xdata) => {
    let matches = true;

    if (query) {
      let containsQuery = false;

      if (xdata.product_name.toLowerCase().includes(query.toLowerCase())) {
        containsQuery = true;
      }

      if (!containsQuery) {
        matches = false;
      }
    }
    return matches;
  });
};

const applyPagination = (clinic, page, limit) => {
  return clinic.slice(page * limit, page * limit + limit);
};
