import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { Box, Typography, FormControl, InputLabel, OutlinedInput, IconButton, InputAdornment, CircularProgress } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send' 
import { blue, grey } from '@material-ui/core/colors';
import Notify from 'src/notification/Notify';
import Axios from 'axios';

const Chats = ({ selected }) => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [conversation, setConversation] = useState([])
    const [conversationRd, setConversationRd] = useState(false) 
    const [isprocess, setIsProcess] = useState(false) 

    const containerRef = useRef(null)

    const getConversation = useCallback( async () =>{
        try { 
            const params = { 
                token,
                user_id,
                senderId: selected.senderId
            }
            const request = await Axios.get('telerad/message/get-message-conversation', { params })
                setConversation(request.data) 
                setConversationRd(true)  
                scrollToBottom()
        } catch (error) {
            Notify.requestError(error)
        }
    },[selected, user_id, token])

    const handleSendMessage = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
            formdata.append('token', token)
            formdata.append('user_id', user_id)
            formdata.append('imaging_id', selected.senderId)
        var error = [];
        if(formdata.get('message').trim() === '' || formdata.get('message').length === 0){
            error = 'error'
        }

        if(error.length > 0){
            console.warn('form has an error.')
        }else{
            setIsProcess(true)
            Axios.post('telerad/message/send-message', formdata)
            .then((response) =>{
                const data = response.data;
                if(data === 'success'){
                    e.target.reset()
                    getConversation()
                }
            }).catch((error) =>{
                Notify.requestError(error)
            }).finally(() =>{
                setIsProcess(false)
            })
        }
    }

    const scrollToBottom = () =>{ 
        containerRef.current.scrollTop = containerRef.current.scrollHeight
    }

    useEffect(() =>{

        getConversation()

    },[getConversation])


    return(
        <Box m={1}>  
            {/* chats container */}  
            <Box
                my={2}
                height={'calc(100vh - 160px)'}
                overflow={'auto'} 
                ref = { containerRef }
            > 
                {
                    conversationRd ?
                        conversation.length > 0 ?
                            conversation.map(( data, key) =>(
                                <Fragment
                                    key={key}
                                >
                                    { 
                                        data.sender_user_id === user_id ? //me is the sender  
                                            <Box 
                                                display="flex"  
                                            > 
                                                <Box flexGrow={1} />
                                                <Box
                                                    id={`sender`}
                                                    mb={2}
                                                    p={1}
                                                    borderRadius={5}
                                                    border={1}
                                                    borderColor={blue[100]}
                                                    bgcolor={blue[50]}
                                                    width={'70%'}
                                                >
                                                    <Typography
                                                        color="primary" 
                                                    >
                                                        { data.message }
                                                    </Typography>
                                                </Box> 
                                            </Box> 
                                        :   // me is the receiver
                                            <Box 
                                                id={`reciever`}
                                                display="flex"
                                                mb={2}
                                                p={1}
                                                borderRadius={5}
                                                border={1}
                                                borderColor={grey[200]}
                                                bgcolor={grey[100]}
                                                width={'70%'}
                                            >
                                                <Typography
                                                    color="primary"  
                                                >
                                                    { data.message }
                                                </Typography>
                                            </Box>
                                    }
                                </Fragment>
                            ))
                        : Notify.noRecord()
                    : Notify.loading()
                }
            </Box>    
            {/* form message */}
            <form
                onSubmit={ handleSendMessage }
            >
                <FormControl
                    fullWidth   
                    variant="outlined"
                >
                    <InputLabel 
                        htmlFor="message-label"
                    >
                        Message
                    </InputLabel>
                    <OutlinedInput
                        id="message-label"  
                        labelWidth={70}
                        autoComplete="off"
                        name="message"
                        required
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton 
                                    type={"submit"}
                                    disabled={ isprocess }
                                    color="primary"
                                    edge="end"
                                >
                                    { isprocess ? <CircularProgress size={20} /> : <SendIcon />  }
                                </IconButton>
                            </InputAdornment>
                        } 
                    />
                </FormControl> 
            </form>
        </Box>
    )
}

export default Chats;