import {
  Grid,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
} from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import React, { useState } from "react";
import Container from "src/layout/Container";
import ManagementPharmacy from "./ManagementPharmacy";

const SelectManagement = ({ list }) => {
  const [selectedManagement, setSelectedManagement] = useState(null);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "management",
        items: [{ name: "Dashboard", push: "/clinic/app/doctor" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>management</Box>
          <Box>
            <ButtonGroup
              size="large"
              variant="contained"
              color="primary"
              ref={anchorRef}
              aria-label="split button"
            >
              <Button>
                {selectedManagement === null
                  ? "Select Management"
                  : selectedManagement._management_name}
              </Button>
              <Button color="primary" size="small" onClick={handleToggle}>
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              style={{ zIndex: 999999 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList>
                        {list.ready ? (
                          list.data.length > 0 ? (
                            list.data.map((option, index) => (
                              <MenuItem
                                key={index}
                                selected={
                                  selectedManagement &&
                                  selectedManagement.management_id ===
                                    option.management_id
                                }
                                disabled={
                                  selectedManagement &&
                                  selectedManagement.management_id ===
                                    option.management_id
                                }
                                onClick={() => {
                                  setOpen(false);
                                  setSelectedManagement(option);
                                }}
                              >
                                {option._management_name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem>
                              <Typography color="secondary">
                                No managment found.
                              </Typography>
                            </MenuItem>
                          )
                        ) : (
                          <MenuItem>
                            <Typography color="primary">
                              loading, please wait...
                            </Typography>
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {selectedManagement === null && (
            <Box
              minHeight={"calc(80vh - 100px)"}
              display={"flex"}
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <Card elevation={5}>
                <CardHeader title="No Management" />
                <CardContent>
                  <Typography color="secondary" variant="h6">
                    Select management to view information.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          )}

          {selectedManagement !== null && (
            <ManagementPharmacy details={selectedManagement} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SelectManagement;
