import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Drawer,
  Typography,
  Tooltip,
  Button,
  DialogActions,
  Badge,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import { useHistory, useParams } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";
import OnlinechecupChat from "src/oncheckupchat/OnlinechecupChat";
import ChatIcon from "@material-ui/icons/QuestionAnswer";
import Axios from "axios";
import CachedIcon from "@material-ui/icons/Cached";
import CallEnd from "@material-ui/icons/CallEnd";
import ReloadIcon from "@material-ui/icons/Sync";
import { XCircle } from "react-feather";
import { ExitToApp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  coloredIconButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover, &.Mui-focusVisible": {
      backgroundColor: theme.palette.primary.dark,
    },
    padding: theme.spacing(1.5),
  },

  coloredIconButtonDanger: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    "&:hover, &.Mui-focusVisible": {
      backgroundColor: theme.palette.secondary.dark,
    },
    padding: theme.spacing(1.5),
  },
  iconSize: {
    fontSize: 25,
  },
}));

const RTCMultiConnection = require("rtcmulticonnection");

const EnterRoomOld = () => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");

  const myvideoRef = useRef(null);
  const clientvideoRef = useRef(null);

  const [validreq, setValidReq] = useState(false);
  const [prepared, setPrepared] = useState(false);
  const [drawer, setDrawer] = useState("");
  const [roomnotFound, setRoomNotFound] = useState(false);
  const { ref_number } = useParams();
  const [redialBtnDisabled, setRedialBtnDisabled] = useState(true);
  const [refDetails, setRefDetails] = useState(null);
  const [con, setCon] = useState(null);
  const [remoteId, setRemoteId] = useState(null); 
  const history = useHistory();
  const [unreadMsg, setUnreadMsg] = useState(0);

  const [ismyVideoTagLoaded, setIsmyVideoTagLoaded] = useState(false);

  const [ismyClientVideoTagLoaded, setIsmyClientVideoTagLoaded] =
    useState(false);
  const [doctorIsDisconnected, setDoctorIsDisconnected] = useState(false);

  const [cancelRoom, setCancelRoom] = useState(false);
  const [recordedStream, setRecordStream] = useState(null);

  var timeInterval = null;
  var msgInterval = null;
  var connection = null;

  const checkMicandVideo = (details) => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        setRecordStream(stream);
        setValidReq(true);
        setPrepared(true);
        initializeRtc(details);
      })
      .catch(() => {
        setValidReq(false);
        setPrepared(true);
        Notify.customToast(
          "Camera/Mic Not Found.",
          "Enable your camera to continue."
        );
      });
  };

  const initializeRtc = (details) => {
    connection = new RTCMultiConnection();
    connection.socketURL = "https://rtcmulticonnection.herokuapp.com:443/";
    connection.enableLogs = false;
    connection.userid = username;
    connection.autoCreateMediaElement = false;
    connection.maxParticipantsAllowed = 3;
    connection.session = {
      audio: true,
      video: true,
    };

    connection.iceServers = [];

    connection.iceServers.push({
      urls: "stun:ss-turn1.xirsys.com",
      url: "stun:ss-turn1.xirsys.com",
    });

    connection.iceServers.push({
      username:
        "k-Q-ZfWXGqvHPX9tVkr_lE3Lz6jBb6C35rcWuF5u7qi00zlap5EVdHIzpDMi_XWtAAAAAGA1z6NraW5nc2xheWVy",
      credential: "ff1aeda4-7654-11eb-90f2-0242ac140004",
      urls: [
        "turn:ss-turn1.xirsys.com:80?transport=udp",
        "turn:ss-turn1.xirsys.com:3478?transport=udp",
        "turn:ss-turn1.xirsys.com:80?transport=tcp",
        "turn:ss-turn1.xirsys.com:3478?transport=tcp",
        "turns:ss-turn1.xirsys.com:443?transport=tcp",
        "turns:ss-turn1.xirsys.com:5349?transport=tcp",
      ],
    });

    connection.sdpConstraints.mandatory = {
      OfferToReceiveAudio: true,
      OfferToReceiveVideo: true,
    };

    setCon(connection);
    initializeAnswer(connection, details);

    console.log(connection);
  };

  const initializeAnswer = (rtc, details) => {
    rtc.onstream = (event) => {
      if (event.type === "local") {
        setTimeout(() => {
          setIsmyVideoTagLoaded(true);
          var video = myvideoRef.current;
          video.srcObject = event.stream;
          video.play();
        }, 2000);
      }

      if (event.type === "remote") {
        setInterval(() => {
          setIsmyClientVideoTagLoaded(true);
        }, 2000);
        setRemoteId(event.userid);
        var videox = clientvideoRef.current;
        videox.srcObject = event.stream;
        videox.play();
      }
    };

    rtc.onleave = function (event) {
      var remoteUserId = event.userid;
      Notify.customToast(`${remoteUserId} is disconnected.`);
      clearInterval(timeInterval);
      setRedialBtnDisabled(false);
      doctorDisconnected();
    };

    setTimeout(() => {
      rtc.checkPresence(
        details.room_number,
        function (isRoomExist, roomid, error) {
          if (isRoomExist === true) {
            Notify.customToast("Connected", "You are now connected.");
          } else {
            console.log(isRoomExist, roomid, error);
            setRedialBtnDisabled(false);
            doctorDisconnected();
            Notify.customToast(
              "Room Closed",
              "Room is not available. Try to chat your doctor."
            );
          }
        }
      );
    }, 3000);

    rtc.join(details.room_number);
  };

  const getVcallRoom = () => {
    var params = {
      token: token,
      user_id: user_id,
      ref_number: ref_number,
      connection: "online",
    };
    Axios.get("patient/online/appointment/getrefinfo", { params })
      .then((response) => {
        const data = response.data;
        if (Object.keys(data).length > 0) {
          checkMicandVideo(data);
          setRefDetails(data);
          intializeGetUnreadMsgInterval(data.doctors_userid);
        } else {
          Notify.requestError("Room Not Found");
          setRoomNotFound(true);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getLatestMessageFromDoctor = (doctorsId) => {
    var params = {
      token: token,
      user_id: user_id,
      senders_id: doctorsId,
      connection: "online",
    };
    Axios.get("patient/get/all-unread-msg", { params })
      .then((response) => {
        const data = response.data;
        setUnreadMsg(parseInt(data.length));
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const intializeGetUnreadMsgInterval = (doctorsId) => {
    msgInterval = setInterval(() => {
      getLatestMessageFromDoctor(doctorsId);
    }, 10000);
  };

  const handleReenterRoom = () => {
    setIsmyVideoTagLoaded(false);
    setIsmyClientVideoTagLoaded(false);
    setRedialBtnDisabled(true);
    getVcallRoom();
  };

  const endCall = () => {
    if (con) {
      var mystream = con.streamEvents.selectFirst({ local: true });
      mystream.stream.getVideoTracks().forEach(function (track) {
        track.stop();
      });
      con.deletePeer(remoteId);
      con.closeSocket();
      con.getAllParticipants().forEach(function (participantId) {
        con.disconnectWith(participantId);
      });
    }

    removeRoom();
  };

  const removeRoom = () => {
    var params = {
      token: token,
      user_id: user_id,
      app_ref_number: refDetails ? refDetails.ref_number : "",
      connection: "online",
    };
    Axios.get("patient/online/appointment/delete-room", { params })
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.customToast("Room Deleted", "Room Successfully Destroy. ");
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => history.goBack());
  };

  const doctorDisconnected = () => {
    setDoctorIsDisconnected(true);
    setRoomNotFound(true);
    if (con) {
      con.deletePeer(remoteId);
      con.closeSocket();
      con.getAllParticipants().forEach(function (participantId) {
        con.disconnectWith(participantId);
      });
    }
  };

  const handleEndRoom = () => {
    if (recordedStream !== null) {
      recordedStream.getTracks().forEach((track) => {
        track.stop();
      });
    }

    endCall();
  };

  useEffect(() => {
    getVcallRoom();

    return () => {
      if (con) {
        endCall();
      }
      clearInterval(timeInterval);
      clearInterval(msgInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog open={true} disableBackdropClick disableEscapeKeyDown fullScreen>
        <DialogContent
          style={{
            backgroundColor: "#000",
          }}
        >
          {doctorIsDisconnected || roomnotFound ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={"calc(100vh - 50px)"}
              border={1}
            >
              <Box alignItems="center" align="center">
                <Typography color="primary" align="center" variant="subtitle2">
                  CONNECTION LOST
                </Typography>
                <Typography color="error" align="center" variant="caption">
                  ROOM NOT FOUND
                </Typography>
                <Box mt={2} display="flex">
                  <Box mr={2}>
                    <Button
                      startIcon={<ReloadIcon />}
                      color="primary"
                      variant="contained"
                      onClick={() => history.go()}
                    >
                      Reload
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      endIcon={<ExitToApp />}
                      color="secondary"
                      variant="contained"
                      onClick={() => setCancelRoom(true)}
                    >
                      Exit
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : prepared ? (
            <>
              {validreq && (
                <>
                  <Box>
                    <Drawer
                      variant="persistent"
                      anchor={"left"}
                      open={drawer === "drawer-chat" ? true : false}
                      onClose={() => setDrawer("")}
                      transitionDuration={{
                        enter: 600,
                        exit: 600,
                      }}
                    >
                      <Box
                        width={
                          window.innerWidth > 900 ? 400 : window.innerWidth
                        }
                      >
                        <OnlinechecupChat
                          client_id={refDetails && refDetails.doctors_userid}
                          close={() => setDrawer("")}
                        />
                      </Box>
                    </Drawer>
                  </Box>

                  {/* video holder */}
                  <Box>
                    <Box className={`myvideotagOnWeb`}>
                      {!ismyVideoTagLoaded && (
                        <Box
                          className={"myvtagisloading"}
                          color="white"
                          align="center"
                        >
                          {" "}
                          <CircularProgress size={20} color="inherit" />{" "}
                        </Box>
                      )}
                      <video
                        playsInline
                        ref={myvideoRef}
                        style={{
                          objectFit: "contain",
                        }}
                        muted
                      />
                    </Box>

                    {/* client video */}
                    <Box className={`myClientVideotagOnWeb`}>
                      {!ismyClientVideoTagLoaded && (
                        <Box
                          align="center"
                          color="white"
                          className={"myclientvtagisloading"}
                        >
                          {" "}
                          <CircularProgress
                            size={20}
                            color="inherit"
                          /> <br /> connecting doctors video...
                        </Box>
                      )}
                      <video
                        playsInline
                        ref={clientvideoRef}
                        style={{
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                  </Box>

                  {/* buttons */}
                  {/* <Box m={1} mt={2} align="center">
                                                <Tooltip title="Timer" arrow>
                                                    { window.innerWidth > 600 ?  
                                                            <Typography color="primary" variant="h5"> 
                                                                { calculateTime() } : {callTimeSeconds > 10 ? callTimeSeconds : `0${callTimeSeconds}`} 
                                                            </Typography>
                                                        :   <Typography color="primary" variant="caption"> 
                                                                { calculateTime() } : {callTimeSeconds > 10 ? callTimeSeconds : `0${callTimeSeconds}`} 
                                                            </Typography>
                                                    }
                                                </Tooltip>
                                            </Box>  */}

                  <Box className="_buttonContainer">
                    {ismyVideoTagLoaded && (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        alignSelf="center"
                        my={2}
                        bottom={1}
                      >
                        <Box m={2} align="center">
                          <Tooltip title="Chat" arrow>
                            <Badge badgeContent={unreadMsg} color="secondary">
                              <IconButton
                                className={classes.coloredIconButton}
                                onClick={() => setDrawer("drawer-chat")}
                              >
                                <ChatIcon className={classes.iconSize} />
                              </IconButton>
                            </Badge>
                          </Tooltip>

                          <Box mt={0.5}>
                            <Typography
                              variant="caption"
                              style={{ color: "#fff" }}
                            >
                              CHAT
                            </Typography>
                          </Box>
                        </Box>

                        {redialBtnDisabled && (
                          <Box m={2} align="center">
                            <Tooltip title="Re-enter room" arrow>
                              <Badge badgeContent={unreadMsg} color="secondary">
                                <IconButton
                                  className={classes.coloredIconButton}
                                  onClick={handleReenterRoom}
                                >
                                  <CachedIcon className={classes.iconSize} />
                                </IconButton>
                              </Badge>
                            </Tooltip>
                            <Box mt={0.5}>
                              <Typography
                                variant="caption"
                                style={{ color: "#fff" }}
                              >
                                RE-ENTER
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        <Box m={2} align="center">
                          <Tooltip title="End call and back to home" arrow>
                            <IconButton
                              className={classes.coloredIconButtonDanger}
                              onClick={() => setCancelRoom(true)}
                            >
                              <CallEnd className={classes.iconSize} />
                            </IconButton>
                          </Tooltip>

                          <Box mt={0.5}>
                            <Typography
                              variant="caption"
                              style={{ color: "#fff" }}
                            >
                              END
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </>
              )}

              {!validreq && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={"calc(100vh - 50px)"}
                  border={1}
                >
                  <Box>
                    <Alert severity="info">
                      <AlertTitle>Camera Or Microphone Error !</AlertTitle>
                      Enable your camera and microphone to continue.
                    </Alert>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            //  preparing requirements
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={"calc(100vh - 50px)"}
              border={1}
            >
              <Box alignItems="center" align="center" color="white">
                <CircularProgress size={30} color="inherit" />
                <Typography align="center"> please wait... </Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>

      {/* delete and exit room dialog */}
      {/* <Dialog
        open={exitDialog}
        onClose={() => setExitDialog(false)}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <Box>
            <Typography>Are you sure to exit and destroy this room?</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            startIcon={<ClearIcon />}
            onClick={() => setExitDialog(false)}
          >
            No
          </Button>

          <Button
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
            onClick={() => {
              endCall();
            }}
          >
            {`Yes`}
          </Button>
        </DialogActions>
      </Dialog> */}

      <Dialog open={cancelRoom} onClose={() => setCancelRoom(false)}>
        <DialogContent>
          <Typography>Are you sure to end this room?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            startIcon={<XCircle />}
            onClick={() => setCancelRoom(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<CallEnd />}
            onClick={handleEndRoom}
          >
            End
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EnterRoomOld;
