import React, { Component, Fragment } from "react";
import Notify from "../../notification/Notify";
import Axios from "axios";
import { Form, FormControl } from "react-bootstrap";
import {
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { CheckSquare, XCircle } from "react-feather";

export default class AppointmentNotificationMsgDetails extends Component {
  _mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      _user_id: localStorage.getItem("user_id"),
      _token: localStorage.getItem("token"),
      _management_id: localStorage.getItem("management_id"),
      _type: localStorage.getItem("type"),
      _username: localStorage.getItem("username"),

      _notifid: this.props.notifid,

      _notif_info: [],
      _notif_info_ready: false,

      _isprocess: false,
    };
  }

  getNotificationMsgDetails() {
    var params = {
      token: this.state._token,
      user_id: this.state._user_id,
      notifid: this.props.notifid,
    };
    Axios.get("appointment/patient/notification-msg-details", { params })
      .then(
        function (response) {
          const data = response.data;
          if (this._mounted) {
            this.setState({
              _notif_info: data,
              _notif_info_ready: true,
            });
          }
        }.bind(this)
      )
      .catch((error) => {
        Notify.requestError(error);
      });
  }

  componentDidMount() {
    this._mounted = true;
    this.getNotificationMsgDetails();
  }

  componentDidUpdate() {
    if (this.state._notifid !== this.props.notifid) {
      this.setState({
        _notifid: this.props.notifid,
        _notif_info_ready: false,
      });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleReplyNotification = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", this.state._user_id);
    formdata.set("token", this.state._token);
    formdata.set("username", this.state._username);
    formdata.set("notif_id", this.props.notifid);

    var error = [];

    if (
      formdata.get("notification").length === 0 ||
      formdata.get("notification").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("notification");
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      console.warn("Form has an error, oberserved");
    } else {
      this.setState({ _isprocess: true });
      Axios.post("appointment/patient/notification-msg-reply", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            this.props.closeNotifDialog();
            this.props.getNotificationMsg();
            Notify.successRequest("Notification REply");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "db-error") {
            Notify.warnRequest("Notification REply");
          }
        })
        .catch((err) => {
          Notify.requestError(err);
        })
        .finally(() => {
          this.setState({ _isprocess: false });
        });
    }
  };

  render() {
    return (
      <Fragment>
        {this.props.detailstype === "view" ? (
          <Fragment>
            {/* view notification */}
            {this.state._notif_info_ready ? (
              this.state._notif_info.length > 0 ? (
                <Fragment>
                  <DialogContent dividers className={`gtc-textfield-noborder`}>
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label="Doctor"
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                        multiline
                        defaultValue={this.state._notif_info[0].doctors_name}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label="Message"
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                        multiline
                        defaultValue={
                          this.state._notif_info[0].notification_msg
                        }
                      />
                    </Box>

                    <Box>
                      <TextField
                        fullWidth
                        label="Date"
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                        defaultValue={Notify.dateTimeConvert(
                          this.state._notif_info[0].created_at
                        )}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.props.closeNotifDialog()}
                      disabled={this.state._isprocess ? true : false}
                      variant="contained"
                      color="primary"
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Fragment>
              ) : (
                Notify.noRecord()
              )
            ) : (
              Notify.loading()
            )}
          </Fragment>
        ) : (
          // reply notification
          <Form onSubmit={this.handleReplyNotification}>
            <DialogContent dividers>
              <FormControl
                name="doctors_id"
                readOnly
                hidden
                defaultValue={
                  this.state._notif_info.length > 0
                    ? this.state._notif_info[0].doctors_id
                    : null
                }
              />
              <FormControl
                name="patient_id"
                readOnly
                hidden
                defaultValue={
                  this.state._notif_info.length > 0
                    ? this.state._notif_info[0].patient_id
                    : null
                }
              />
              <FormControl
                name="appointment_id"
                readOnly
                hidden
                defaultValue={
                  this.state._notif_info.length > 0
                    ? this.state._notif_info[0].appointment_id
                    : null
                }
              />

              {/* <FormGroup>
                                        <span className="gtc-formlabel isrequired"> notification</span>
                                        <FormControl name="notification" as="textarea"/>
                                    </FormGroup> */}
              <Box>
                <TextField
                  fullWidth
                  margin="normal"
                  name="notification"
                  label="Message"
                  variant="outlined"
                  multiline
                />
              </Box>

              <Box>
                <TextField
                  fullWidth
                  margin="normal"
                  name="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                />
              </Box>
              {/* <FormGroup>
                                        <span className="gtc-formlabel isrequired"> password</span>
                                        <FormControl name="password" type="password"/>
                                    </FormGroup> */}
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => this.props.closeNotifDialog()}
                variant="contained"
                color="default"
                startIcon={<XCircle />}
              >
                No
              </Button>
              <Button
                type="submit"
                disabled={this.state._isprocess}
                variant="contained"
                color="primary"
                startIcon={
                  this.state._isprocess ? (
                    <CircularProgress size={23} color="inherit" />
                  ) : (
                    <CheckSquare />
                  )
                }
              >
                Send
              </Button>
            </DialogActions>
          </Form>
        )}
      </Fragment>
    );
  }
}
