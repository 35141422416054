import React, { Fragment, useEffect, useState, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Paper, Typography } from '@material-ui/core';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import Container from 'src/layout/Container';

const PersonalAllergies = () => {
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('token')
    const [patientInfo, setPatientInfo] = useState([])
    const [patientInfoRdy, setPatientInfoRdy] = useState(false)

    const fetchPatientInfo = useCallback(async () => {
        try {
            var params = {
                token: token,
                user_id: user_id,
            }
            const request = await Axios.get('patient/get-personal-info-by-id', { params })
            setPatientInfo(request.data)
            setPatientInfoRdy(true)
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, user_id])

    useEffect(() => {
        fetchPatientInfo()
    }, [fetchPatientInfo])

    return (
        <>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'allergies',
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' },
                        { name: 'Profile', path: '/clinic/app/patient/profile' }
                    ]
                }}
                title="My Allergies"
            >
                {
                    patientInfoRdy && (
                        parseFloat(patientInfo.length) > 0 && (
                            <Fragment>
                                <Box p={2} component={Paper} variant="outlined">
                                    <Grid container>
                                        <Grid item xs={12} sm={12}>
                                            <Box>
                                                <Typography color="primary" variant="subtitle2">
                                                    Only your appointed doctor can add allergies.
                                            </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Box mt={2} dangerouslySetInnerHTML={{ __html: patientInfo[0]._allergies === null ? 'None' : patientInfo[0]._allergies }} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Fragment>
                        )
                    )
                }
            </Container>
        </>
    )
}

export default PersonalAllergies;