import React, { Fragment, useEffect, useState } from "react";
import Notify from "../../notification/Notify";
import Axios from "axios";
import AppointmentNotificationMsgDetails from "./AppointmentNotificationMsgDetails";
import {
  Dialog,
  DialogTitle,
  TablePagination,
  Typography,
  Button,
  Card,
  CardContent,
  Box,
} from "@material-ui/core";
import Container from "src/layout/Container";
import { ReplyAll } from "@material-ui/icons";

const AppointmentNotificationMsg = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [_notification, set_notification] = useState([]);
  const [_selected_notifid, set_selected_notifid] = useState(null);
  const [_dialog_notifopen, set_dialog_notifopen] = useState(false);
  const [_notifDetailstType, set_notifDetailstType] = useState("view");
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [page, setPage] = useState(0);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const getNotificationMsg = () => {
    var params = {
      token: token,
      user_id: user_id,
    };
    Axios.get("appointment/patient/notification-msg", { params })
      .then((response) => {
        const data = response.data;
        set_notification(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const selectedNotif = (e) => {
    set_selected_notifid(e.currentTarget.getAttribute("notifid"));
    set_notifDetailstType(e.currentTarget.getAttribute("type"));
    set_dialog_notifopen(true);
  };

  const closeNotifDialog = () => {
    set_selected_notifid(null);
    set_dialog_notifopen(false);
    set_notifDetailstType("view");
  };

  useEffect(() => {
    getNotificationMsg();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "appointment notif",
          items: [
            { name: "Dashboard", path: "/clinic/app/patient" },
            { name: "Appointment", path: "/clinic/app/patient/appointment" },
          ],
        }}
        title="Appointment Notif"
      >
        <Box>
          {_notification.length > 0
            ? (rowsPerPage > 0
                ? _notification.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : _notification
              ).map((data, index) => {
                return (
                  <Card key={index} id={data.id} component={Box} mb={2}>
                    <CardContent>
                      <Box>
                        <Typography
                          variant="subtile1"
                          className="gtc-uppercase"
                        >
                          {data.doctors_name} on{" "}
                          {Notify.dateTimeConvert(data.created_at)}
                        </Typography>

                        <Box my={1}>
                          <Typography
                            noWrap
                            variant="subtitle2"
                            color="textSecondary"
                          >
                            {data.notification_msg}
                          </Typography>
                        </Box>

                        <Box>
                          <Button
                            onClick={selectedNotif}
                            notifid={data.notif_id}
                            color="primary"
                            type="view"
                          >
                            view
                          </Button>

                          {data.notification_type === "inbox" ? (
                            <Typography variant="caption">
                              This is your reply on
                              {Notify.dateTimeConvert(data.created_at)}
                            </Typography>
                          ) : (
                            <>
                              <Button
                                onClick={selectedNotif}
                                notifid={data.notif_id}
                                type="reply"
                                color="primary"
                                startIcon={<ReplyAll />}
                              >
                                Reply
                              </Button>

                              {data.is_read ? (
                                <Typography variant="caption">
                                  seen on
                                  {Notify.dateTimeConvert(data.updated_at)}
                                </Typography>
                              ) : (
                                <Typography variant="caption">
                                  unseen (click to open)
                                </Typography>
                              )}
                            </>
                          )}
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                );
              })
            : Notify.noRecord()}

          <TablePagination
            labelRowsPerPage="List"
            rowsPerPageOptions={[6, 20, 50, 100, { label: "All", value: -1 }]}
            component="div"
            count={_notification.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <Dialog
            disableBackdropClick
            open={_dialog_notifopen}
            onClose={closeNotifDialog}
          >
            <DialogTitle>Notification</DialogTitle>

            <AppointmentNotificationMsgDetails
              notifid={_selected_notifid}
              detailstype={_notifDetailstType}
              getNotificationMsg={getNotificationMsg}
              closeNotifDialog={closeNotifDialog}
            />
          </Dialog>
        </Box>
      </Container>
    </Fragment>
  );
};

export default AppointmentNotificationMsg;
