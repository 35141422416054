import React, { Fragment, useCallback, useContext, useEffect } from "react";
import Notify from "src/notification/Notify";
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Container from "src/layout/Container";

import AppointmentCounts from "./alternatives/AppointmentCounts";
import IncomeGraph from "./alternatives/IncomeGraph";

import Typography from "@material-ui/core/Typography";
import AppointmentCalendarDaily from "./alternatives/AppointmentCalendarDaily";
import IsMountedRef from "src/utils/IsMountedRef";
import { Box, Button, Hidden } from "@material-ui/core";
import GtcNews from "src/gtcnews";
import { PatientsUnRead } from "src/ContextAPI";
import { useHistory } from "react-router-dom";

const DashboardAlternative = () => {
  const ismounted = IsMountedRef();
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const { personalInfo } = useContext(PatientsUnRead);
  const history = useHistory();

  const movePatientToList = useCallback(async () => {
    try {
      var formdata = new FormData();
      formdata.set("token", token);
      formdata.set("user_id", user_id);
      await axios.post("doctor/patients/moveto-list", formdata);
      console.log("patient move to list successful");
    } catch (error) {
      Notify.requestError(error);
    }
  }, [token, user_id]);

  useEffect(() => {
    movePatientToList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ismounted]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: false,
          current: "SAMOPLPERLS PAERL PSE",
          items: [],
        }}
        title={
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant={"h4"}>Dashboard</Typography>
              <Typography variant="subtitle1">
                {" "}
                Welcome back, {username} 👋👋👋{" "}
              </Typography>
            </Box>

            <Box>
              {Boolean(personalInfo.has_management) && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    history.push(`/clinic/app/doctors/management`);
                  }}
                >
                  Management
                </Button>
              )}
            </Box>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            {/*  appointment counts in cards  */}
            <AppointmentCounts />

            {/* daily appointment calendar */}
            <Hidden smDown>
              <AppointmentCalendarDaily />
            </Hidden>

            {/* income doctor report montly */}
            <Hidden smDown>
              <IncomeGraph />
            </Hidden>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Box mb={2}>
              <GtcNews module={`Doctor`} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default DashboardAlternative;
