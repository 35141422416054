import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { Home, Users, FilePlus, Lock, Database } from "react-feather";
import ImagingDashboard from "src/imaging/imaging_new/ImagingDashboard";
import Account from "src/imaging/imaging_new/account/Account";
import ImagingTest from "../imaging/imaging_new/test/ImagingTest";
import ImagingOrderVirtual from "src/imaging/imaging_new/virtual/ImagingOrderVirtual";
import CheckInternet from "src/utils/CheckInternet";
import ImagingPatients from "src/imaging/imaging_new/ImagingPatients";
import { Badge } from "@material-ui/core";
import ImagingReport from "src/imaging/imaging_new/ImagingReport";
import Logout from "src/welcome/Logout";

const RouteImagingNew = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const [sidebarHeader, setSidebarHeader] = useState([]);
  const [newPatientCountLocal, setNewPatientCountLocal] = useState(0);
  const [newPatientCountVirtual, setNewPatientCountVirtual] = useState(0);
  var interval = null;

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { user_id: user_id, token: token };
      const response = await axios.get("imaging/sidebar/header-infomartion", {
        params,
      });
      setSidebarHeader(response.data);
    } catch (error) {
      console.log("Unable to get sidebar header info.", error);
    }
  };

  const getImagingVirtualOrder = async () => {
    try {
      var params = { user_id: user_id, token: token };
      const response = await axios.get(
        "imaging/virtual/get/patient/forimaging",
        { params }
      );
      setNewPatientCountVirtual(response.data.length);
    } catch (error) {
      console.log("Unable to get badge for virtual.", error);
    }
  };

  const getNewPatientForImaging = () => {
    var params = {
      user_id: user_id,
      management_id: management_id,
      token: token,
    };
    axios.get("imaging/get/patient/forimaging", { params }).then((response) => {
      const data = response.data;
      setNewPatientCountLocal(data.length);
    });
  };

  const checkinternet = () => {
    interval = setInterval(() => {
      CheckInternet.online()
        .then(() => {
          getImagingVirtualOrder();
        })
        .catch(() => {
          clearInterval(interval);
        });

      getNewPatientForImaging();
    }, 10000);
  };

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <Home color="white" />,
      path: "/clinic/app",
      subitem: [],
    },
    {
      name: "Patients",
      show: true,
      icon: (
        <Badge
          badgeContent={
            parseInt(newPatientCountLocal) + parseInt(newPatientCountVirtual)
          }
          color="error"
        >
          {" "}
          <Users color="white" />{" "}
        </Badge>
      ),
      path: "/clinic/app/patients",
      subitem: [],
    },
    {
      name: "Imaging Test",
      show: true,
      icon: <FilePlus color="white" />,
      path: "/clinic/app/test",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: <Lock color="white" />,
      path: "/clinic/app/account",
      subitem: [],
    },

    {
      name: "Report",
      show: true,
      icon: <Database color="white" />,
      path: "/clinic/app/reports",
      subitem: [],
    },
  ];

  useEffect(() => {
    getSidebarHeaderInfo();
    checkinternet();

    return () => {
      clearInterval(interval);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <BrowserRouter>
        <Sidebar
          notification={{
            enable: false,
            owner: "imaging",
          }}
          header={sidebarHeader}
          routes={sidebarRoute}
          module={"imaging"}
        />

        <Switch>
          <Route
            exact
            path="/"
            component={() => <Redirect to="/clinic/app" />}
          />
          <Route
            exact
            path="/clinic"
            component={() => <Redirect to="/clinic/app" />}
          />
          <Route exact path="/clinic/app" component={ImagingDashboard} />
          <Route
            exact
            path="/clinic/app/patients"
            component={ImagingPatients}
          />
          <Route exact path="/clinic/app/test" component={ImagingTest} />
          <Route exact path="/clinic/app/account" component={Account} />
          <Route exact path="/clinic/app/reports" component={ImagingReport} />
          <Route
            exact
            path="/clinic/app/virtual"
            component={ImagingOrderVirtual}
          />

          <Route exact path="/clinic/app/logout" component={Logout} />
          <Route render={() => <PageNotFound title="Page not found" />} />
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

export default RouteImagingNew;
