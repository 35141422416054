import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Box, DialogContent, TextField } from "@material-ui/core";
import Axios from "axios";
import Notify from "../../notification/Notify";

function ContactInformation({ patient_id, close }) {
  const [token] = useState(localStorage.getItem("token"));
  const [user_id] = useState(localStorage.getItem("user_id"));

  const [contact, setcontact] = useState({
    data: [],
    ready: false,
  });

  const contactInfo = useCallback(() => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", "online");

    Axios.post("doctors/appointment/virtual/patientcontact-info", formdata)
      .then((response) => {
        const data = response.data;
        setcontact({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, user_id, patient_id]);

  useEffect(() => {
    contactInfo();
  }, [patient_id, contactInfo]);

  return (
    <Fragment>
      <DialogContent>
        {contact.ready ? (
          contact.data.length > 0 ? (
            <Box className="gtc-textfield-noborder" mb={3}>
              <Box>
                <TextField
                  variant="outlined"
                  label="Email"
                  value={
                    contact.data[0].email === null
                      ? "None"
                      : contact.data[0].email
                  }
                  InputProps={{
                    inputProps: {
                      readOnly: true,
                    },
                  }}
                />
              </Box>

              <Box>
                <TextField
                  margin="normal"
                  variant="outlined"
                  label="Mobile"
                  value={
                    contact.data[0].mobile === null
                      ? "None"
                      : contact.data[0].mobile
                  }
                  InputProps={{
                    inputProps: {
                      readOnly: true,
                    },
                  }}
                />
              </Box>

              <Box>
                <TextField
                  margin="normal"
                  variant="outlined"
                  label="Telephone"
                  value={
                    contact.data[0].telephone === null
                      ? "None"
                      : contact.data[0].telephone
                  }
                  InputProps={{
                    inputProps: {
                      readOnly: true,
                    },
                  }}
                />
              </Box>
            </Box>
          ) : (
            "No contact found."
          )
        ) : (
          Notify.loading()
        )}
      </DialogContent>
    </Fragment>
  );
}

export default ContactInformation;
