import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  IconButton,
  Tooltip,
  TableContainer,
} from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import PatientDetailsAppointmentCreate from "./PatientDetailsAppointmentCreate";
import Notify from "src/notification/Notify";
import Axios from "axios";
import Label from "src/utils/Label";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import PatientDetailsAppointmentDetails from "./PatientDetailsAppointmentDetails";
import PatientDetailsAppointmentResched from "./PatientDetailsAppointmentResched";
import PerfectScrollbar from "react-perfect-scrollbar";

const PatientDetailsAppointment = ({ patient_id }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [createDialog, setCreateDialog] = useState(false);
  const [appointment, setAppointment] = useState({
    data: [],
    ready: false,
  });

  const [detailsDialog, setDetailsDialog] = useState({
    data: null,
    open: false,
  });

  const [reschedDialog, setReschedDialog] = useState({
    data: null,
    open: false,
  });

  const getAppointmentListLocal = useCallback(() => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);

    Axios.post("encoder/patients/appointment/local/list", formdata)
      .then((response) => {
        const data = response.data;
        setAppointment({ data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, user_id, patient_id]);

  useEffect(() => {
    getAppointmentListLocal();
  }, [getAppointmentListLocal]);

  return (
    <>
      <Box component={Paper} variant="outlined" mt={2} p={2}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography
              className={`gtc-uppercase`}
              variant="subtitle2"
              color="primary"
            >
              APPOINTMENT LIST
            </Typography>
          </Box>

          <Box>
            <Tooltip arrow title="Create Appointment">
              <IconButton color="primary" onClick={() => setCreateDialog(true)}>
                <ControlPointIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Box>
          <TableContainer>
            <PerfectScrollbar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> Date </TableCell>
                    <TableCell> Reason </TableCell>
                    <TableCell> Service </TableCell>
                    <TableCell> Amount </TableCell>
                    <TableCell> Status </TableCell>
                    <TableCell> Reschedule </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {appointment.ready ? (
                    appointment.data.length > 0 ? (
                      appointment.data.map((data, index) => (
                        <TableRow key={index} hover>
                          <TableCell>
                            {Boolean(data.is_reschedule)
                              ? Notify.dateTimeConvert(data.is_reschedule_date)
                              : Notify.dateTimeConvert(data.app_date)}
                          </TableCell>
                          <TableCell> {data.app_reason} </TableCell>
                          <TableCell> {data.services} </TableCell>
                          <TableCell> {data.amount} </TableCell>
                          <TableCell>
                            <Label
                              color={
                                Boolean(data.is_complete) ? "success" : "error"
                              }
                            >
                              {" "}
                              {Boolean(data.is_complete)
                                ? "Complete"
                                : "Incomplete "}{" "}
                            </Label>
                          </TableCell>
                          <TableCell>
                            <Label
                              color={
                                Boolean(data.is_reschedule)
                                  ? "success"
                                  : "primary"
                              }
                            >
                              {" "}
                              {Boolean(data.is_reschedule) ? "Yes" : "No "}{" "}
                            </Label>
                          </TableCell>
                          <TableCell>
                            <Box display="flex">
                              <Tooltip arrow title="Reschedule Appointment">
                                <Box>
                                  <IconButton
                                    onClick={() =>
                                      setReschedDialog({
                                        data: data,
                                        open: true,
                                      })
                                    }
                                    disabled={Boolean(data.is_complete)}
                                  >
                                    <ScheduleIcon />
                                  </IconButton>
                                </Box>
                              </Tooltip>
                              <Tooltip arrow title="Appointment Details">
                                <IconButton
                                  onClick={() =>
                                    setDetailsDialog({
                                      data: data,
                                      open: true,
                                    })
                                  }
                                >
                                  <ArrowRightAltIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Typography color="secondary">
                            No appointment found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography color="primary">please wait...</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </TableContainer>
        </Box>
      </Box>

      {/* add local appointment */}
      <Dialog
        open={createDialog}
        onClose={() => setCreateDialog(false)}
        disableBackdropClick
      >
        <DialogTitle disableTypography>
          <Typography variant="subtitle2" className="gtc-uppercase">
            Create Appointment
          </Typography>
        </DialogTitle>
        <PatientDetailsAppointmentCreate
          patient_id={patient_id}
          close={() => setCreateDialog(false)}
          reload={getAppointmentListLocal}
        />
      </Dialog>

      {/* details appointment */}
      <Dialog
        open={detailsDialog.open}
        onClose={() => setDetailsDialog({ data: null, open: false })}
        disableBackdropClick
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle disableTypography>
          <Typography variant="subtitle2" className="gtc-uppercase">
            Appointment Details
          </Typography>
        </DialogTitle>
        {detailsDialog.data && (
          <PatientDetailsAppointmentDetails
            app={detailsDialog.data}
            close={() => setDetailsDialog({ data: null, open: false })}
          />
        )}
      </Dialog>

      {/* appointment reschedule */}
      <Dialog
        open={reschedDialog.open}
        onClose={() => setReschedDialog({ data: null, open: false })}
        disableBackdropClick
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle disableTypography>
          <Typography variant="subtitle2" className="gtc-uppercase">
            Appointment Reschedule
          </Typography>
        </DialogTitle>
        {reschedDialog.data && (
          <PatientDetailsAppointmentResched
            app={reschedDialog.data}
            close={() => setReschedDialog({ data: null, open: false })}
            reload={getAppointmentListLocal}
          />
        )}
      </Dialog>
    </>
  );
};

export default PatientDetailsAppointment;
