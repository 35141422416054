import {
  Grid,
  Box,
  Paper,
  FormHelperText,
  Divider,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useState, useEffect } from "react";
import Container from "src/layout/Container";
import ImagingTestList from "./ImagingTestList";
import Notify from "src/notification/Notify";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";

const ImagingTest = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const username = localStorage.getItem("username");

  const [testList, setTestList] = useState({
    data: [],
    ready: false,
  });

  const getFormData = (object) => {
    const formData = new FormData();

    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const getImagingTest = async () => {
    try {
      var params = { user_id: user_id, management_id: management_id, token };
      const request = await axios.get("imaging/test/getAllTest", { params });
      setTestList({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      Notify.requestError(error);
    }
  };

  useEffect(() => {
    getImagingTest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "imaging test",
          items: [{ name: "Dashboard", path: "/clinic/app" }],
        }}
        title="Imaging Test"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} md={8}>
            <ImagingTestList list={testList} getImagingTest={getImagingTest} />
          </Grid>

          <Grid item xs={12} sm={5} md={4}>
            <Box component={Paper} variant="outlined" p={2}>
              <Formik
                initialValues={{
                  username: username,
                  user_id: user_id,
                  management_id: management_id,
                  token: token,
                  order: "",
                  shots: "",
                  rate: "",
                  password: "",
                }}
                validationSchema={Yup.object().shape({
                  shots: Yup.number().min(1).required(),
                  order: Yup.string().trim().required(),
                  rate: Yup.number().min(1).required(),
                  password: Yup.string().required(),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting, resetForm }
                ) => {
                  try {
                    const request = await axios.post(
                      "imaging/test/newtest-save",
                      getFormData(values)
                    );
                    if (request.data === "pass-invalid") {
                      setErrors({ password: "Password is invalid." });
                      Notify.fieldInvalid("password");
                    }
                    if (request.data === "success") {
                      Notify.successRequest("add new imaging test");
                      resetForm();
                      setSubmitting(true);
                      getImagingTest();
                    }
                  } catch (error) {
                    const message = error.message || "Something went wrong";
                    setErrors({ submit: message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Box mb={2}>
                      <Typography color="primary" variant="subtitle1">
                        NEW IMAGING TEST
                      </Typography>
                    </Box>

                    <Divider />

                    <Box my={2} className={`labselect2`}>
                      <TextField
                        error={Boolean(touched.order && errors.order)}
                        helperText={touched.order && errors.order}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.order}
                        fullWidth
                        required
                        name="order"
                        label="Order Description"
                        variant="outlined"
                      />
                    </Box>

                    <Box my={2} className={`labselect2`}>
                      <TextField
                        error={Boolean(touched.shots && errors.shots)}
                        helperText={touched.shots && errors.shots}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.shots}
                        fullWidth
                        required
                        name="shots"
                        label="Number of shots"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 1,
                            max: 100,
                            step: 1,
                          },
                        }}
                      />
                    </Box>

                    <Box mb={2} className={`labselect2`}>
                      <TextField
                        error={Boolean(touched.rate && errors.rate)}
                        helperText={touched.rate && errors.rate}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.rate}
                        fullWidth
                        required
                        name="rate"
                        label="Order Rate"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 1,
                            max: 100000,
                            step: 0.01,
                          },
                        }}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        fullWidth
                        required
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        label="Enter your password"
                        variant="outlined"
                        type="password"
                      />
                    </Box>

                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}

                    <Divider />

                    <Box my={2} display="flex">
                      <Box flexGrow={1} />

                      <Button
                        size="large"
                        variant="contained"
                        color="default"
                        type="reset"
                        startIcon={<ClearIcon />}
                      >
                        Clear
                      </Button>
                      <Box ml={2}>
                        <Button
                          size="large"
                          type="submit"
                          variant="contained"
                          color="primary"
                          startIcon={<CheckIcon />}
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default ImagingTest;
