import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import Notify from 'src/notification/Notify';
import { blue, red } from '@material-ui/core/colors';

const LaboratoryOrderSibDetails = ({ selectedIdAndCat }) => {
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id');
    const orderID = selectedIdAndCat.orderID;
    const orderCategory = selectedIdAndCat.orderCategory;
    const [dataSet, setDataSet] = useState({ data: [], ready: false, category: null })

    const fetchClinicChemical = useCallback(() => {
        var params = {
            token: token,
            user_id: user_id,
            order_id: orderID,
        }
        Axios.get('patient/order/order-id-chem', { params })
            .then((response) => {
                const data = response.data;
                setDataSet({ data: data, ready: true, category: 'chem' })
            })
            .catch(error => {
                Notify.requestError(error);
            })
    }, [user_id, token, orderID])

    const fetchClinicMicroscopy = useCallback(() => {
        var params = {
            token: token,
            user_id: user_id,
            order_id: orderID,
        }
        Axios.get('patient/order/order-id-micro', { params })
            .then((response) => {
                const data = response.data;
                setDataSet({ data: data, ready: true, category: 'micro' })
            })
            .catch(error => {
                Notify.requestError(error);
            })
    }, [user_id, token, orderID])

    const fetchFecalAnalysis = useCallback(() => {
        var params = {
            token: token,
            user_id: user_id,
            order_id: orderID,
        }
        Axios.get('patient/order/order-id-fecal', { params })
            .then((response) => {
                const data = response.data;
                setDataSet({ data: data, ready: true, category: 'fecal' })
            })
            .catch(error => {
                Notify.requestError(error);
            })
    }, [user_id, token, orderID])

    const fetchHemathology = useCallback(() => {
        var params = {
            token: token,
            user_id: user_id,
            order_id: orderID,
        }
        Axios.get('patient/order/order-id-hema', { params })
            .then((response) => {
                const data = response.data;
                setDataSet({ data: data, ready: true, category: 'hema' })
            })
            .catch(error => {
                Notify.requestError(error);
            })
    }, [user_id, token, orderID])

    const fetchSerology = useCallback(() => {
        var params = {
            token: token,
            user_id: user_id,
            order_id: orderID,
        }
        Axios.get('patient/order/order-id-sero', { params })
            .then((response) => {
                const data = response.data;
                setDataSet({ data: data, ready: true, category: 'sero' })
            })
            .catch(error => {
                Notify.requestError(error);
            })
    }, [user_id, token, orderID])


    useEffect(() => {
        if (orderCategory === 'clinical-chem') {
            fetchClinicChemical();
        }
        if (orderCategory === 'clinical-micro') {
            fetchClinicMicroscopy();
        }
        if (orderCategory === 'fecal-analysis') {
            fetchFecalAnalysis();
        }
        if (orderCategory === 'hemathology') {
            fetchHemathology();
        }
        if (orderCategory === 'serology') {
            fetchSerology();
        }
    }, [fetchClinicChemical, fetchClinicMicroscopy, fetchFecalAnalysis, fetchHemathology, fetchSerology, orderCategory])

    return (
        <Fragment>
            <TableContainer>
                <Table>
                    <TableBody>
                        {dataSet.ready ?
                            dataSet.data !== null && Object.keys(dataSet.data).length > 0 ?
                                <Fragment>
                                    {dataSet.category === 'chem' && (
                                        <Fragment>
                                            {dataSet.data.glucose !== null && (<TableRow>
                                                <TableCell>Glucose</TableCell>
                                                <TableCell>{dataSet.data.glucose}</TableCell>
                                            </TableRow>)}
                                            {dataSet.data.creatinine !== null && (<TableRow>
                                                <TableCell> Creatinine </TableCell>
                                                <TableCell> {dataSet.data.creatinine} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.uric_acid !== null && (<TableRow>
                                                <TableCell> Uric Acid </TableCell>
                                                <TableCell> {dataSet.data.uric_acid} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.cholesterol !== null && (<TableRow>
                                                <TableCell> Cholesterol </TableCell>
                                                <TableCell> {dataSet.data.cholesterol} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.triglyceride !== null && (<TableRow>
                                                <TableCell> Triglyceride </TableCell>
                                                <TableCell> {dataSet.data.triglyceride} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.hdl_cholesterol !== null && (<TableRow>
                                                <TableCell> HDL </TableCell>
                                                <TableCell> {dataSet.data.hdl_cholesterol} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.ldl_cholesterol !== null && (<TableRow>
                                                <TableCell> LDL </TableCell>
                                                <TableCell> {dataSet.data.ldl_cholesterol} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.sgot !== null && (<TableRow>
                                                <TableCell> SGOT </TableCell>
                                                <TableCell> {dataSet.data.sgot} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.sgpt !== null && (<TableRow>
                                                <TableCell> SGPT </TableCell>
                                                <TableCell> {dataSet.data.sgpt} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.glucose === null && dataSet.data.creatinine === null && dataSet.data.uric_acid === null && dataSet.data.cholesterol === null && dataSet.data.triglyceride === null && dataSet.data.hdl_cholesterol === null && dataSet.data.ldl_cholesterol === null && dataSet.data.sgot === null && dataSet.data.sgpt === null && (<TableRow>
                                                <TableCell colSpan={2} align="center"> No result yet. </TableCell>
                                            </TableRow>)}
                                        </Fragment>
                                    )}

                                    {dataSet.category === 'micro' && (
                                        <Fragment>
                                            {parseFloat(dataSet.data.chemical_test) > 0 && (
                                                dataSet.data.chemical_test_color !== null || dataSet.data.chemical_test_transparency !== null || dataSet.data.chemical_test_ph !== null || dataSet.data.chemical_test_spicific_gravity !== null || dataSet.data.chemical_test_glucose !== null || dataSet.data.chemical_test_albumin !== null ?
                                                    <TableRow>
                                                        <TableCell colSpan={2} align="left"> <strong color="primary">Chemical Test: </strong></TableCell>
                                                    </TableRow>
                                                    : null
                                            )}
                                            {dataSet.data.chemical_test_color !== null && (<TableRow>
                                                <TableCell> Color </TableCell>
                                                <TableCell> {dataSet.data.chemical_test_color} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.chemical_test_transparency !== null && (<TableRow>
                                                <TableCell> Transparency </TableCell>
                                                <TableCell> {dataSet.data.chemical_test_transparency} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.chemical_test_ph !== null && (<TableRow>
                                                <TableCell> PH </TableCell>
                                                <TableCell> {dataSet.data.chemical_test_ph} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.chemical_test_spicific_gravity !== null && (<TableRow>
                                                <TableCell> Specific Gravity </TableCell>
                                                <TableCell> {dataSet.data.chemical_test_spicific_gravity} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.chemical_test_glucose !== null && (<TableRow>
                                                <TableCell> Glucose </TableCell>
                                                <TableCell> {dataSet.data.chemical_test_glucose} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.chemical_test_albumin !== null && (<TableRow>
                                                <TableCell> Albumin </TableCell>
                                                <TableCell> {dataSet.data.chemical_test_albumin} </TableCell>
                                            </TableRow>)}
                                            {parseFloat(dataSet.data.microscopic_test) > 0 && (
                                                dataSet.data.microscopic_test_squamous !== null || dataSet.data.microscopic_test_pus !== null || dataSet.data.microscopic_test_redblood !== null || dataSet.data.microscopic_test_hyaline !== null || dataSet.data.microscopic_test_wbc !== null || dataSet.data.microscopic_test_rbc !== null || dataSet.data.microscopic_test_fine_granular !== null || dataSet.data.microscopic_test_coarse_granular !== null || dataSet.data.microscopic_test_calcium_oxalate !== null || dataSet.data.microscopic_test_triple_phospahte !== null || dataSet.data.microscopic_test_leucine_tyrosine !== null || dataSet.data.microscopic_test_ammonium_biurate !== null || dataSet.data.microscopic_test_amorphous_urates !== null || dataSet.data.microscopic_test_amorphous_phosphates !== null || dataSet.data.microscopic_test_uricacid !== null || dataSet.data.microscopic_test_mucus_thread !== null || dataSet.data.microscopic_test_bacteria !== null || dataSet.data.microscopic_test_yeast !== null ?
                                                    <TableRow>
                                                        <TableCell colSpan={2} align="left"> <strong color="primary">Microscopic Test: </strong></TableCell>
                                                    </TableRow>
                                                    : null
                                            )}
                                            {dataSet.data.microscopic_test_squamous !== null && (<TableRow>
                                                <TableCell> Squamous Cells </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_squamous} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_pus !== null && (<TableRow>
                                                <TableCell> Pus Cells </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_pus} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_redblood !== null && (<TableRow>
                                                <TableCell> Red Blood Cells </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_redblood} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_hyaline !== null && (<TableRow>
                                                <TableCell> Hyaline Cast </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_hyaline} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_wbc !== null && (<TableRow>
                                                <TableCell> WBC Cast </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_wbc} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_rbc !== null && (<TableRow>
                                                <TableCell> RBC Cast </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_rbc} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_fine_granular !== null && (<TableRow>
                                                <TableCell> Fine Granular Cast </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_fine_granular} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_coarse_granular !== null && (<TableRow>
                                                <TableCell> Coarse Granular Cast </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_coarse_granular} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_calcium_oxalate !== null && (<TableRow>
                                                <TableCell> Calcium Oxalate </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_calcium_oxalate} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_triple_phospahte !== null && (<TableRow>
                                                <TableCell> Triple Phosphate </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_triple_phospahte} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_leucine_tyrosine !== null && (<TableRow>
                                                <TableCell> Leucine/Tyrosine </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_leucine_tyrosine} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_ammonium_biurate !== null && (<TableRow>
                                                <TableCell> Ammonium Biurate </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_ammonium_biurate} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_amorphous_urates !== null && (<TableRow>
                                                <TableCell> Amorphous Urates </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_amorphous_urates} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_amorphous_phosphates !== null && (<TableRow>
                                                <TableCell> Amorphous Phosphates </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_amorphous_phosphates} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_uricacid !== null && (<TableRow>
                                                <TableCell> Uric Acid </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_uricacid} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_mucus_thread !== null && (<TableRow>
                                                <TableCell> Mucus Thread </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_mucus_thread} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_bacteria !== null && (<TableRow>
                                                <TableCell> Bacteria </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_bacteria} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.microscopic_test_yeast !== null && (<TableRow>
                                                <TableCell> Yeast </TableCell>
                                                <TableCell> {dataSet.data.microscopic_test_yeast} </TableCell>
                                            </TableRow>)}
                                            {parseFloat(dataSet.data.pregnancy_test_hcg) > 0 && (
                                                dataSet.data.pregnancy_test_hcg_result !== null ?
                                                    <TableRow>
                                                        <TableCell colSpan={2} align="left"> <strong color="primary">Pregnancy Test: </strong></TableCell>
                                                    </TableRow>
                                                    : null
                                            )}
                                            {dataSet.data.pregnancy_test_hcg_result !== null && (<TableRow>
                                                <TableCell> Result </TableCell>
                                                <TableCell> {dataSet.data.pregnancy_test_hcg_result} </TableCell>
                                            </TableRow>)}
                                            {/* no record yet indication */}
                                            {dataSet.data.chemical_test_color === null && dataSet.data.chemical_test_transparency === null && dataSet.data.chemical_test_ph === null && dataSet.data.chemical_test_spicific_gravity === null && dataSet.data.chemical_test_glucose === null && dataSet.data.chemical_test_albumin === null && dataSet.data.microscopic_test_squamous === null && dataSet.data.microscopic_test_pus === null && dataSet.data.microscopic_test_redblood === null && dataSet.data.microscopic_test_hyaline === null && dataSet.data.microscopic_test_wbc === null && dataSet.data.microscopic_test_rbc === null && dataSet.data.microscopic_test_fine_granular === null && dataSet.data.microscopic_test_coarse_granular === null && dataSet.data.microscopic_test_calcium_oxalate === null && dataSet.data.microscopic_test_triple_phospahte === null && dataSet.data.microscopic_test_leucine_tyrosine === null && dataSet.data.microscopic_test_ammonium_biurate === null && dataSet.data.microscopic_test_amorphous_urates === null && dataSet.data.microscopic_test_amorphous_phosphates === null && dataSet.data.microscopic_test_uricacid === null && dataSet.data.microscopic_test_mucus_thread === null && dataSet.data.microscopic_test_bacteria === null && dataSet.data.microscopic_test_yeast === null && dataSet.data.pregnancy_test_hcg_result === null && (<TableRow>
                                                <TableCell colSpan={2} align="center"> No result yet. </TableCell>
                                            </TableRow>)}
                                        </Fragment>
                                    )}
                                    {dataSet.category === 'fecal' && (
                                        <Fragment>
                                            {dataSet.data.cellular_elements_color !== null && (<TableRow>
                                                <TableCell> Color </TableCell>
                                                <TableCell> {dataSet.data.cellular_elements_color} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.cellular_elements_consistency !== null && (<TableRow>
                                                <TableCell> Consistency </TableCell>
                                                <TableCell> {dataSet.data.cellular_elements_consistency} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.cellular_elements_pus !== null && (<TableRow>
                                                <TableCell> Pus </TableCell>
                                                <TableCell> {dataSet.data.cellular_elements_pus} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.cellular_elements_rbc !== null && (<TableRow>
                                                <TableCell> RBC </TableCell>
                                                <TableCell> {dataSet.data.cellular_elements_rbc} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.cellular_elements_fat_globules !== null && (<TableRow>
                                                <TableCell> Fat Globules </TableCell>
                                                <TableCell> {dataSet.data.cellular_elements_fat_globules} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.cellular_elements_occultblood !== null && (<TableRow>
                                                <TableCell> Occult Blood </TableCell>
                                                <TableCell> {dataSet.data.cellular_elements_occultblood} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.cellular_elements_bacteria !== null && (<TableRow>
                                                <TableCell> Bacteria </TableCell>
                                                <TableCell> {dataSet.data.cellular_elements_bacteria} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.cellular_elements_color === null && dataSet.data.cellular_elements_consistency === null && dataSet.data.cellular_elements_pus === null && dataSet.data.cellular_elements_rbc === null && dataSet.data.cellular_elements_fat_globules === null && dataSet.data.cellular_elements_occultblood === null && dataSet.data.cellular_elements_bacteria === null && (<TableRow>
                                                <TableCell colSpan={2} align="center"> No result yet. </TableCell>
                                            </TableRow>)}
                                        </Fragment>
                                    )}
                                    {dataSet.category === 'hema' && (
                                        <Fragment>
                                            {dataSet.data.hemoglobin !== null && (<TableRow>
                                                <TableCell> Hemoglobin </TableCell>
                                                <TableCell> {dataSet.data.hemoglobin} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.hematocrit !== null && (<TableRow>
                                                <TableCell> Hematocrit </TableCell>
                                                <TableCell> {dataSet.data.hematocrit} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.rbc !== null && (<TableRow>
                                                <TableCell> RBC </TableCell>
                                                <TableCell> {dataSet.data.rbc} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.wbc !== null && (<TableRow>
                                                <TableCell> WBC </TableCell>
                                                <TableCell> {dataSet.data.wbc} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.platelet_count !== null && (<TableRow>
                                                <TableCell> Platelet Count </TableCell>
                                                <TableCell> {dataSet.data.platelet_count} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.differential_count !== null && (<TableRow>
                                                <TableCell> Differential Count </TableCell>
                                                <TableCell> {dataSet.data.differential_count} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.neutrophil !== null && (<TableRow>
                                                <TableCell> Neutrophil </TableCell>
                                                <TableCell> {dataSet.data.neutrophil} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.lymphocyte !== null && (<TableRow>
                                                <TableCell> Lymphocyte </TableCell>
                                                <TableCell> {dataSet.data.lymphocyte} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.monocyte !== null && (<TableRow>
                                                <TableCell> Monocyte </TableCell>
                                                <TableCell> {dataSet.data.monocyte} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.eosinophil !== null && (<TableRow>
                                                <TableCell> Eosinophil </TableCell>
                                                <TableCell> {dataSet.data.eosinophil} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.basophil !== null && (<TableRow>
                                                <TableCell> Basophil </TableCell>
                                                <TableCell> {dataSet.data.basophil} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.bands !== null && (<TableRow>
                                                <TableCell> Bands </TableCell>
                                                <TableCell> {dataSet.data.bands} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.abo_blood_type_and_rh_type !== null && (<TableRow>
                                                <TableCell> ABO Blood Type / RH Type </TableCell>
                                                <TableCell> {dataSet.data.abo_blood_type_and_rh_type} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.bleeding_time !== null && (<TableRow>
                                                <TableCell> Bleeding Time </TableCell>
                                                <TableCell> {dataSet.data.bleeding_time} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.clotting_time !== null && (<TableRow>
                                                <TableCell> Clotting Time </TableCell>
                                                <TableCell> {dataSet.data.clotting_time} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.hemoglobin === null && dataSet.data.hematocrit === null && dataSet.data.rbc === null && dataSet.data.wbc === null && dataSet.data.platelet_count === null && dataSet.data.differential_count === null && dataSet.data.neutrophil === null && dataSet.data.lymphocyte === null && dataSet.data.monocyte === null && dataSet.data.eosinophil === null && dataSet.data.basophil === null && dataSet.data.bands === null && dataSet.data.abo_blood_type_and_rh_type === null && dataSet.data.bleeding_time === null && dataSet.data.clotting_time === null && (<TableRow>
                                                <TableCell colSpan={2} align="center"> No result yet. </TableCell>
                                            </TableRow>)}
                                        </Fragment>
                                    )}
                                    {dataSet.category === 'sero' && (
                                        <Fragment>
                                            {dataSet.data.hbsag !== null && (<TableRow>
                                                <TableCell> Hepatitis B surface Antigen (HBsAg) </TableCell>
                                                <TableCell> {dataSet.data.hbsag} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.hav !== null && (<TableRow>
                                                <TableCell> HAV (Hepatitis A Virus) IgG/IgM </TableCell>
                                                <TableCell> {dataSet.data.hav} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.hcv !== null && (<TableRow>
                                                <TableCell> HCV (Hepatitis C Virus) </TableCell>
                                                <TableCell> {dataSet.data.hcv} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.vdrl_rpr !== null && (<TableRow>
                                                <TableCell> VDRL/RPR </TableCell>
                                                <TableCell> {dataSet.data.vdrl_rpr} </TableCell>
                                            </TableRow>)}
                                            {dataSet.data.hbsag === null && dataSet.data.hav === null && dataSet.data.hcv === null && dataSet.data.vdrl_rpr === null && (<TableRow>
                                                <TableCell colSpan={2} align="center"> No result yet. </TableCell>
                                            </TableRow>)}
                                        </Fragment>
                                    )}
                                </Fragment>
                                :
                                <TableRow >
                                    <TableCell style={{ color: red[500] }} colSpan={2} align="center">No record.</TableCell>
                                </TableRow>
                            :
                            <TableRow>
                                <TableCell style={{ color: blue[500] }} colSpan={2} align="center">Loading...</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
}

export default LaboratoryOrderSibDetails;