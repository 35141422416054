import React, { useState } from "react";
import {
  Box,
  CardMedia,
  Grid,
  Typography,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  makeStyles,
  DialogActions,
} from "@material-ui/core";
import Axios from "axios";
import IsMountedRef from "../utils/IsMountedRef";
import SwipeableViews from "react-swipeable-views";
import { Skeleton } from "@material-ui/lab";
import { WifiOff } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL;

const useStyle = makeStyles((theme) => ({
  roundedImage: {
    maxWidth: 150,
  },
  thumbnail: {
    width: "100%",
    objectFit: "cover",
    borderRadius: 10,
    cursor: "grab",
  },
  thumbnail_title: {
    background: "linear-gradient(to right, rgba(0,0,0,0.8), rgba(0,0,0,0))",
    color: "#fff",
    position: "absolute",
    bottom: 1,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 3,
    paddingBottom: 3,
    borderBottomLeftRadius: 15,
    fontSize: window.innerWidth > 600 ? "20px" : "12px",
    whiteSpace: "nowrap",
    width: "90%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
    "&:hover": {
      color: "#0078ff",
    },
  },
  tag: {
    zIndex: 1,
    position: "absolute",
    right: 60,
    paddingTop: 10,
  },
  tag_label: {
    backgroundColor: "#00000094",
    color: "#fff",
    padding: 2,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 20,
  },
  customOpacity: {
    backgroundColor: "#0000008a",
  },
}));

function NewsInDialog() {
  const token = localStorage.getItem("token");
  const classes = useStyle();
  const [featured, setFeatured] = React.useState({ data: [], ready: false });
  const [list, setList] = React.useState({ data: [], ready: false });
  const [activeStep, setActiveStep] = React.useState(0);
  const [dialog, setDialog] = useState(true);
  const ismounted = IsMountedRef();
  const themes = useTheme();

  const getNewsInDialogFeatured = async () => {
    try {
      var params = { is_featured: 3, token: token };
      let response = await Axios.get("gtc/news/featured-list", { params });
      if (ismounted.current) {
        setFeatured({ data: response.data, ready: true });
      }
    } catch (error) {
      setFeatured({ ...list, ready: true });
    }
  };

  const getNewsInDialogFeaturedList = async () => {
    try {
      var params = { is_featured: 2, token: token };
      let response = await Axios.get("gtc/news/featured-list", { params });
      if (ismounted.current) {
        setList({ data: response.data, ready: true });
      }
    } catch (error) {
      setList({ ...list, ready: true });
    }
  };

  React.useEffect(() => {
    getNewsInDialogFeatured();
    getNewsInDialogFeaturedList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        maxWidth={"sm"}
        fullWidth
        fullScreen={window.innerWidth > 600 ? false : true}
        className={classes.customOpacity}
      >
        <Box color={themes.palette.primary.main}>
          <DialogTitle>Global Telemedicine Corp.</DialogTitle>
        </Box>

        <DialogContent dividers>
          <Box mb={3}>
            {featured.ready ? (
              featured.data.length > 0 ? (
                <>
                  <div className={classes.tag}>
                    <span className={classes.tag_label}>
                      {`${activeStep + 1} / ${featured.data.length}`}
                    </span>
                  </div>
                  <SwipeableViews
                    index={activeStep}
                    onChangeIndex={(step) => setActiveStep(step)}
                    enableMouseEvents
                    style={styles.featuredContainer}
                    slideStyle={styles.slideContainer}
                  >
                    {featured.data.map((data, index) => (
                      <Box key={index}>
                        <img
                          // mponent={"img"}
                          alt="gtc-news"
                          src={`${imageLocation}news/thumbnail/${data.news_thumbnail}`}
                          className={classes.thumbnail}
                        />

                        <div
                          className={classes.thumbnail_title}
                          onClick={() => window.open(data.news_url, "_blank")}
                        >
                          {data.news_title}
                        </div>
                      </Box>
                    ))}
                  </SwipeableViews>
                </>
              ) : null
            ) : (
              <Box>
                <Skeleton
                  variant="rect"
                  width={"100%"}
                  height={250}
                  style={{ borderRadius: 5 }}
                />
              </Box>
            )}
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              {list.ready ? (
                list.data.length > 0 ? (
                  list.data.map((data, index) => (
                    <Paper
                      elevation={1}
                      key={index}
                      component={Box}
                      mb={2}
                      onClick={() => window.open(data.news_url, "_blank")}
                    >
                      <Box display="flex">
                        <Box
                          flexGrow={1}
                          className={classes.roundedImage}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          alignSelf="center"
                          p={1}
                        >
                          <CardMedia
                            component="img"
                            alt=""
                            src={`${imageLocation}news/thumbnail/${data.news_thumbnail}`}
                          />
                        </Box>
                        <Box ml={2}>
                          <Box>
                            <Typography variant="subtitle2" color="primary">
                              {data.tag}
                            </Typography>
                          </Box>

                          <Box component={"div"} textOverflow="ellipsis">
                            {data.news_title}
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  ))
                ) : null
              ) : (
                <Box width={"100%"}>
                  <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={150}
                    style={{ borderRadius: 5 }}
                  />
                </Box>
              )}
            </Grid>
          </Grid>

          {featured.ready &&
            featured.data.length === 0 &&
            list.ready &&
            list.data.length === 0 && (
              <Box
                display="flex"
                align="center"
                alignItems="center"
                justifyContent="center"
                alignSelf={"center"}
              >
                <Box p={2} color="secondary" noWrap>
                  <Box mb={2}>
                    <WifiOff size={100} style={{ color: "#f50057" }} />
                  </Box>
                  <Box>
                    <Typography variant="h6" color="secondary">
                      GTC News featured is currently empty. try again later.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
        </DialogContent>

        <DialogActions>
          <Button size={"large"} variant="text" color="primary">
            Read More...
          </Button>
          <Button
            size={"large"}
            variant="contained"
            color="primary"
            onClick={() => setDialog(false)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default NewsInDialog;

const styles = {
  featuredContainer: {
    padding: "0 20px",
    justifyContent: "center",
    alignItems: "center",
  },
  slideContainer: { padding: "0 10px" },
};
