import React from "react";
import RouteDoctor from "../routes/RouteDoctor";
import RoutePatients from "../routes/RoutePatients";
import RouteAdministrator from "../routes/RouteAdministrator";
import RouteLaboratory from "../routes/RouteLaboratory";
import RouteClinicSecretary from "../routes/RouteClinicSecretary";
import RouteTelerad from "src/routes/RouteTelerad";
import RouteRadiologist from "src/routes/RouteRadiologist";
import RouteClinicSecretaryLocal from "src/routes/RouteEncoder";
import RouteImagingNew from "src/routes/RouteImagingNew";
import RoutePharmacyNew from "src/routes/RoutePharmacyNew";
import RoutePharmacyWarehouse from "src/routes/RoutePharmacyWarehouse";

const LoginChecker = (type) => {
  if (type === "Pharmacy") {
    return <RoutePharmacyNew />;
  } else if (type === "Doctor") {
    return <RouteDoctor />;
  } else if (type === "Patient") {
    return <RoutePatients />;
  } else if (type === "Administrator") {
    return <RouteAdministrator />;
  } else if (type === "Laboratory") {
    return <RouteLaboratory />;
  } else if (type === "Imaging") {
    return <RouteImagingNew />;
  } else if (type === "ClinicSecretary") {
    return <RouteClinicSecretary />;
  } else if (type === "Teleradiologist") {
    return <RouteTelerad />;
  } else if (type === "Radiologist") {
    return <RouteRadiologist />;
  } else if (type === "Pharmacy-Warehouse") {
    return <RoutePharmacyWarehouse />;
  } else if (type === "Encoder") {
    return <RouteClinicSecretaryLocal />;
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("is_login");
    window.location.href = "/clinic/app";
  }
};

export default LoginChecker;
