
import React, { useState, useEffect, Fragment, useCallback } from 'react';   
import Axios from 'axios';
import Notify from '../../notification/Notify'; 
import { Grid, Box, Collapse, Typography, TablePagination, DialogTitle, DialogContent, DialogActions, Dialog, Button, CircularProgress, Zoom, Menu, MenuItem, Card, CardHeader, CardMedia, Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import NotesForm from './NotesForm';
import ChevronRight from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'; 
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete'; 
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import NotesCanvas from './NotesCanvas';
import { Lightbox } from "react-modal-image";
import { Wallpaper } from '@material-ui/icons';

const imageLocation = process.env.REACT_APP_API_IMAGE;

const Notes = (props) => {

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id')) 
 
    const [hasNotes, sethasNotes] = useState(false);  
    const [category, setCategory] = useState(null);  
    
    const [notes, setnotes] = useState([])
    const [notesready, setnotesready] = useState(false)

    const [selectedId, setSelectedId] = useState(null);

    const [rowsPerPage, setRowsPerPage] = useState(5);    
    const [page, setPage] = useState(0);  

    const [canvasRowsPerPage, setCanvasRowsPerPage] = useState(5);    
    const [canvasPage, setCanvasPage] = useState(0);  

    const [removeDialog, setRemoveDialog] = useState(false);
    const [removeid, setRemoveId] = useState(null);
    const [isProcess, setIsProcess] = useState(null);

    const [anchorUpdate, setAnchorUpdate] = React.useState(null);

    const [openCanvasDialog, setOpenCanvasDialog] = useState(false) 

    const [canvasNotes ,setCanvasNotes] = useState({
        data:[],
        ready: false
    })

    const [selectedNotes, setSelectedNotes] = useState({ 
        diagnosis: null,
        notes: null,
        id: null
    }) 

    const [imagebox, setImageBox] = useState({
        image: null,
        open: false,
    })

    const handleClick = (event) => {
        setAnchorUpdate(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorUpdate(null);
    };

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };   


    const handleCanvasChangePage = (event, newPage) => {
        event.persist()
        setCanvasPage(newPage);
    };
    
    const handleCanvasChangeRowsPerPage = (event) => {
        setCanvasRowsPerPage(parseInt(event.target.value, 10));
        setCanvasPage(0);
    };  

    const getNotes = useCallback(() =>{
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('patient_id', props.patient_id);    
        
        Axios.post('doctor/patient/notes/notes-list', formdata )
        .then( (response) => { 
            const data = response.data;    
            setnotes(data)
            setnotesready(true)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    },[token, user_id, props.patient_id]);
    

    const handleRemoveNotes = (e) =>{
        e.preventDefault();
        e.persist();

        var formdata = new FormData();
            formdata.append('token',  token);
            formdata.append('user_id',  user_id); 
            formdata.append('notes_id',  removeid);
        var error = []; 

        if(formdata.get('notes_id').length === 0 || formdata.get('notes_id').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('notes'); 
        }

        if(error.length > 0){
            Notify.consoleLog('delete notes')
        }else{
            setIsProcess(true)
            Axios.post('doctor/patient/notes/delete-notes', formdata)
            .then((response)=>{ 
                const data = response.data;
                if(data === 'success'){
                    setRemoveId(null)
                    setRemoveDialog(false)
                    getNotes();
                    Notify.successRequest('delete notes');
                } 
                if(data === 'db-error'){
                    Notify.warnRequest('delete notes')
                } 
            })
            .catch((error)=>{ 
                Notify.requestError(error);
            }).finally(()=>{
                setIsProcess(false)
            })
        }

    }


    const getCanvasNotes = useCallback( async () =>{
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);     
        formdata.set('patient_id', props.patient_id);     
        formdata.set('type', 'file');     
        
        Axios.post('doctor/patient/notes/patient-canvasnoteslist', formdata )
        .then( (response) => { 
            const data = response.data;    
            setCanvasNotes({
                data: data,
                ready: true
            }) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    },[token, user_id, props]) 

    useEffect(() =>{

        getNotes()

        getCanvasNotes() 
    }, [getNotes, getCanvasNotes])  

    return (
        <Fragment>
            <Grid container spacing={2}>  
                <Grid item xs={12} sm={12} md={7} lg={8}> 
                    <Box 
                        p={2} 
                        component={Paper}
                        elevation={2}
                    >
                        {/* <Box display="flex">
                            <Box flexGrow={1}> 
                                <Typography variant="h6" color="textSecondary"> Notes </Typography> 
                            </Box>
                            <Box>
                                <Button  
                                    color="primary" 
                                    onClick={ () => { sethasNotes(true); setCategory('add-notes'); }}
                                    hidden={ hasNotes ? true : false }
                                    startIcon={
                                        <AddCircleOutlineIcon />
                                    }
                                >
                                    Update
                                </Button> 
                            </Box> 
                        </Box> */}
 
                        <Box display="flex"> 
                            <Box flexGrow={1}> 
                                <Typography variant="h6" color="textSecondary"> Notes </Typography> 
                            </Box>
                            <Button
                                onClick={handleClick}
                                color="primary"
                                startIcon={ <AddCircleOutlineIcon /> }
                            >
                                update
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorUpdate}
                                keepMounted
                                open={Boolean(anchorUpdate)}
                                onClose={handleClose} 
                            >
                                <MenuItem  onClick={ () => { sethasNotes(true); setCategory('add-notes'); }} > New Notes </MenuItem>
                                <MenuItem onClick = { () => setOpenCanvasDialog(true) } > New Notes Canvas </MenuItem> 
                            </Menu> 
                        </Box>  
                        
                        <Collapse in={ hasNotes ? true : false } timeout={1000}>
                            <NotesForm 
                                patient_id = { props.patient_id }
                                closeForm ={ () => {
                                    sethasNotes(false);
                                    setCategory(null);
                                    setSelectedNotes({
                                        diagnosis: null,
                                        notes: null,
                                        id: null
                                    });
                                }}
                                getNotes = { () => getNotes() }
                                category = { category }
                                editNotes = { selectedNotes} 
                            />
                        </Collapse>  
 
                        {/* notes list */}
                        <Box hidden={ hasNotes ? true : false }> 
                            {
                                notesready ? 
                                    notes.length > 0 ?
                                        (rowsPerPage > 0
                                            ? notes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : notes
                                        ).map((data, index) => ( 
                                            <Box 
                                                p={1} 
                                                key={index}
                                                borderRadius={4} 
                                            >
                                                <Box display="flex">
                                                    <Box flexGrow={1}>
                                                        <Typography 
                                                            variant="subtitle1"
                                                            noWrap 
                                                        > 
                                                            { data.initial_diagnosis }
                                                            <br />
                                                            <small className="text-muted"> { Notify.dateTimeConvert(data.created_at) } </small>
                                                        </Typography>
                                                    </Box>   

                                                    <Box> 
                                                        <IconButton
                                                            color="primary"
                                                            onClick={ () => setSelectedId(selectedId === data.id ? null : data.id)}
                                                        >
                                                            { selectedId === data.id ? <KeyboardArrowDownIcon fontSize="small" />  : <ChevronRight fontSize="small" /> } 
                                                        </IconButton> 
                                                        <IconButton 
                                                            color="primary"
                                                            onClick={ () => {
                                                                sethasNotes(true);
                                                                setCategory('edit-notes');
                                                                setSelectedNotes({
                                                                    diagnosis: data.initial_diagnosis,
                                                                    notes: data.notes,
                                                                    id: data.notes_id
                                                                })
                                                            }}
                                                        >
                                                            <EditIcon fontSize="small"/>
                                                        </IconButton>
                                                        <IconButton 
                                                            color="secondary"
                                                            onClick={
                                                                ()=>{
                                                                    setRemoveDialog(true);
                                                                    setRemoveId(data.notes_id)
                                                                }
                                                            }
                                                        >
                                                            <DeleteIcon fontSize="small"/>
                                                        </IconButton>
                                                    </Box>
                                                </Box>

                                                <Collapse in={selectedId === data.id ? true : false }> 
                                                    <div className="py-2" dangerouslySetInnerHTML={{__html: data.notes}} />
                                                </Collapse>  
                                            </Box>
                                        ))
                                    : Notify.noRecord()
                                : Notify.loading()
                            } 
                        </Box>
                        <TablePagination
                            hidden={ hasNotes ? true : false }
                            labelRowsPerPage = "List"
                            rowsPerPageOptions={[5, 20, 50, 100]}
                            component="div"
                            count={notes.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Box>
                </Grid>  
            
            
                <Grid item xs={12} sm={12} md={5} lg={4}> 
                    <Box>
                        {
                            canvasNotes.ready ? 
                                canvasNotes.data.length > 0 ?
                                    (canvasRowsPerPage > 0
                                        ? canvasNotes.data.slice(canvasPage * canvasRowsPerPage, canvasPage * canvasRowsPerPage + canvasRowsPerPage)
                                        : canvasNotes.data
                                    ).map((data, index) =>{
                                        return(
                                            <Card key={index} component={Box} mb={2}> 
                                                <CardHeader
                                                    disableTypography
                                                    title={ Notify.dateTimeConvert(data.created_at) }
                                                /> 
                                                <CardMedia
                                                    component="img"
                                                    src={`${imageLocation}doctors/notes/${data.canvas}`}
                                                    alt=""
                                                    style={{ maxHeight: 100 }}
                                                    onClick={ () =>{
                                                        setImageBox({
                                                            image: data.canvas,
                                                            open:  true
                                                        })
                                                    }}
                                                />
                                            </Card>
                                        )
                                    })
                                : 
                                <Box p={3} component={Paper} align="center"> 
                                    {/* { Notify.noRecord() }  */}
                                    <Wallpaper color="error" style={{ fontSize: '3rem'}} />
                                    <Typography color="error"> No canvas note created</Typography>
                                </Box>
                            : <Box align="center" mt={3}> 
                                { Notify.loading() }
                            </Box>
                        } 
                    </Box>
                    <TablePagination
                        rowsPerPageOptions={[5, 20, 50, 100]}
                        component="div"
                        count={canvasNotes.data.length}
                        rowsPerPage={canvasRowsPerPage}
                        page={canvasPage}
                        onChangePage={handleCanvasChangePage}
                        onChangeRowsPerPage={handleCanvasChangeRowsPerPage}
                        labelRowsPerPage="List"
                    />

                    {/* openimage larger */}
                    {
                        imagebox.open && imagebox.image && (
                            <Lightbox
                                large={imageLocation+'doctors/notes/'+imagebox.image}
                                alt=""  
                                onClose = { () =>{
                                    setImageBox({
                                        image: null,
                                        open:  false
                                    })
                                }}
                            />
                        )
                    }
                </Grid>
            </Grid>
            {/* delete notes */}
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                open={removeDialog} 
                TransitionComponent={Zoom}
                transitionDuration={500}
            > 
                <DialogContent>  
                    <Typography>
                        Are you sure to remove this note? 
                    </Typography>
                </DialogContent >
                <DialogActions>
                    <Button 
                        variant="contained" 
                        color="inherit" 
                        startIcon={<HighlightOffIcon />}
                        onClick={ () => setRemoveDialog(false)}
                    >
                        NO
                    </Button>
                    <Button 
                        autoFocus 
                        variant="contained" 
                        color="secondary" 
                        startIcon={
                            isProcess ?  <CircularProgress color="inherit" size={15} />  : <CheckCircleOutline /> }
                        disabled={ isProcess } 
                        onClick={ handleRemoveNotes }
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
         
            {/* canvas dialog notes */}
            <Dialog
                open={ openCanvasDialog }
                onClose= { () => setOpenCanvasDialog(false) }
                disableBackdropClick
                disableEscapeKeyDown
                fullScreen
            > 
                <DialogTitle
                    disableTypography
                >
                    <Typography variant="subtitle2" className={`gtc-uppercase`}>
                        Draw Notes
                    </Typography>
                </DialogTitle> 

                <NotesCanvas 
                    getCanvasNotes = { getCanvasNotes }
                    close= { () => setOpenCanvasDialog(false) } 
                    patient_id= { props.patient_id } /> 
            </Dialog>
        </Fragment>
    )
}

export default Notes; 