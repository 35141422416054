import React from 'react'
import { Box, Typography, Grid } from '@material-ui/core';
import QRCode from 'qrcode.react';
import { isMobile } from "react-device-detect";

const MyQRCode = () => {
    const user_id = localStorage.getItem('user_id');

    return (
        <>
            <Box m={4} />
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <Grid container justify="center" alignContent="center">
                        <QRCode value={user_id} level="H" size={250} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center" alignContent="center">
                        <Box m={4}>
                            <Typography
                                variant={isMobile ? 'subtitle1' : 'h6'}
                                color="primary"
                            >
                                Delivery purposes only.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default MyQRCode;