import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Typography, IconButton, Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import Label from 'src/utils/Label';
import TrashIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LaunchIcon from '@material-ui/icons/Launch';
import { useHistory } from 'react-router-dom';
import PermissionRemove from './PermissionRemove';
import PermissionApproved from './PermissionApproved';
import Container from 'src/layout/Container';
import { Alert } from 'react-bootstrap';

const Permissions = () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [permission, setPermission] = useState([])
    const [permissionRd, setPermissionRd] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();

    const [selectedPermission, setSelectedPermission] = useState({
        open: false,
        permission: null,
        doctors_id: null
    })

    const [approvePermission, setApprovePermission] = useState({
        open: false,
        permission: null,
        doctors_id: null
    })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getPermission = useCallback(async () => {
        try {
            var params = {
                token: token,
                user_id: user_id,
            }
            const request = await Axios.get('patient/permission/permission-getList', { params })
            setPermission(request.data)
            setPermissionRd(true)
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, user_id])

    useEffect(() => {
        getPermission()
    }, [getPermission])

    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'permission',
                    items: [
                        { name: 'Dashboard', path: '/clinic/app/patient' }
                    ]
                }}
                title="Medical Record Access"
            >
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={8} md={9} lg={10}>
                        <Paper
                            component={Box}
                            variant="outlined"
                            p={2}
                        >
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                <strong>
                                                    Doctors
                                                </strong>
                                            </TableCell>
                                            <TableCell align="center">
                                                <strong>
                                                    Date
                                                </strong>
                                            </TableCell>
                                            <TableCell align="center">
                                                <strong>
                                                    Status
                                                </strong>
                                            </TableCell>
                                            <TableCell align="center">
                                                <strong>
                                                    Action
                                                </strong>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            permissionRd ?
                                                permission.length > 0 ?
                                                    permission.map((data, index) => (
                                                        <TableRow
                                                            key={index}
                                                            hover
                                                        >
                                                            <TableCell align="left">
                                                                <Typography
                                                                    className={`gtc-capitalize`}
                                                                >
                                                                    {data.doctors_name}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {Notify.dateTimeConvert(data.created_at)}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Typography
                                                                    className={`gtc-capitalize`}
                                                                >
                                                                    <Label color={data.permission_status === 'approved' ? "success" : "error"}> {data.permission_status} </Label>
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                            >
                                                                <IconButton
                                                                    color="default"
                                                                    onClick={handleClick}
                                                                >
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                                <Menu
                                                                    id="simple-menu"
                                                                    anchorEl={anchorEl}
                                                                    keepMounted
                                                                    open={Boolean(anchorEl)}
                                                                    onClose={handleClose}
                                                                >
                                                                    {data.permission_status === 'approved' ? (
                                                                        <MenuItem
                                                                            onClick={() => {
                                                                                handleClose();
                                                                                setSelectedPermission({
                                                                                    open: true,
                                                                                    permission: data.permission_id,
                                                                                    doctors_id: data.doctors_id
                                                                                });
                                                                            }}
                                                                        >
                                                                            <ListItemIcon>
                                                                                <TrashIcon />
                                                                            </ListItemIcon>
                                                                            <Typography>
                                                                                Remove
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    ) : (
                                                                        <MenuItem
                                                                            onClick={() => {
                                                                                handleClose();
                                                                                setApprovePermission({
                                                                                    open: true,
                                                                                    permission: data.permission_id,
                                                                                    doctors_id: data.doctors_id
                                                                                });
                                                                            }}
                                                                        >
                                                                            <ListItemIcon>
                                                                                <TrashIcon />
                                                                            </ListItemIcon>
                                                                            <Typography>
                                                                                Approve
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    )}
                                                                    <MenuItem
                                                                        onClick={() => history.push(`/clinic/app/patient/appointment/doctor/${data.doctors_id}`)}
                                                                    >
                                                                        <ListItemIcon>
                                                                            <LaunchIcon />
                                                                        </ListItemIcon>
                                                                        <Typography>
                                                                            View Doctor
                                                                        </Typography>
                                                                    </MenuItem>
                                                                </Menu>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                    :
                                                    <TableRow>
                                                        <TableCell colSpan={4}>
                                                            <Typography color="secondary">
                                                                No doctor(s) have access to your health record.
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={4}>
                                                        <Typography color="primary">
                                                            Loading...
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={2} >
                        <Box>
                            <Alert variant="info text-center border border-info">
                                <Typography align="center" variant="h2"> {permission.length > 0 ? permission[0].hasaccess_count : 0} </Typography>
                                <Typography align="center" variant="subtitle2"> Doctor(s) have access to your health records. </Typography>
                            </Alert>
                        </Box>
                    </Grid>

                    {/* remove dialog */}
                    {selectedPermission && (
                        <PermissionRemove
                            reload={getPermission}
                            remove={selectedPermission}
                            close={() => setSelectedPermission({
                                open: false,
                                permission: null,
                                doctors_id: null
                            })}
                        />)}

                    {/* remove dialog */}
                    {selectedPermission && (
                        <PermissionApproved
                            reload={getPermission}
                            approved={approvePermission}
                            close={() => setApprovePermission({
                                open: false,
                                permission: null,
                                doctors_id: null
                            })}
                        />)}
                </Grid>
            </Container>
        </Fragment>
    )
}

export default Permissions;