import { Grid, Typography, Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import axios from "axios";
import Label from "src/utils/Label";
import { BarChart2 } from "react-feather";
import { useHistory } from "react-router";
import { Fragment } from "preact";

const ClinicalMicroscopy = ({ trace_number, patient_id }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");

  const [details, setDetails] = useState([]);

  const history = useHistory();

  const getpaidLaboratoryOrderDetails = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("patient_id", patient_id);
    formdata.set("trace_number", trace_number);
    formdata.set("table", "laboratory_microscopy");
    formdata.set("connection", "local");

    axios
      .post("doctor/patient/laboratory/order/paid-detailsbytable", formdata)
      .then((res) => {
        const data = res.data;
        setDetails(data);
      })
      .catch((er) => Notify.requestError(er));
  };

  const checkResult = (order) => {
    return order === "new-order" ? (
      <Label color="error"> no result </Label>
    ) : order === "refund" ? (
      <Label color="warning"> {order} </Label>
    ) : (
      <Label color="success"> {order} </Label>
    );
  };

  useEffect(() => {
    getpaidLaboratoryOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trace_number, patient_id]);

  return (
    <>
      {details.length > 0 && (
        <Box my={2} p={2}>
          <Box display="flex" mb={2}>
            <Box flexGrow={1}>
              <Typography variant="h6">
                <strong> Clinical Microscopy Order </strong>
              </Typography>
            </Box>
            <Box>
              <Button
                color="primary"
                variant="text"
                startIcon={<BarChart2 />}
                onClick={() =>
                  history.push(
                    `/clinic/app/doctor/patient/laboratory/microscopy/${patient_id}`
                  )
                }
                className={`d-print-none`}
              >
                Graph
              </Button>
            </Box>
          </Box>

          {details.map((data, key) => (
            <Fragment key={key}>
              {key === 0 && (
                <Box mt={1} display="flex">
                  <Typography variant="caption">
                    <b> SPECIMEN: </b>
                  </Typography>
                  <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                    {checkResult(data.spicemen)}
                  </Box>
                </Box>
              )}

              <Box mb={2}>
                {/* clinical microscopy */}
                {Boolean(parseInt(data.chemical_test)) && (
                  <>
                    {parseInt(data.chemical_test) === 2 ? ( // 2 is refund
                      <Box display={"flex"} mt={1}>
                        <Box>
                          <Typography variant="caption">
                            <b> CHEMICAL TEST </b>
                          </Typography>
                        </Box>
                        <Box
                          mx={2}
                          align="center"
                          minWidth={100}
                          borderBottom={1}
                        >
                          <Label color="warning"> refund </Label>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Box mt={1}>
                          <Typography variant="caption">
                            <b> CHEMICAL TEST </b>
                          </Typography>
                        </Box>
                        <Box ml={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={4} sm={4}>
                              <Box mt={1} display="flex">
                                <Typography variant="caption">
                                  COLOR:
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  {checkResult(data.chemical_test_color)}
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography variant="caption">
                                  TRANSPARENCY:
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  {checkResult(data.chemical_test_transparency)}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                              <Box mt={1} display="flex">
                                <Typography variant="caption"> PH: </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  {checkResult(data.chemical_test_ph)}
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography variant="caption">
                                  SPECIFIC GRAVITY:
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  {checkResult(
                                    data.chemical_test_spicific_gravity
                                  )}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                              <Box mt={1} display="flex">
                                <Typography variant="caption">
                                  GLUCOSE:
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  {checkResult(data.chemical_test_glucose)}
                                </Box>
                              </Box>

                              <Box mt={1} display="flex">
                                <Typography variant="caption">
                                  ALBUMIN:
                                </Typography>
                                <Box
                                  mx={2}
                                  align="center"
                                  minWidth={100}
                                  borderBottom={1}
                                >
                                  {checkResult(data.chemical_test_albumin)}
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Box>

              <Box mb={2}>
                {/* clinical microscopy */}
                {Boolean(parseInt(data.microscopic_test)) && (
                  <>
                    {parseInt(data.microscopic_test) === 2 ? ( // 2 is refund
                      <Box display={"flex"}>
                        <Box>
                          <Typography variant="caption">
                            <b> MICROSCOPIC TEST </b>
                          </Typography>
                        </Box>
                        <Box
                          mx={2}
                          align="center"
                          minWidth={100}
                          borderBottom={1}
                        >
                          <Label color="warning"> refund </Label>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Box>
                          <Typography variant="caption">
                            <b> MICROSCOPIC TEST </b>
                          </Typography>
                        </Box>
                        <Box ml={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                              <>
                                <Box mt={1}>
                                  <Typography variant="caption">
                                    <b> CELLS </b>
                                  </Typography>
                                </Box>
                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    SQUAMOUS CELLS
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.microscopic_test_squamous
                                    )}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    PUS CELLS
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(data.microscopic_test_pus)}
                                  </Box>
                                  <Typography variant="caption">
                                    /HPF
                                  </Typography>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    RED BLOOD CELLS
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.microscopic_test_redblood
                                    )}
                                  </Box>
                                  <Typography variant="caption">
                                    /HPF
                                  </Typography>
                                </Box>
                              </>
                              <>
                                <Box mt={1}>
                                  <Typography variant="caption">
                                    <b> CASTS </b>
                                  </Typography>
                                </Box>
                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    HYALINE CAST
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(data.microscopic_test_hyaline)}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    WBC CAST
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(data.microscopic_test_wbc)}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    RBC CAST
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(data.microscopic_test_rbc)}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    FINE GRANUALAR CAST
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.microscopic_test_fine_granular
                                    )}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    COARSE GRANUALAR CAST
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.microscopic_test_coarse_granular
                                    )}
                                  </Box>
                                </Box>
                              </>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                              <>
                                <Box mt={1}>
                                  <Typography variant="caption">
                                    <b> CRYSTALS </b>
                                  </Typography>
                                </Box>
                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    CALCIUM OXALATE
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.microscopic_test_calcium_oxalate
                                    )}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    TRIPLE PHOSPHATE
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.microscopic_test_triple_phospahte
                                    )}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    LEUCINE/TYROSINE
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.microscopic_test_leucine_tyrosine
                                    )}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    AMMONIUM BIURATE
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.microscopic_test_ammonium_biurate
                                    )}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    AMORPHOUS URATES
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.microscopic_test_amorphous_urates
                                    )}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    AMORPHOUS PHOSPHATES
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.microscopic_test_amorphous_phosphates
                                    )}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    URIC ACID
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.microscopic_test_uricacid
                                    )}
                                  </Box>
                                </Box>
                              </>
                              <>
                                <Box mt={1}>
                                  <Typography variant="caption">
                                    <b> OTHERS </b>
                                  </Typography>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    MUCUS THREAD
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.microscopic_test_mucus_thread
                                    )}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    BACTERIA
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(
                                      data.microscopic_test_bacteria
                                    )}
                                  </Box>
                                </Box>

                                <Box mt={1} display="flex">
                                  <Typography variant="caption">
                                    YEAST
                                  </Typography>
                                  <Box
                                    mx={2}
                                    align="center"
                                    minWidth={100}
                                    borderBottom={1}
                                  >
                                    {checkResult(data.microscopic_test_yeast)}
                                  </Box>
                                </Box>
                              </>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Box>

              <Box mb={2}>
                {/* clinical microscopy */}
                {Boolean(parseInt(data.pregnancy_test_hcg)) && (
                  <>
                    <Box mt={1} display="flex">
                      <Typography variant="caption">
                        <b> PREGNANCY TEST (HCG): </b>
                      </Typography>
                      {parseInt(data.pregnancy_test_hcg) === 2 ? ( // 2 is refund
                        <>
                          <Box
                            mx={2}
                            align="center"
                            minWidth={100}
                            borderBottom={1}
                          >
                            <Label color="warning"> refund </Label>
                          </Box>
                        </>
                      ) : (
                        <Box
                          mx={2}
                          align="center"
                          minWidth={100}
                          borderBottom={1}
                        >
                          {checkResult(data.pregnancy_test_hcg_result)}
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </Box>

              <Box mb={2}>
                {Boolean(parseInt(data.seminalysis_test)) && (
                  <>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="subtitle1">
                          <b> SEMINALYSIS TEST </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box mt={1}>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                          }}
                        >
                          {data.seminalysis_result}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>

              <Box mb={2}>
                {Boolean(parseInt(data.grams_stain)) && (
                  <>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="subtitle1">
                          <b> GRAMS STAIN TEST </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box mt={1}>
                        <Typography
                          style={{
                            whiteSpace: "pre-line",
                          }}
                        >
                          {data.grams_stain_result}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>

              <Box mb={2}>
                {Boolean(parseInt(data.afb_stain)) && (
                  <>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="subtitle1">
                          <b> AFB STAIN TEST </b>
                        </Typography>
                      </Box>
                    </Box>

                    {Boolean(parseInt(data.is_processed)) && (
                      <Box mt={1}>
                        <Box>
                          <Typography
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {data.afb_stain_result}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </Box>

              {key === details.length - 1 && (
                <Box mb={2}>
                  {/* clinical microscopy */}
                  <>
                    <Box mt={1} display="flex">
                      <Typography variant="caption">
                        <b> REMARKS: </b>
                      </Typography>
                      <Box
                        mx={2}
                        align="center"
                        minWidth={100}
                        borderBottom={1}
                      >
                        {checkResult(details.result_remarks)}
                      </Box>
                    </Box>
                  </>
                </Box>
              )}
            </Fragment>
          ))}
        </Box>
      )}
    </>
  );
};

export default ClinicalMicroscopy;
