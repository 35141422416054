import { Grid, Card, Box, Typography, CardHeader, useTheme, CardContent, Hidden } from '@material-ui/core';
import React, { useState } from 'react' 
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventNote';
import EventIcon from '@material-ui/icons/Event'; 

import Label from 'src/utils/Label';  
import { useHistory } from 'react-router';
import axios from 'axios'
import IsMountedRef from 'src/utils/IsMountedRef';

const AppointmentCounts = () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const ismounted = IsMountedRef()
    const theme = useTheme() 
    const history = useHistory() 
    const [countNew, setCountNew] = useState(0)
    const [countApproved, setCountApproved] = useState(0)
    const [countCompleted, setCountCompleted] = useState(0)

    const getNewAppointmentCount = async() =>{
        try { 
            var params = {
                token : token,
                user_id : user_id,
                status : 'new'
            }
                
            const response = await axios.get('doctors/fullcalendar/appointment/counts-bystatus', { params })
            const data = response.data;
            if (ismounted.current) { 
                setCountNew(data.length)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const getApprovedAppointmentCount = async() =>{
        try {

            var params = {
                token : token,
                user_id : user_id,
                status : 'approved'
            }
 
            const response = await axios.get('doctors/fullcalendar/appointment/counts-bystatus', { params })
            const data = response.data;
            if (ismounted.current) { 
                setCountApproved(data.length)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getCompletedAppointmentCount = async() =>{
        try { 
            var params = {
                token : token,
                user_id : user_id,
                status : 'completed'
            }
            const response = await axios.get('doctors/fullcalendar/appointment/counts-bystatus', { params })
            const data = response.data;
            if (ismounted.current) { 
                setCountCompleted(data.length)
            }
        } catch (error) {
            console.log(error)
        }
    }


    React.useEffect(() =>{  
        
        getNewAppointmentCount()
        getApprovedAppointmentCount()
        getCompletedAppointmentCount()  

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ismounted]) 


    return(
        <>
            <Grid container spacing={2}>  
                <Grid item xs={4} sm={4} lg={4}>
                    <Card  
                        elevation={3}
                        className={'pointer'}
                        component={Box}
                        borderLeft={7}
                        borderColor={ theme.palette.warning.light }  
                        onClick={ () => history.push(`/clinic/app/doctor/appointment/calendar/list/new`) }
                    > 
                        <CardHeader 
                            style={{ padding: theme.spacing(1) }}
                            title={ <Label color="warning"> NEW </Label> }
                        />

                        <CardContent style={{ padding: theme.spacing(1) }}>
                            <Box 
                                align="center"
                                display="flex" 
                            > 
                                <Box flexGrow={1}>  
                                    <EventIcon style={{  color: theme.palette.warning.light, fontSize: window.innerWidth > 600 ? '4em' : '2.5em'}} /> 
                                </Box>
                                <Box flexGrow={1}>
                                    <Typography variant="h4" color="textSecondary"> { countNew } </Typography>
                                    <Hidden xsDown> <Typography variant="subtitle2" color="textSecondary"> APPOINTMENT </Typography> </Hidden>
                                </Box>
                            </Box> 
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={4} sm={4} lg={4}>
                    <Card  
                        elevation={2}
                        className={'pointer'}
                        component={Box}
                        borderLeft={7}
                        borderColor={ theme.palette.primary.light }  
                        onClick={ () => history.push(`/clinic/app/doctor/appointment/calendar/list/approved`) }
                    > 
                        <CardHeader 
                            style={{ padding: theme.spacing(1) }}
                            title={ <Label color="primary"> APPROVED </Label>   }
                        />

                        <CardContent style={{ padding: theme.spacing(1) }}>
                            <Box 
                                align="center"
                                display="flex" 
                            > 
                                <Box flexGrow={1}>  
                                    <EventBusyIcon style={{  color: theme.palette.primary.light, fontSize: window.innerWidth > 600 ? '4em' : '2.5em'}} /> 
                                </Box>
                                <Box flexGrow={1}>
                                    <Typography variant="h4" color="textSecondary"> { countApproved } </Typography>
                                    <Hidden xsDown> <Typography variant="subtitle2" color="textSecondary"> APPOINTMENT </Typography> </Hidden>
                                </Box>
                            </Box> 
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={4} sm={4} lg={4}> 
                    <Card  
                        elevation={2}
                        className={'pointer'}
                        component={Box}
                        borderLeft={7}
                        borderColor={ theme.palette.success.light }  
                        onClick={ () => history.push(`/clinic/app/doctor/appointment/calendar/list/completed`) }
                    > 
                        <CardHeader 
                            style={{ padding: theme.spacing(1) }}
                            title={ <Label color="success"> COMPLETED </Label> }
                        />

                        <CardContent style={{ padding: theme.spacing(1) }}>
                            <Box 
                                align="center"
                                display="flex" 
                            > 
                                <Box flexGrow={1}>  
                                    <EventAvailableIcon style={{  color: theme.palette.success.light, fontSize: window.innerWidth > 600 ? '4em' : '2.5em'}} /> 
                                </Box>
                                <Box flexGrow={1}>
                                    <Typography variant="h4" color="textSecondary"> { countCompleted } </Typography>
                                    <Hidden xsDown> <Typography variant="subtitle2" color="textSecondary"> APPOINTMENT </Typography> </Hidden>
                                </Box>
                            </Box> 
                        </CardContent>
                    </Card> 
                </Grid>  

            </Grid>  
              
        </>
    )
}

export default AppointmentCounts;