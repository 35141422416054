import React, { useEffect, useState, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import AppointmentDetails from "./AppointmentDetails";
import Notify from "../../notification/Notify";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Card,
  CardContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import Label from "src/utils/Label";
import Container from "src/layout/Container";
import { XCircle } from "react-feather";

const Appointment = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [appointment, setAppointment] = useState([]);
  const [appointmentReady, setAppointmentReady] = useState(false);
  const [_open_appointment, set_open_appointment] = useState(false);
  const [_selected_app_id, set_selected_app_id] = useState(null);

  const getAppointment = () => {
    var params = {
      token: token,
      user_id: user_id,
    };
    Axios.get("appointment/patient/appointment-list", { params })
      .then((response) => {
        const data = response.data;
        setAppointment(data);
        setAppointmentReady(true);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  // const getNotificationMsg = () => {
  //     var formdata = new FormData();
  //     formdata.set('token', token);
  //     formdata.set('user_id', user_id);

  //     Axios.post('appointment/patient/notification-msg', formdata)
  //         .then((response) => {
  //             const data = response.data;
  //             set_notification_count(data.length)
  //         }).catch(error => {
  //             Notify.requestError(error);
  //         });
  // }

  const handleSelectedAppointment = (e) => {
    set_selected_app_id(e.currentTarget.id);
    set_open_appointment(true);
  };

  useEffect(() => {
    getAppointment();
    // getNotificationMsg()
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "appointment list",
          items: [
            { name: "Dashboard", path: "/clinic/app/patient" },
            { name: "Appointment", path: "/clinic/app/patient/appointment" },
          ],
        }}
        title="Appointment List"
      >
        <Grid container spacing={2}>
          {appointmentReady ? (
            appointment.length > 0 ? (
              appointment.map((data, index) => {
                return (
                  <Grid key={index} item sm={6} lg={4}>
                    <Card
                      id={data.appointment_id}
                      onClick={handleSelectedAppointment}
                      className="pointer"
                    >
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            className="gtc-darkblue"
                          >
                            <div className="p-app-date-container mx-auto">
                              <FontAwesomeIcon icon={faCalendar} size="6x" />
                              <p className="app-date-day m-0 mt-3">
                                {new Date(
                                  Boolean(data.is_reschedule)
                                    ? data.is_reschedule_date
                                    : data.appointment_date.replace(/-/g, "/")
                                ).toLocaleString("en-US", { day: "2-digit" })}
                              </p>
                              <p className="app-date-time m-0">
                                {new Date(
                                  Boolean(data.is_reschedule)
                                    ? data.is_reschedule_date
                                    : data.appointment_date.replace(/-/g, "/")
                                ).toLocaleString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                })}
                              </p>
                              <p className="app-date-month m-0">
                                {new Date(
                                  Boolean(data.is_reschedule)
                                    ? data.is_reschedule_date
                                    : data.appointment_date.replace(/-/g, "/")
                                ).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                })}
                              </p>
                            </div>
                          </Grid>

                          <Grid item xs={12} sm={6} md={8}>
                            <Box mb={0.5}>
                              <Label
                                color={
                                  data.appointment_status === "successful"
                                    ? "primary"
                                    : data.appointment_status === "approved"
                                    ? "success"
                                    : "secondary"
                                }
                              >
                                {data.appointment_status}
                              </Label>
                            </Box>

                            <Box mb={0.5}>
                              <Typography
                                variant="caption"
                                className="gtc-uppercase mx-1"
                              >
                                ID :{" "}
                              </Typography>
                              <Typography
                                variant="caption"
                                className="gtc-uppercase mx-1"
                              >
                                {data.appointment_id}
                              </Typography>
                            </Box>

                            <Box mb={0.5}>
                              <Typography
                                variant="caption"
                                className="gtc-uppercase mx-1"
                              >
                                TYPE :
                              </Typography>
                              <Typography
                                variant="caption"
                                className="gtc-uppercase mx-1"
                              >
                                {data.services}
                              </Typography>
                            </Box>

                            <Box mb={0.5}>
                              <Typography
                                variant="caption"
                                className="gtc-uppercase mx-1"
                              >
                                CREDIT :
                              </Typography>
                              <Typography
                                variant="caption"
                                className="gtc-uppercase mx-1"
                              >
                                {data.credit_fee}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography
                                variant="caption"
                                className="gtc-uppercase mx-1"
                              >
                                DOCTOR :
                              </Typography>
                              <Typography
                                variant="caption"
                                className="gtc-uppercase mx-1"
                              >
                                {data.doctors_name}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <div className="col-sm-12 ">
                <div className="py-3"> {Notify.noRecord()} </div>
              </div>
            )
          ) : (
            <div className="col-sm-12 ">
              <div className="py-3"> {Notify.loading()} </div>
            </div>
          )}
        </Grid>

        {/* appointment dialog */}
        <Dialog
          open={_open_appointment}
          onClose={() => set_open_appointment(false)}
        >
          <DialogTitle>
            <Box display="flex">
              <Box flexGrow={1}>Appointment Details</Box>
              <Box>
                <IconButton
                  onClick={() => set_open_appointment(false)}
                  color="secondary"
                >
                  <XCircle />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <AppointmentDetails appointment_id={_selected_app_id} />
          </DialogContent>
        </Dialog>
      </Container>
    </Fragment>
  );
};

export default Appointment;
