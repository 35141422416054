import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Box,
  TableBody,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SorologyRef from "../references/Ref_Sorology";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const SorologyOrder = ({ formheader, serologyOrderDetails }) => {
  return (
    <>
      <Card>
        <Box display="flex" justifyContent="center">
          <Box mt={2}>
            {formheader && (
              <CardMedia
                style={{ width: 70 }}
                component={"img"}
                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
              />
            )}
          </Box>
          <Box>
            <CardHeader
              component={Box}
              align="center"
              title={formheader && formheader.name}
              subheader={formheader && formheader.address}
            />
          </Box>
        </Box>

        <CardContent>
          <Header details={serologyOrderDetails} />
        </CardContent>

        <CardContent>
          <Box>
            <Typography variant="h6" align="center">
              <b> SEROLOGY </b>
            </Typography>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <b> EXAMINATION REQUESTED </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> NORMAL </b>
                  </TableCell>
                  <TableCell align="center">
                    <b> TEST RESULT </b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {serologyOrderDetails.data.length > 0 ? (
                  serologyOrderDetails.data.map((data, key) => (
                    <Fragment key={key}>
                      {data.hbsag !== null && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              Hepatitis B surface Antigen (HBsAg)
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {SorologyRef.normal()}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{
                                whiteSpace: "pre-line",
                              }}
                            >
                              {data.hbsag}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.hav !== null && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              HAV (Hepatitis A Virus) lgG/lgM
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {SorologyRef.normal()}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{
                                whiteSpace: "pre-line",
                              }}
                            >
                              {data.hav}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.hcv !== null && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              HCV (Hepatitis C Virus)
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {SorologyRef.normal()}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{
                                whiteSpace: "pre-line",
                              }}
                            >
                              {data.hcv}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data.vdrl_rpr !== null && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              VDRL/RPR
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {SorologyRef.normal()}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{
                                whiteSpace: "pre-line",
                              }}
                            >
                              {data.vdrl_rpr}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {Boolean(parseInt(data.syphilis_test)) && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              SYPHILIS TEST
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* {SorologyRef.normal()} */}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{
                                whiteSpace: "pre-line",
                              }}
                            >
                              {data.syphilis_test_result}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {Boolean(parseInt(data.hiv_test)) && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              HIV TEST
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* {SorologyRef.normal()} */}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{
                                whiteSpace: "pre-line",
                              }}
                            >
                              {data.hiv_test_result}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {Boolean(parseInt(data.cd4_test)) && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              CD4 TEST
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* {SorologyRef.normal()} */}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{
                                whiteSpace: "pre-line",
                              }}
                            >
                              {data.cd4_test_result}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {Boolean(parseInt(data.rpr_test)) && (
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={`gtc-uppercase`}
                            >
                              RPR TEST
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {/* {SorologyRef.normal()} */}
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              style={{
                                whiteSpace: "pre-line",
                              }}
                            >
                              {data.rpr_test_result}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography color="secondary">
                        No record found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box>
            <Footer formheader={formheader} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default SorologyOrder;
