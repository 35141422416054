import axios from "axios";
import React, {  useEffect, useState } from "react";  
import {Box, Checkbox, FormControlLabel, Hidden, Menu, MenuItem, Button, Typography  } from "@material-ui/core";    
import Notify from "src/notification/Notify";    
import IsMountedRef from "src/utils/IsMountedRef"; 
import { Tag as BoxIcon, Tag } from "react-feather";
import { Line } from 'react-chartjs-2';   
import { amber, blue, green, indigo, red, purple, teal, yellow, orange, grey } from "@material-ui/core/colors";


const GraphChemistry = ({ type, patient_id, containerRef }) => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [ready, setReady] = useState(false) 
    const [label, setLabel] = useState([]) 
    const [anchorEl, setAnchorEl] = React.useState(null); 
    
    var chartRef = React.useRef() 
    const ismounted = IsMountedRef()

    
    const [glucose, setGlucose] = useState([]) 
    const [creatinine, setCreatinine] = useState([]) 
    const [uricAcid, setUricAcid] = useState([])   
    const [cholesterol, setCholesterol] = useState([])  
    const [triglyceride, setTriglyceride] = useState([])  
    const [hdl, setHdl] = useState([]) 
    const [ldl, setLdl] = useState([]) 
    const [sgot, setSgot] = useState([]) 
    const [sgpt, setSgpt] = useState([])  

    var interval = null;

    
    const getChemistryRecord = React.useCallback (async () => {
        try {

            var formdata = new FormData();
                formdata.set('token', token)
                formdata.set('user_id', user_id) 
                formdata.set('patient_id', patient_id)

            const response = await axios.post('doctor/patient/laboratory/chemistry/graph/get-chemistrydata', formdata)
                if(ismounted.current){
                    pushDataToChart(response.data)
                    setReady(true)
                }
        } catch (error) {
            console.log(error)
        }
    },[patient_id, user_id, token, ismounted])
    

    const pushDataToChart = (data) => {   
        for (let i = 0; i < data.length; i++) {  

            setGlucose((glucose) => glucose.concat(data[i].glucose === null ? 0 : data[i].glucose)) 
            setCreatinine((creatinine) => creatinine.concat(data[i].creatinine === null ? 0 : data[i].creatinine))  
            setUricAcid((uric_acid) => uric_acid.concat(data[i].uric_acid === null ? 0 : data[i].uric_acid))   
            setCholesterol((cholesterol) => cholesterol.concat(data[i].cholesterol === null ? 0 : data[i].cholesterol))   
            setTriglyceride((triglyceride) => triglyceride.concat(data[i].triglyceride === null ? 0 : data[i].triglyceride))   
            setHdl((hdl_cholesterol) => hdl_cholesterol.concat(data[i].hdl_cholesterol === null ? 0 : data[i].hdl_cholesterol))   
            setLdl((ldl_cholesterol) => ldl_cholesterol.concat(data[i].ldl_cholesterol === null ? 0 : data[i].ldl_cholesterol))   
            setSgot((sgot) => sgot.concat(data[i].sgot === null ? 0 : data[i].sgot))   
            setSgpt((sgpt) => sgpt.concat(data[i].sgpt === null ? 0 : data[i].sgpt))    

            setLabel((label) => label.concat(Notify.dateTimeConvert(data[i].created_at)))
        }  
    }  
   
    const initializeChartWidth = () =>{  
        chartRef.current.chartInstance.canvas.parentNode.style.width = `${label.length > 10 ? 2000 : containerRef.clientWidth - 47}px`;     
        chartRef.current.chartInstance.canvas.parentNode.style.height = `calc(85vh - 150px)`;     


        interval = setInterval(() =>{
            chartRef.current.chartInstance.canvas.parentNode.style.width = `${label.length > 10 ? 2000 : containerRef.clientWidth - 47}px`;     
            chartRef.current.chartInstance.canvas.parentNode.style.height = `calc(85vh - 150px)`;     
        },5000)
    }

    useEffect(() =>{ 

        if(chartRef.current){    
            getChemistryRecord() 
            initializeChartWidth() //cavas width checker for sidebar open or close
        }   
  
        return(() => clearInterval(interval))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])   
  
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleHideLegend  = ( type, key ) =>{
        if(type === 'all'){
            chartRef.current.chartInstance.data.datasets.forEach(function(ds) {
                ds.hidden = !ds.hidden; 
            });
        }else{
            chartRef.current.chartInstance.data.datasets[key].hidden = ! chartRef.current.chartInstance.data.datasets[key].hidden ;
        }  
        chartRef.current.chartInstance.update(); 
    } 

    const data = {
        labels: label,
        datasets: [
            {
                label: 'Glucose', 
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: green[600],
                borderColor: green[600], 
                pointHoverRadius: 8,
                borderWidth: 2,
                pointRadius: 8 ,
                lineTension: 0,
                data: glucose
            },
            {
                label: 'Creatinine', 
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: blue[600],
                borderColor: blue[600], 
                pointHoverRadius: 8,
                borderWidth: 2,
                pointRadius: 8 ,
                lineTension: 0,
                data: creatinine
            },
            {
                label: 'Uric Acid', 
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: red[600],
                borderColor: red[600], 
                pointHoverRadius: 8,
                borderWidth: 2,
                pointRadius: 8 ,
                lineTension: 0,
                data: uricAcid
            },
            {
                label: 'Cholesterol', 
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: purple[600],
                borderColor: purple[600], 
                pointHoverRadius: 8,
                borderWidth: 2,
                pointRadius: 8 ,
                lineTension: 0,
                data: cholesterol
            },
            {
                label: 'Triglyceride', 
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: yellow[600],
                borderColor: yellow[600], 
                pointHoverRadius: 8,
                borderWidth: 2,
                pointRadius: 8 ,
                lineTension: 0,
                data: triglyceride
            },
            {
                label: 'HDL - Cholesterol', 
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: orange[600],
                borderColor: orange[600], 
                pointHoverRadius: 8,
                borderWidth: 2,
                pointRadius: 8 ,
                lineTension: 0,
                data: hdl
            },
            {
                label: 'LDL - Cholesterol', 
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: indigo[600],
                borderColor: indigo[600], 
                pointHoverRadius: 8,
                borderWidth: 2,
                pointRadius: 8 ,
                lineTension: 0,
                data: ldl
            },
            {
                label: 'SGOT', 
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: teal[600],
                borderColor: teal[600], 
                pointHoverRadius: 8,
                borderWidth: 2,
                pointRadius: 8 ,
                lineTension: 0,
                data: sgot
            },
            {
                label: 'SGPT', 
                backgroundColor: 'rgba(0, 123, 155, 0)',
                pointBackgroundColor: amber[600],
                borderColor: amber[600], 
                pointHoverRadius: 8,
                borderWidth: 2,
                pointRadius: 8 ,
                lineTension: 0,
                data: sgpt
            }   
        ] 
    } 

    const options = {
        animationEnabled: true,  
        maintainAspectRatio: false,    
        legend:{
            position: 'bottom',
            cursor: 'pointer',   
            display: false, 
        }, 
        tooltips: {   
            enabled: true
        },
        animation:{
            onComplete: () => {  
                const copyWidth = chartRef.current.chartInstance.scales['y-axis-0'].width - 10;
                const copyHeight = chartRef.current.chartInstance.scales['y-axis-0'].height + chartRef.current.chartInstance.scales['y-axis-0'].top + 10;
                const targetCtx = document.getElementById("myChartAxis").getContext("2d");
                      targetCtx.canvas.width = copyWidth;
                      targetCtx.drawImage(chartRef.current.chartInstance.canvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight); 
            } 
        }
    } 

    return ( 
        <React.Fragment> 
            
            <ChartHeader handleHideLegend = { handleHideLegend }  type = { type }  handleClick = { handleClick } />

            <Box display="flex">     

                <Box flexGrow={1} > 
                    { ! ready && 
                        <Box align="center"> 
                           <Typography color="primary" variant="subtitle2" >
                                fetching chemistry records...
                           </Typography>
                        </Box>
                    }
                     

                    <div className="chartWrapper">  
                        <div 
                            style={{
                                width: `${containerRef.clientWidth - 30}px`,
                                overflowX: 'auto'
                            }}
                        >   
                            <div>
                                <Line    
                                    ref = { chartRef }
                                    data = { chartRef && data } 
                                    options={chartRef && options}  
                                />  
                            </div>
                        </div> 
                        <canvas id="myChartAxis" height="100%" width="0"></canvas> 
                    </div>  
                </Box>   

                <Box ml={2}>   
                    { chartRef && (
                        <RenderHemaOptions 
                            anchorEl = { anchorEl }
                            handleClose = { handleClose }
                            handleClick = { handleClick }
                            chartJs = {  chartRef.current  }
                            handleHideLegend = { handleHideLegend }
                        />
                    )}
                </Box>
            </Box>    
        </React.Fragment>
    );
}

export default GraphChemistry; 

const ChartHeader = ({ type, handleClick }) => (
    <Box display="flex" mb={2}>
        <Box flexGrow={1}>
            <Typography 
                className={`gtc-capitalize`} 
                variant={'h6'}
                color="textSecondary"
            >
                { type } Graph
            </Typography>
        </Box>
          
        <Box mx={2}>
            <Button startIcon={ <Tag size={15} /> } variant="outlined"  size="large" color="primary"  onClick={handleClick}>
                <Hidden xsDown> Graph </Hidden> LEgend
            </Button> 
        </Box> 
    </Box>
)

const RenderHemaOptions = ({ anchorEl, handleClose, chartJs, handleHideLegend }) => { 

    const [ hideAll, setHideAll] = useState(false)
    
    return (  
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}  
            PaperProps={{
                style: {
                    maxHeight: 300,
                    overflow: ' none',
                }
            }}
        >   
            <MenuItem> 
                <FormControlLabel
                    className={`gtc-uppercase`}
                    control={
                        <Checkbox 
                            checked={ hideAll }  
                            onChange={ () => {
                                handleHideLegend('all', 0)
                                setHideAll( !hideAll )
                            }}
                        />
                    }
                    label={ `Hide All` }
                />
            </MenuItem> 
            
            { chartJs && chartJs.chartInstance && ( 
                chartJs.chartInstance.legend.legendItems.map((data, index) =>(
                    <MenuItem 
                        key={index }
                    >  
                        <FormControlLabel
                            onClick={ () => handleHideLegend('byone', index)}
                            className={`gtc-uppercase`}
                            control={
                                <Box ml={1} mr={2}>  <BoxIcon color={data.hidden ? grey[200] : data.strokeStyle} /> </Box>
                            }
                            label={ data.text }
                        />
                    </MenuItem>
                ))  
            ) }  
        </Menu>   
    );
}
 