import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardHeader,
  CardContent,
  DialogTitle,
  DialogContent,
  Dialog,
  TextField,
  DialogActions,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import Notify from "src/notification/Notify";
import axios from "axios";
import { CheckSquare, Filter, XCircle } from "react-feather";
import { useReactToPrint } from "react-to-print";
import { Print } from "@material-ui/icons";

const ClinicPrescriptionReport = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const [dateFrom, setDateFrom] = useState(new Date("2020-01-01"));
  const [dateTo, setDateTo] = useState(new Date());
  const [orderBy, setOrderBy] = useState("asc");
  const [filterDialog, setFilterDialog] = useState(false);

  const [prescription, setPrescription] = useState({
    data: [],
    ready: false,
  });

  var printableRef = useRef();

  var totalPurchased = 0;

  const handleReportByDate = () => {
    if (
      parseInt(new Date(dateTo).getTime()) <
      parseInt(new Date(dateFrom).getTime())
    ) {
      Notify.customToast(
        "Invalid Date To",
        "Date To must be greather than Date From"
      );
    } else {
      setPrescription({
        data: [],
        ready: false,
      });
      getLocalPrescriptionByDate();
    }
  };

  const calculateTotalPruchased = (amount, qty) => {
    var subtotal = amount === null ? 0 : parseFloat(amount) * parseInt(qty);
    totalPurchased += subtotal;
    return Notify.convertToNumber(subtotal);
  };

  const getLocalPrescriptionByDate = () => {
    var error = [];

    if (
      parseInt(new Date(dateTo).getTime()) <
      parseInt(new Date(dateFrom).getTime())
    ) {
      error = "error";
      Notify.customToast(
        "Invalid Date To",
        "Date To must be greather than Date From"
      );
    }
    if (error.length > 0) {
      console.log("Error is sample only..");
    } else {
      axios
        .get("doctor/billing/records/presc-by-date", {
          params: {
            token,
            management_id,
            user_id,
            date_from: dateFrom,
            date_to: dateTo,
            connection: "offline",
            prescription_type: "clinic",
            orderBy,
          },
        })
        .then((response) => {
          const data = response.data;
          setPrescription({ data: data, ready: true });
        })
        .catch((error) => {
          Notify.requestError(error);
        });
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  useEffect(() => {
    getLocalPrescriptionByDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card ref={printableRef}>
        <CardHeader
          title={
            <Box display="flex">
              <Box flexGrow={1}>
                Clinic Prescription
                <Typography variant="subtitle2">
                  From : {Notify.createdAt(dateFrom.toLocaleDateString())} - To
                  :{Notify.createdAt(dateTo.toLocaleDateString())}
                </Typography>
              </Box>

              <Box className="d-none-print">
                <Button
                  onClick={() => setFilterDialog(true)}
                  color="primary"
                  startIcon={<Filter />}
                >
                  Filter
                </Button>
              </Box>
            </Box>
          }
        />

        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Patient</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Order Qty</TableCell>
                  <TableCell>Purchased Qty</TableCell>
                  <TableCell>Rem. Qty</TableCell>
                  <TableCell>Total Purchased</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {prescription.ready ? (
                  prescription.data.length > 0 ? (
                    prescription.data.map((data, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {Notify.dateTimeConvert(data.created_at)}
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {`${data.firstname} ${data.firstname}`}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {data.product_amount === null
                              ? 0
                              : parseFloat(data.product_amount)}
                          </TableCell>
                          <TableCell align="right"> {data.quantity} </TableCell>
                          <TableCell align="right">
                            {data.quantity_claimed}
                          </TableCell>
                          <TableCell align="right">
                            {parseInt(data.quantity) -
                              parseInt(data.quantity_claimed)}
                          </TableCell>
                          <TableCell align="right">
                            {calculateTotalPruchased(
                              data.product_amount,
                              data.quantity_claimed
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <Typography color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Typography color="primary"> Please wait... </Typography>
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePrint}
                      className="d-print-none"
                      startIcon={<Print />}
                    >
                      Print
                    </Button>
                  </TableCell>
                  <TableCell colSpan={5} align="right">
                    <Typography>
                      <b> Total Purchased </b>
                    </Typography>
                  </TableCell>

                  <TableCell align="right">
                    <Typography>
                      <b> &#8369; {Notify.convertToNumber(totalPurchased)} </b>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Box p={2}>
        {/* Fiter Dialog */}
        <Dialog open={filterDialog} onClose={() => setFilterDialog(false)}>
          <DialogTitle> Filter </DialogTitle>
          <DialogContent dividers>
            <Box>
              <DatePicker
                autoOk
                fullWidth
                label="Date From"
                format="MM/dd/yyyy"
                inputVariant="outlined"
                value={dateFrom}
                onChange={(date) => setDateFrom(date)}
              />
            </Box>

            <Box my={2}>
              <DatePicker
                autoOk
                fullWidth
                label="Date To"
                format="MM/dd/yyyy"
                inputVariant="outlined"
                value={dateTo}
                onChange={(date) => setDateTo(date)}
              />
            </Box>

            <Box my={2}>
              <TextField
                fullWidth
                label="Order By"
                value={orderBy}
                onChange={(e) => setOrderBy(e.target.value)}
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              >
                <option value="asc"> Ascending </option>
                <option value="desc"> Descending </option>
              </TextField>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setFilterDialog(false)}
              startIcon={<XCircle />}
            >
              No
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleReportByDate();
                setFilterDialog(false);
              }}
              startIcon={<CheckSquare />}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ClinicPrescriptionReport;
