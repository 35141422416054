import {
  Box,
  Dialog,
  TextField,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  CardMedia,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import Notify from "src/notification/Notify";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DraggableDialog from "src/utils/DraggableDialog";
import { useHistory } from "react-router-dom";
import { CheckSquare, XCircle } from "react-feather";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const CBCOrderDetails = ({
  formheader,
  order,
  getLabHemaOrder,
  resetDisplay,
}) => {
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");

  const history = useHistory();
  const [savebtnDisabled, setSavebtnDisabled] = useState(true);
  const [orderDetails, setOrderDetails] = useState({
    data: null,
    ready: false,
  });

  const [pendingDialog, setPendingDialog] = useState(false);
  const [processDialog, setProcessDialog] = useState({
    data: null,
    dialog: false,
  });

  const [pendingSubmitting, setPendingSubmitting] = useState(false);
  const [processSubmitting, setProcessSubmitting] = useState(false);
  const [resultSubmitting, setResultSubmitting] = useState(false);

  // const [orderItems, setOrderItems] = useState([]);

  const saveButtonRef = React.useRef();
  const [confirmDialog, setConfirmDialog] = useState(false);

  const getLabOrderDetails = () => {
    var params = { token, user_id, trace_number: order.trace_number };
    Axios.get("laboratory/order/ordernew-cbc/details", { params })
      .then((response) => {
        const data = response.data;
        setOrderDetails({
          data: data,
          ready: true,
        });

        checkIfSaveBtnIsEnabled(data);
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const checkIfSaveBtnIsEnabled = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (Boolean(data[i].is_processed)) {
        setSavebtnDisabled(false);
      }
    }
  };

  useEffect(() => {
    getLabOrderDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleSaveResult = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("trace_number", order.trace_number);

    var error = [];
    setResultSubmitting(true);
    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      Axios.post("laboratory/order/ordernew-cbc/save-process-result", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("order result added. Redirect to receipt.");
            setTimeout(() => {
              history.push(
                `/clinic/app/laboratory/record/print/order/${order.trace_number}`
              );
            }, 5000);

            setSavebtnDisabled(true);
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setResultSubmitting(false));
    }
  };

  const handlePendingOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("token", token);
    formdata.set("username", username);
    formdata.set("order_id", order.order_id);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setPendingSubmitting(true);
      Axios.post(
        "laboratory/order/ordernew-hemathology/save-setpending",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            getLabHemaOrder();
            resetDisplay();
            setPendingDialog(false);
            Notify.successRequest("order pending");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setPendingSubmitting(false));
    }
  };

  const handleProcessOrder = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("username", username);
    formdata.set("order_id", processDialog.data.order_id);

    var error = [];

    if (error.length > 0) {
      console.log("Form has an error.");
    } else {
      setProcessSubmitting(true);
      Axios.post("laboratory/order/ordernew-cbc/save-setprocessing", formdata)
        .then((response) => {
          const data = response.data;
          if (data.message === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data.message === "success") {
            getLabOrderDetails();
            setProcessDialog({ dialog: false, data: null });
            Notify.successRequest("order processed");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => setProcessSubmitting(false));
    }
  };

  const handleProcessSelectedOrder = async (data) => {
    setProcessDialog({ data: data, dialog: true });
  };

  return (
    <>
      <form onSubmit={handleSaveResult}>
        <Card elevation={0}>
          <Box display="flex" justifyContent="center">
            <Box mt={2}>
              {formheader && (
                <CardMedia
                  style={{ width: 70 }}
                  component={"img"}
                  src={`${imageLocation}laboratory/logo/${formheader.logo}`}
                />
              )}
            </Box>
            <Box>
              <CardHeader
                component={Box}
                align="center"
                title={formheader && formheader.name}
                subheader={formheader && formheader.address}
              />
            </Box>
          </Box>

          <CardContent>
            {/* paitent information */}
            <Box display="flex">
              <Box flexGrow={1} mb={2}>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      NAME:
                    </Typography>
                    {`${order.firstname} ${order.lastname}`}
                  </Typography>
                </Box>

                <Box display="flex" mb={2}>
                  <Box>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        AGE:
                      </Typography>
                      {order.birthday === null
                        ? "none"
                        : Notify.calculateAge(order.birthday)}
                    </Typography>
                  </Box>
                  <Box ml={5}>
                    <Typography>
                      <Typography
                        variant="caption"
                        className="font-weight-bold"
                      >
                        SEX:
                      </Typography>
                      {order.gender === null ? "none" : order.gender}
                    </Typography>
                  </Box>
                </Box>

                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      ADDRESS:
                    </Typography>
                    {`${order.street} ${order.barangay} ${order.city} ${order.zip} `}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="caption" className="font-weight-bold">
                    PATIENT CONDITION:
                  </Typography>
                  {order.patient_condition === null
                    ? " none"
                    : order.patient_condition}
                </Box>
              </Box>

              <Box>
                <Box mb={2}>
                  <Typography>
                    <Typography variant="caption" className="font-weight-bold">
                      DATE:
                    </Typography>
                    {Notify.createdAt(new Date().toLocaleString())}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>

          <CardContent>
            {orderDetails.ready ? (
              orderDetails.data.length > 0 ? (
                orderDetails.data.map((data, index) => (
                  <Fragment key={index}>
                    <Box>
                      <TextField
                        margin="dense"
                        fullWidth
                        name="order_id[]"
                        label="order_id"
                        hidden
                        value={data.order_id}
                      />

                      <TextField
                        margin="dense"
                        fullWidth
                        name="patient_id"
                        label="Result"
                        hidden
                        value={data.patient_id}
                      />

                      <TextField
                        margin="dense"
                        fullWidth
                        name="doctors_id"
                        label="doctor_id"
                        hidden
                        value={data.doctor_id === null ? "" : data.doctor_id}
                      />
                    </Box>

                    {!Boolean(parseInt(data.cbc)) && (
                      <Box>
                        <Box display="flex" my={2}>
                          <Box flexGrow={1}>
                            <Typography variant="body1">
                              <b> HEMATOLOGY CBC </b>
                            </Typography>
                          </Box>

                          <Box>
                            <Button
                              disabled={Boolean(parseInt(data.is_processed))}
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setProcessDialog({
                                  data: data,
                                  dialog: true,
                                });
                              }}
                            >
                              Process
                            </Button>
                          </Box>
                        </Box>

                        <Box hidden={!Boolean(parseInt(data.is_processed))}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="WBC"
                                  margin="dense"
                                  fullWidth
                                  name="wbc[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="LYM"
                                  margin="dense"
                                  fullWidth
                                  name="lym[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MID"
                                  margin="dense"
                                  fullWidth
                                  name="mid[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="NEUT"
                                  margin="dense"
                                  fullWidth
                                  name="neut[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="RBC"
                                  margin="dense"
                                  fullWidth
                                  name="rbc[]"
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="HGB"
                                  margin="dense"
                                  fullWidth
                                  name="hgb[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="HTC"
                                  margin="dense"
                                  fullWidth
                                  name="hct[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MCV"
                                  margin="dense"
                                  fullWidth
                                  name="mcv[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MCH"
                                  margin="dense"
                                  fullWidth
                                  name="mch[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MCHC"
                                  margin="dense"
                                  fullWidth
                                  name="mchc[]"
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="RDW-SD"
                                  margin="dense"
                                  fullWidth
                                  name="rdw_sd[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="RDW-CV"
                                  margin="dense"
                                  fullWidth
                                  name="rdw_cv[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MPV"
                                  margin="dense"
                                  fullWidth
                                  name="mpv[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="PDW"
                                  margin="dense"
                                  fullWidth
                                  name="pdw[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="PCT"
                                  margin="dense"
                                  fullWidth
                                  name="pct[]"
                                />
                              </Box>
                            </Grid>
                          </Grid>

                          <Box mt={2}>
                            <TextField
                              variant="standard"
                              label="P-LCR"
                              margin="dense"
                              fullWidth
                              name="p_lcr[]"
                            />
                          </Box>

                          <Box mt={2}>
                            <TextField
                              variant="standard"
                              label="Remarks"
                              margin="dense"
                              fullWidth
                              name="remarks[]"
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {!Boolean(parseInt(data.cbc_platelet)) && (
                      <Box my={3}>
                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Typography variant="body1">
                              <b> HEMATOLOGY CBC WITH PLATELET </b>
                            </Typography>
                          </Box>

                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={Boolean(parseInt(data.is_processed))}
                              onClick={() => handleProcessSelectedOrder(data)}
                            >
                              Process
                            </Button>
                          </Box>
                        </Box>

                        <Box hidden={!Boolean(parseInt(data.is_processed))}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="WBC"
                                  margin="dense"
                                  fullWidth
                                  name="wbc[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="LYM"
                                  margin="dense"
                                  fullWidth
                                  name="lym[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MID"
                                  margin="dense"
                                  fullWidth
                                  name="mid[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="NEUT"
                                  margin="dense"
                                  fullWidth
                                  name="neut[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="RBC"
                                  margin="dense"
                                  fullWidth
                                  name="rbc[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="HGB"
                                  margin="dense"
                                  fullWidth
                                  name="hgb[]"
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="HTC"
                                  margin="dense"
                                  fullWidth
                                  name="hct[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MCV"
                                  margin="dense"
                                  fullWidth
                                  name="mcv[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MCH"
                                  margin="dense"
                                  fullWidth
                                  name="mch[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MCHC"
                                  margin="dense"
                                  fullWidth
                                  name="mchc[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="RDW-SD"
                                  margin="dense"
                                  fullWidth
                                  name="rdw_sd[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="RDW-CV"
                                  margin="dense"
                                  fullWidth
                                  name="rdw_cv[]"
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="MPV"
                                  margin="dense"
                                  fullWidth
                                  name="mpv[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="PDW"
                                  margin="dense"
                                  fullWidth
                                  name="pdw[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="PCT"
                                  margin="dense"
                                  fullWidth
                                  name="pct[]"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  variant="standard"
                                  label="PLT"
                                  margin="dense"
                                  fullWidth
                                  name="plt[]"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  variant="standard"
                                  label="P-LCR"
                                  margin="dense"
                                  fullWidth
                                  name="p_lcr[]"
                                />
                              </Box>
                            </Grid>
                          </Grid>

                          <Box mt={2}>
                            <TextField
                              variant="standard"
                              label="Remarks"
                              margin="dense"
                              fullWidth
                              name="remarks[]"
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Fragment>
                ))
              ) : (
                <Typography color="secondary" variant="subtitle2">
                  No record found.
                </Typography>
              )
            ) : (
              <Typography color="primary" variant="subtitle2">
                please wait...
              </Typography>
            )}
          </CardContent>

          <Box display="flex" m={1}>
            <Box flexGrow={1} />
            <Box>
              <CardActions>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  hidden
                  ref={saveButtonRef}
                  disabled={!confirmDialog}
                >
                  Save Result Button
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => setConfirmDialog(true)}
                  disabled={savebtnDisabled}
                >
                  Save Result
                </Button>
              </CardActions>
            </Box>
          </Box>
        </Card>
      </form>

      {/* set as pending dialog */}
      <Dialog
        open={pendingDialog}
        onClose={() => setPendingDialog(false)}
        disableBackdropClick
        PaperComponent={DraggableDialog}
      >
        <DialogTitle id="draggable-handle">Set as pending</DialogTitle>
        <form onSubmit={handlePendingOrder}>
          <DialogContent dividers>
            <Box mb={2}>
              <TextField
                rows={5}
                fullWidth
                name="reason"
                label={`Pending Reason`}
                variant="standard"
                margin="dense"
                multiline
              />
            </Box>

            <Box>
              <TextField
                fullWidth
                name="password"
                label={`Password`}
                variant="standard"
                margin="dense"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setPendingDialog(false)}
              startIcon={<HighlightOffIcon />}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={pendingSubmitting}
              startIcon={
                pendingSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <ErrorOutlineIcon />
                )
              }
            >
              pending
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* set as process dialog */}
      <Dialog
        open={processDialog.dialog}
        onClose={() => setProcessDialog({ dialog: false, data: null })}
        disableBackdropClick
        PaperComponent={DraggableDialog}
      >
        <DialogTitle id="draggable-handle">Set as processing</DialogTitle>
        <form onSubmit={handleProcessOrder}>
          <DialogContent dividers>
            <Box>
              <TextField
                fullWidth
                name="password"
                label={`Password`}
                variant="outlined"
                type="password"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => setProcessDialog({ dialog: false, data: null })}
              startIcon={<HighlightOffIcon />}
            >
              NO
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={processSubmitting}
              startIcon={
                processSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckCircleIcon />
                )
              }
            >
              process
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* confirmation dialog */}
      <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogContent>
          <Typography>Are you sure to continue and save result?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            startIcon={<XCircle />}
            onClick={() => setConfirmDialog(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={resultSubmitting}
            startIcon={
              resultSubmitting ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <CheckSquare />
              )
            }
            onClick={() => saveButtonRef.current.click()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CBCOrderDetails;
