import React, { useEffect, useState } from "react";
import Axios from "axios";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Notify from "src/notification/Notify";
import { Paper } from "@material-ui/core";

const imageLocation = "https://globaltelemedicinecorp.net/api/v1/images/";

const PatientDetails = ({ patient_id }) => {
  const token = localStorage.getItem("token");
  const [info, setInfo] = useState({
    data: null,
    ready: false,
  });

  const getPatientInformation = () => {
    var formdata = new FormData();
    formdata.set("token", token);
    formdata.set("patient_id", patient_id);
    formdata.set("connection", "online");

    Axios.post("encoder/online/patient/patient-information", formdata)
      .then((response) => {
        const data = response.data;
        setInfo({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getPatientInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <>
      <Box component={Paper} borderRadius={4} p={2}>
        {info.ready ? (
          info.data ? (
            <div className="clearfix">
              <div className="float-left">
                <Avatar
                  style={{
                    width: "120px",
                    height: "120px",
                  }}
                  src={
                    info.data.image !== null
                      ? imageLocation + "patients/" + info.data.image
                      : ""
                  }
                />
              </div>
              <div className="ml-sm-4 float-left">
                <p className="h5 header-label text-capitalize m-0 my-2 font-weight-bold">
                  {info.data.lastname}, {info.data.firstname} {info.data.middle}
                </p>
                <p className="m-0 my-2">
                  <span className="text-muted gtc-small text-uppercase">
                    Age:
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {Notify.calculateAge(info.data.birthday)}
                </p>
                <p className="m-0 my-2">
                  <span className="text-muted gtc-small text-uppercase">
                    Gender:
                  </span>
                  &nbsp; {info.data.gender}
                </p>
                <p className="m-0 my-2">
                  <span className="text-muted gtc-small text-uppercase">
                    Birthday:
                  </span>
                  {Notify.birthday(info.data.birthday)}
                </p>
                <p className="m-0 my-2">
                  <span className="text-muted gtc-small text-uppercase">
                    Address:
                  </span>
                  {`${info.data.street} ${info.data.barangay} ${info.data.city}`}
                </p>
              </div>
            </div>
          ) : (
            Notify.noRecord()
          )
        ) : (
          Notify.loading()
        )}
      </Box>
    </>
  );
};

export default PatientDetails;
