import React, { useState } from "react";
import { Box, TextField, Button, CircularProgress } from "@material-ui/core";
import ReactQuill from "react-quill";
import Diagnosis from "../../utils/Diagnosis";
import Axios from "axios";
import Notify from "../../notification/Notify";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Keyboard } from "@material-ui/icons";
import CreatableSelect from "react-select/creatable";
import { grey } from "@material-ui/core/colors";

const HealthDiagnosisForm = (props) => {
  const [token] = useState(localStorage.getItem("token"));
  const [user_id] = useState(localStorage.getItem("user_id"));
  const [username] = useState(localStorage.getItem("username"));

  const [isProcess, setIsProcess] = useState(false);
  const [isSearchable, setIsSearchable] = useState(false);

  const [diagnosisRemarks, setDiagnosisRemarks] = useState("");

  const handleNewDiagnosis = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("token", token);
    formdata.set("user_id", user_id);
    formdata.set("username", username);
    formdata.set("patient_id", props.patient_id);
    formdata.set("connection", props.connection);
    formdata.set(
      "diagnosis_remarks",
      diagnosisRemarks.replace("<p><br></p>", "")
    );

    var error = [];
    if (
      formdata.get("diagnosis[]").length === 0 ||
      formdata.get("diagnosis[]").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("diagnosis");
    }
    if (
      formdata.get("diagnosis_remarks").length === 0 ||
      formdata.get("diagnosis_remarks").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("remarks");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }

    if (error.length > 0) {
      console.warn("Form has an error. Please check.");
    } else {
      setIsProcess(true);
      Axios.post("doctor/patient/diagnosis/newdiagnosis", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "success") {
            props.closeForm();
            props.getDiagnosis();
            e.target.reset();
            setDiagnosisRemarks("");
            Notify.successRequest("diagnosis");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setIsProcess(false);
        });
    }
  };

  return (
    <form onSubmit={handleNewDiagnosis} className="d-newdiagnosis-form">
      <Box>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="caption" color="textSecondary">
              {" "}
              SELECT DIAGNOSIS{" "}
            </Typography>
          </Box>

          <Box>
            <IconButton
              onClick={() => {
                setIsSearchable(!isSearchable);
              }}
              size="small"
              color={isSearchable ? "primary" : "default"}
            >
              <Keyboard />
            </IconButton>
          </Box>
        </Box>

        <CreatableSelect
          options={Diagnosis.list()}
          isMulti
          name="diagnosis[]"
          placeholder="Diagnosis"
          isSearchable={isSearchable}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
              color: "black",
            }),
            control: (base) => ({
              ...base,
              minHeight: 55,
            }),
          }}
        />

        {/* <Select
                    options ={Diagnosis.list()}
                    placeholder="Diagnosis"
                    name="diagnosis[]"
                    isMulti={true} 
                    isSearchable={ isSearchable }  
                    styles={{ 
                        menu: provided => ({ 
                            ...provided, zIndex: 9999, 
                            color: 'black'
                        }),
                        control: (base) =>({
                            ...base,
                            minHeight: 55
                        })
                    }}
                /> */}
      </Box>

      <Box mt={2}>
        <ReactQuill
          name="diagnosis_remarks"
          theme="snow"
          value={diagnosisRemarks}
          onChange={(e) => setDiagnosisRemarks(e)}
          placeholder="Enter you remarks here."
          style={{
            backgroundColor: grey[50],
            color: "#000",
          }}
        />
      </Box>
      <Box mt={2}>
        <TextField
          fullWidth
          label="Enter Password"
          name="password"
          type="password"
          variant="outlined"
        />
      </Box>
      <Box display="flex" mt={1} mb={3}>
        <Button
          startIcon={
            isProcess ? (
              <CircularProgress color="inherit" size={15} />
            ) : (
              <CheckCircleOutline />
            )
          }
          disabled={isProcess}
          color="primary"
          variant="contained"
          type="submit"
        >
          Save
        </Button>
        <Box ml={2}>
          <Button
            onClick={() => props.closeForm()}
            startIcon={<HighlightOffIcon />}
            color="inherit"
            variant="contained"
          >
            No
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default HealthDiagnosisForm;
